<ng-container *ngIf="item">
  <div class="flexbox position-relative vh-100">
    <div class="container vh-100">
      <app-builder-mode-header class="header" [Item]="item">
      </app-builder-mode-header>

      <app-item-builder-mode-selection class="w-100 selection" [modifiers]="modifiers" [variations]="variations"
        [isCombo]="item.IsCombo" [numInvisibleGroups]='numInvisibleGroups' [comboGroups]="comboGroup"
        (emitComboGroup)="setSelectedComboGroup($event)" (emitSelectedModifier)="setSelectedModifiers($event)"
        (emitSelectedVariation)="setSelectedVariations($event)" (setVariationsValid)="setVariationsValid($event)"
        [ItemName]="item.Name == undefined ? '' : item.Name" [ItemDescription]="(item.FullDescription && item.FullDescription != '') ? item.FullDescription : 
        (item.ShortDescription && item.ShortDescription != '') ? item.ShortDescription : ''" [ItemId]="item.ItemID"
        [ItemId]="item.ItemID" [parentQuantity]="quantity">
      </app-item-builder-mode-selection>

      <app-item-footer class="footer position-fixed bottom-0" [isReady]="isReady" [isComboItem]="isComboItem"
        [modifiers]="_selectedModifiers" [variations]="_selectedVariations" [comboGroup]="_selectedComboGroup"
        [itemID]="item.ItemID" [categoryID]="category.CategoryID" [isModifiersValid]="isModifiersValid"
        [isVariationsValid]="isVariationsValid" [isComboGroupValid]="isComboGroupValid" [inputItem]="item"
        [modifierContainer]="modifierContainer" [comboGroupContainer]="comboGroupContainer"
        [builderMode]="builderModeSelection" [quantityWhenAdd]="quantity" [cartQuantity]="cartQuantity"
        (comboModelClose)="closeModel($event)" (quantityChange)="headerQuantityChange($event)">
      </app-item-footer>
    </div>
  </div>
</ng-container>

<app-timeout style="display: none" [hidden]="true"></app-timeout>