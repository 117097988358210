import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { LoginGuardService } from './login-guard.service';
import { NetworkGuardService } from './network-guard.service';
@Injectable({
  providedIn: 'root',
})
export class MasterGuardService {
  constructor(
    private network: NetworkGuardService,
    private login: LoginGuardService,
    private router: Router
  ) {}

  waitAndTest(counter: number) {
    return new Promise<boolean>((res, rej) => {
      if (counter >= 5) {
        res(false);
        return;
      }
      let times=[1,3,5,10,15]
      setTimeout(() => {
        this.network.ping().then(async (online: boolean) => {
          if (online) {
            res(true);
          } else {
            counter = counter + 1;
            this.waitAndTest(counter).then((data) => {
              res(data);
            });
          }
        });
      }, times[counter]*1000);
    });
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise((resolve, reject) => {
      this.waitAndTest(0).then(
        async (online: boolean) => {
          if (online) {
            let fresh = await this.login.checkDatabase();
            if (fresh) {
              resolve(true);
            } else {
              this.login.redirect();
            }
          } else {
            this.network.redirect();
          }
        }
      );
    });
  }
}
