import { Injectable } from '@angular/core';
import { GeneralSetting } from './generalSetting.service';
import { loggingData, LogService } from 'src/app/services/log.service';
import { TimeoutError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CartService } from './cart.service';
import { textChangeRangeIsUnchanged } from 'typescript';

@Injectable({
  providedIn: 'root'
})
// prettier-ignore
export class CloverService {
  data: any;
  cloverPaymentId: string = '';
  cloverOrderId: string = '';
  cloverDeductedAmount: number = 0.0;
  cloverChargeAmount: number = 0.0;
  cloverCardType: number = 0;
  isCloverPaymentCompleted: boolean = false;
  isPartialPayment: boolean = false;
  errorModelText = 'Payment failed. Please try again';

  cloverNodeJsUrl: string;
  constructor(private readonly logger: LogService) {
    // environment setting
    this.cloverNodeJsUrl = environment.cloverNodeJsUrl;
  }

  private getCloverApiBaseUrl(): string {
    if (GeneralSetting.getCloverEnvironment().toString() == 'Staging') {
      return 'https://sandbox.dev.clover.com';
    } else {
      //if (GeneralSetting.getCloverEnvironment().toString() == 'Production') {
      return 'https://www.clover.com';
    }
  }
  public isCloverConfigured(): boolean {
    if (!GeneralSetting.getMerchantId()) return false;

    return true;
  }

  public isLinkedToDevice(): boolean {
    if (!GeneralSetting.getDeviceHSN()) return false;

    return true;
  }

  public ping() {
    return new Promise((res, rej) => {
      var cloverPingURL = environment.cloverNodeJsUrl + '/ping';
      fetch(cloverPingURL, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
          'Accept-Encoding': 'gzip, deflate, br',
          Connection: 'keep-alive',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          baseURL: this.getCloverApiBaseUrl(),
          applicationId: GeneralSetting.getApplicationId().toString(),
          deviceId: GeneralSetting.getDeviceHSN().toString(),
          merchantId: GeneralSetting.getMerchantId().toString(),
          accessToken: GeneralSetting.getAccessToken().toString(),
          friendlyId: GeneralSetting.getFriendlyId().toString(),
          isPing: true,
          branchId: GeneralSetting.getBranchId(),
          companyId: GeneralSetting.getCompanyId(),
          serialNo: GeneralSetting.getSerialNo(),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          res(data);
        })
        .catch((e) => {
          rej(e);
        });
    });
  }
  public getCloverValidation() {
    if (
      GeneralSetting.getDeviceHSN() == '' ||
      GeneralSetting.getApplicationId() == '' ||
      GeneralSetting.getMerchantId() == '' ||
      GeneralSetting.getAccessToken() == ''
    ) {
      return false;
    } else {
      return true;
    }
  }

  private sendExceptionToServer(location: any, exception: any) {
    const errorLog = new loggingData(
      'Clover Error',
      'Clover failed in: ' + location,
      'clover.service.ts',
      '\n' + 'Error in: ' + location + '\n' + 'Exception: ' + exception,
      true
    );

    this.logger.sendLogToServer(errorLog);
  }
  private sendDebugToServer(location: any, debug: any) {
    const errorLog = new loggingData(
      'Clover Log',
      'Debug Location: ' + location,
      'clover.service.ts',
      '\n' + 'Location: ' + location + '\n' + 'Debug: ' + JSON.stringify(debug),
      true
    );

    this.logger.sendLogToServer(errorLog);
  }

  private setCloverCardType(resultPaymentCardTransactionCardType: any, cardlabel: string) {
    // set card type (e.g. 0=None, 1=Visa, 2=Master, 3=Amex, 4=Discover, 5=Diners Club, 6=China UnionPay, 7=JCB, 8=Laser, 9=Maestro, 10=Dankort)
    switch (resultPaymentCardTransactionCardType) {
      case 'VISA': {
        this.cloverCardType = 1;
        break;
      }
      case 'MC': {
        this.cloverCardType = 2;
        break;
      }
      case 'AMEX': {
        this.cloverCardType = 3;
        break;
      }
      case 'DISCOVER': {
        this.cloverCardType = 4;
        break;
      }
      case 'DINERS_CLUB': {
        this.cloverCardType = 5;
        break;
      }
      case 'CHINA_UNION_PAY': {
        this.cloverCardType = 6;
        break;
      }
      case 'JCB': {
        this.cloverCardType = 7;
        break;
      }
      case 'LASER': {
        this.cloverCardType = 8;
        break;
      }
      case 'MAESTRO': {
        this.cloverCardType = 9;
        break;
      }
      default: {
        this.cloverCardType = 0;
        break;
      }
    }
    const errorLog = new loggingData(
      `Payment device : Card type: ${cardlabel}, sub-type: ${resultPaymentCardTransactionCardType}`,
      `Payment device : Card type: ${cardlabel}, sub-type: ${resultPaymentCardTransactionCardType} in: ${location}`,
      'clover.service.ts',
      `Payment device : Card type: ${cardlabel}, sub-type: ${resultPaymentCardTransactionCardType}`,
      true
    );
    this.logger.sendLogToServer(errorLog);
  }

  public async createCloverCloudPaySaleRequest(
    cloverChargeAmount: any,
    cartService: CartService,
    tax: any
  ): Promise<boolean> {
    if (this.cloverPaymentId == '' || this.cloverPaymentId == undefined) {
      this.cloverPaymentId = 'InitialPayment';
    }
    if (this.cloverOrderId == '' || this.cloverOrderId == undefined) {
      this.cloverOrderId = 'InitialOrder';
    }

    // samsung kiosk doesn't have proper version of npm packages and not available to use abort controller
    // const controller = new AbortController()
    // const timeoutId = setTimeout(() => controller.abort(), 180000);
    try {
      this.isCloverPaymentCompleted = false;
      this.isPartialPayment = false;

      var adjustedChargeAmount = Math.round(
        (cloverChargeAmount - cartService.discount) * 100
      );
      var taxInCents = Math.round(tax * 100);
      var tipInCents = Math.round(cartService.tip * 100);

      var requestBody = // Dynamic Sale Request
        JSON.stringify({
          baseURL: this.getCloverApiBaseUrl(),
          applicationId: GeneralSetting.getApplicationId().toString(),
          deviceId: GeneralSetting.getDeviceHSN().toString(),
          merchantId: GeneralSetting.getMerchantId().toString(),
          accessToken: GeneralSetting.getAccessToken().toString(),
          friendlyId: GeneralSetting.getFriendlyId().toString(),
          saleamt: adjustedChargeAmount,
          tipAmt: tipInCents,
          taxAmt: taxInCents,
          IsSale: true,
          IsPartialRefund: false,
          IsFullRefund: false,
          isVoid: false,
          paymentId: null,
          orderId: null,
          refundAmt: null,
          cancelreason: null,
          branchId: GeneralSetting.getBranchId().toString(),
          companyId: GeneralSetting.getCompanyId().toString(),
          serialNo: GeneralSetting.getSerialNo().toString(),
        });

      // Reset these on a new Sale request
      this.cloverOrderId = '';
      this.cloverPaymentId = '';

      let response = await fetch(this.cloverNodeJsUrl + '/cloverCloudPayV3', {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
          'Accept-Encoding': 'gzip, deflate, br',
          Connection: 'keep-alive',
          'Access-Control-Allow-Origin': '*',
        },

        // Dynamic Sale Request
        body: requestBody,
      });

      let result = await response.json();
      GeneralSetting.setPaymentResponse(JSON.stringify(result));
      if (result.debug) {
        this.sendDebugToServer(
          'createCloverCloudPaySaleRequest() > fetch > status == 200',
          result.debug
        );
      }

      // Handle any failures
      if (result.success === false || response.status != 200) {

        this.errorModelText =
          result.message ?? 'Unable to process payment. Please try again.';

        // Firehose
        this.sendCloverRequestErrorLog(
          'Clover Error Type ' + result.result,
          'Reason:' + result.reason + ' Message:' + result.message,
          requestBody,
          result // <- lastDeviceActivityStart or lastDeviceActivityEnd should help diagnose any failures
        );

        return false;
      }

      if (result.payment.cardTransaction && typeof result.payment.cardTransaction.cardType !== 'undefined') {
        let cardlabel = result?.payment?.tender?.label || null
        this.setCloverCardType(result.payment.cardTransaction.cardType, cardlabel);
      } else if(result.payment.tender.label.toLowerCase() === 'gift cards') {
        this.cloverCardType  = 10; // Gift Cards
      }

      this.cloverPaymentId = result.payment.id;
      this.cloverOrderId = result.payment.order.id;
      this.cloverDeductedAmount =
        this.cloverChargeAmount -
        (result.payment.amount * 0.01 - result.payment.taxAmount * 0.01);

      // Firehose
      var amountToTestFromOldCode = Number(
        (
          result.payment.amount * 0.01 -
          result.payment.taxAmount * 0.01
        ).toFixed(2)
      );

      if (cloverChargeAmount > amountToTestFromOldCode) {
        // Send over a log that our old calc would have been wrong
        this.sendCloverRequestErrorLog(
          'Clover Error Type 4500 - Old Calculation is wrong, check totals',
          `cloverChargeAmount: ${cloverChargeAmount} vs. amountToTestFromOldCode: ${amountToTestFromOldCode}`,
          requestBody,
          result
        );
      }

      var totalChargeAmountExpectedToBeCharged = Math.round(
        adjustedChargeAmount + taxInCents + tipInCents - cartService.discount
      );

      var totalAmountReceivedAsPayment = Math.round(
        result.payment.amount + result.payment.taxAmount + result.payment.tipAmount
      );

      if (totalChargeAmountExpectedToBeCharged > totalAmountReceivedAsPayment) {
        this.errorModelText =
          'The card you used did not have enough funds to complete this transaction. A refund has been issued for the paid amount. Please try again with a different card.';
        // Firehose
        this.sendCloverRequestErrorLog(
          'Clover Error Type 4400 - Total Charged and Total Received did not match',
          `expectedChargeAmount: ${totalChargeAmountExpectedToBeCharged} vs. amountPaid: ${totalAmountReceivedAsPayment}`,
          requestBody,
          result
        );

        this.isPartialPayment = true;
        return false;
      }

      // Clover Cloud Pay Completed
      this.isCloverPaymentCompleted = true;
      return true;

    } catch (exception) {
      this.sendExceptionToServer(
        'createCloverCloudPaySaleRequest() > fetch > catch',
        exception
      );
    }

    return false;
  }

  public createCloverCloudPayRefundRequest(
    cloverPaymentId: String,
    cloverOrderId: String
  ) {
    var fetchBody = JSON.stringify({
      baseURL: this.getCloverApiBaseUrl(),
      applicationId: GeneralSetting.getApplicationId().toString(),
      deviceId: GeneralSetting.getDeviceHSN().toString(),
      merchantId: GeneralSetting.getMerchantId().toString(),
      accessToken: GeneralSetting.getAccessToken().toString(),
      friendlyId: GeneralSetting.getFriendlyId().toString(),
      saleamt: 0,
      tipAmt: 0,
      taxAmt: 0,
      IsSale: false,
      IsPartialRefund: false,
      IsFullRefund: true,
      isVoid: false,
      paymentId: cloverPaymentId,
      orderId: cloverOrderId,
      refundAmt: null,
      cancelreason: null,
    });

    var url = this.cloverNodeJsUrl + '/cloverCloudPayV3';
    fetch(url, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
        'Accept-Encoding': 'gzip, deflate, br',
        Connection: 'keep-alive',
        'Access-Control-Allow-Origin': '*',
      },
      body: fetchBody,
    })
      .then(this.manageErrors)
      .catch((error) => {
        // send refund error to grubbrr server
        this.sendCloverRequestErrorLog(
          'Clover Refund Error',
          '',
          fetchBody,
          error
        );
      });
  }

  private sendCloverRequestErrorLog(
    title: string,
    desc: string,
    request: any,
    response: any
  ) {
    let errorLog = new loggingData(
      title,
      desc,
      'Clover API Failure',
      `Request: ${JSON.stringify(request)} ||| Response: ${JSON.stringify(
        response
      )}`,
      true
    );

    this.logger.sendLogToServer(errorLog);
  }

  private manageErrors(response: any) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }
}
