import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { environment } from '../../../../environments/environment';
import { fadeInGrow } from '../../../animations';
import { OrderReviewPromotionsComponent } from 'src/app/components/order-review/order-review-promotions/order-review-promotions.component';
import { Subscription } from 'rxjs';
import { OLOService } from 'src/app/services/olo.service';
import { GeneralSetting } from '../../../services/generalSetting.service';
import { ItemV2 } from 'src/app/models/item';
import { LoyaltyService } from 'src/app/services/loyalty.service';

@Component({
  selector: 'app-order-review-list',
  templateUrl: './order-review-list.component.html',
  styleUrls: ['./order-review-list.component.css'],
  animations: [fadeInGrow],
})
export class OrderReviewListComponent implements OnInit {

  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  cartItems: ItemV2[] = [];

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  showCalorie: any;

  @Input() sibling: any;

  @Input() promo!: OrderReviewPromotionsComponent;
  quantity: number = 1;

  @Input() hasPromos: boolean = false;

  @Input() loyaltyPromos: any;

  @Input() hasPromosCoupon: boolean = false;

  uniqueConcessionaire: any[] = [];

  isConcessionaire: boolean = false;

  isOLO: boolean = false;

  isBottom: boolean = false;

  checkScrollCheck: boolean = false;

  useLoyalty: boolean = false;

  @ViewChild('cartitemContainerDiv') itemContainerDiv!: ElementRef;

  isLoyalty: boolean = false;

  constructor(
    private readonly cartService: CartService,
    private readonly isKioskService: IsKioskService,
    private readonly olo: OLOService,
    private readonly loyaltyService: LoyaltyService,
  ) { }

  ngOnInit(): void {
    this.showCalorie = GeneralSetting.getShowCalorieCounts();
    this.isConcessionaire = GeneralSetting.getIsConcessionaire().toLowerCase() == "true";
    this.isOLO = this.olo.isOLO;
    this.useLoyalty = GeneralSetting.getUseLoyaltyKiosk() == "True"
    this.isLoyalty = this.loyaltyService.isLoyalty;
    this.getCartItems();


  }

  ngAfterViewInit(): void {
    this.checkScrollCheck = this.checkScroll();
  }

  getCartItems() {
    this.allSubsCription.push(
      this.cartService.subCartItems.subscribe((cartItems) => {
        if (cartItems.length > 0) {
          if (this.isConcessionaire) {
            var allConcessionaire: any[] = [];

            cartItems.forEach(function (x) {
              allConcessionaire.push({
                ConcessionaireId: x.ConcessionaireId,
                ConcessionaireName: x.ConcessionaireName,
                ConcessionaireColorCode: x.ConcessionaireColorCode
              })
            });

            //console.log("allConcessionaire :- ", allConcessionaire);

            let uniqueCon = [...new Map(allConcessionaire.map(item => [item["ConcessionaireId"], item])).values()];

            this.uniqueConcessionaire = uniqueCon;

            //console.log("uniqueCon :- ", uniqueCon);

          }

          this.cartItems = cartItems;
        }

      })
    );
  }


  /**
   * Scrolls down the view
   * @returns 
  */
  scrollDown() {
    if (!this.itemContainerDiv) {
      return;
    }

    let element = this.itemContainerDiv.nativeElement;
    const smoothScrollTo = (to: number, duration: number) => {
      const start = (element as HTMLElement).scrollTop,
        change = to - start,
        startDate = +new Date();

      // t = current time
      // b = start value
      // c = change in value
      // d = duration
      const easeInOutQuad = (t: any, b: any, c: any, d: any) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      const animateScroll = () => {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        (element as HTMLElement).scrollTop = parseInt(
          easeInOutQuad(currentTime, start, change, duration)
        );
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          (element as HTMLElement).scrollTop = to;
        }
        this.isBottom = true;
      };
      animateScroll();
    };
    // if (bottom) bottom.scrollIntoView({ behavior: 'smooth' });
    smoothScrollTo(element?.scrollHeight - element?.clientHeight, 300);
  }

  /**
   * 
   * @returns Scrolls up the view
  */
  scrollTop() {
    if (!this.itemContainerDiv) {
      return;
    }

    let element = this.itemContainerDiv.nativeElement;

    const smoothScrollTo = (to: number, duration: number) => {
      const start = (element as HTMLElement).scrollTop,
        change = to - start,
        startDate = +new Date();
      /**
       * @param {any} t current time
       * @param {any} b start value
       * @param {any} c change in value
       * @param {any} d duration
       */
      const easeInOutQuad = (t: any, b: any, c: any, d: any) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      const animateScroll = () => {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        (element as HTMLElement).scrollTop = parseInt(
          easeInOutQuad(currentTime, start, change, duration)
        );
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          (element as HTMLElement).scrollTop = to;
        }
        this.isBottom = false;
      };
      animateScroll();
    };
    // if (bottom) bottom.scrollIntoView({ behavior: 'smooth' });
    smoothScrollTo(0, 300);
  }

  /**
   * Deducts manual scroll position
   * @param event 
   */
  onScroll(event: any) {
    this.checkScrollCheck = this.checkScroll();
  }

  checkScroll() {
    if (!this.itemContainerDiv) {
      return false;
    }

    let element = this.itemContainerDiv.nativeElement;
    // //console.log('check scroll element', element);
    if (element) {
      if (
        element.scrollTop >=
        (element.scrollHeight - element.clientHeight) * 0.9
      ) {
        this.isBottom = true;
      } else if (
        element.scrollTop <=
        (element.scrollHeight - element.clientHeight) * 0.1
      ) {
        this.isBottom = false;
      } else {
        this.isBottom = false;
      }
      if (element) {
        if ((element.scrollHeight - element.clientHeight) > 0) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  setScrollArrowPosition(hasPromos: boolean, loyaltyPromos: any, hasPromosCoupon: boolean, isLoyalty : boolean) {
    if (hasPromos && loyaltyPromos) {
      return '40vh';
    }
    else if (hasPromos && hasPromosCoupon && !loyaltyPromos) {
      return '27vh';
    }
    else if (hasPromos && !loyaltyPromos) {
      return isLoyalty ? '28vh' : '23vh';
    }
    else if (!hasPromos && loyaltyPromos) {
      return '27vh';
    }
    else if (hasPromosCoupon && !loyaltyPromos) {
      return '18vh';
    }
    else if(isLoyalty){
      return '15vh';
    }
    else {
      return '11vh';
    }
  }
}
