import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs/';
import { DatabaseHandler } from 'src/app/DatabaseHandler';
import { CartService } from 'src/app/services/cart.service';
import { LoyaltyService } from 'src/app/services/loyalty.service';
import { UserService } from 'src/app/services/user.service';
import {
  ReferrizerLoyaltyRewardDetail,
  PunchhLoyaltyRewardDetail,
  OLOPaytronixLoyaltyRewardDetail,
  LoyaltyType,
} from 'src/app/models/loyaltyModal';
import { RedeemRewardComponent } from 'src/app/components/dialogs/redeem-reward/redeem-reward.component';
import { LogService, loggingData } from 'src/app/services/log.service';
import { PromoCodeComponent } from 'src/app/components/dialogs/promo-code/promo-code.component';
import { PaymentTypesService } from 'src/app/services/payment-types.service';
import { CodelessDiscount } from 'src/app/models/discounts';
import { DateService } from 'src/app/services/date.service';
import {
  CategorySalesType,
  CommonFunctions,
  Snackbar,
} from 'src/app/services/common';
import { PaymentService } from 'src/app/services/payment.service';
import { OrderReviewFooterComponent } from '../order-review-footer/order-review-footer.component';
import { MenuItemService } from 'src/app/services/menu-item.service';
import { ItemV2 } from 'src/app/models/item';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { DatePipe } from '@angular/common';
import { GeneralAlertComponent } from '../../dialogs/general-alert/general-alert.component';
import { DiscountConfirmationComponent } from '../../dialogs/discount-confirmation/discount-confirmation.component';
import { DatabaseService } from 'src/app/services/database.service';
import { Category } from 'src/app/models/category';
import { Router } from '@angular/router';
import { ItemTransferService } from 'src/app/services/item-transfer.service';
import { AlcoholicItemsExceedMaxComponent } from '../../dialogs/alcoholic-items-exceed-max/alcoholic-items-exceed-max.component';
import { AlcoholicOver21DialogComponent } from '../../dialogs/alcoholic-over21-dialog/alcoholic-over21-dialog.component';
import { OLOService } from 'src/app/services/olo.service';
import { OLOTotalLoaderComponent } from 'src/app/components/dialogs/olototal-loader/olototal-loader.component';
import { ALERT_TYPE, StringUtils } from 'src/app/resources/strings-en';
import { LanguageService } from 'src/app/services/language.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderreviewService } from '../orderreview.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { DiscountMaster } from 'src/app/models/BranchLoginModels';
import { DiscountService } from 'src/app/services/discount.service';
import { OloErrorPopupComponent } from '../../dialogs/olo-error-popup/olo-error-popup.component';

enum BogoDiscountType {
  FreeItem = '1',
  Percentage = '2',
}

enum BogoFlatOrPercentage {
  Percentage = '1',
  Flat = '2',
}

enum DiscountType {
  total = '1',
  subtotal = '2',
  category = '3',
  item = '4',
  cash = '5',
  card = '6',
}

@Component({
  selector: 'app-order-review-promotions',
  templateUrl: './order-review-promotions.component.html',
  styleUrls: ['./order-review-promotions.component.css'],
})
export class OrderReviewPromotionsComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  hasLoyaltyProgram: boolean = false;
  useLoyalty: boolean = false;

  @Input() subtotal: number = 0.0;

  @Input() total: number = 0.0;

  @Input() removed: boolean = false;

  @Input() inputTax: number = 0.0;

  @Input() orderReviewFooter!: OrderReviewFooterComponent;

  @Output() updateCalculation: EventEmitter<number> = new EventEmitter();

  @Output() hasPromos: EventEmitter<boolean> = new EventEmitter();

  @Output() hasPromosCoupon: EventEmitter<boolean> = new EventEmitter();

  @Output() hasPromosLoyalty: EventEmitter<boolean> = new EventEmitter();

  @Output() signInClicked: EventEmitter<boolean> = new EventEmitter();

  @ViewChild(RedeemRewardComponent)
  redeemRewardPopup!: RedeemRewardComponent;

  @ViewChild(PromoCodeComponent)
  promoPopUp!: PromoCodeComponent;

  categories: Category[] = [];
  promotionCode: string = '';
  itemTransferService: ItemTransferService;
  loyaltyRewards = [] as any[];

  isLoyalty = false;

  availablePoints = 0;

  items: any;

  bogos: any;

  allPromotions = [] as any;

  sneakBarShow: boolean = false;

  sneakBarMsg: string = '';

  displayedPromos: any;

  allPromos: any;

  selectedPromo: CodelessDiscount = {} as CodelessDiscount;

  selectedLoyalty!: any;

  selectedLoyaltyReward = '';

  selectedItemPromo: CodelessDiscount = {} as CodelessDiscount;

  selectedSubtotalPromo: CodelessDiscount = {} as CodelessDiscount;

  isOlo: boolean = false;

  loyaltyType: number = 0;

  isConcessionaire: boolean = false;

  isRemovePricingAndPayments = GeneralSetting.getIsRemovePricingAndPayments();
  languageSub!: Subscription;
  availableDiscountText: string = '';
  pointsEarnedTextA: string = '';
  pointsEarnedTextB: string = '';
  rewardsAccountText: string = '';
  signUpText: string = '';
  itemRewardText: string = '';
  singleItemRewardText: string = '';
  pointsText: string = '';
  usePromoText: string = '';
  bogoText: string = '';
  gotPromoText: string = '';
  positiveColor: string = '';
  insufficentPointsErrorText: string = '';
  errorModel!: DiscountConfirmationComponent;

  constructor(
    ItemTransferService: ItemTransferService,
    private router: Router,
    private readonly databaseService: DatabaseService,
    private readonly cartService: CartService,
    private readonly paymentService: PaymentService,
    private readonly loyaltyService: LoyaltyService,
    private readonly userService: UserService,
    private readonly logger: LogService,
    private readonly paymentTypeService: PaymentTypesService,
    private readonly dateService: DateService,
    private readonly menuItemService: MenuItemService,
    private datePipe: DatePipe,
    private KeyboardService: KeyboardService,
    private readonly olo: OLOService,
    private language: LanguageService,
    private readonly modalService: NgbModal,
    private readonly or: OrderreviewService,
    private discountService: DiscountService
  ) {
    this.itemTransferService = ItemTransferService;
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe(() => {
        this.loadText();
      })
    );
    this.loyaltyType = this.loyaltyService.loyaltyType;

    this.availablePoints =
      this.userService.referrizerLoyaltyUser?.loyaltyPointsCount || 0;

    this.allSubsCription.push(
      this.loyaltyService.redeemedRewardSub.subscribe((points: number) => {
        this.availablePoints = Number(this.availablePoints) - Number(points);
        this.userService.referrizerLoyaltyUser.loyaltyPointsCount =
          this.availablePoints;
      })
    );

    this.allSubsCription.push(
      this.loyaltyService.updateRewardSub.subscribe((points: number) => {
        this.availablePoints = Number(this.availablePoints) + Number(points);
        this.userService.referrizerLoyaltyUser.loyaltyPointsCount =
          this.availablePoints;
      })
    );

    this.allSubsCription.push(
      this.loyaltyService.setLoyaltyPoints.subscribe((points: number) => {
        this.availablePoints = Number(points);
        this.userService.referrizerLoyaltyUser.loyaltyPointsCount =
          this.availablePoints;
      })
    );
  }
  loadText() {
    this.availableDiscountText = this.language.getTextElement(
      'txt_available_discounts'
    );
    this.pointsEarnedTextA = this.language.getTextElement('msg_point_earned_a');
    this.pointsEarnedTextB = this.language.getTextElement('msg_point_earned_b');
    this.itemRewardText = this.language.getTextElement('reward_one_free_from');
    this.singleItemRewardText = this.language.getTextElement('reward_one_free');
    this.usePromoText = this.language.getTextElement('txt_use_promo_code');
    this.gotPromoText = this.language.getTextElement('txt_got_promo_code');
    this.pointsText = this.language.getTextElement('number_points');
    this.rewardsAccountText = this.language.getTextElement(
      'txt_rewards_account'
    );
    this.signUpText = this.language.getTextElement('lbl_sign_up');
    this.bogoText = this.language.getTextElement('txt_buy_one_get_one_free');
    this.insufficentPointsErrorText = this.language.getTextElement(
      'lbl_you_have_insufficient_loyalty_redeem_points'
    );
  }
  ngOnInit(): void {
    this.loadText();
    this.isLoyalty = this.userService.isUserLoggedIn;
    this.isOlo = this.olo.isOLO;
    this.categories = this.databaseService.categorySubject.value;
    this.positiveColor = GeneralSetting.getPositiveColor();
    GeneralSetting.setHPercentDiscount('False');
    this.getLoyaltyProgram().then((data: any) => {
      this.hasLoyaltyProgram = data;
      this.useLoyalty = GeneralSetting.getUseLoyaltyKiosk() == 'True';
    });

    //added back tracing on descrease quantity and
    //removed the discount if order amount is less then minorder amount @dolly
    this.allSubsCription.push(
      this.cartService.sub_subtotal.subscribe((data) => {
        this.subtotal = CommonFunctions.roundDigit(data, 2);
        // if discount on item selected
        if (Object.keys(this.selectedItemPromo).length !== 0) {
          this.cartService.cartItems.forEach((item) => {
            if (item.DiscountID == this.selectedItemPromo.DiscountID) {
              this.subtotal += item.DiscountAmount;
            }
          });
          if (this.subtotal < this.selectedItemPromo.MinOrderAmount) {
            let error = this.language
              .getTextElement('txt_min_order_amount_to_use_this_discount_is')
              .replace(
                '%d',
                GeneralSetting.getCurrencySymbol() +
                  this.selectedItemPromo.MinOrderAmount
              );
            Snackbar.show(error, 2000);
            this.removeItemLevelDiscount();
          }
        }
        // if discount on subtotal selected
        if (Object.keys(this.selectedSubtotalPromo).length !== 0) {
          if (this.subtotal < this.selectedSubtotalPromo.MinOrderAmount) {
            let error = this.language
              .getTextElement('txt_min_order_amount_to_use_this_discount_is')
              .replace(
                '%d',
                GeneralSetting.getCurrencySymbol() +
                  this.selectedSubtotalPromo.MinOrderAmount
              );
            Snackbar.show(error, 2000);
            this.removeOrderLevelDiscount();
          }
        }
      })
    );

    //added back tracing on descrease quantity and
    //removed the discount if order amount is less then minorder amount @dolly
    this.allSubsCription.push(
      this.cartService.sub_subtotal.subscribe((data) => {
        this.subtotal = CommonFunctions.roundDigit(data, 2);
        // if discount on item selected
        if (Object.keys(this.selectedItemPromo).length !== 0) {
          this.cartService.cartItems.forEach((item) => {
            if (item.DiscountID == this.selectedItemPromo.DiscountID) {
              this.subtotal += item.DiscountAmount;
            }
          });
          if (this.subtotal < this.selectedItemPromo.MinOrderAmount) {
            let error = this.language
              .getTextElement('txt_min_order_amount_to_use_this_discount_is')
              .replace(
                '%d',
                GeneralSetting.getCurrencySymbol() +
                  this.selectedItemPromo.MinOrderAmount
              );
            Snackbar.show(error, 2000);
            this.removeItemLevelDiscount();
          }
        }
        // if discount on subtotal selected
        if (Object.keys(this.selectedSubtotalPromo).length !== 0) {
          if (this.subtotal < this.selectedSubtotalPromo.MinOrderAmount) {
            let error = this.language
              .getTextElement('txt_min_order_amount_to_use_this_discount_is')
              .replace(
                '%d',
                GeneralSetting.getCurrencySymbol() +
                  this.selectedSubtotalPromo.MinOrderAmount
              );
            Snackbar.show(error, 2000);
            this.removeOrderLevelDiscount();
          }
        }
      })
    );

    if (this.loyaltyService.isLoyalty && this.userService.isUserLoggedIn) {
      if (this.loyaltyType == LoyaltyType.OLOPunchh) {
        this.allSubsCription.push(
          this.loyaltyService.subLoyaltyRewards.subscribe((data) => {
            this.loyaltyRewards = data;
          })
        );
        setTimeout(() => {
          this.or.OloTotalLoaderModel = OLOTotalLoaderComponent.open(
            this.modalService,
            false,
            true
          ).componentInstance;
        }, 0);
      } else if (this.loyaltyType == LoyaltyType.LevelUp) {
        //handled by order review footer
      } else if (this.loyaltyType == LoyaltyType.Referrizer) {
        setTimeout(() => {
          this.or.OloTotalLoaderModel = OLOTotalLoaderComponent.open(
            this.modalService,
            false,
            true
          ).componentInstance;
        }, 0);
      }
      this.loyaltyService.getLoyaltyRewards()!.then(async (data: any) => {
        if (this.loyaltyType == LoyaltyType.Referrizer) {
          await this.referrizerRewardProcessor(data);
          this.or.OloTotalLoaderModel?.clear();
        } else if (this.loyaltyType == LoyaltyType.LevelUp) {
          //need levelUp implementation
        } else if (this.loyaltyType == LoyaltyType.OLOPunchh) {
          if (data == null) {
            this.or.OloTotalLoaderModel?.clear();
            //user not signed in
          } else {
            await this.punchhRewardProcessor(data);
            this.or.OloTotalLoaderModel?.clear();
          }
        } else if (this.loyaltyType == LoyaltyType.OLOPaytronix) {
          await this.paytronixRewardProcessor(data);
        }
      });
    } else {
      this.isLoyalty = false;
    }

    this.items = this.cartService.getCartItems();

    this.codeLessPromotions().then((promos) => {
      this.displayedPromos = promos;
      this.hasPromos.emit(this.displayedPromos.length > 0);

      /**
       * Auto apply discount if it is already applied @nilesh
       */
      if (
        this.cartService.selectedItemPromo &&
        this.cartService.selectedItemPromo.DiscountID
      ) {
        this.selectedItemPromo = this.cartService.selectedItemPromo;
      }
      if (
        this.cartService.selectedSubtotalPromo &&
        this.cartService.selectedSubtotalPromo.DiscountID
      ) {
        this.selectDiscount(this.cartService.selectedSubtotalPromo, true);
      }
    });

    this.getPromotions().then((results: any) => {
      this.allPromotions = results;
      if (this.allPromotions && this.allPromotions.length > 0) {
        this.hasPromosCoupon.emit(true);
      }
    });

    this.AllValueSubscriber();
    this.updateCalculation.emit();

    this.isConcessionaire =
      GeneralSetting.getIsConcessionaire().toLowerCase() == 'true';
  }
  signIn() {
    this.signInClicked.emit(true);
  }

  private AllValueSubscriber() {
    this.allSubsCription.push(
      this.loyaltyService.subSelectedLoyaltyReward.subscribe((data) => {
        this.selectedLoyaltyReward = data;
        if (data == '') {
          if (this.loyaltyService.loyaltyType == LoyaltyType.Referrizer) {
            this.selectedLoyalty = {} as ReferrizerLoyaltyRewardDetail;
          } else if (this.loyaltyService.loyaltyType == LoyaltyType.OLOPunchh) {
            this.selectedLoyalty = {} as PunchhLoyaltyRewardDetail;
          } else if (
            this.loyaltyService.loyaltyType == LoyaltyType.OLOPaytronix
          ) {
            this.selectedLoyalty = {} as OLOPaytronixLoyaltyRewardDetail;
          }
        }
      })
    );
  }

  private async changePrices(element: any, removeReward: boolean = false) {
    if (Object.keys(element).length === 0) {
      let error = this.language.getTextElement('invalid_promo_code');
      Snackbar.show(error, 3000);

      return;
    }

    let newPrice: any[] = [];
    // calculate and display new prices
    if (element.DiscountTypeID == DiscountType.subtotal) {
      // Added discount limitation check api call @rekha
      // await this.discountService
      // .checkDisountLimitation(GeneralSetting.getCustomerId(), element.DiscountID)
      // .toPromise()
      // .then((data: any) => {
      // if (true) {
      this.selectedSubtotalPromo = element;
      this.cartService.promo = element;
      if (element.DiscountName == 'Assigned to Cash')
        this.paymentTypeService.isCashPromo = true;
      else if (element.DiscountName == 'Assigned to Card')
        this.paymentTypeService.isCardPromo = true;
      if (
        (element &&
          element.UsePercentage === 'False' &&
          element.DiscountAmount != '0.00') ||
        element.RegularOrBogoDiscount == '1'
      ) {
        if (element.DiscountTypeID == DiscountType.subtotal) {
          this.cartService.setPromotion(
            element.DiscountAmount,
            true,
            !element.CategoryName && !element.ItemName,
            element.ConcessionaireIds
          );
          newPrice = this.cartService.calculateDiscountByAmount(
            element.DiscountAmount,
            element.ConcessionaireIds
          );
        }
      } else if (element) {
        if (element.DiscountTypeID == DiscountType.subtotal) {
          this.cartService.setPromotion(
            element.DiscountPercentage,
            false,
            !element.ItemName && !element.CategoryName,
            element.ConcessionaireIds
          );
          newPrice = this.cartService.calculateDiscountByPercent(
            element.DiscountPercentage,
            element.ConcessionaireIds
          );
          this.allSubsCription.push(
            this.cartService.sub_total.subscribe((data) => {
              let total = CommonFunctions.roundDigit(data, 2);
              if (element.DiscountPercentage == 100 && total == 0) {
                GeneralSetting.setHPercentDiscount('True');
              } else {
                GeneralSetting.setHPercentDiscount('False');
              }
            })
          );
        }
      }

      let subTotal = this.cartService.calculateSubtotal();

      if (subTotal < newPrice[3]) {
        let error = this.language.getTextElement('invalid_promo_code');
        Snackbar.show(error, 3000);
        return;
      }
      this.paymentService.IsDiscount = true;

      this.paymentService.DiscountAmount = newPrice[3];

      this.paymentService.DiscountPer = element.DiscountPercentage;

      this.paymentService.DiscountTypeId = element.DiscountTypeID?.toString();

      this.paymentService.DiscountId = element.DiscountID?.toString();

      this.paymentService.PercentageOrFlatRate =
        element.UsePercentage == 'True' ? '2' : '1';
      this.paymentService.PercentageOrFlatRateVal =
        element.UsePercentage == 'True'
          ? element.DiscountPercentage
          : element.DiscountAmount;

      if (removeReward) {
        if (
          this.loyaltyType == LoyaltyType.Referrizer &&
          this.selectedLoyalty &&
          (this.selectedLoyalty.type == 'CASH_DISCOUNT' ||
            this.selectedLoyalty.type == 'PERCENT_DISCOUNT')
        )
          this.updateLoyaltyPoints();
      }
    }
    // else {
    //   this.discountErrorMsg(data.message);
    // }
    // });
    // }
    this.updateCalculation.emit();
  }

  openPromo() {
    var modalRef = this.promoPopUp.open();
    modalRef.componentInstance.doit();
    modalRef.result
      .then((result) => {
        if (result != 'canceled') {
          this.doPromotion(result);
        }
      })
      .finally(() => {
        this.KeyboardService.manual = false;
        modalRef.componentInstance.doit2();
        this.KeyboardService.KeyboardStatus = false;
      });
  }

  private doPromotion(promoCode: string) {
    // get active promotions

    // only allows one discount applied
    let isCouponMatch = false;
    for (let index = 0; index < this.allPromotions.length; index++) {
      const element = this.allPromotions[index];
      if (element.CouponCode.toLowerCase() == promoCode.toLowerCase()) {
        if (element.RegularOrBogoDiscount == '1') {
          if (
            this.selectedItemPromo.DiscountID != undefined &&
            element.DiscountID == this.selectedItemPromo.DiscountID
          ) {
            let error = this.language.getTextElement('promo_code_already_used');
            Snackbar.show(error, 2500);
          } else {
            this.selectDiscount(element, false);
          }
          isCouponMatch = true;
          break;
        } else if (this.isItemDiscountApply(element)) {
          if (
            this.selectedItemPromo.DiscountID != undefined &&
            element.DiscountID == this.selectedItemPromo.DiscountID
          ) {
            let error = this.language.getTextElement('promo_code_already_used');
            Snackbar.show(error, 2500);
          } else {
            this.selectDiscount(element, false);
          }
          isCouponMatch = true;
          break;
        } else if (element.DiscountTypeID == DiscountType.subtotal) {
          this.selectDiscount(element, false);
          isCouponMatch = true;
          break;
        }
      }
    }

    if (!isCouponMatch) {
      let error = this.language.getTextElement('invalid_promo_code');
      Snackbar.show(error, 2500);
    }
  }

  private codeLessPromotions() {
    return new Promise((resolve) => {
      const callback = async (tx: string, results: any) => {
        var data = await this.filterPromotions(results);
        this.allPromos = data;
        if (this.allPromos && this.allPromos.length > 0) {
          this.hasPromos.emit(true);
        }
        resolve(this.removeDuplicates(data, (item: any) => item.DiscountID));
      };

      const errorCallback = (tx: string, results: any) => {
        console.log('Coless discount error', results);
        // removed local query logs @nilesh
        resolve([]);
      };

      // const query = `SELECT * FROM DiscountMasters WHERE DiscountTypeID IN ('2','3','4','0')
      // AND  (RequiresCouponCode='False' OR RequiresCouponCode='false')
      // AND (IsShowOnKiosk = 'true' OR IsShowOnKiosk = 'True')
      // AND  (IsActive = 'true' OR IsActive = 'True')
      // AND  (StartDate <= '${this.datePipe.transform(new Date(), 'YYYY-MM-dd hh:mm:ss')}' OR StartDate = '' )
      // AND  (EndDate >='${this.datePipe.transform(new Date(), 'YYYY-MM-dd hh:mm:ss')}' OR EndDate = '' )
      // AND  BranchID ='${GeneralSetting.getBranchId()}' AND  CompanyID = '${GeneralSetting.getCompanyId()}' ORDER BY CreatedDate DESC;`;
      // console.log(query);

      // Removed this code because regular discount list affected. @nilesh
      //   (select Group_Concat(dcm.ConcessionaireID) from Discount_Concessionaire_Mappings as dcm where dcm.DiscountID = dm.DiscountID)
      //   as ConcessionaireIDs

      const query = `
        SELECT dm.*,
            (select Group_Concat(dcm.ConcessionaireID) from Discount_Concessionaire_Mappings as dcm where dcm.DiscountID = dm.DiscountID)
            as ConcessionaireIDs

          FROM DiscountMasters as dm

      WHERE dm.DiscountTypeID IN ('2','3','4','0')
      AND  (dm.RequiresCouponCode='False' OR dm.RequiresCouponCode='false')
      AND (dm.IsShowOnKiosk = 'true' OR dm.IsShowOnKiosk = 'True')
      AND  (dm.IsActive = 'true' OR dm.IsActive = 'True')
      AND  (StartDate <= '${this.datePipe.transform(
        new Date(),
        'yyyy-MM-dd HH:mm:ss'
      )}' OR StartDate = '' ) 
      AND  (EndDate >='${this.datePipe.transform(
        new Date(),
        'yyyy-MM-dd HH:mm:ss'
      )}' OR EndDate = '' ) 
      AND  dm.BranchID ='${GeneralSetting.getBranchId()}'
      AND  dm.CompanyID = '${GeneralSetting.getCompanyId()}' ORDER BY dm.CreatedDate DESC;`;
      DatabaseHandler.executeSqlStatement(query, [], callback, errorCallback);
    });
  }

  private getItemIDsByDiscount(data: CodelessDiscount): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let query = '';
      if (data.DiscountTypeID.toString() == DiscountType.item) {
        query = `SELECT  DIM.ItemID as ItemID from Discount_Item_Mappings as DIM 
      inner join DiscountMasters as DM on DM.DiscountID = DIM.DiscountID 
      where DIM.DiscountID ='${data.DiscountID}' AND DM.DiscountTypeID='${data.DiscountTypeID}'`;
      } else if (data.DiscountTypeID.toString() == DiscountType.category) {
        query = `SELECT I.ItemID as ItemID from Items as I 
      inner join Item_Category_Mappings as ICM on ICM.ItemID=I.ItemID 
      INNER JOIN CategoryMasters as CM on CM.CategoryID= ICM.CategoryID 
      INNER JOIN Discount_Category_Mappings as DIM on DIM.CategoryID = CM.CategoryID 
      inner join DiscountMasters as DM on DM.DiscountID = DIM.DiscountID 
      where  DIM.DiscountID ='${data.DiscountID}' AND DM.DiscountTypeID='${data.DiscountTypeID}'`;
      }

      const callback = (tx: string, results: any) => {
        var items = [];
        for (let index = 0; index < results.rows.length; index++) {
          const element = results.rows[index];
          items.push(element.ItemID);
        }
        // var data: string[] = Array.from(results.rows);
        // console.log(items);

        resolve(items);
      };

      const errorCallback = (tx: string, results: any) => {
        resolve([]);
      };
      DatabaseHandler.executeSqlStatement(query, [], callback, errorCallback);
    });
  }

  private filterPromotions(results: any): Promise<CodelessDiscount[]> {
    return new Promise<CodelessDiscount[]>(async (resolve, reject) => {
      const regex = /  /gm;
      const data: CodelessDiscount[] = [];

      let uniqueConList = [] as any[];

      if (GeneralSetting.getIsConcessionaire().toLowerCase() == 'true') {
        let cartItems = this.cartService.getCartItems();

        let allCartConIds = [] as any[];

        cartItems.forEach(function (x) {
          allCartConIds.push({ ConcessionaireId: x.ConcessionaireId });
        });

        uniqueConList = [
          ...new Map(
            allCartConIds.map((item) => [item['ConcessionaireId'], item])
          ).values(),
        ];
      }

      for (const discount of results.rows) {
        let itemIds = await this.getItemIDsByDiscount(discount);

        let concessionaireIds = [];

        if (discount.ConcessionaireIDs && discount.ConcessionaireIDs != '') {
          concessionaireIds = discount.ConcessionaireIDs.split(',');
        }

        let startDate = new Date(-8640000000000000);
        let endDate = new Date(8640000000000000);
        try {
          if (
            discount.StartDate &&
            discount.StartDate != undefined &&
            discount.StartDate.toString().trim() != ''
          ) {
            startDate = new Date(
              this.dateService.convertTime12to24(
                discount.StartDate.replace(regex, ' ')
              )
            );
          }

          if (
            discount.EndDate &&
            discount.EndDate != undefined &&
            discount.EndDate.toString().trim() != ''
          ) {
            endDate = new Date(
              this.dateService.convertTime12to24(
                discount.EndDate.replace(regex, ' ')
              )
            );
          }
        } catch (e) {
          console.log(discount.StartDate, discount.EndDate);
        }

        var item: CodelessDiscount = {
          CouponCode: discount.CouponCode,
          CategoryID: discount.CategoryID,
          CategoryName: discount.CategoryName,
          DiscountAmount: Number(discount.DiscountAmount),
          DiscountName: discount.DiscountName,
          DiscountID: Number(discount.DiscountID),
          DiscountPercentage: Number(discount.DiscountPercentage),
          DiscountTypeID: Number(discount.DiscountTypeID),
          RegularOrBogoDiscount: discount.RegularOrBogoDiscount,
          PurchaseOrGet: discount.PurchaseOrGet,
          BogoPurchaseQuantity: discount.BogoPurchaseQuantity,
          BogoAppliesTo: discount.BogoAppliesTo,
          BOGODiscountAmount: discount.BOGODiscountAmount,
          BogoDiscountType: discount.BogoDiscountType,
          BogoFlatOrPercentage: discount.BogoFlatOrPercentage,
          BogoGetQuantity: discount.BogoGetQuantity,
          ItemID: discount.ItemID,
          ItemName: discount.ItemName,
          Name: discount.Name,
          BogoDiscountPercentage: discount.BogoDiscountPercentage,
          isSelected: false,
          StartDate: startDate, //min date
          EndDate: endDate, // max date
          ItemIDs: itemIds,
          UsePercentage: discount.UsePercentage,
          ConcessionaireIds: concessionaireIds,
          MinOrderAmount:
            typeof discount.MinOrderAmount == 'undefined'
              ? 0 // minorderamount with the discount
              : Number(discount.MinOrderAmount),
        };

        if (GeneralSetting.getIsConcessionaire().toLowerCase() == 'true') {
          if (item.ConcessionaireIds.length == 0) {
            data.push(item);
          } else {
            for (var i = 0; i < uniqueConList.length; i++) {
              if (
                item.ConcessionaireIds.includes(
                  uniqueConList[i].ConcessionaireId
                )
              ) {
                data.push(item);
                break;
              }
            }
          }
        } else {
          data.push(item);
        }
      }
      resolve(data);
    });
  }

  private getPromotions() {
    return new Promise((resolve, reject) => {
      const callback = (tx: string, results: any) => {
        resolve(this.filterPromotions(results));
      };

      const errorCallback = (tx: string, results: any) => {
        const log = new loggingData(
          'Getting Discount from sql',
          results.message,
          'Discount Error',
          'Getting Discount from sql Error',
          false
        );
        this.logger.sendLogToServer(log);

        reject(results);
      };
      // Removed this code because regular discount list affected. @nilesh
      //   (select Group_Concat(dcm.ConcessionaireID) from Discount_Concessionaire_Mappings as dcm where dcm.DiscountID = dm.DiscountID)
      //   as ConcessionaireIDs

      const query = `SELECT dm.*

        FROM DiscountMasters as dm
        WHERE dm.DiscountTypeID IN ('2','3','4','0')
         AND  (dm.RequiresCouponCode='True' OR dm.RequiresCouponCode='true')
         AND (dm.IsShowOnKiosk = 'true' OR dm.IsShowOnKiosk = 'True')
         AND  (dm.IsActive = 'true' OR dm.IsActive = 'True')
         AND  (StartDate <= '${this.datePipe.transform(
           new Date(),
           'yyyy-MM-dd HH:mm:ss'
         )}' OR StartDate = '' ) 
         AND  (EndDate >='${this.datePipe.transform(
           new Date(),
           'yyyy-MM-dd HH:mm:ss'
         )}' OR EndDate = '' ) 
         AND  dm.BranchID ='${GeneralSetting.getBranchId()}'
         AND  dm.CompanyID = '${GeneralSetting.getCompanyId()}' ORDER BY dm.CreatedDate DESC;`;

      DatabaseHandler.executeSqlStatement(query, [], callback, errorCallback);
    });
  }

  /**
   * Remove item level discount when click on back to menu @nilesh
   */
  removeItemLevelDiscount() {
    this.cartService.removeItemDiscounts();
    this.selectedItemPromo = {} as CodelessDiscount;
  }
  /**
   * Remove order level discount when click on back to menu @dolly
   */
  removeOrderLevelDiscount() {
    this.cartService.removeOrderLevelDiscount.next(true);
    this.selectedSubtotalPromo = {} as CodelessDiscount;
  }
  /**
   * This method is used to apply discount stupidly @nilesh
   * @param event is selected discount from availabel discount list
   * @param isEdit
   * @returns
   */
  selectDiscount(event: any, isEdit: boolean) {
    this.cartService.didDiscount = false;
    let flag = 0;
    const subtotal = this.cartService.calculateSubtotal();

    let isDiscountApplied = false;
    if (
      this.selectedItemPromo != null &&
      event.DiscountID == this.selectedItemPromo.DiscountID &&
      event.RegularOrBogoDiscount != '1'
    ) {
      //Snackbar.show('Item level discount already selected', 3000);
      //remove item level discount
      this.cartService.removeItemDiscounts();

      // this.cartService.setPromotion(0, true, false);
      // this.cartService.setPromotion(0, false, false);
      // this.paymentService.DiscountAmount = 0;
      // this.paymentService.DiscountPer = 0;
      // this.discount = 0;
      // this.paymentTypeService.isCardPromo = false;

      this.selectedItemPromo = {} as CodelessDiscount;
      this.updateCalculation.emit();
      return;
    }
    if (event.DiscountID == this.selectedSubtotalPromo.DiscountID) {
      return;
    } else if (
      event.UsePercentage === 'False' &&
      event.DiscountAmount > subtotal //this.cartService.calculateTotal()
    ) {
      flag = 1;
      let error = this.language.getTextElement('discount_greater_than_total');
      Snackbar.show(error, 3000);
      return;
    } else if (GeneralSetting.getIsConcessionaire().toLowerCase() == 'true') {
      let concessionaireIds = event.ConcessionaireIds;
      if (concessionaireIds.length > 0) {
        let concessionareItems = this.cartService
          .getCartItems()
          .filter((x) => concessionaireIds.includes(x.ConcessionaireId));
        if (concessionareItems.length <= 0) {
          flag = 1;
          let error = this.language.getTextElement(
            'txt_discount_is_not_available'
          );
          Snackbar.show(error, 3000);
          return;
        }
        if (event.UsePercentage === 'False') {
          if (
            event.DiscountAmount >
            CommonFunctions.getSubTotalByItems(concessionareItems)
          ) {
            flag = 1;
            let error = this.language.getTextElement(
              'discount_greater_than_total'
            );
            Snackbar.show(error, 3000);
            return;
          }
        }
      }
    }
    const index = this.items.findIndex(
      (x: any) =>
        (x.ItemID == event.ItemID || x.CategoryId == event.CategoryID) &&
        x.Price > event.DiscountAmount
    );
    if (
      this.loyaltyType == LoyaltyType.Referrizer &&
      this.userService.isUserLoggedIn
    ) {
      if (
        this.selectedLoyalty &&
        (this.selectedLoyalty.type == 'CASH_DISCOUNT' ||
          this.selectedLoyalty.type == 'PERCENT_DISCOUNT')
      ) {
        if (
          event.DiscountTypeID == DiscountType.subtotal ||
          event.DiscountTypeID == DiscountType.total
        ) {
          this.cartService.discount = 0;
          this.cartService.broadcastAll();
          this.orderReviewFooter.removeDiscount();
          this.selectedLoyalty = {} as ReferrizerLoyaltyRewardDetail;
          this.cartService.setPromotion(0, true, false, []);
        }
      }
    }

    if (index != -1) {
      this.items[index].DiscountAmount = event.DiscountAmount;
    }
    if (event.RegularOrBogoDiscount == '1') {
      if (
        this.selectedItemPromo != null &&
        event.DiscountID == this.selectedItemPromo.DiscountID
      ) {
        this.clearBogoDiscount();
        this.selectedItemPromo = {} as CodelessDiscount;
        this.updateCalculation.emit();
        return;
      } else {
        if (
          this.selectedItemPromo.DiscountID != null &&
          event.DiscountID != this.selectedItemPromo.DiscountID
        ) {
          let dcc = DiscountConfirmationComponent.open(
            this.modalService,
            StringUtils.only_one_discount_apply_msg,
            StringUtils.yes,
            StringUtils.no,
            ALERT_TYPE.DISCOUNT_REMOVE_ALERT
          );
          dcc.result.then((res: any) => {
            if (res == 'Yes') {
              this.clearBogoDiscount();
              let isBogoApplied = false;
              this.applyBogo(event, false).then((flag) => {
                isBogoApplied = flag;
                // console.log("Bogo applied:", isBogoApplied);
                if (!isBogoApplied) {
                  let discount = this.selectedItemPromo;
                  this.removeItemLevelDiscount();
                  this.selectDiscount(discount, true);
                }
              });
            }
          });
        } else {
          this.applyBogo(event, false).then((flag) => {
            let isBogoApplied = false;
            // console.log("Bogo applied:", isBogoApplied);
          });
        }
      }
    } else if (
      this.selectedItemPromo.DiscountID != null &&
      event.DiscountID != this.selectedItemPromo.DiscountID &&
      (event.DiscountTypeID == DiscountType.item ||
        event.DiscountTypeID == DiscountType.category)
    ) {
      let dcc = DiscountConfirmationComponent.open(
        this.modalService,
        StringUtils.only_one_discount_apply_msg,
        StringUtils.yes,
        StringUtils.no,
        ALERT_TYPE.DISCOUNT_REMOVE_ALERT
      );
      dcc.result.then((res: any) => {
        if (res == 'Yes') {
          if (this.isItemDiscountApply(event as CodelessDiscount)) {
            const selectedDiscount: CodelessDiscount = event;
            if (
              event.DiscountTypeID == DiscountType.item ||
              event.DiscountTypeID == DiscountType.category
            ) {
              //Remove bogo discount
              if (this.selectedItemPromo.RegularOrBogoDiscount == '1') {
                this.clearBogoDiscount();
              }
              for (let index = 0; index < this.items.length; index++) {
                //reset old discount
                if (this.selectedItemPromo != null) {
                  this.items[index].DiscountID = '';
                  this.items[index].DiscountName = '';
                  this.items[index].DiscountPer = '';
                  this.items[index].DiscountAmount = '';
                }

                if (selectedDiscount.ItemIDs.length > 0) {
                  if (
                    selectedDiscount.ItemIDs.includes(this.items[index].ItemID)
                  ) {
                    // console.log("Discount applicable to ", this.items[index].Name);
                    // Discount will be applied to item
                    var totalPrice = CommonFunctions.getItemPrice(
                      this.items[index]
                    );
                    if (selectedDiscount.UsePercentage == 'False') {
                      if (
                        totalPrice >= Number(selectedDiscount.DiscountAmount)
                      ) {
                        isDiscountApplied = true;
                        this.items[index].DiscountID =
                          selectedDiscount.DiscountID;
                        this.items[index].DiscountName = selectedDiscount.Name;
                        this.items[index].DiscountAmount =
                          Number(selectedDiscount.DiscountAmount) *
                          Number(this.items[index].Quantity);
                        this.items[index].IsDiscount = true;
                      } else {
                        isDiscountApplied = true;
                        this.items[index].DiscountID =
                          selectedDiscount.DiscountID;
                        this.items[index].DiscountName = selectedDiscount.Name;
                        this.items[index].DiscountAmount =
                          Number(totalPrice) *
                          Number(this.items[index].Quantity);
                        this.items[index].IsDiscount = true;
                      }
                    } else {
                      isDiscountApplied = true;
                      this.items[index].DiscountID =
                        selectedDiscount.DiscountID;
                      this.items[index].DiscountName = selectedDiscount.Name;
                      this.items[index].DiscountPer =
                        selectedDiscount.DiscountPercentage;
                      this.items[index].DiscountAmount =
                        (totalPrice *
                          Number(this.items[index].Quantity) *
                          Number(selectedDiscount.DiscountPercentage)) /
                        100;
                      this.items[index].IsDiscount = true;
                    }
                    // console.log("Item Discount:", this.items[index].DiscountAmount);
                  }
                }
              }
            }
            if (this.isSubtotalDiscount(event)) {
              //check minimum order amount while applying discount
              if (
                selectedDiscount.MinOrderAmount == 0 ||
                (selectedDiscount.MinOrderAmount != 0 &&
                  selectedDiscount.MinOrderAmount <= subtotal)
              ) {
                // this.orderReviewFooter.removeDiscount();
                // this.selectedSubtotalPromo = event;
                this.changePrices(event, true);
              } else {
                flag = 1;
                let error = this.language
                  .getTextElement(
                    'txt_min_order_amount_to_use_this_discount_is'
                  )
                  .replace(
                    '%d',
                    GeneralSetting.getCurrencySymbol() +
                      selectedDiscount.MinOrderAmount
                  );
                Snackbar.show(error, 2000);
              }
            } else {
              if (isDiscountApplied) {
                this.selectedItemPromo = event;
                this.changePrices(this.selectedItemPromo);
                if (!isEdit) {
                  let title = this.language.getTextElement('text_discount');
                  let subTitle = this.language.getTextElement(
                    'discount_successful'
                  );
                  GeneralAlertComponent.open(
                    this.modalService,
                    title,
                    subTitle
                  );
                }
              } else {
                // after checking all conditions if flag is 0 then discount is not applicable.
                if (flag == 0) {
                  let error = this.language.getTextElement(
                    'txt_discount_is_not_applicable'
                  );
                  Snackbar.show(error, 2000);
                }
              }
            }
            this.updateCalculation.emit();
          } else {
            this.showDiscountNotApply();
            // Snackbar.show("Discount can not apply", 2000);
          }
        } else {
          // console.log("Click on no");
        }
      });
    } else if (!this.isSubtotalDiscount(event)) {
      // this.cartService.setPromotion(0, true, false);
      const selectedDiscount: CodelessDiscount = event;
      if (
        event.DiscountTypeID == DiscountType.item ||
        event.DiscountTypeID == DiscountType.category
      ) {
        // we also need to check on order level if MinOrderAmount is less then subtotal
        if (
          selectedDiscount.MinOrderAmount == 0 ||
          (selectedDiscount.MinOrderAmount != 0 &&
            selectedDiscount.MinOrderAmount <= subtotal)
        ) {
          for (let index = 0; index < this.items.length; index++) {
            //reset old discount
            if (this.selectedItemPromo != null) {
              this.items[index].DiscountID = '';
              this.items[index].DiscountName = '';
              this.items[index].DiscountPer = '';
              this.items[index].DiscountAmount = '';
              this.items[index].IsDiscount = false;
            }
            if (selectedDiscount.ItemIDs.length > 0) {
              if (selectedDiscount.ItemIDs.includes(this.items[index].ItemID)) {
                // console.log("Discount applicable to ", this.items[index].Name);
                // Discount will be applied to item
                var totalPrice = CommonFunctions.getItemPrice(
                  this.items[index]
                );
                if (selectedDiscount.UsePercentage == 'False') {
                  if (totalPrice >= Number(selectedDiscount.DiscountAmount)) {
                    isDiscountApplied = true;
                    this.items[index].DiscountID = selectedDiscount.DiscountID;
                    this.items[index].DiscountName = selectedDiscount.Name;
                    this.items[index].DiscountAmount =
                      Number(selectedDiscount.DiscountAmount) *
                      Number(this.items[index].Quantity);
                    this.items[index].IsDiscount = true;
                  } else {
                    isDiscountApplied = true;
                    this.items[index].DiscountID = selectedDiscount.DiscountID;
                    this.items[index].DiscountName = selectedDiscount.Name;
                    this.items[index].DiscountAmount =
                      Number(totalPrice) * Number(this.items[index].Quantity);
                    this.items[index].IsDiscount = true;
                  }
                } else {
                  isDiscountApplied = true;
                  this.items[index].DiscountID = selectedDiscount.DiscountID;
                  this.items[index].DiscountName = selectedDiscount.Name;
                  this.items[index].DiscountPer =
                    selectedDiscount.DiscountPercentage;
                  this.items[index].DiscountAmount =
                    (totalPrice *
                      Number(this.items[index].Quantity) *
                      Number(selectedDiscount.DiscountPercentage)) /
                    100;
                  this.items[index].IsDiscount = true;
                }
                // console.log("Item Discount:", this.items[index].DiscountAmount);
              }
            }
          }
        } else {
          flag = 1;
          let error = this.language
            .getTextElement('txt_min_order_amount_to_use_this_discount_is')
            .replace(
              '%d',
              GeneralSetting.getCurrencySymbol() +
                selectedDiscount.MinOrderAmount
            );
          Snackbar.show(error, 2000);
        }
        if (this.isSubtotalDiscount(event)) {
          if (
            selectedDiscount.MinOrderAmount == 0 ||
            (selectedDiscount.MinOrderAmount != 0 &&
              selectedDiscount.MinOrderAmount <= subtotal)
          ) {
            // this.orderReviewFooter.removeDiscount();
            // this.selectedSubtotalPromo = event;
            this.changePrices(event, true);
          } else {
            flag = 1;
            let error = this.language
              .getTextElement('txt_min_order_amount_to_use_this_discount_is')
              .replace(
                '%d',
                GeneralSetting.getCurrencySymbol() +
                  selectedDiscount.MinOrderAmount
              );
            Snackbar.show(error, 2000);
          }
        } else {
          if (isDiscountApplied) {
            this.selectedItemPromo = event;
            this.changePrices(this.selectedItemPromo);
            if (!isEdit) {
              let title = this.language.getTextElement('text_discount');
              let subTitle = this.language.getTextElement(
                'discount_successful'
              );
              GeneralAlertComponent.open(this.modalService, title, subTitle);
            }
          } else {
            if (flag == 0) {
              let error = this.language.getTextElement(
                'txt_discount_is_not_applicable'
              );
              Snackbar.show(error, 2000);
            }
          }
        }
      }
    } else {
      if (this.isSubtotalDiscount(event)) {
        if (
          event.MinOrderAmount == 0 ||
          (event.MinOrderAmount != 0 && event.MinOrderAmount <= subtotal)
        ) {
          // this.orderReviewFooter.removeDiscount();
          // this.selectedSubtotalPromo = event;
          this.changePrices(event, true);
        } else {
          flag = 1;
          let error = this.language
            .getTextElement('txt_min_order_amount_to_use_this_discount_is')
            .replace(
              '%d',
              GeneralSetting.getCurrencySymbol() + event.MinOrderAmount
            );
          Snackbar.show(error, 2000);
        }
      }
    }
  }

  showDiscountNotApply() {
    let dcc = DiscountConfirmationComponent.open(
      this.modalService,
      StringUtils.discount_items_not_available,
      StringUtils.yes,
      StringUtils.no,
      ALERT_TYPE.DISCOUNT_NOT_APPLY
    );
    dcc.result.then((res: any) => {
      if (res == 'Yes') {
        this.redirectToMenu();
      }
    });
  }

  redirectToMenu() {
    this.cartService.setTip(0);
    this.removeItemLevelDiscount();
    //this.removeDiscount();

    if (this.isConcessionaire) {
      this.router.navigateByUrl('/ConcessionaireGrid');
    } else {
      this.router.navigateByUrl('/menu');
    }
  }

  isItemDiscountApply(selectedDiscount: CodelessDiscount): boolean {
    //console.log("selected Discount: " + selectedDiscount);
    for (let index = 0; index < this.items.length; index++) {
      //reset old discount
      if (selectedDiscount.ItemIDs.length > 0) {
        if (selectedDiscount.ItemIDs.includes(this.items[index].ItemID)) {
          return true;
          // if (selectedDiscount.UsePercentage == 'False') {
          //   if (this.items[index].totalPrice > Number(selectedDiscount.DiscountAmount)) {
          //     return true;
          //   }
          // }
          // else {
          //   return true;
          // }
        }
      }
    }
    return false;
  }

  isSubtotalDiscount(discount: CodelessDiscount) {
    return !(
      String(discount.DiscountTypeID) == DiscountType.item ||
      String(discount.DiscountTypeID) == DiscountType.category
    );
  }

  /**
   * Apply bogo discount @nilesh
   * @param selectedDiscount selected discount pass to check bogo
   * @param isEdit to manage bogo discount apply when click on plus minus quantity
   * @returns
   */
  async applyBogo(
    selectedDiscount: CodelessDiscount,
    isEdit: boolean
  ): Promise<boolean> {
    // console.log(selectedDiscount);
    if (isEdit) {
      this.clearBogoDiscount();
    }
    let isDiscountApplied: boolean = false;
    let currentPurchaseMultiply: any,
      currentFreeMultiply: any,
      freeApplied = 0,
      cartPurchaseItemQtyTotal: any,
      cartFreeItemQtyTotal: any,
      purchaseApplied = 0,
      bogoFreeQty: any,
      bogoPurchaseQty: any,
      finalMultiply = 1,
      bogoFreeMultiplyQty: any,
      bogoPurchaseMultiplyQty: any;

    bogoFreeQty = Number(selectedDiscount.BogoGetQuantity);
    bogoPurchaseQty = Number(selectedDiscount.BogoPurchaseQuantity);
    var itemIDs = '';
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i] as ItemV2;
      if (i == this.items.length - 1) {
        itemIDs += "'" + item.ItemID + "'";
      } else {
        itemIDs += "'" + item.ItemID + "',";
      }
    }
    let bogoPurchaseItemsTemp = await this.getOrderBogoItems(
      false,
      selectedDiscount?.DiscountID?.toString() || '',
      itemIDs
    );
    let mostExpensive = selectedDiscount.BogoAppliesTo == '2' ? true : false;
    var bogoPurchaseItems = bogoPurchaseItemsTemp.sort(
      (item1: ItemV2, item2: ItemV2) => {
        if (item1.totalPrice! > item2.totalPrice!) {
          return mostExpensive ? 1 : -1;
        }
        if (item1.totalPrice! < item2.totalPrice!) {
          return mostExpensive ? -1 : 1;
        }
        return 0;
      }
    );
    cartPurchaseItemQtyTotal = 0;
    for (let i = 0; i < bogoPurchaseItems.length; i++) {
      cartPurchaseItemQtyTotal += Number(bogoPurchaseItems[i].Quantity);
    }
    // console.log("cart purchase item qty:", cartPurchaseItemQtyTotal);
    cartFreeItemQtyTotal = await this.getFreeItemQty(
      selectedDiscount?.DiscountID?.toString() || '',
      itemIDs
    );
    // console.log("cart free item qty:", cartFreeItemQtyTotal);

    let freeItemsTemp = await this.getOrderBogoItems(
      true,
      selectedDiscount?.DiscountID?.toString() || '',
      itemIDs
    );
    let allFreeAndPurchase = bogoPurchaseItems;
    allFreeAndPurchase.push(...freeItemsTemp);
    allFreeAndPurchase = allFreeAndPurchase.filter(
      (v, i, a) => a.findIndex((t) => t.guid === v.guid) === i
    );
    let qty = 0;
    for (let index = 0; index < allFreeAndPurchase.length; index++) {
      const item = allFreeAndPurchase[index] as ItemV2;
      qty += Number(item.Quantity);
    }

    let appliedQtyBogo = qty / (bogoFreeQty + bogoPurchaseQty);
    let purchaseBogoMultiply = cartPurchaseItemQtyTotal / bogoPurchaseQty;
    let freeBogoMultiply = cartFreeItemQtyTotal / bogoFreeQty;

    cartFreeItemQtyTotal =
      Math.min(
        appliedQtyBogo,
        Math.min(purchaseBogoMultiply, freeBogoMultiply)
      ) * bogoFreeQty;

    currentPurchaseMultiply = Math.floor(
      cartPurchaseItemQtyTotal / bogoPurchaseQty
    );
    currentFreeMultiply = Math.floor(cartFreeItemQtyTotal / bogoFreeQty);
    if (currentPurchaseMultiply > 0 && currentFreeMultiply > 0) {
      finalMultiply = Math.round(
        Math.min(currentPurchaseMultiply, currentFreeMultiply)
      );
    }

    bogoPurchaseMultiplyQty = finalMultiply * bogoPurchaseQty;
    bogoFreeMultiplyQty = finalMultiply * bogoFreeQty;
    if (
      cartPurchaseItemQtyTotal >= bogoPurchaseMultiplyQty &&
      cartFreeItemQtyTotal >= bogoFreeMultiplyQty
    ) {
      for (const bogoPurchaseItem of bogoPurchaseItems) {
        if (
          bogoPurchaseItem.PurchaseOrGet == '1' &&
          purchaseApplied < bogoPurchaseMultiplyQty
        ) {
          purchaseApplied = purchaseApplied + Number(bogoPurchaseItem.Quantity);
          bogoPurchaseItem.PurchaseOrGet = '1';
          bogoPurchaseItem.BogoAppliedQty = purchaseApplied + '';
          bogoPurchaseItem.DiscountID = selectedDiscount.DiscountID;
          bogoPurchaseItem.DiscountTypeID = bogoPurchaseItem.DiscountTypeID;
          bogoPurchaseItem.IsDiscountedItem = 'False';
          bogoPurchaseItem.IsDiscount = false;
          bogoPurchaseItem.DiscountName = selectedDiscount.Name;
          if (
            bogoPurchaseMultiplyQty - purchaseApplied < 0 &&
            (await this.isCommonItem(
              bogoPurchaseItem.DiscountID.toString(),
              bogoPurchaseItem.ItemID
            ))
          ) {
            bogoPurchaseItem.RemainAppliedQty =
              Math.abs(bogoPurchaseMultiplyQty - purchaseApplied) + '';
            bogoPurchaseItem.IsBOGO = 'True';
          } else {
            bogoPurchaseItem.IsBOGO = 'True';
            bogoPurchaseItem.RemainAppliedQty = '0';
          }
          isDiscountApplied = true;
          for (let i = 0; i < this.items.length; i++) {
            if (
              this.items[i].guid &&
              this.items[i].guid == bogoPurchaseItem.guid
            ) {
              this.items[i] = bogoPurchaseItem;
              break;
            }
          }
        }
      }
      let bogoFreeItemListTemp = await this.getOrderBogoItems(
        true,
        selectedDiscount?.DiscountID?.toString() || '',
        itemIDs
      );
      var bogoFreeItemList = bogoFreeItemListTemp.sort(
        (item1: ItemV2, item2: ItemV2) => {
          if (item1.totalPrice! > item2.totalPrice!) {
            return mostExpensive ? -1 : 1;
          }
          if (item1.totalPrice! < item2.totalPrice!) {
            return mostExpensive ? 1 : -1;
          }
          return 0;
        }
      );
      let totalFreeItemQty = bogoFreeMultiplyQty;
      let discountedAmountFlat =
        Number(selectedDiscount.BOGODiscountAmount) * finalMultiply;
      let singleDiscAmountFlat = discountedAmountFlat / bogoFreeMultiplyQty;
      for (const bogoFreeItem of bogoFreeItemList) {
        let bogoFreeItemQty = Number(
          bogoFreeItem.RemainAppliedQty ? bogoFreeItem.RemainAppliedQty : 0
        );
        let currentItemTotal =
          CommonFunctions.getItemPrice(bogoFreeItem) *
          Number(bogoFreeItem.Quantity);
        // (bogoFreeItem.totalPrice ? bogoFreeItem.totalPrice : 0.0) *
        // Number(bogoFreeItem.Quantity);
        if (bogoFreeItemQty == 0) {
          bogoFreeItemQty = Number(bogoFreeItem.Quantity);
        } else {
          currentItemTotal =
            (currentItemTotal / Number(bogoFreeItem.Quantity)) *
            bogoFreeItemQty;
        }
        if (bogoFreeItemQty <= totalFreeItemQty && bogoFreeItemQty > 0) {
          bogoFreeItem.IsBOGO = 'True';
          totalFreeItemQty = totalFreeItemQty - bogoFreeItemQty;
          bogoFreeItem.PurchaseOrGet = '2';
          bogoFreeItem.BogoAppliedQty = freeApplied + '';
          bogoFreeItem.DiscountID = selectedDiscount.DiscountID;
          bogoFreeItem.DiscountTypeID = bogoFreeItem.DiscountTypeID;
          bogoFreeItem.IsDiscountedItem = 'True';
          bogoFreeItem.IsDiscount = true;
          bogoFreeItem.DiscountName = selectedDiscount.Name;
          if (selectedDiscount.BogoDiscountType == '1') {
            bogoFreeItem.DiscountAmount = currentItemTotal;
          } else if (
            selectedDiscount.BogoDiscountType == '2' &&
            selectedDiscount.BogoFlatOrPercentage == '1'
          ) {
            let discountedAmountPer =
              (currentItemTotal *
                Number(selectedDiscount.BogoDiscountPercentage)) /
              100;
            bogoFreeItem.DiscountAmount = discountedAmountPer;
            bogoFreeItem.DiscountPer = Number(
              selectedDiscount.BogoDiscountPercentage
            );
            bogoFreeItem.IsPercentage = 'True';
          } else if (
            selectedDiscount.BogoDiscountType == '2' &&
            selectedDiscount.BogoFlatOrPercentage == '2'
          ) {
            let finalFlatDiscount = bogoFreeItemQty * singleDiscAmountFlat;
            if (finalFlatDiscount <= currentItemTotal) {
              bogoFreeItem.DiscountAmount = finalFlatDiscount;
            } else {
              bogoFreeItem.DiscountAmount = currentItemTotal;
            }
            bogoFreeItem.IsPercentage = 'False';
          }
          isDiscountApplied = true;
          this.selectedItemPromo = selectedDiscount;
          for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].guid && this.items[i].guid == bogoFreeItem.guid) {
              this.items[i] = bogoFreeItem;
              break;
            }
          }
        } else if (bogoFreeItem.PurchaseOrGet == '2' && totalFreeItemQty > 0) {
          bogoFreeItem.IsBOGO = 'True';
          let singleItemPrice = currentItemTotal / bogoFreeItemQty;
          let actualFreeItemTotal = totalFreeItemQty * singleItemPrice;
          bogoFreeItem.PurchaseOrGet = '2';
          bogoFreeItem.BogoAppliedQty = freeApplied + '';
          bogoFreeItem.DiscountID = selectedDiscount.DiscountID;
          bogoFreeItem.DiscountTypeID = bogoFreeItem.DiscountTypeID;
          bogoFreeItem.IsDiscountedItem = 'True';
          bogoFreeItem.IsDiscount = true;
          bogoFreeItem.DiscountName = selectedDiscount.Name;
          if (selectedDiscount.BogoDiscountType == '1') {
            bogoFreeItem.DiscountAmount = actualFreeItemTotal;
          } else if (
            selectedDiscount.BogoDiscountType == '2' &&
            selectedDiscount.BogoFlatOrPercentage == '1'
          ) {
            let discountedAmountPer =
              (actualFreeItemTotal *
                Number(selectedDiscount.BogoDiscountPercentage)) /
              100;
            bogoFreeItem.DiscountAmount = discountedAmountPer;
            bogoFreeItem.DiscountPer = Number(
              selectedDiscount.BogoDiscountPercentage
            );
            bogoFreeItem.IsPercentage = 'True';
          } else if (
            selectedDiscount.BogoDiscountType == '2' &&
            selectedDiscount.BogoFlatOrPercentage == '2'
          ) {
            let finalFlatDiscount = 0;
            if (singleDiscAmountFlat > singleItemPrice) {
              finalFlatDiscount = totalFreeItemQty * singleItemPrice;
            } else {
              finalFlatDiscount = totalFreeItemQty * singleDiscAmountFlat;
            }
            if (finalFlatDiscount <= currentItemTotal) {
              bogoFreeItem.DiscountAmount = finalFlatDiscount;
            } else {
              bogoFreeItem.DiscountAmount =
                (bogoFreeItem.totalPrice ? bogoFreeItem.totalPrice : 0.0) *
                Number(bogoFreeItem.Quantity);
            }
            bogoFreeItem.IsPercentage = 'False';
          }
          isDiscountApplied = true;

          //assign bogo discount as selected discount
          this.selectedItemPromo = selectedDiscount;

          for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].guid && this.items[i].guid == bogoFreeItem.guid) {
              this.items[i] = bogoFreeItem;
              break;
            }
          }
          totalFreeItemQty = 0;
        }
      }
    }
    if (isDiscountApplied && !isEdit) {
      let title = this.language.getTextElement('text_discount');
      let subTitle = this.language.getTextElement('discount_successful');
      GeneralAlertComponent.open(this.modalService, title, subTitle);
    } else if (!isDiscountApplied) {
      this.selectedItemPromo = {} as CodelessDiscount;
      if (!isEdit) this.showDiscountNotApply();
      // Snackbar.show("Discount is not applicable", 2000);
    }
    this.updateCalculation.emit();
    return isDiscountApplied;
  }

  /**
   * Get bogo purchase and free items from cart based on bogo discount selected @nilesh
   * @param isFree check if retrive purchase or free items
   * @param discountId selected discount id
   * @param itemIDs item ids comma saperated
   * @returns
   */
  getOrderBogoItems(
    isFree: boolean,
    discountId: string,
    itemIDs: string
  ): Promise<ItemV2[]> {
    return new Promise((resolve, reject) => {
      const callback = async (tx: string, results: any) => {
        var itemList: ItemV2[] = [];
        for (var r = 0; r < this.items.length; r++) {
          var cartItem = this.items[r] as ItemV2;
          for (var i = 0; i < results.rows.length; i++) {
            if (cartItem.ItemID == results.rows[i].ItemID) {
              cartItem.PurchaseOrGet = results.rows[i].PurchaseOrGet;
              cartItem.DiscountID = results.rows[i].DID;
              itemList.push(cartItem);
              break;
            }
          }
        }
        // console.log(itemList);
        resolve(itemList);
      };

      const errorCallback = (tx: string, results: any) => {
        resolve([]);
      };

      var productType = isFree ? '2' : '1';
      var query = '';

      query =
        'SELECT DIM.ItemID,DIM.PurchaseOrGet, DIM.GroupCategoryID, DM.BogoPurchaseQuantity,DM.DiscountID as DID,' +
        'DM.BogoGetQuantity FROM Discount_Item_Mappings AS DIM Inner join DiscountMasters AS DM ON DM.DiscountID = DIM.DiscountID ' +
        " WHERE DIM.PurchaseOrGet = '" +
        productType +
        "' AND DIM.DiscountID='" +
        discountId +
        "' AND DIM.ItemID IN (" +
        itemIDs +
        ');';
      // console.log("Bogo query:", query);

      DatabaseHandler.executeSqlStatement(query, [], callback, errorCallback);
    });
  }

  /**
   * Get free item total quantity @nilesh
   * @param discountId
   * @param itemIDs
   * @returns
   */
  getFreeItemQty(discountId: string, itemIDs: string): Promise<number> {
    return new Promise((resolve, reject) => {
      const callback = async (tx: string, results: any) => {
        var totalQty = 0;
        for (var r = 0; r < this.items.length; r++) {
          var cartItem = this.items[r] as ItemV2;
          for (var i = 0; i < results.rows.length; i++) {
            if (cartItem.ItemID == results.rows[i].ItemID) {
              totalQty += Number(cartItem.Quantity);
              break;
            }
          }
        }
        // console.log("Free item qty", totalQty);

        resolve(totalQty);
      };

      const errorCallback = (tx: string, results: any) => {
        resolve(0);
      };

      var query = '';

      query =
        'SELECT DIM.ItemID,DIM.PurchaseOrGet, DIM.GroupCategoryID, DM.BogoPurchaseQuantity,DM.DiscountID as DID,' +
        'DM.BogoGetQuantity FROM Discount_Item_Mappings AS DIM Inner join DiscountMasters AS DM ON DM.DiscountID = DIM.DiscountID ' +
        " WHERE DIM.PurchaseOrGet = '2' AND DIM.DiscountID='" +
        discountId +
        "' AND DIM.ItemID IN (" +
        itemIDs +
        ');';
      // console.log("Free item qty query:", query);

      DatabaseHandler.executeSqlStatement(query, [], callback, errorCallback);
    });
  }

  /**
   * Check is bogo purchase and free items same @nilesh
   * @param discountId
   * @param itemID
   * @returns
   */
  isCommonItem(discountId: string, itemID: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const callback = async (tx: string, results: any) => {
        var totalQty = false;
        if (results && results.rows.length > 0) {
          resolve(results.row[0].isFreeItemInCart);
        }
      };

      const errorCallback = (tx: string, results: any) => {
        resolve(false);
      };

      var query = '';

      query =
        "select (CASE When COUNT(ItemID) >=2 THEN  'True' else 'False' END) as isFreeItemInCart from DiscountItemMappings where " +
        "(PurchaseOrGet = '1' OR PurchaseOrGet = '2') AND ItemID = '" +
        itemID +
        "' AND DiscountID = '" +
        discountId +
        "'";
      // console.log("Free item qty query:", query);

      DatabaseHandler.executeSqlStatement(query, [], callback, errorCallback);
    });
  }

  /**
   * Remove bogo discount @nilesh
   */
  clearBogoDiscount() {
    for (let i = 0; i < this.items.length; i++) {
      let item = this.items[i] as ItemV2;
      item.IsDiscount = false;
      item.IsPercentage = 'False';
      item.IsBOGO = 'False';
      item.IsDiscountedItem = 'False';
      item.DiscountAmount = 0.0;
      item.DiscountID = 0;
      item.DiscountName = '';
      item.DiscountPer = 0;
      item.BogoAppliedQty = '0';
      item.PurchaseOrGet = '0';
      item.RemainAppliedQty = '0';
      this.items[i] = item;
    }
  }

  private removeDuplicates(data: any, key: any) {
    return [...new Map(data.map((item: any) => [key(item), item])).values()];
  }

  //Loyalty Related Method

  private removeAllSelectedLoyaltyCheckBox() {
    const ele: any = document.getElementsByClassName('JS_LoyaltyCheckBox');
    for (let i = 0; i < ele.length; i++) ele[i].checked = false;
    if (this.loyaltyType == LoyaltyType.Referrizer) {
      this.selectedLoyalty = {} as ReferrizerLoyaltyRewardDetail;
    } else if (this.loyaltyType == LoyaltyType.OLOPunchh) {
      this.selectedLoyalty = {} as PunchhLoyaltyRewardDetail;
    } else if (this.loyaltyType == LoyaltyType.OLOPaytronix) {
      this.selectedLoyalty = {} as OLOPaytronixLoyaltyRewardDetail;
    }
  }

  /**
   * @description checks current loyaltyType and applies appropriate logic for reward selection
   * @param {-{event} e -
   * @param {-{any} reward1 - the reward selected by user, to be checked and applied
   * @returns
   */
  onLoyaltyPromotionClick(e: Event, reward1: any) {
    if (this.orderReviewFooter.paymentTaken) {
      return;
    }
    let tempReward;
    if (
      this.loyaltyType == LoyaltyType.OLOPunchh ||
      this.loyaltyType == LoyaltyType.OLOPaytronix
    ) {
      tempReward = this.loyaltyService.loyaltyRewards.find(
        (x) => x.reference == reward1.reference
      );
    } else if (this.loyaltyType == LoyaltyType.Referrizer) {
      tempReward = this.loyaltyService.loyaltyRewards.find(
        (x) => x.id == reward1.id
      );
    }
    const reward = tempReward;
    let tempPreviouslySelectedLoyaltyReward;
    if (this.loyaltyType == LoyaltyType.Referrizer) {
      tempPreviouslySelectedLoyaltyReward = {} as ReferrizerLoyaltyRewardDetail;
    } else if (this.loyaltyType == LoyaltyType.OLOPunchh) {
      tempPreviouslySelectedLoyaltyReward = {} as PunchhLoyaltyRewardDetail;
    } else if (this.loyaltyType == LoyaltyType.OLOPaytronix) {
      tempPreviouslySelectedLoyaltyReward =
        {} as OLOPaytronixLoyaltyRewardDetail;
    }
    let previouslySelectedLoyaltyReward: any =
      tempPreviouslySelectedLoyaltyReward;
    try {
      if (
        this.selectedLoyalty != undefined &&
        JSON.stringify(this.selectedLoyalty) != ''
      ) {
        if (this.loyaltyType == LoyaltyType.Referrizer) {
          previouslySelectedLoyaltyReward = JSON.parse(
            JSON.stringify(this.selectedLoyalty)
          ) as ReferrizerLoyaltyRewardDetail;
        } else if (this.loyaltyType == LoyaltyType.OLOPunchh) {
          previouslySelectedLoyaltyReward = JSON.parse(
            JSON.stringify(this.selectedLoyalty)
          ) as PunchhLoyaltyRewardDetail;
        } else if (this.loyaltyType == LoyaltyType.OLOPaytronix) {
          previouslySelectedLoyaltyReward = JSON.parse(
            JSON.stringify(this.selectedLoyalty)
          ) as OLOPaytronixLoyaltyRewardDetail;
        }
      }
    } catch (e) {}

    if (reward == undefined || reward == this.selectedLoyalty) {
      if (reward != undefined) {
        const pointValue = Number(reward.points);
        if (pointValue > 0) {
          this.loyaltyService.updateLoyaltyPoints(Number(reward.points));
        }
      }
      this.removeLoyaltyDiscount();
      return;
    } else {
      if (
        this.selectedLoyalty != undefined &&
        Object.keys(this.selectedLoyalty).length > 0
      ) {
        this.removeAllSelectedLoyaltyCheckBox();
        this.selectedLoyalty = previouslySelectedLoyaltyReward;
        let tempCheckBox;
        if (this.loyaltyType == LoyaltyType.Referrizer) {
          tempCheckBox = document.getElementById(
            this.selectedLoyalty.id
          ) as HTMLInputElement;
        } else if (
          this.loyaltyType == LoyaltyType.OLOPunchh ||
          this.loyaltyType == LoyaltyType.OLOPaytronix
        ) {
          tempCheckBox = document.getElementById(
            this.selectedLoyalty.reference
          ) as HTMLInputElement;
        }
        const checkBox = tempCheckBox;
        checkBox!.checked = true;
        let error = this.language.getTextElement(
          'lbl_can_not_redeem_more_offer'
        );
        Snackbar.show(error, 3000);
        return;
      }
    }
    this.selectedLoyalty = reward;
    // no selected reward
    if (
      this.loyaltyService.selectedLoyaltyReward == '' ||
      this.loyaltyService.selectedLoyaltyReward == '0'
    ) {
      if (this.loyaltyType == LoyaltyType.Referrizer) {
        //unable to select
        if (
          Number(reward.points) >
          Number(this.userService.referrizerLoyaltyUser.loyaltyPointsCount)
        ) {
          Snackbar.show(this.insufficentPointsErrorText, 3000);
          this.removeLoyaltyDiscount();
          return;
        }
        if (this.userService.referrizerLoyaltyUser.loyaltyPointsCount <= 0) {
          return;
        }
        //able to select
        this.openLoyaltyRedeemPoints(reward);
      } else if (this.loyaltyType == LoyaltyType.OLOPunchh) {
        //no need to handle cases where reward redemption is not allowed
        //OLOPunchh returns only valid rewards
        //able to select
        this.openLoyaltyRedeemPoints(reward);
      } else if (this.loyaltyType == LoyaltyType.OLOPaytronix) {
        //no need to handle cases where reward redemption is not allowed
        //OLOPaytronix returns only valid rewards
        //able to select
        this.openLoyaltyRedeemPoints(reward);
      }
      //selected reward is clicked &&referrizer
    } else if (
      this.loyaltyType == LoyaltyType.Referrizer &&
      reward.id == this.loyaltyService.selectedLoyaltyReward
    ) {
      let pointValue = Number(reward.points);
      if (pointValue > 0) {
        this.loyaltyService.updateLoyaltyPoints(Number(reward.points));
      }
      this.removeLoyaltyDiscount();
      //selected reward is clicked &&Punchh
    } else if (
      this.loyaltyType == LoyaltyType.OLOPunchh &&
      reward.reference == this.loyaltyService.selectedLoyaltyReward
    ) {
      //need to call removeAppliedRewards API
      this.removeLoyaltyDiscount();
    } else if (
      this.loyaltyType == LoyaltyType.OLOPaytronix &&
      reward.reference == this.loyaltyService.selectedLoyaltyReward
    ) {
      //need to call removeAppliedRewards API
      this.removeLoyaltyDiscount();
      //selected reward, but not the one clicked
    } else {
      //Todo Add Msg for customer
      if (Object.keys(previouslySelectedLoyaltyReward!).length > 0) {
        if (this.loyaltyType == LoyaltyType.Referrizer) {
          const pointValue = Number(previouslySelectedLoyaltyReward!.points);
          if (pointValue > 0) {
            this.loyaltyService.updateLoyaltyPoints(Number(reward.points));
          }
        }
      }
      this.removeLoyaltyDiscount();
    }
  }

  addDirectToCart(item: any) {
    this.cartService.createCartItemV2(item).then((data: any) => {
      const newItem = data as ItemV2;
      this.cartService.getCartItems().push(newItem);
    });
  }

  /**
   * @description performs all actions related to the redemption of a reward, including necessary item checks
   * @param {-ReferrizerLoyaltyRewardDetail|PunchhLoyaltyRewardDetail-reward to be shown on the redeemRewardPopup
   */
  private openLoyaltyRedeemPoints(
    reward:
      | ReferrizerLoyaltyRewardDetail
      | PunchhLoyaltyRewardDetail
      | OLOPaytronixLoyaltyRewardDetail
  ) {
    var isError = false;
    this.redeemRewardPopup.open(reward, this.loyaltyType);
    this.redeemRewardPopup.modalReference.result.then(
      async (data: any) => {
        if (data != 'error') {
          if (this.loyaltyType == LoyaltyType.Referrizer) {
            if (data.statusCode && data.statusCode == '400') {
              isError = true;
              this.selectedLoyaltyReward = '';
              let gac = GeneralAlertComponent.open(
                this.modalService,
                'Error',
                data.message
              );
              gac.result.then((res: any) => {
                this.loyaltyService.selectedLoyaltyReward = '';
                this.loyaltyService.subSelectedLoyaltyReward.next('');
                this.removeAllSelectedLoyaltyCheckBox();
                return;
              });
            } else if (data.item) {
              const direction = CommonFunctions.getItemType(data.item);
              if (
                data.item.ItemCategorySalesTypeID ===
                CategorySalesType.Alcohol.toString()
              ) {
                let max: number = Number(
                  GeneralSetting.getMaxAlcoholicItemsPerOrder() || 0
                );
                if (max > 0) {
                  const current: number = Number(
                    GeneralSetting.getCurrentCountofAlkol()
                  );
                  if (current === max) {
                    AlcoholicItemsExceedMaxComponent.open(this.modalService);
                  } else {
                    let ao21dc = AlcoholicOver21DialogComponent.open(
                      this.modalService
                    );
                    ao21dc.result.then((age: any) => {
                      if (age === 'over') {
                        if (direction == 'menu') {
                          this.addDirectToCart(data.item);
                        } else {
                          this.itemTransferService.snapshotItemV2(data.item);
                          this.router.navigate([`/${direction}`], {
                            queryParams: {
                              id: data.item.ItemID,
                              category: data.item.CategoryID,
                              isFromReview: true,
                              loyalty: true,
                            },
                          });
                        }
                      }
                    });
                  }
                }
              } else if (direction == 'menu') {
                this.addDirectToCart(data.item);
              } else {
                this.itemTransferService.snapshotItemV2(data.item);
                this.router.navigate([`/${direction}`], {
                  queryParams: {
                    id: data.item.ItemID,
                    category: data.item.CategoryID,
                    isFromReview: true,
                    loyalty: true,
                  },
                });
              }
            }
            //this.addDirectToCart(data.item);
            if (!isError) {
              this.loyaltyService.selectedLoyaltyReward = data.reward;
              this.loyaltyService.subSelectedLoyaltyReward.next(data.reward);
              this.selectedPromo = {} as CodelessDiscount;
              if (reward.type == 'CASH_DISCOUNT') {
                if (
                  this.selectedSubtotalPromo.DiscountTypeID &&
                  (this.selectedSubtotalPromo.DiscountTypeID.toString() ==
                    DiscountType.subtotal ||
                    this.selectedSubtotalPromo.DiscountTypeID.toString() ==
                      DiscountType.total)
                ) {
                  this.orderReviewFooter.removeDiscount(false);
                  this.cartService.discount = this.selectedLoyalty.value;
                  this.paymentService.DiscountAmount =
                    this.selectedLoyalty.value;
                  this.cartService.setPromotion(
                    this.paymentService.DiscountAmount,
                    true,
                    true,
                    []
                  );
                }
              } else if (reward.type == 'PERCENT_DISCOUNT') {
                if (
                  this.selectedSubtotalPromo.DiscountTypeID &&
                  (this.selectedSubtotalPromo.DiscountTypeID.toString() ==
                    DiscountType.subtotal ||
                    this.selectedSubtotalPromo.DiscountTypeID.toString() ==
                      DiscountType.total)
                ) {
                  this.orderReviewFooter.removeDiscount(false);
                  this.cartService.setPromotion(
                    this.paymentService.DiscountPer,
                    false,
                    true,
                    []
                  );
                }
              }
            }
            this.updateCalculation.emit();
          } else if (this.loyaltyType == LoyaltyType.OLOPunchh) {
            this.addDirectToCart(data.item);
            this.loyaltyService.selectedLoyaltyReward = data.reward;
            this.loyaltyService.subSelectedLoyaltyReward.next(data.reward);
            this.selectedPromo = {} as CodelessDiscount;
            this.updateCalculation.emit();
          } else if (this.loyaltyType == LoyaltyType.OLOPaytronix) {
            this.addDirectToCart(data.item);
            this.loyaltyService.selectedLoyaltyReward = data.reward;
            this.loyaltyService.subSelectedLoyaltyReward.next(data.reward);
            this.selectedPromo = {} as CodelessDiscount;
            this.updateCalculation.emit();
          }
          // this.loyaltyService.selectedLoyaltyReward = data.reward;
          // this.loyaltyService.subSelectedLoyaltyReward.next(data.reward);
          // this.selectedPromo = {} as CodelessDiscount;
          if (!isError) {
            if (reward.type == 'CASH_DISCOUNT') {
              this.cartService.setPromotion(
                this.paymentService.DiscountAmount,
                true,
                true,
                []
              );
              this.cartService.broadcastAll();
            } else if (reward.type == 'PERCENT_DISCOUNT') {
              this.cartService.setPromotion(
                this.paymentService.DiscountPer,
                false,
                true,
                []
              );
            }
          }
          this.updateCalculation.emit();
        } else {
          this.removeLoyaltyDiscount();
        }
      },
      () => {}
    );
  }

  private async removeLoyaltyDiscount() {
    this.removeAllSelectedLoyaltyCheckBox();

    let itemWithLoyaltyDiscount = this.cartService
      .getCartItems()
      .find((x: ItemV2) => x.IsLoyaltyDiscountItem);

    if (itemWithLoyaltyDiscount) {
      this.cartService.removeFromCart(itemWithLoyaltyDiscount);
      if (this.loyaltyService.loyaltyType == LoyaltyType.OLOPunchh) {
        await this.loyaltyService.removeOLOPunchhReward();
      } else if (this.loyaltyService.loyaltyType == LoyaltyType.OLOPaytronix) {
        await this.loyaltyService.removeOLOPaytronixReward();
      }
    }
    this.loyaltyService.punchhAppliedRewardID = '';
    this.loyaltyService.oloPaytronixAppliedRewardID = '';
    this.loyaltyService.selectedLoyaltyReward = '';
    this.loyaltyService.subSelectedLoyaltyReward.next('');

    if (this.loyaltyType == LoyaltyType.Referrizer) {
      this.selectedLoyalty = {} as ReferrizerLoyaltyRewardDetail;
    } else if (this.loyaltyType == LoyaltyType.OLOPunchh) {
      this.selectedLoyalty = {} as PunchhLoyaltyRewardDetail;
    } else if (this.loyaltyType == LoyaltyType.OLOPaytronix) {
      this.selectedLoyalty = {} as OLOPaytronixLoyaltyRewardDetail;
    }
    // calculate and display new prices
    if (
      !(
        this.selectedPromo.DiscountID ||
        this.selectedItemPromo.DiscountID ||
        this.selectedSubtotalPromo.DiscountID
      )
    ) {
      this.cartService.setPromotion(0, false, false, []);
      this.paymentService.DiscountPer = 0;
      this.paymentService.DiscountAmount = 0;
      this.cartService.promotionPercent = 0;
      this.cartService.promotionAmount = 0;
    }
    this.updateCalculation.emit();
  }

  onLoyaltyRemoveClick(reward: any) {
    // this.availablePoints = Number(this.availablePoints) + Number(reward.points);
    // this.userService.referrizerLoyaltyUser.loyaltyPointsCount = this.availablePoints;
    this.loyaltyService.updateLoyaltyPoints(Number(reward.points));
    this.removeLoyaltyDiscount();
  }

  getRewardDesc(reward: any) {
    return reward.type.toLowerCase() == 'item'
      ? reward.Items!.length > 1
        ? this.itemRewardText.replace('%d', reward.Items!.length)
        : this.singleItemRewardText.replace(
            '%d',
            reward.Items[0].DisplayName ?? reward.Items[0].Name
          )
      : reward.type.toLowerCase() == 'PERCENT_DISCOUNT'.toLowerCase()
      ? this.language
          .getTextElement('reward_off')
          .replace('%d', reward?.value + '%')
      : reward.type.toLowerCase() == 'CASH_DISCOUNT'.toLowerCase()
      ? this.language
          .getTextElement('reward_off')
          .replace(
            '%d',
            GeneralSetting.getCurrencySymbol() + ' ' + reward.value
          )
      : '';
  }

  private updateLoyaltyPoints() {
    const reward = this.loyaltyService.loyaltyRewards.find(
      (x) => x.id == this.loyaltyService.selectedLoyaltyReward
    );
    if (reward == undefined) return;
    this.loyaltyService.updateLoyaltyPoints(Number(reward.points));
    this.loyaltyService.selectedLoyaltyReward = '';
    this.loyaltyService.subSelectedLoyaltyReward.next('');
  }

  async referrizerRewardProcessor(data: any) {
    let loyaltyRewardsDetailRefactorV2: ReferrizerLoyaltyRewardDetail[] = [];
    for (let i = 0; i < data.length; i++) {
      let loyaltyReward = data[i];
      if (loyaltyReward.type != null && loyaltyReward.type === 'ITEM') {
        if (loyaltyReward.ItemID != null && loyaltyReward.ItemID != '') {
          if (
            loyaltyRewardsDetailRefactorV2.filter(
              (x) => x.id === loyaltyReward.id
            ).length === 0
          ) {
            let isItemAvailable =
              await this.menuItemService.checkItemIsAvailableFromSql(
                loyaltyReward.ItemID,
                3
              );
            if (isItemAvailable) {
              if (loyaltyReward.MultiItemIDs) {
                loyaltyReward.MultiItemIDs.push(loyaltyReward.ItemID);
              } else {
                loyaltyReward.MultiItemIDs = [];
                loyaltyReward.Items = [];
                loyaltyReward.MultiItemIDs.push(loyaltyReward.ItemID);
              }
              loyaltyRewardsDetailRefactorV2.push(loyaltyReward);
            }
          } else {
            for (let j = 0; j < loyaltyRewardsDetailRefactorV2.length; j++) {
              if (loyaltyRewardsDetailRefactorV2[j].id === loyaltyReward.id) {
                loyaltyRewardsDetailRefactorV2[j].MultiItemIDs!.push(
                  loyaltyReward.ItemID
                );
                break;
              }
            }
          }
        }
      } else {
        loyaltyRewardsDetailRefactorV2.push(loyaltyReward);
      }
    }
    for (let i = 0; i < loyaltyRewardsDetailRefactorV2.length; i++) {
      if (
        loyaltyRewardsDetailRefactorV2[i].MultiItemIDs &&
        loyaltyRewardsDetailRefactorV2[i].MultiItemIDs!.length > 0
      ) {
        for (
          let j = 0;
          j < loyaltyRewardsDetailRefactorV2[i].MultiItemIDs!.length;
          j++
        ) {
          let itemBank = await this.databaseService.getItemByItemIdFromSql(
            loyaltyRewardsDetailRefactorV2[i].MultiItemIDs![j]
          );
          if (itemBank.length) {
            loyaltyRewardsDetailRefactorV2[i].Items?.push(itemBank[0]);
            itemBank[0].Price = '0.00';
            //itemBank[0].Modifiers=await this.databaseService.getModifierByItemIDFromSql(itemBank[0].ItemID)
            if (
              itemBank[0].AttributeCount != null &&
              itemBank[0].AttributeCount > 0
            ) {
              itemBank[0].Variations =
                await this.databaseService.getPersistentVariationsAsync(
                  itemBank[0].ItemID
                );
            }
            if (itemBank[0].IsCombo) {
              itemBank[0].ComboGroup =
                await this.databaseService.getPersistentComboGroupItemsAsync(
                  itemBank[0].ItemID
                );
            }
          }
          for (let k = 0; k < this.categories.length; k++) {
            if (this.categories[k].associatedItems) {
              for (
                let l = 0;
                l < this.categories[k].associatedItems!.length;
                l++
              ) {
                if (
                  this.categories[k].associatedItems![l].ItemID ===
                  loyaltyRewardsDetailRefactorV2[i].MultiItemIDs![j]
                ) {
                  if (
                    loyaltyRewardsDetailRefactorV2[i].Items!.filter(
                      (x) =>
                        x.ItemID ===
                        this.categories[k].associatedItems![l].ItemID
                    ).length == 0
                  ) {
                    let copy = JSON.parse(
                      JSON.stringify(this.categories[k].associatedItems![l])
                    );
                    copy.Price = '0.00';
                    loyaltyRewardsDetailRefactorV2[i].Items!.push(copy);
                  }
                }
              }
            }
          }
        }
      }
    }
    this.loyaltyRewards = loyaltyRewardsDetailRefactorV2;

    if (this.loyaltyRewards.length > 0 && this.userService.isUserLoggedIn) {
      this.isLoyalty = true;
      this.hasPromosLoyalty.emit(true);
    } else {
      this.isLoyalty = false;
      this.hasPromosLoyalty.emit(false);
    }

    this.loyaltyService.loyaltyRewards = loyaltyRewardsDetailRefactorV2;
  }
  /**
   * @description helper function to parse data returned by loyaltyService.getLoyaltyRewards()
   * @description should not be called directly
   * @param data - reward data passed by calling function
   */
  async punchhRewardProcessor(data: any) {
    let loyaltyRewardsDetailRefactorV2: PunchhLoyaltyRewardDetail[] = [];
    for (let i = 0; i < data.length; i++) {
      let loyaltyReward = data[i];
      if (data[i].reference == 'banked') {
        data[i].fineprint = 'Use your earned dollars';
        data[i].description = `Up to $${data[i].quantityavailable}`;
      }
      loyaltyRewardsDetailRefactorV2.push(loyaltyReward);
      this.loyaltyRewards = loyaltyRewardsDetailRefactorV2;
      if (this.loyaltyRewards.length > 0 && this.userService.isUserLoggedIn) {
        this.isLoyalty = true;
        this.hasPromosLoyalty.emit(true);
      } else {
        this.isLoyalty = false;
        this.hasPromosLoyalty.emit(false);
      }
      this.loyaltyService.loyaltyRewards = loyaltyRewardsDetailRefactorV2;
    }
  }
  async paytronixRewardProcessor(data: any) {
    let loyaltyRewardsDetailRefactorV2: OLOPaytronixLoyaltyRewardDetail[] = [];
    for (let i = 0; i < data.length; i++) {
      let loyaltyReward = data[i];
      loyaltyRewardsDetailRefactorV2.push(loyaltyReward);
      this.loyaltyRewards = loyaltyRewardsDetailRefactorV2;
      if (this.loyaltyRewards.length > 0 && this.userService.isUserLoggedIn) {
        this.isLoyalty = true;
        this.hasPromosLoyalty.emit(true);
      } else {
        this.isLoyalty = false;
        this.hasPromosLoyalty.emit(false);
      }
      this.loyaltyService.loyaltyRewards = loyaltyRewardsDetailRefactorV2;
    }
  }

  /**
   * get loyalty program setting from database
   */
  getLoyaltyProgram() {
    return new Promise<Boolean>((resolve) => {
      var sqlStr =
        'SELECT IsActive FROM LoyaltyPrograms WHERE IsActive = "True"';

      const callback = (tx: string, data: any) => {
        if (data.rows.length > 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      const error = (err: any) => {
        var log = new loggingData(
          'Loyalty Program Error',
          'Loyalty Program Encountered Error Fetching data',
          'Loyalty Error',
          `Loyalty Program Encountered Error: ${err}`,
          true
        );
        this.logger.sendLogToServer(log);
        resolve(false);
      };

      DatabaseHandler.executeSqlStatement(sqlStr, [], callback, error);
    });
  }

  /**
   * Shows error message @rekha
   */
  private discountErrorMsg(msg: string) {
    let okButtonText = this.language.getTextElement('lbl_ok');
    let invalidMsg = this.language.getTextElement('lbl_invalid_discount');
    if (this.errorModel == undefined) {
      this.errorModel = new DiscountConfirmationComponent(
        this.modalService,
        this.language
      );
    } else {
      this.errorModel.onNoClick();
    }

    this.errorModel.open(
      msg && msg != '' ? msg : invalidMsg,
      okButtonText,
      '',
      ALERT_TYPE.DISCOUNT_REMOVE_ALERT
    );
  }
}
