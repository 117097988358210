<section class="fullPageDiv">
  <div>
    <div class="container-fluid" id="main-container">
      <div class="row">
        <div class="text-center">
          <div class="login-content text-left">
            <div class="logo-box mb-0 mt-0">
              <img
                src="assets/images/logo.png"
                onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
                class="logo img-responsive"
              />
            </div>
            <h2>{{ descriptionText }}</h2>
            <app-login-form></app-login-form>
            <div  class="lang-btn">
              <button id="language1" class="btn me-5" [ngClass]="defaultLag === 'en' ? 'active' : ''" (click)="swapLanguage('en')">
                <div id="language1" class="flag">
                  <img id="language1" src="assets/images/enImg.svg" />
                </div>
                English
              </button>
              <button id="language2" class="btn me-5" [ngClass]="defaultLag === 'es' ? 'active' : ''" (click)="swapLanguage('es')">
                <div id="language2" class="flag">
                  <img id="language2" src="assets/images/esImg.svg" />
                </div>
                Español
              </button>
              <!-- <button id="language3" class="btn" [ngClass]="defaultLag === 'fr' ? 'active' : ''" (click)="swapLanguage('fr')">
                <div id="language3" class="flag">
                  <img id="language3" src="assets/images/frImg.svg" />
                </div>
                Français
              </button> -->
              <!-- <button class="flag-button" (click)="swapLanguage('fr')"><div class="flag"><img/></div>Francais</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>