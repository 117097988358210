<div class="col-md-12 col-12 header-report">
  <h2><span>{{orderHistoryText}}</span></h2>
  <h4>
    <!--i class="fa fa-calendar"></i-->
    <input style="width: 12rem; font-weight: 700; margin-left: 1rem;margin-right: 1rem" matInput readonly [matDatepicker]="datePicker"
      [ngModel]="dt" (ngModelChange)="setHistoryDate($event)" id="datePicker" />
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker touchUi #datePicker></mat-datepicker>


  </h4>
</div>

<div class="row mb-3rem btn_offlineTransaction">
  <div class="col-md-3 col-3 text-left">

  </div>
  <!-- <div class="col-md-9 col-9 text-left">
    <div class="btn2">
      <button (click)="doProcessOfflineTransaction()">
        <i class="fa fa-sync"></i> Process Offline Transaction
      </button>
    </div>
  </div> -->
</div>
<!--<p>{{dt3 | date:'MM/dd/yyyy'}}</p>-->
<br /><br />

<div class="row mb-3rem">
  <table class="
      table
      orderHistoryTable
      table-borderless
      border-white border-radius-10
    ">
    <thead>
      <tr>
        <th></th>
        <th></th>
        <th>{{tokenNoText}}</th>
        <th>{{refOrderText}}</th>
        <th>{{invoiceText}}</th>
        <th>{{orderTotalText}}</th>
        <th>{{paymentTypeText}}</th>
        <th>{{statusText}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let order of orders">
        <td><button (click)="printOrder(order)">{{printText}}</button></td>
        <td>
          <!-- <button
            *ngIf="order.IsRefunded == 'False' && order.PaymentTypeID == '2' && ((order.ThirdPartyPaymentID == '17' && order.TransactionID !='') || order.ThirdPartyPaymentID != '17')"
            (click)="startRefund(order)">
            {{refundText}}
          </button> -->
          <p class="refunded" *ngIf="order.IsRefunded == 'True'">{{refundedText}}</p>
        </td>
        <td>{{ order.T_TokenCount }}</td>
        <td>{{ order.InvoiceRefNo }}</td>
        <td>{{ order.OrderInvoiceNo }}</td>
        <td>{{ order.OrderTotal | number: "1.2-2" | CurrencyPipe }} <span *ngIf="order.OrderSurcharge"><br />(Surcharge: {{ order.OrderSurcharge | number: "1.2-2" | CurrencyPipe }})</span></td>
        <td *ngIf="order.PaymentTypeID === '1' || order.PaymentTypeID === '0'">{{cashText}}</td>
        <td *ngIf="order.PaymentTypeID === '13'">{{houseAccountText}}</td>
        <td *ngIf="order.PaymentTypeID === '2'">{{cardText}}</td>
        <td *ngIf="order.PaymentTypeID === '6'">{{fullDiscountText}}</td>
        <td *ngIf="order.PaymentTypeID === '22'">{{payWithLoyaltyText}}</td>
        <td *ngIf="order.PaymentTypeID === '17'">{{giftCardText}}</td>
        <td *ngIf="order.OrderUpStatus === '1';else failPart" style="color: green;">{{statusSuccess}}</td>
        <ng-template #failPart>
          <td style="color: red;">{{statusFailed}}</td>
        </ng-template>

      </tr>
    </tbody>
  </table>
</div>
<br />
<br />