import { IsKioskService } from '../../../services/is-kiosk.service';
import { GeneralSetting } from '../../../services/generalSetting.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ModifierV2, ComboGroupV2 } from '../../../models/item';
import { Variation, VariationOption } from '../../../models/Variations';
import { CommonFunctions } from '../../../services/common';
import { DatabaseService } from '../../../services/database.service';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-item-builder-mode-selection',
  templateUrl: './item-builder-mode-selection.component.html',
  styleUrls: ['./item-builder-mode-selection.component.css'],
})
export class ItemBuilderModeSelectionComponent implements OnInit, OnDestroy {
  currentModifier: ModifierV2 | null = null;
  @Input() modifiers: ModifierV2[] = [];
  @Input() parentQuantity: number = 1;
  currentVariation: Variation | null = null;
  @Input() variations: Variation[] = [] as Variation[];

  currentComboGroup: ComboGroupV2 | null = null;
  @Input() comboGroups: ComboGroupV2[] = [] as ComboGroupV2[];

  @Input() isCombo: boolean = false;
  @Input() numInvisibleGroups: number = 0
  @Input() IsComboPopupComponent = false;

  @Input() ItemName: string = '';
  @Input() ItemDescription: string = '';

  @Input() ItemId: string = '';

  @Output() emitSelectedVariation: EventEmitter<VariationOption[]> =
    new EventEmitter<VariationOption[]>();

  @Output() emitSelectedModifier: EventEmitter<{
    inputModifier: ModifierV2[];
    isValid: boolean;
  }> = new EventEmitter<{ inputModifier: ModifierV2[]; isValid: boolean }>();

  @Output() emitComboGroup: EventEmitter<{
    inputComboGroup: ComboGroupV2[];
    isValid: boolean;
  }> = new EventEmitter<{
    inputComboGroup: ComboGroupV2[];
    isValid: boolean;
  }>();

  @Output() setVariationsValid: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Output() isModifierValid: boolean = false;

  @ViewChild('selectionComponent') selectionComponent!: ElementRef;

  IsComboGroupValid: boolean = false;

  selectedVariation: VariationOption = {} as VariationOption;

  imageUrl: string = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  primaryColor: string = '';

  primaryTextColor: string = '';

  showNegativeModifiers: string = '';

  noAnimation: boolean = true;

  modifierStartingIndex: number = 0;

  isFirstTimeLoad = true;

  isVariationValid: boolean = false;

  showFixItemButton: boolean = false;

  isVariationSelected = false;

  isModifierSelected = false;

  modifierSelectedIndex = 0;

  comboGroupSelectedIndex = 0;

  IsComboGroupSelected = false;

  categoryCards!: HTMLCollection;

  hasSubCategory = false;

  //firstLevel
  selectedModifierSubGroup: ModifierV2 | null = null;

  //selectedModifierSubGroup Second level
  selectedModifierSubGroupLevel2: ModifierV2 = {} as ModifierV2;

  hasSecondLevelSubgroup: boolean = false;

  subGroupIndex: number = 0;

  languageSub!: Subscription
  requiredText: string = ''
  ofText: string = ''
  backText: string = ''
  nextText: string = ''
  fixItemsText: string = ''
  placeholderImage: string = '';
  chooseOneText: string = ''

  showAggregateRule: boolean = false;
  deselectText: string = ''
  isAggregate = false;

  constructor(private readonly isKioskService: IsKioskService, private readonly db: DatabaseService, private language: LanguageService, private readonly dataService: DataService) { }
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText() {
    this.requiredText = this.language.getTextElement('txt_required')
    this.ofText = this.language.getTextElement('txt_of')
    this.backText = this.language.getTextElement('back')
    this.nextText = this.language.getTextElement('next')
    this.fixItemsText = this.language.getTextElement('lbl_choices_required')
    this.chooseOneText = this.language.getTextElement('choose_exactly_one')
  }
  ngOnInit(): void {
    this.isAggregate = this.modifiers.length > 0 ? (this.modifiers[this.modifierSelectedIndex].UseAdvancedModifierQuantitySettings == 'True') ? true : false : false;
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    this.categoryCards = document.getElementsByClassName('d-flex');
    this.primaryColor = GeneralSetting.getPrimaryColor();
    this.primaryTextColor = GeneralSetting.getPositiveColorText();
    this.showNegativeModifiers = GeneralSetting.getShowNegativeModifiers();
    // console.log(this.categoryCards);
    this.setDefault();
    if (this.isCombo) {
      this.checkItemValid();
    }
    else this.checkModifierValid();

    this.getModifierRules();
  }

  init(): void {
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    this.categoryCards = document.getElementsByClassName('d-flex');
    this.primaryColor = GeneralSetting.getPrimaryColor();
    this.primaryTextColor = GeneralSetting.getPositiveColorText();
    this.showNegativeModifiers = GeneralSetting.getShowNegativeModifiers();
    // console.log(this.categoryCards);
    this.setDefault();
    if (this.isCombo) {
      this.checkItemValid();
    }
    else this.checkModifierValid();

    this.getModifierRules();
  }

  public setDefault() {
    let isCombo = this.isCombo.toString();

    if (isCombo.toLowerCase() == 'true') {
      if (this.comboGroups.length > 0) {
        this.onItemSelect(0);
      }
    } else {
      this.setDefaultVariation();
    }
    this.isFirstTimeLoad = false;
  }

  private setDefaultVariation() {
    if (this.variations && this.variations.length > 0) {
      if (this.variations[0].variationOptions.length > 0) {
        const selectedVariation = this.variations[0].variationOptions.find(
          (x: VariationOption) => x.isSelected
        );

        if (selectedVariation) {
          this.selectedVariation = selectedVariation;
          this.isVariationValid = true;
          this.setVariationsValid.emit(true);
          this.emitSelectedVariation.emit([this.selectedVariation]);
        } else {
          this.variations[0].variationOptions[0].isSelected = true;
          this.selectedVariation = this.variations[0].variationOptions[0];
          this.isVariationValid = true;
          this.setVariationsValid.emit(true);
          this.emitSelectedVariation.emit([this.selectedVariation]);
        }
      }
    } else {
      this.isVariationSelected = false;
    }

    if (this.variations && Object.entries(this.variations).length > 0) {
      this.modifierStartingIndex = 1;
      if (this.isFirstTimeLoad) {
        this.onVariationSelect();
      }
    } else {
      this.modifierStartingIndex = 0;
      if (this.isFirstTimeLoad) {
        this.onModifierSelect(0);
      }
    }
  }

  showFixedItemButton() {
    let isValid = true;

    if (this.isCombo) {
      if (
        this.comboGroups.length > 0 &&
        this.comboGroupSelectedIndex == this.comboGroups.length - (this.numInvisibleGroups + 1) &&
        !this.IsComboGroupValid
      ) {
        isValid = false;
      }
    } else {
      if (this.variations.length > 0 && !this.isVariationValid) {
        if (this.modifiers.length <= 0) {
          isValid = false;
        } else if (
          this.modifiers.length > 0 &&
          this.modifierSelectedIndex == this.modifiers.length - 1 &&
          !this.isModifierValid
        ) {
          isValid = false;
        }
      } else {
        if (
          this.modifiers.length > 0 &&
          this.modifierSelectedIndex == this.modifiers.length - 1 &&
          !this.isModifierValid
        ) {
          isValid = false;
        }
      }
    }

    this.showFixItemButton = !isValid;
  }


  //for subgroup selection 
  private setSubGroupModifierSelection(modifier: ModifierV2) {


    if (modifier.Ingredients.length == 1 && modifier.Ingredients[0].IsModifier) {
      const childModifier = modifier.Ingredients[0] as ModifierV2;

      const selectedIngredient = CommonFunctions.getSelectedIngredientCount(childModifier);
      if (selectedIngredient > 0) {
        modifier.IsSelected = true;
        childModifier.IsSelected = true;
      }
      else {
        modifier.IsSelected = false;
        childModifier.IsSelected = false;
      }

      if (
        selectedIngredient >= childModifier.min &&
        selectedIngredient <= childModifier.max
      ) {
        childModifier.isValid = true;
      } else {
        childModifier.isValid = false;
        if (selectedIngredient > 0) this.isModifierValid = false;
      }
    }
    else {
      const selectedIngredient = CommonFunctions.getSelectedIngredientCount(modifier);

      if (selectedIngredient > 0) {
        modifier.IsSelected = true;
      }
      else {
        modifier.IsSelected = false;
      }

      if (
        selectedIngredient >= modifier.min &&
        selectedIngredient <= modifier.max
      ) {
        modifier.isValid = true;
      } else {
        modifier.isValid = false;
        if (selectedIngredient > 0) this.isModifierValid = false;
      }
    }


  }

  // for checking and removing parent selection on modifier selection logic 
  private subGroupParentDeselectionLogic(modifier: ModifierV2, index: number) {
    if (modifier.IsSubGroup) {
      this.setSubGroupModifierSelection(modifier);
    }

    if (modifier.Ingredients.length > 0) {
      for (let j = 0; j < modifier.Ingredients.length; j++) {
        const checkSubGroupModifiers = modifier.Ingredients;

        const childModifiers = checkSubGroupModifiers.filter(x => x.IsModifier) as ModifierV2[];

        if (childModifiers && childModifiers.length > 0) {

          for (let i = 0; i < childModifiers.length; i++) {
            if (childModifiers[i].IsSubGroup) {

              if (childModifiers[i].IsSelected) {
                CommonFunctions.selectModifierLogic(childModifiers[i], i);
              }

              this.setSubGroupModifierSelection(childModifiers[i]);
            }
          }

          modifier.count = CommonFunctions.getSelectedIngredientCount(modifier);
        }
      }
    }
  }

  async checkModifierValid(openNext: boolean = false) {
    // is valid by default
    this.isModifierValid = true;

    if (this.modifiers && this.modifiers.length > 0) {
      for (let i = 0; i < this.modifiers.length; i++) {
        const modifier = this.modifiers[i];
        //For changing parent if subgroup fails
        this.subGroupParentDeselectionLogic(modifier, i);

        /**
         * Check modifier validate based on min max aggregate @nilesh
         */
        let selectedIngredient = 0
        modifier.UseAdvancedModifierQuantitySettings == 'False'
          ? selectedIngredient = CommonFunctions.getSelectedIngredientCount(modifier)
          : selectedIngredient = CommonFunctions.getOnlySelectedIngredientCount(modifier)

        if (modifier.IsSelected) {
          //if (modifier.Ingredients.length > 0) {

          let aggregateCondition = modifier.MaxAggregateQty && modifier.MinAggregateQty && modifier.MaxAggregateQty != 0 && modifier.MinAggregateQty != 0
            ? modifier.countAggregate <= Number(modifier.MaxAggregateQty) && modifier.countAggregate >= Number(modifier.MinAggregateQty)
            : true;
          let condition = true;
          if (modifier.UseAdvancedModifierQuantitySettings == 'False') {
            condition = selectedIngredient >= modifier.min &&
              selectedIngredient <= modifier.max;
          } else {

            condition = (selectedIngredient >= modifier.min &&
              selectedIngredient <= modifier.max)
              && aggregateCondition;
          }
          if (condition) {
            modifier.isValid = true;
            modifier.IsSelected = true;
          } else {
            modifier.isValid = false;
            this.isModifierValid = false;
          }
          //}
        } else {
          if (selectedIngredient > 0) {
            if (selectedIngredient >= modifier.min && selectedIngredient <= modifier.max) {
              modifier.isValid = true;
              modifier.IsSelected = true;
            } else {
              modifier.isValid = false;
              this.isModifierValid = false;
            }
          } else if (modifier.min != 0) {
            modifier.isValid = false;
            this.isModifierValid = false;
          }else{
            modifier.isValid = true;
            modifier.IsSelected = true;
          }
        }

        /**
         * https://grubbrr.atlassian.net/browse/SK-1920 - Modifier group isselected flag update @nilesh
         */
        if (selectedIngredient == 0) {
          modifier.IsSelected = false;
        }

        if (openNext) {
          if (modifier.isValid) {
            if (i == this.modifierSelectedIndex) {
              if (this.modifierSelectedIndex != this.modifiers.length - 1) {
                this.onModifierSelect(this.modifierSelectedIndex + 1);
              } else {
                this.onModifierSelect(this.modifierSelectedIndex);
              }
            }
          } else {
            this.onModifierSelect(i);
            break;
          }
        }
      }

      this.emitSelectedModifier.emit({
        inputModifier: this.modifiers,
        isValid: this.isModifierValid,
      });
    }

    this.showFixedItemButton();
  }

  isObjectEmpty(obj: any) {
    return Object.keys(obj).length === 0;
  }

  setVariationOptionSelected(selectedVariation: VariationOption) {
    this.selectedVariation = selectedVariation;
    this.isVariationValid = true;

    setTimeout(() => {
      this.onNextClick();
    }, 50);

    this.emitSelectedVariation.emit([this.selectedVariation]);
    this.setVariationsValid.emit(true);
  }

  onVariationSelect() {
    this.nullifySelections();
    this.currentVariation = this.variations[0];
    this.isVariationSelected = true;
    this.isModifierSelected = false;
  }

  async onModifierSelect(index: number) {
    this.modifierSelectedIndex = index;

    this.nullifySelections();
    this.currentModifier = this.modifiers[index];

    this.isModifierSelected = true;
    this.isVariationSelected = false;
    
    this.isAggregate = this.modifiers.length > 0 ? (this.modifiers[this.modifierSelectedIndex].UseAdvancedModifierQuantitySettings == 'True') ? true : false : false;
    const isHasSubGroup = this.checkIfHaveSubGroup(index);
    if (this.modifiers.length>0 && this.modifiers[this.modifierSelectedIndex].UseAdvancedModifierQuantitySettings == 'True') {
      this.showAggregateRule = CommonFunctions.getOnlySelectedIngredientCount(this.modifiers[this.modifierSelectedIndex]) > 0
    }
    else {
      this.showAggregateRule = false;
    }
    if (isHasSubGroup) {

      const isSubGroupHaveSingleModifier = this.checkIfSubGroupHaveModifier(isHasSubGroup);

      if (isSubGroupHaveSingleModifier) {
        this.selectedModifierSubGroupLevel2 = isHasSubGroup.Ingredients[0] as ModifierV2;

        if (!this.selectedModifierSubGroupLevel2.Ingredients || this.selectedModifierSubGroupLevel2.Ingredients.length <= 0) {

          this.selectedModifierSubGroupLevel2.Ingredients = await this.db.getNestedModifierByItemAndModifier(this.selectedModifierSubGroupLevel2.ItemID, this.selectedModifierSubGroupLevel2.ModifierID);
        }

        this.hasSecondLevelSubgroup = true;
      }

      this.hasSubCategory = true;
      this.selectedModifierSubGroup = isHasSubGroup;
      this.subGroupIndex = 0;

    } else {
      this.hasSubCategory = false;
    }

    this.scrollToCard();
    this.checkModifierValid();
    this.getModifierRules();
  }

  private checkIfSubGroupHaveModifier(subGroup: ModifierV2) {

    if (subGroup.Ingredients.length == 1 && subGroup.Ingredients[0].IsModifier) {
      return true;
    }

    return false;
  }

  private checkIfHaveSubGroup(index: number) {
    const modifer = this.modifiers[index];
    if (!modifer || !modifer.Ingredients || modifer.Ingredients.length <= 0) {
      return null;
    }

    for (let i = 0; i < modifer.Ingredients.length; i++) {
      const ing = modifer.Ingredients[i];

      if (ing.IsModifier) {
        const mod = ing as ModifierV2;

        if (mod.IsSubGroup) {
          this.subGroupIndex = i;
          return mod;
        }
      }
    }

    return null;
  }

  parentChangeIngredient(modifier: ModifierV2) {
    this.modifiers[this.modifierSelectedIndex] = modifier;
    if (modifier.UseAdvancedModifierQuantitySettings == 'True') {
      this.showAggregateRule = CommonFunctions.getOnlySelectedIngredientCount(modifier) > 0
    }
    else {
      this.showAggregateRule = false;
    }
    setTimeout(() => {
      // const selectedIngredient =
      //   CommonFunctions.getSelectedIngredientCount(modifier);
      // if (modifier.max == selectedIngredient) {
      //   this.onNextClick();
      //   return;
      // }

      if (modifier.UseAdvancedModifierQuantitySettings != 'True') {
        if (modifier.max == modifier.count) {
          this.onNextClick();
          return;
        }
      } else {
        if (Number(modifier.MaxAggregateQty) == modifier.countAggregate) {
          this.onNextClick();
          return;
        }
      }
    }, 50);
    this.getModifierRules();
    this.checkModifierValid();
  }

  onNextClick() {
    if (this.isCombo) {
      if (this.comboGroups.length > 0) {
        if (this.IsComboGroupSelected) {
          if (
            this.comboGroups[this.comboGroupSelectedIndex].isValid &&
            this.comboGroupSelectedIndex != this.comboGroups.length - (this.numInvisibleGroups + 1)
          ) {
            this.onItemSelect(this.comboGroupSelectedIndex + 1);
          }
        } else {
          this.onItemSelect(0);
        }
      }

      this.checkItemValid();
    } else {
      if (this.isVariationSelected && this.variations.length > 0) {
        if (
          this.selectedVariation &&
          Object.entries(this.selectedVariation).length > 0
        ) {
          if (this.modifiers.length > 0) {
            this.onModifierSelect(0);
          }
        }
      } else if (this.modifiers.length > 0) {
        if (this.isModifierSelected) {
          if (
            this.modifiers[this.modifierSelectedIndex].isValid &&
            this.modifierSelectedIndex != this.modifiers.length - 1
          ) {
            this.onModifierSelect(this.modifierSelectedIndex + 1);
          }
        } else {
          this.onModifierSelect(0);
        }
      }

      this.checkModifierValid();
    }
    this.scrollToCard();
  }

  scrollToCard() {
    // have to add these together to get index of card
    if (!this.isFirstTimeLoad) {
      let index =
        this.modifierSelectedIndex +
        this.comboGroupSelectedIndex +
        this.modifierStartingIndex;
      // console.log(index);
      this.categoryCards[index].scrollIntoView({
        block: 'center',
        inline: 'center',
      });
    }
  }

  onPreviousClick() {
    if (this.isCombo) {
      if (this.IsComboGroupSelected && this.comboGroups.length > 0) {
        if (this.comboGroupSelectedIndex == 0) {
          return;
        } else {
          this.onItemSelect(this.comboGroupSelectedIndex - 1);
        }
      }
    } else {
      if (this.isVariationSelected && this.variations.length > 0) return;

      if (this.isModifierSelected && this.modifiers.length > 0) {
        if (this.modifierSelectedIndex == 0) {
          if (this.variations.length > 0) this.onVariationSelect();
          return;
        } else {
          this.onModifierSelect(this.modifierSelectedIndex - 1);
        }
      }
    }

    this.scrollToCard();
    this.showFixedItemButton();
  }

  onFixItemsClick() {
    if (this.isCombo) {
      this.checkItemValid(true);
    } else {
      if (this.variations.length > 0 && !this.isVariationValid) {
        this.onVariationSelect();
      }

      this.checkModifierValid(true);
    }

    this.scrollToCard();

    // shake animation to grab users attention

    if (this.selectionComponent) {
      const selections = this.selectionComponent.nativeElement.querySelector(
        '#selections'
      ) as HTMLElement;
      selections.className += ' animated shake';
      setTimeout(() => {
        selections.className = 'modifier-list ng-star-inserted';
      }, 1000);
    }
  }

  //combo Related methods

  async checkItemValid(openNext: boolean = false) {
    // is valid by default
    this.IsComboGroupValid = true;

    if (this.comboGroups && this.comboGroups.length > 0) {
      for (let i = 0; i < this.comboGroups.length; i++) {
        const comboGroup = this.comboGroups[i];

        const groupSelectedItem =
          CommonFunctions.getParentCountItem(comboGroup);

        // min and max data can be not correct to fix them and check
        if (comboGroup.min != 0 && comboGroup.min < 0) {
          comboGroup.min = 0;
        }

        if (comboGroup.max <= 0) {
          comboGroup.max = 99999;
        }

        if (comboGroup.IsSelected) {
          if (comboGroup.Items.length > 0) {
            if (
              groupSelectedItem >= comboGroup.min &&
              groupSelectedItem <= comboGroup.max
            ) {
              comboGroup.isValid = true;
            } else {
              this.IsComboGroupValid = false;
              comboGroup.isValid = false;
            }
          }
        } else {
          if (
            groupSelectedItem >= comboGroup.min &&
            groupSelectedItem <= comboGroup.max
          ) {
            comboGroup.isValid = true;
            comboGroup.isSelected = true;
          } else {
            comboGroup.isSelected = false;
            this.IsComboGroupValid = false;
          }
        }

        //direct to error item page
        if (openNext) {
          if (comboGroup.isValid) {
            if (i == this.comboGroupSelectedIndex) {
              if (this.comboGroupSelectedIndex != this.comboGroups.length - (this.numInvisibleGroups + 1)) {
                this.onItemSelect(this.comboGroupSelectedIndex + 1);
              } else {
                this.onItemSelect(this.comboGroupSelectedIndex);
              }
            }
          } else {
            this.onItemSelect(i);
            break;
          }
        }
      }

      this.emitComboGroup.emit({
        inputComboGroup: this.comboGroups,
        isValid: this.IsComboGroupValid,
      });
    }

    this.showFixedItemButton();
  }

  nullifySelections() {
    this.currentComboGroup = null;
    this.currentModifier = null;
    this.currentVariation = null;
  }

  onItemSelect(index: number) {
    this.comboGroupSelectedIndex = index;

    this.nullifySelections();
    this.currentComboGroup = this.comboGroups[index];

    this.IsComboGroupSelected = true;
    this.isVariationSelected = false;
    this.isModifierSelected = false;

    this.scrollToCard();
    this.checkItemValid();
  }

  parentChangeItem(comboGroup: ComboGroupV2) {
    this.comboGroups[this.comboGroupSelectedIndex] = comboGroup;
    // 50 millisecond delay to allow user to see selection
    setTimeout(() => {
      const groupSelectedItem = CommonFunctions.getParentCountItem(comboGroup);
      if (comboGroup.max == groupSelectedItem) {
        this.onNextClick();
      }
    }, 50);

    this.checkItemValid();
  }

  //combo Related methods end

  getSelectedIngredientArrayByModifier(modifier: ModifierV2) {
    return modifier.Ingredients.filter((x) => x.IsSelected).slice(0, 6);
  }

  getSelectedItemArrayByComboGroup(comboGroup: ComboGroupV2) {
    return comboGroup.Items.filter((x) => x.isSelected).slice(0, 6);
  }

  subGroupIngredientChange(modifier: ModifierV2) {
    const selectedModifier = this.modifiers[this.modifierSelectedIndex];

    const parentCount = CommonFunctions.getSelectedIngredientCount(modifier);

    if (parentCount == 0) {
      modifier.IsSelected = false;
    }

    for (let i = 0; i < selectedModifier.Ingredients.length; i++) {
      const ing = selectedModifier.Ingredients[i];

      if (ing.IsModifier) {
        let mod = ing as ModifierV2;

        if (mod.IsSubGroup) {
          mod = modifier;
        }
      }
    }

    this.checkModifierValid();
  }

  rules = ""
  isRequired = false;
  isCompleted = false;
  getModifierRules() {
    let modifier = this.modifiers[this.modifierSelectedIndex];
    if (!this.isAggregate) {
      if (modifier && modifier.rule != '') {
        this.rules = modifier.rule;
        this.isRequired = false;
        this.isCompleted = false;
      } else {
        this.isRequired = false;
        this.isCompleted = false;
        this.rules = '';
      }
    } else {
      const selectedIngredients = CommonFunctions.getOnlySelectedIngredientCount(modifier);
      if (selectedIngredients > 0) {
        if (modifier.UseAdvancedModifierQuantitySettings == 'True') {
          this.showAggregateRule = true;
        }
        if (modifier.countAggregate <= modifier.MaxAggregateQty
          && modifier.countAggregate >= modifier.MinAggregateQty) {
          this.rules = 'Complete'
          this.isRequired = false;
          this.isCompleted = true;
        } else {
          this.rules = 'Required';
          this.isCompleted = false;
          this.isRequired = true;
        }

      } else {
        if (modifier.rule != '') {
          this.rules = modifier.rule;
          this.isRequired = false;
          this.isCompleted = false;
        } else {
          this.rules = '';
          this.isRequired = false;
          this.isCompleted = false;
        }
      }
    }
  }
}
