import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { cartSlideIn } from 'src/app/animations';
import { CartService } from 'src/app/services/cart.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { environment } from 'src/environments/environment';
import { CartComponent } from 'src/app/components/cart/cart.component';
import { AlcoholicItemsExceedMaxComponent } from 'src/app/components/dialogs/alcoholic-items-exceed-max/alcoholic-items-exceed-max.component';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import {
  CategorySalesType,
  CommonFunctions,
  Snackbar,
} from 'src/app/services/common';
import { ItemV2 } from 'src/app/models/item';
import { LoyaltyService } from 'src/app/services/loyalty.service';
import { DatabaseService } from 'src/app/services/database.service';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { FixedItemDiscount } from 'src/app/models/discounts';
import { DiscountService } from 'src/app/services/discount.service';
import { ItemV2 as Item} from 'src/app/models/item';
import { ParBrinkService } from 'src/app/services/par-brink.service';

@Component({
  selector: 'app-cart-items',
  templateUrl: './cart-items.component.html',
  styleUrls: ['./cart-items.component.css'],
  animations: [cartSlideIn],
})
export class CartItemsComponent implements OnInit,  OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }
  private allSubsCription: Subscription[] = [];

  @Input() isCartOpen: boolean = true;

  @Input() cart!: CartComponent;

  @Input() removeAlk!: boolean;

  @Input() isHybrid: boolean = false;

  @Output() changePrice = new EventEmitter<boolean>();

  @Output() isCartOpenChange = new EventEmitter<boolean>();

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  cartItems: ItemV2[] = [];

  branchLogo: string = '';

  loyaltyType: number = 0;

  textColor: string = '';

  priceColor: string = '';

  bordersTypeItem: boolean = false;

  imageRadius: string = '';

  primaryColor: string = '';

  isRemovePricingAndPayments = GeneralSetting.getIsRemovePricingAndPayments();

  upsellItemText: string = '';

  rewardItemText: string = '';

  yourItemsText: string = '';

  placeholderImage : string = '';
  
  fixedItemDisc : FixedItemDiscount[] = [] as FixedItemDiscount[];

  constructor(
    private readonly cartService: CartService,
    private readonly loyaltyService: LoyaltyService,
    private readonly isKioskService: IsKioskService,
    private readonly router: Router,
    private database: DatabaseService,
    private language: LanguageService,
    private readonly modalService: NgbModal,
    private readonly dataService : DataService,
    private discountService: DiscountService,
    private parbrink:ParBrinkService
  ) {

  }

  ngOnInit(): void {
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );

    this.branchLogo = GeneralSetting.getBranchLogo();
    this.textColor = GeneralSetting.getTextColor();
    this.priceColor = GeneralSetting.getPriceColor();
    this.primaryColor = GeneralSetting.getPrimaryColor();

    this.getItems(true);
    this.loyaltyType = this.loyaltyService.loyaltyType;
    this.imageRadius = GeneralSetting.getBorderPercentage();
    this.bordersTypeItem =
      GeneralSetting.getBorderTypeItems().toLowerCase() == 'true';
    this.cartService.cartItemsComponent = this;

    this.setSubscription();
    this.setFixedItemDisc();
  }

  ngAfterViewInit() {
    this.getItems(true);
  }

  async setFixedItemDisc(){
    this.fixedItemDisc = await this.discountService.getFixedItemDisc();
  }

  loadText() {
    this.rewardItemText = this.language.getTextElement('lbl_loyalty_item');
    this.upsellItemText = this.language.getTextElement('lbl_upsell_item');
    this.yourItemsText = this.language.getTextElement('your_items');
  }

  private setSubscription() {
    this.allSubsCription.push(
      this.cartService.doScrollCart.subscribe((data) => {
        this.scrollCart();
      })
    );
    this.allSubsCription.push(
      this.cartService.subCartItems.subscribe((data : Item[]) => {
        this.getItems(false);
        //this.changePrice.emit(true);
      })
    );
    this.allSubsCription.push(
      this.cartService.sub_subtotal.subscribe((data) => {
        this.updateFixedItemOnSubtotal(data);
      })
    );
  }

  getItems(shouldScroll: boolean) {
    const cartItems = this.cartService.getCartItems();

    if (!GeneralSetting.getIsRemovePricingAndPayments()) {
      for (let ciI = 0; ciI < cartItems.length; ciI++) {
        const item = cartItems[ciI];
        item.totalPrice = CommonFunctions.getItemPrice(item) * Number(item.Quantity);
      }
    }

    if (shouldScroll) {
      this.cartService.doScrollCart.next(true);
      //this.cartService.scrollCart();
    }

    this.cartItems = cartItems;

  }

  getCurrentQuantity(item: any) {
    const cartItems = this.cartService.getCartItems();
    this.cartItems = cartItems;

    let qty = 0;
    for (let cartItem of this.cartItems) {
      if (cartItem.ItemID === item.ItemID) {
        qty += Number(cartItem.Quantity);
      }
    }
    return qty;
  }

  async removeFromCart(cartItem: ItemV2) {
    const quantityInCart = cartItem.Quantity;
    if (quantityInCart === undefined) return;
    this.cartService.removeFromCart(cartItem);
    if (cartItem.IsLoyaltyDiscountItem) {
      this.loyaltyService.punchhAppliedRewardID = '';
      this.loyaltyService.oloPaytronixAppliedRewardID = '';
      this.loyaltyService.selectedLoyaltyReward = '';
      this.loyaltyService.subSelectedLoyaltyReward.next('');
    }

    this.getItems(true);
    this.changePrice.emit(true);
    if (this.cartItems.length == 1) {
      if (this.cartItems[0].IsLoyaltyDiscountItem) {
        this.removeFromCart(this.cartItems[0]);
      }
    }
  }

  getFormattedURL(item: any): string {
    return (
      (item.ImageUrl &&
        (item.ImageUrl.startsWith(this.baseImageUrl)
          ? item.ImageUrl
          : this.baseImageUrl + item.ImageUrl)) ||
        (this.placeholderImage !=''? this.baseImageUrl+this.placeholderImage : '')
    );
  }

  checkAlkolLimit(
    element: ItemV2,
    currValue: any,
    totalComboAlcoholCount: any = 0
  ) {
    const alkolMax = GeneralSetting.getMaxAlcoholicItemsPerOrder();
    if (alkolMax !== null && Number(alkolMax) > 0) {
      if (Number(element.OrderMaximumQuantity.toString()) > 0) {
        element.OrderMaximumQuantity = Math.min(
          Number(element.OrderMaximumQuantity.toString()),
          Number(alkolMax)
        );
      } else {
        element.OrderMaximumQuantity = Number(alkolMax);
      }

      let newAlcoholCount = CommonFunctions.getLatestAlcoholicCount(
        totalComboAlcoholCount,
        currValue,
        true
      );
      if (newAlcoholCount <= Number(alkolMax)) {
        if (currValue < element.OrderMaximumQuantity) {
          element.Quantity = (currValue + 1).toString();
          if(GeneralSetting.getParBrinkIntegrationID()!=''){
            this.parbrink.edited=true
          }
          GeneralSetting.setCurrentCountofAlkol(newAlcoholCount.toString());
        }
      } else {
        AlcoholicItemsExceedMaxComponent.open(this.modalService);
      }
    }
  }

  increaseAmount(item: ItemV2) {
    
    // Checks fixed item quantity limit
    if(this.fixedItemDisc != undefined && this.fixedItemDisc.length > 0){
      var isFixedItemAvail = this.discountService.isFixedItemAvail(this.fixedItemDisc[0],item);
      if(isFixedItemAvail){
        return;
      }
    }

    let currentQty = this.getCurrentQuantity(item);
    const value = Number(item.Quantity);
    const quantity = Number(item.Quantity);
    const maxNo = Number(item.OrderMaximumQuantity);
    const alkolMax = Number(GeneralSetting.getMaxAlcoholicItemsPerOrder());
    var isCombo = item.IsCombo.toString();
    if (isCombo.toLowerCase() == '0' || isCombo.toLowerCase() == 'false') {
      if (!maxNo) {
        if (
          item.ItemCategorySalesTypeID ==
            CategorySalesType.Alcohol.toString() &&
          alkolMax > 0
        ) {
          const currentAlcohol = Number(
            GeneralSetting.getCurrentCountofAlkol()
          );
          if (currentAlcohol + 1 > alkolMax) {
            AlcoholicItemsExceedMaxComponent.open(this.modalService);
          } else {
            if(GeneralSetting.getParBrinkIntegrationID()!=''){
              this.parbrink.edited=true
            }
            item.Quantity = String(quantity + 1);
            GeneralSetting.increaseAlcohol(1);
            this.changePrice.emit(true);
          }
        } else {
          if(GeneralSetting.getParBrinkIntegrationID()!=''){
            this.parbrink.edited=true
          }
          item.Quantity = String(quantity + 1);
          this.changePrice.emit(true);
        }
      } else {
        if (
          item.ItemCategorySalesTypeID ===
            CategorySalesType.Alcohol.toString() &&
          alkolMax > 0
        ) {
          const currentAlcohol = Number(
            GeneralSetting.getCurrentCountofAlkol()
          );
          if (currentAlcohol + 1 > alkolMax) {
            AlcoholicItemsExceedMaxComponent.open(this.modalService);
          } else {
            if(GeneralSetting.getParBrinkIntegrationID()!=''){
              this.parbrink.edited=true
            }
            item.Quantity = String(quantity + 1);
            GeneralSetting.increaseAlcohol(1);
            this.changePrice.emit(true);
          }
        } else {
          if(GeneralSetting.getParBrinkIntegrationID()!=''){
            this.parbrink.edited=true
          }
          item.Quantity = String(quantity + 1);
          currentQty++;
          this.changePrice.emit(true);
        }
      }
    } else {
      let totalComboAlcoholCount = CommonFunctions.isComboAlcoholic(item)
      if (totalComboAlcoholCount > 0) {
        this.checkAlkolLimit(item, value, totalComboAlcoholCount)
      } else {
        const maxQuantity = Number(item.OrderMaximumQuantity.toString())
        if (maxQuantity > 0) {
          if (value < Number(maxQuantity)) {
            if(GeneralSetting.getParBrinkIntegrationID()!=''){
              this.parbrink.edited=true
            }
            item.Quantity = (value + 1).toString()
          } else {
            let error = this.language.getTextElement('item_has_max_quantity')
            Snackbar.show(`${error} ${maxQuantity}`, 2500)
          }
        } else {
          if(GeneralSetting.getParBrinkIntegrationID()!=''){
            this.parbrink.edited=true
          }
          item.Quantity = (value + 1).toString()
        }
      }
    }
    this.getItems(false);
  }

  decreaseAmount(item: ItemV2, index: number) {
    
    let currentQty = this.getCurrentQuantity(item);
    const value = Number(item.Quantity);
    var isCombo = item.IsCombo.toString();
    if (isCombo.toLowerCase() == '0' || isCombo.toLowerCase() == 'false') {
      const quantity = Number(item.Quantity);
      let minNo = item.OrderMinimumQuantity;
      if (!minNo) {
        minNo = 1;
      }
      if (quantity > minNo) {
        if(GeneralSetting.getParBrinkIntegrationID()!=''){
          this.parbrink.edited=true
        }
        item.Quantity = String(quantity - 1);
        this.changePrice.emit(true);
        if (
          item.ItemCategorySalesTypeID === CategorySalesType.Alcohol.toString()
        ) {
          GeneralSetting.decreaseAlcohol();
        }
        if (item.CurrentStock != null) {
          let newOne = this.database.categorySubject.getValue();
          for (let i = 0; i < newOne.length; i++) {
            if (newOne[i].CategoryID == item.CategoryID) {
              for (let j = 0; j < newOne[i].associatedItems!.length; j++) {
                if (newOne[i].associatedItems![j].ItemID == item.ItemID) {
                  newOne[i].associatedItems![j].CurrentStock = (
                    Number(newOne[i].associatedItems![j].CurrentStock) + 1
                  ).toString();
                  if (
                    Number(newOne[i].associatedItems![j].CurrentStock) >
                    Number(newOne[i].associatedItems![j].LowThreshold)
                  ) {
                    newOne[i].associatedItems![j].SoldOut = false;
                  }
                  this.database.categorySubject.next(newOne);
                  break;
                }
              }
              break;
            }
          }
        }
      } else {
        if (currentQty == 1) {
          this.removeFromCart(item);
        } else {
          if(GeneralSetting.getParBrinkIntegrationID()!=''){
            this.parbrink.edited=true
          }
          item.Quantity = (value - 1).toString();
        }
      }
    } else {
      let totalComboAlcoholCount = CommonFunctions.isComboAlcoholic(item);
      if (totalComboAlcoholCount > 0) {
        if (currentQty == 1) {
          this.removeFromCart(item);
        } else {
          if(GeneralSetting.getParBrinkIntegrationID()!=''){
            this.parbrink.edited=true
          }
          item.Quantity = (currentQty - 1).toString();
          let newAlcoholCount = CommonFunctions.getLatestAlcoholicCount(
            totalComboAlcoholCount,
            currentQty,
            false
          );
          GeneralSetting.setCurrentCountofAlkol(newAlcoholCount.toString());
        }
      } else {
        if (currentQty == 1) {
          this.removeFromCart(item);
        } else {
          if(GeneralSetting.getParBrinkIntegrationID()!=''){
            this.parbrink.edited=true
          }
          item.Quantity = (value - 1).toString();
        }
      }
    }
    this.getItems(false);
  }

  editItem(item: ItemV2) {
    const direction: string = CommonFunctions.getItemType(item, true);
    if (direction == 'menu') {
      return false;
    }
    if(GeneralSetting.getParBrinkIntegrationID()!=''){
      this.parbrink.edited=true
    }
    this.router.navigate([`/${direction}`], {
      queryParams: {
        id: item.ItemID,
        category: item.CategoryID,
        guid: item.guid,
        concessionaireId: item.ConcessionaireId,
      },
    })
    return false;
  }

  scrollID(guid: string) {
    let item = document.getElementById(guid);
    console.dir({ ...item, guid });
    if (item) item.scrollIntoView();
  }

  private scrollCart() {
    setTimeout(() => {
      var element = document.getElementById('cart-list');
      if (element) {
        const smoothScrollTo = (to: number, duration: number) => {
          const start = (element as HTMLElement).scrollLeft,
            change = to - start,
            startDate = +new Date();
          // t = current time
          // b = start value
          // c = change in value
          // d = duration
          const easeInOutQuad = (t: any, b: any, c: any, d: any) => {
            t /= d / 2;
            if (t < 1) return (c / 2) * t * t + b;
            t--;
            return (-c / 2) * (t * (t - 2) - 1) + b;
          };

          const animateScroll = () => {
            const currentDate = +new Date();
            const currentTime = currentDate - startDate;
            (element as HTMLElement).scrollLeft = parseInt(
              easeInOutQuad(currentTime, start, change, duration)
            );
            if (currentTime < duration) {
              requestAnimationFrame(animateScroll);
            } else {
              (element as HTMLElement).scrollLeft = to;
            }
          };
          animateScroll();
        };
        // if (bottom) bottom.scrollIntoView({ behavior: 'smooth' });
        smoothScrollTo(element?.scrollWidth - element?.clientWidth, 300);
      }
    }, 100);
  }

  /**
   * Check and alert after every subtotal update if fixed item available in the cart
   * @param subtotal 
   */
  private async updateFixedItemOnSubtotal(subtotal : number){
      var fixedItemDiscount = await this.discountService.checkFreeItemOnSubtotalUpdate(subtotal);
      this.findFixedItemOperation(fixedItemDiscount);
  }

  /**
   * Check fixed item's Quantity need to be updated or removed from the cart
   * @param fixedItemDiscount 
   */
  private findFixedItemOperation(fixedItemDiscount : any){
    if(fixedItemDiscount && fixedItemDiscount != undefined){
      const cartItems = this.cartService.getCartItems().filter(
        (x) => x.ItemID == fixedItemDiscount.ItemID
      );
      if(cartItems && cartItems != undefined && cartItems.length > 0){
        if(fixedItemDiscount.Check == 1){  
          var decQty = Number(cartItems[0].Quantity) - fixedItemDiscount.FixedQty;
          for(var i=0; i<decQty ; i++){
            this.decreaseAmount(cartItems[0],0);
          } 
        }else if(fixedItemDiscount.Check == 2){
          this.removeFromCart(cartItems[0]);
        }
      }
    }
  }
}
