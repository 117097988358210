import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { loggingData, LogService } from 'src/app/services/log.service';
import { DownloadingPopupComponent } from '../../dialogs/downloading-popup/downloading-popup.component';
import { environment } from 'src/environments/environment';
import { AskConfigureYourDeviceComponent } from '../../dialogs/ask-configure-your-device/ask-configure-your-device.component';
import { AskDeviceNameComponent } from '../../dialogs/ask-device-name/ask-device-name.component';
import { LoginService } from 'src/app/services/login.service';
import { GeneralSetting } from '../../../services/generalSetting.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { DatabaseService } from '../../../services/database.service';
import { PusherService } from '../../../services/pusher.service';
import { SaveSettingsService } from '../../../services/save-settings.service';
import { OLOService } from 'src/app/services/olo.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { LanguageService } from 'src/app/services/language.service';
import { timeout } from 'rxjs/operators';
import { Snackbar } from 'src/app/services/common';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
})
export class LoginFormComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.timeOutThemeRedirect) {
      clearTimeout(this.timeOutThemeRedirect);
    }

    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  @ViewChild(DownloadingPopupComponent)
  downloadingPopup!: DownloadingPopupComponent;

  @ViewChild(AskConfigureYourDeviceComponent)
  askConfigureYourDevice!: AskConfigureYourDeviceComponent;

  @ViewChild(AskDeviceNameComponent)
  askDeviceNameComponent!: AskDeviceNameComponent;

  //introduce this variable because if we minimize app
  // and load again then receive broad cast 10 times
  downloadCompleteCount = 0;

  ref: any;

  timeOutThemeRedirect!: any;

  isProduction: boolean = environment.production;

  branchInfo: any;

  username: string = '';

  password: string = '';

  isShown: boolean = false;

  isLoading: boolean = false;

  isModalOpen: boolean = false;

  branchDetails: any;

  totalDownloadSets: number = 0;

  totalDownloadSetsQueued: number = 0;

  downloadUrls: string[] = [];

  dataService: DataService;

  ipAddress: string = '';

  deviceID: string = '';

  deviceName: string = '';

  version = environment.Version;

  isKiosk = this.isKioskService.isKiosk();

  isDev: boolean = true;

  private anim: any;

  public animationSpeed: any = 1;


  constructor(
    private readonly isKioskService: IsKioskService,
    private loginService: LoginService,
    DataService: DataService,
    private router: Router,
    private logger: LogService,
    // private networkStatusService: NetworkStatusService,
    private olo: OLOService,
    private modalService: NgbModal,
    private KeyboardService: KeyboardService,
    private readonly db: DatabaseService,
    private readonly pusherService: PusherService,
    private readonly saveSettingsService: SaveSettingsService,
    private language: LanguageService,
    public http: HttpClient
  ) {
    this.dataService = DataService;
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }

  loginText: string = '';
  passwordText: string = '';
  ipAddressText: string = '';
  versionNameText: string = '';
  appIDText: string = '';
  invalidText: string = '';
  userText: string = '';
  environmentText: string = '';
  currentEnvironmentText: string = '';

  loadText() {
    this.appIDText = this.language.getTextElement('txt_application_id');
    this.invalidText = this.language.getTextElement(
      'txt_invalid_email_or_password'
    );
    this.versionNameText = this.language.getTextElement('text_version_name');
    this.ipAddressText = this.language.getTextElement('txt_ip_address');
    this.loginText = this.language.getTextElement('txt_login');
    this.passwordText = this.language.getTextElement('password');
    this.userText = this.language.getTextElement('username');
    this.environmentText = this.language.getTextElement('environment');
    this.currentEnvironmentText = environment.environment;
  }

  ngOnInit(): void {
    // this.KeyboardService.open()
    // this.KeyboardService.stringSub.next(this.username)

    this.checkLoading();
    // this.networkStatusService.check();
    GeneralSetting.setBaudRate(environment.baudRate.toString());
    GeneralSetting.setBaudRate(
      GeneralSetting.getBaudRate() != ''
        ? GeneralSetting.getBaudRate()
        : environment.baudRate.toString()
    );

    this.getDeviceName();
    this.getSerialNumber();

    const databasePresent = GeneralSetting.getIsDataBasePresent();

    this.allSubsCription.push(
      this.saveSettingsService.themeSub.subscribe((data) => {
        if (data && databasePresent == 'True') {
          this.timeOutThemeRedirect = setTimeout(() => {
            this.router.navigateByUrl('/touchToStart');
          }, 500);
        }
      })
    );

    if (databasePresent == 'True') {
      this.db.getDetailSetting();
      this.db.getKitchenPrinters(true);
      this.pusherService.getPusherKeys(true);

      this.saveSettingsService.theme();

      var log = new loggingData(
        'KIOSK Restarted',
        'Redirect to touch to start',
        'Log',
        'KIOSK Restarted, Redirect to touch to start.',
        false
      );
      this.logger.sendLogToServer(log);
    }
    this.KeyboardPopup();
    this.loginWithQR();
  }
  // add click event on first input field @jay
  KeyboardPopup() {
    let usernameElement: HTMLElement = document.getElementById(
      'name-text'
    ) as HTMLElement;
    usernameElement.click();
  }

  checkLoading() {
    this.allSubsCription.push(
      this.loginService.isLoading.subscribe((data) => (this.isLoading = data))
    );
    this.allSubsCription.push(
      this.loginService.isModalOpen.subscribe(
        (data) => (this.isModalOpen = data)
      )
    );
  }

  // toggle password visibility
  revealPassword() {
    this.isShown = !this.isShown;
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  stop() {
    this.anim.stop();
  }

  play() {
    this.anim.play();
  }

  pause() {
    this.anim.pause();
  }

  setSpeed(speed: any) {
    this.animationSpeed = speed;
    this.anim.setSpeed(speed);
  }

  getDeviceName() {
    // send message to tizen scope to retrieve kiosk specific data
    const message = {
      messageType: 'getDeviceName',
    };
    window.top!.postMessage(message, '*');

    window.onmessage = (event: any) => {
      if (event.data.messageType == 'DeviceNameReturn') {
        // console.log("DeviceName in Ts",event.data.data.deviceName);

        // GeneralSetting.setDeviceName(event.data.data.deviceName);
        this.deviceName = event.data.data.deviceName;
      }
    };
  }

  getSerialNumber() {
    const message = {
      messageType: 'getSerialNo',
    };

    window.top!.postMessage(message, '*');

    window.onmessage = (event: any) => {
      if (
        event.data.messageType == 'SerialNoReturn' ||
        event.data.messageType == 'getSerialNo'
      ) {
        // only messages that of the type we want

        // if no serial number data -> login as device #1
        //we are calling this service from different
        // place so no need below code in setting screen

        if (event.data.data) {
          try {
            this.deviceID = event.data.data.serialNo;
            this.ipAddress = event.data.data.ipAddr;
          } catch (e) {}
        }
      } else if (event.data.messageType == 'DeviceNameReturn') {
        // console.log("DeviceName in Ts",event.data.data.deviceName);

        // GeneralSetting.setDeviceName(event.data.data.deviceName);
        this.deviceName = event.data.data.deviceName;
      }
    };
  }
  /**
   * @description activates kiosk scanner and sets showQR flag (used to track scanner status programmatically) to true
   */
  startScanner() {
    var messageType = 'startScanner';
    var message = {
      messageType: messageType,
    };
    window.top!.postMessage(message, '*');
    setTimeout(() => {
      this.stopScanner();
    }, 10 * 1000);
  }
  /**
   * @description deactivates kiosk scanner and sets showQR flag (used to track scanner status programmatically) to false
   */
  stopScanner() {
    var messageType = 'stopScanner';
    var message = {
      messageType: messageType,
    };
    window.top!.postMessage(message, '*');
  }
  listeners: EventListener[] = [];
  listener: EventListener | undefined;
  removeAllListeners() {
    if (this.listeners.length) {
      this.listeners.forEach((x: EventListener) => {
        window.removeEventListener('message', x);
      });
    }
    this.listeners = [];
  }

  validateAuth(key: string) {
    const arr = key.split('|');
    if (arr[0] && arr[0] == 'basic') {
      if(arr.length==3){
        this.username = arr[1];
        this.password = arr[2];
        return true;
      }else{
        return false
      }
    } else {
      return false;
    }
  }
  loginWithQR() {
    this.listener = (event: any) => {
      if (event.data.messageType === 'ScannerResult') {
        this.removeAllListeners();
        if (event.data.result) {
          let valid =this.validateAuth(event.data.result);
          if (valid) {
            this.doLogin();
          }else{
            Snackbar.show("The scanned QR code is not supported, please scan again", 3000)
          }
        }
      }
    };
    if (this.listeners.length == 0) {
      this.listeners.push(this.listener);
      window.addEventListener('message', this.listener);
    }
  }
  doLogin() {
    this.isLoading = true;
    this.stopScanner();
    this.KeyboardService.KeyboardStatus = false;
    this.dataService
      .IsOnlineCheckSK()
      .pipe(timeout(50000))
      .toPromise()
      .then(
        (data) => {
          this.KeyboardService.KeyboardStatus = false;
          this.loginService.doLogin(this.username, this.password).then(() => {
            var log = new loggingData(
              'Login Form',
              'Branch Login Succeeded',
              'Success BranchLogin',
              `Branch Login Succeeded`,
              true
            );

            this.logger.sendLogToServer(log);
          });
          this.checkLoading();
        },
        (error) => {
          let err = this.language.getTextElement('device_offline_error');
          // let newLog:loggingData= new loggingData('Error during Login', "alert SKAR","login",JSON.stringify(error),true)
          // this.logger.sendLogToServer(newLog)
          Snackbar.show(err, 3000);
        }
      );
  }
}
