<div class="search-scannable-items">
    <div class="category-name" [ngStyle]="{ 'background-color': primaryColor}">
        <h2>{{searchScannableText}}</h2>
    </div>
    <div class="search-form">
        <div class="input-group">
            <span class="input-group-text">
                <img src="../../assets/images/barcode-identification.png" class="barscan" />
            </span>
            <input KeyboardCustom readonly id="searchitem" type="search" class="form-control" placeholder="Search" 
            [(ngModel)]="searchitem" name="search" (input)="searchThis()">
            <span class="input-search-span-close" (click)="clearSearch()">
                <img src="../../assets/images/close.png"/>
            </span>
        </div>
    </div>

    <h4>{{searchResultsText}}</h4>
    <div class="search-result"  [ngStyle]="setSearchListHeight()">
        <div *ngFor="let item of searchableItems" [ngClass]="{'item-container' : searchableItems.length> 0,
            'item-container-one': searchableItems.length == 0 }" class="result"
            appDebounceClick  id="{{ item.ItemID }}" (debounceClick)="onItemClick(item, $event)">
            <div >
                <strong>{{ item.Name }}</strong> <br />
                <span> {{ (item.Price | CurrencyPipe) }} </span>
            </div>
        </div>
    </div>

    <app-cart-items
    [isCartOpen]="false"
    [isHybrid]="true"
    [removeAlk]="removeAlcohol"
    ></app-cart-items>
    <div class="footer-search justify-content-start">
        <button matRipple class="white-btn warn" (click)="backtomenu()" [style.background-color]="positiveColor" [style.color]='positiveColorText'>{{backText}}</button>
    </div>
</div>

<div style="display: none">
    <app-timeout style="display: none" [hidden]="true"></app-timeout>
</div>
