<div class="pageContainer">
  <!-- Scroll Animation -->
  <div class="overlay-screen" [hidden]="!showOverlay">
    <img src="../../../assets/images/scrollformore.gif"  (click)="showOverlay = false;" />
    <h3>{{scrollForMoreText}}</h3>
  </div>
  <!--concessionaire bar started-->
  <!-- scroll bar -->
  <ng-container *ngIf="isConcessionaire">
    <div class="cons-two menu-cons">
      <div class="cons-header" [style.background-color]="primaryColor" style="background-image : url({{
          concessionaireHeaderImage | functionCall: setHeaderImage:imageBaseUrl
        }})">
        <div class="company-logo" (click)="backtoGridScreen()">
          <img *ngIf="!isShowBranchLogo" src="{{ branchLogo }}" onerror="this.src='assets/images/Image_21.png'"
            class="img-fluid" alt="logo" />
        </div>
        <button (click)="clickFilter($event)" class="{{
            isHideFilter
              ? 'do-noth btn btn-warning filter-btn'
              : 'btn btn-warning filter-btn '
          }}" [disabled]="isHideFilter">
          <svg width="29" height="26" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M23.4387 15.9189C26.1556 15.936 28.352 18.1339 28.3348 20.818C28.3177 23.502 26.0934 25.6721 23.3765 25.655C20.6579 25.6378 18.46 23.4399 18.4772 20.7558C18.4943 18.0718 20.7202 15.9017 23.4387 15.9189ZM23.4236 18.2774C22.0145 18.2685 20.861 19.3871 20.8521 20.7708C20.8433 22.1561 21.9824 23.2875 23.3915 23.2964C24.7991 23.3053 25.951 22.1882 25.9599 20.803C25.9687 19.4193 24.8312 18.2863 23.4236 18.2774ZM11.8446 19.5957C12.5001 19.5998 13.0287 20.1315 13.0245 20.7825C13.0204 21.4334 12.485 21.9584 11.8295 21.9543L1.85313 21.8914C1.19764 21.8872 0.66903 21.3556 0.673191 20.7046C0.677351 20.0537 1.21272 19.5287 1.8682 19.5328L11.8446 19.5957ZM5.68468 0.541809C8.40321 0.558944 10.5995 2.75846 10.5824 5.44249C10.5652 8.12652 8.34099 10.295 5.62246 10.2779C2.90551 10.2608 0.707583 8.06439 0.724737 5.38036C0.741891 2.69633 2.96773 0.524684 5.68468 0.541809ZM5.66961 2.90036C4.26205 2.89149 3.10854 4.01007 3.09969 5.39533C3.09085 6.77901 4.22997 7.91048 5.63753 7.91935C7.04667 7.92823 8.19858 6.81121 8.20742 5.42752C8.21628 4.04227 7.07874 2.90924 5.66961 2.90036ZM26.3671 4.44663C27.0226 4.45076 27.5512 4.98243 27.5471 5.63339C27.5429 6.28435 27.0076 6.80931 26.3521 6.80518L16.3773 6.74231C15.7218 6.73818 15.1932 6.20651 15.1973 5.55555C15.2015 4.90459 15.7369 4.37963 16.3923 4.38376L26.3671 4.44663Z"
              style="fill:{{ positiveTextColor }}" />
          </svg>

          {{foodFiltersText}}
        </button>

        <div *ngIf="isLoyalty && useLoyalty && !isRemovePricingAndPayments" class="user-info" (click)="onUserClick()">
          <button class="btn btn-primary">
            <span class="material-icons"> account_circle </span>{{ userName }}
          </button>
        </div>
      </div>

      <div class="cons-brands" style=" position: relative; height:{{
          isFilterApplied
            | functionCall: getFilterContainerHeight:isItemTagsSelected:conSize
        }}">
        <div class="filter-blocks" *ngIf="isFilterApplied != '' || isItemTagsSelected != ''">
          <ng-container *ngFor="let selcon of selConcessionaireFilters">
            <p *ngIf="selcon.isSelected" id="{{ selcon.ConcessionaireTypeID }}">
              {{ selcon.Name }}
              <button class="btn btn-primary" (click)="
                  onConcessionaireTypeSelection(
                    selcon.ConcessionaireTypeID,
                    false,
                    $event,
                    selcon.isItemTag
                  )
                ">
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.8278 33.6663C26.0325 33.6663 33.4945 26.2044 33.4945 16.9997C33.4945 7.79493 26.0325 0.333008 16.8278 0.333008C7.62305 0.333008 0.161133 7.79493 0.161133 16.9997C0.161133 26.2044 7.62305 33.6663 16.8278 33.6663Z"
                    style="fill:{{ secondaryColor }}" />
                  <path d="M21.8281 12L11.8281 22" style="stroke:{{ positiveTextColor }}" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11.8281 12L21.8281 22" style="stroke:{{ positiveTextColor }}" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </button>
            </p>
          </ng-container>
        </div>
        <div class="brans-info">
          <ng-container *ngFor="let con of allConcessionaireSub | async">
            <div class="{{
                isFilter
                  | functionCall
                    : getActiveConceptCard
                    : selectedConcessionaire
                    : con
              }}" (click)="onConcessionaireClick(con)">
              <img src="{{
                  con
                    | functionCall
                      : getConcessionaireLogo
                      : imageBaseUrl
                      : concessionaireBaseUrl
                }}" alt="company-logo" class="img-fluid" />
              <div class="white-bg"></div>
            </div>
          </ng-container>

          <div class="{{
              isFilter ? 'brands-block brands-block-sm' : 'brands-block'
            }}" [style.background-color]="secondaryColor" (click)="onBrandFilter()"
            *ngIf="conSize > (isFilter ? 7 : 6)">
            <p>
              {{viewMoreText}}
              <span class="material-icons"> keyboard_arrow_down </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!--end -->

  <div class="no-result"
    *ngIf="(isFilterApplied == '' && isItemTagsSelected =='') && allConcessionaireSub && conSize <= 0">
    <img src="/assets/images/loading/refresh_loading.gif" class="loading-spinner" />
  </div>

  <div class="no-result"
    *ngIf="(isFilterApplied != '' || isItemTagsSelected !='') && allConcessionaireSub && conSize <= 0">
    <div class="img">
      <img src="../../../assets/images/no-result.svg" alt="No-Result" class="img-fluid" />
    </div>
    <h1>{{noResultsText}}</h1>
    <p>{{tryDifferentFilterText}}</p>
  </div>

  <ng-container *ngIf="isConcessionaire ? conSize > 0 : 1">
    <div class="menuContainer" [ngClass]="{ open: openCart }" style="position: relative; height:{{
        openCart
          ? isConcessionaire
            ? '69vh'
            : '80vh'
          : isConcessionaire
          ? '71vh'
          : '85vh'
      }}">
      <div class="categoryBar" [ngClass]="{ open: openCart }" style="height:{{
          openCart
            ? isConcessionaire
              ? (isFilterApplied != ''|| isItemTagsSelected != '')
                ? '64.6vh'
                : '68.4vh'
              : '84.2vh'
            : isConcessionaire
            ? (isFilterApplied != ''|| isItemTagsSelected != '')
              ? '67.4vh'
              : '71vh'
            : '87vh'
        }}" [ngStyle]="{ 'background-color': categoryColor }">
        <div class="branchLogo" [hidden]="isConcessionaire">
          <img src="{{ branchLogo }}" onerror="this.onerror=null; this.src='../../../assets/images/Image_21.png'"
            class="logo img-responsive even-pad4" />
        </div>

        <!-- Side Category Start -->
        <div style="position: relative; height: {{
            isConcessionaire
              | functionCall: getContainerHeight:openCart:isHybridMode
          }}" id="category-container" 
          (scroll)="onCategoryScroll()"
          #categoryContainerDiv>
          <div class="sidebar-logo" [ngStyle]="{ 'background-color': categoryColor }" *ngIf="selectedConcessionaire">
            <img src="{{
                selectedConcessionaire
                  | functionCall
                    : getConcessionaireLogo
                    : imageBaseUrl
                    : concessionaireBaseUrl
                    : true
              }}" onerror="this.src='assets/images/Image_21.png'" />
          </div>
          <div *ngIf="selectedCategory !== undefined && selectedCategory?.isSub">
            <button class="top-button" [ngStyle]="{ 'background-color': categoryColor }"
              (click)="onSideCategoryClick(selectedCategory?.parent, true)">
              <i class="fas fa-angle-double-left" [ngStyle]="{ color: textColor }">
                {{ selectedCategory?.parent?.Name|uppercase }}
              </i>
            </button>
          </div>
          <div *ngIf="selectedCategory" class="categoryItems category"
            [ngStyle]="{ 'background-color': categoryColor }">
            <div *ngFor="let category of currentCategoryToShow" class="slide animated fadeIn bmargin8"
              (click)="onSideCategoryClick(category)" id="{{ category.CategoryID }}">
              <!--<div [innerHtml]="cartItem.Modifiers | functionCall : getFormattedModifier"></div>-->
              <img [ngStyle]="{
                  'border-radius': bordersTypeCategory ? imageRadius : '0%'
                }" src="{{
                  category
                    | functionCall
                      : getCategoryImage
                      : selectedCategory
                      : imageBaseUrl
                }}" onerror="this.src='assets/images/Image_21.png'" />
              <strong class="font-size5" [ngStyle]="{
                  color:
                    category.CategoryID === selectedCategory?.CategoryID
                      ? accentColor
                      : textColor
                }">
                {{ category.Name }}
              </strong>

              <div class="spacer"></div>
            </div>
          </div>
          <div *ngIf="selectedCategory !== undefined && selectedCategory?.isSub" class="sub-cat-back-btn">     
            <button class="bottom-button" 
            [style.bottom]="openCart ? showScroll ? '19.9vh' : '15.7vh' : showScroll ? '17vh' : '13vh'"
              (click)="onSideCategoryClick(selectedCategory?.parent, true)">
              <i class="fas fa-angle-double-left white"></i> {{backText|uppercase}}
            </button>
          </div>
          <!-- && !checkForScrollForMoreCheck && isBottomScrollMore -->
          <button class="scroll-more" (click)="scrollDownLeft()"
            [style.bottom]="openCart ? '15.7vh' : '13vh'"
            [hidden]="!showScroll" >
              {{scrollForMoreLBText}}
              
            <img src="../../../assets/images/Arrowsdown.gif" *ngIf="!isScrollArrowUp" />
            <img src="../../../assets/images/ArrowUp.gif" *ngIf="isScrollArrowUp" />
          </button>
        </div>
        <!-- Side Category End -->
      </div>
      <div class="rightMenucolumn" [ngClass]="{ open: openCart }">
        <div class="category-name" [ngStyle]="{
            'background-color': headerImage ? headerImage.bgColor : ''
          }" style="background-image: url('{{
            headerImage ? headerImage.imageURL : branchLogo
          }}');" *ngIf="!isConcessionaire">

          <div class="cat-bar" id="categoryName">
            <h2 class="headerCategoryText" *ngIf="hideHeaderCategoryText == 'false'">
              {{ categoryTitle }}
            </h2>
            <h5 class="category-desc">{{categoryDescription}}</h5>
          </div>
          <div class="{{ (headerImage && headerImage.imageURL !='') ? 'overlay' : ''}}"></div>

          <div class="user-info" *ngIf="isLoyalty && useLoyalty && !isRemovePricingAndPayments" (click)="onUserClick()">
            <button class="btn btn-primary">
              <span class="material-icons"> account_circle </span>{{ userName }}
            </button>
          </div>
        </div>

        <!-- Menu Items Start  -->

        <div class="itemContainer menu_Items_Container" [ngClass]="{ open: openCart }" [ngStyle]="{
            'background-image': 'url(' + backgroundImageCategory + ')',
            'background-color': backgroundColor
          }" style=" height:{{
            isConcessionaire
              | functionCall: getItemContainerHeight:openCart:isHybridMode:isFilterApplied:isItemTagsSelected
          }}">
          <div class="item-list" (scroll)="onScroll($event)" id="itemContainer" [ngClass]="{ open: openCart }"
            #itemContainerDiv>
            <div id="topDiv"></div>
            <div #itemsgrid [ngClass]="{
                'mini-three-item-container':
                  selectedCategory &&
                  selectedCategory.DisplayCatItemRowCount == '3'
              }" [ngStyle]="{
                'grid-auto-rows': (minimizeSpace && 'min-content') || 'auto',
                'grid-template-columns':
                  'repeat(' +
                  selectedCategory?.DisplayCatItemRowCount +
                  ', minmax(0, 1fr))',
                display: 'grid'
              }" class="item-grid">
              <div *ngFor="let item of currentItemsSub | async" [ngClass]="{
                  'item-container': currentItems.length > 0,
                  'item-container-one': currentItems.length == 0,
                  'mini-item-container': minimizeSpace
                }">
                <!-- begin applied schedule pricing block -->
                <div style="width: 100%" *ngIf="item.OldPrice; else normal">
                  <div appDebounceClick class="item" style="position: relative" id="{{ item.ItemID }}"
                    (debounceClick)="onItemClick(item, $event)">
                    <div class="image-container" [ngStyle]="getContainerStyle(item)">
                      <img src="{{
                          item.ImageUrl
                            | functionCall: getCommonImage:imageBaseUrl:placeholderImage
                        }}" [ngStyle]="getStyle(item)" [ngClass]="{ ghost: item.SoldOut }"
                        onerror="this.src='assets/images/Image_21.png'" loading="lazy" />

                      <!-- <img src="{{ item.ImageUrl && item.ImageUrl != '' ? imageBaseUrl + item.ImageUrl : 'assets/images/Image_21.png' }}"
                           [ngStyle]="getStyle(item)" [ngClass]="{ ghost: item.SoldOut }"
                           onerror="this.src='assets/images/Image_21.png'" loading="lazy" /> -->
                    </div>

                    <div [ngStyle]="{
                        'align-items': 'horizontal',
                        height: '4rem'
                      }" class="itemTagsContainer">
                      <div *ngFor="let image of item.IconImages" [ngClass]="{ ghost: item.SoldOut }"
                        class="col itemTagsDiv">
                        <img [ngStyle]="{ width: '3rem', height: '3rem' }" *ngIf="image != null && image != ''" src="{{
                            image | functionCall: getCommonImage:imageBaseUrl:placeholderImage
                          }}" onerror="this.src='assets/images/Image_21.png'" />
                      </div>
                    </div>
                    <div class="sold-out red" *ngIf="item.SoldOut">
                      <em>{{ soldOutText }}</em>
                    </div>
                    <p class="calorie-text" style="
                        font-style: italic;
                        color: #9e9e9e;
                        font-size: smaller;
                        font-weight: normal;
                      " *ngIf="showCalorie === 'True' && item.Calorie !== ''"></p>
                    <div style="
                        font-size: 3rem;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        position: absolute;
                        right: 50%;
                        transform: translateX(50%);
                        top: 2%;
                        max-width: 80%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: word;
                        color: white;
                        background-color: green;
                      " class="root-round2">
                      {{ item.AppliedPromoName }}
                    </div>

                    <div class="sold-out red" *ngIf="item.SoldOut">
                      <em>{{ soldOutText }}</em>
                    </div>
                    <strong [ngStyle]="{
                        color: accentColor
                      }" [ngClass]="{ ghost: item.SoldOut }">{{ item.Name | uppercase }}</strong>

                    <p *ngIf="!item.IsCategory && !isRemovePricingAndPayments" [ngStyle]="{ color: 'green' }"
                      [ngClass]="{ ghost: item.SoldOut }">
                      {{
                      ((item.ModifierCount > 0 || item.AttributeCount > 0) &&
                      hideStartAt === "False"
                      ? startsAtText
                      : "") + (item.Price | CurrencyPipe)
                      }}
                    </p>
                    <p *ngIf="!item.IsCategory && !isRemovePricingAndPayments" style="font-size: 2rem"
                      [ngClass]="{ ghost: item.SoldOut }" [ngStyle]="{ color: 'black' }">
                      {{regularPriceText}} {{ item.OldPrice | CurrencyPipe }}
                    </p>
                    <p class="calorie-text" style="
                        font-style: italic;
                        color: #9e9e9e;
                        font-size: smaller;
                        font-weight: normal;
                      " *ngIf="showCalorie === 'True' && item.Calorie !== ''">
                      {{ item.MaxCalories !=='' ? item.Calorie + ' - ' + item.MaxCalories + calorieText : item.Calorie + calorieText}}
                    </p>
                  </div>
                </div>

                <ng-template #normal>
                  <div appDebounceClick class="item" style="position: relative" id="{{ item.ItemID }}"
                    (debounceClick)="onItemClick(item, $event)">
                    <div class="image-container" [ngStyle]="getContainerStyle(item)">
                      <img id="{{ item.ImageUrl }}" [ngStyle]="getStyle(item)" src="{{
                          item.ImageUrl
                            | functionCall: getCommonImage:imageBaseUrl:placeholderImage
                        }}" [ngClass]="{ ghost: item.SoldOut }" [ngStyle]="getStyle(item)"
                        onerror="this.onerror=null; this.src='assets/images/Image_21.png'" loading="lazy" />
                    </div>

                    <div [ngStyle]="{
                        'align-items': 'horizontal',
                        height: '4rem'
                      }" class="itemTagsContainer">
                      <div *ngFor="let image of item.IconImages" [ngClass]="{ ghost: item.SoldOut }"
                        class="col itemTagsDiv">
                        <img [ngStyle]="{ width: '3rem', height: '3rem' }" *ngIf="image != null && image != ''" src="{{
                            image | functionCall: getCommonImage:imageBaseUrl:placeholderImage
                          }}" onerror="this.onerror=null; this.src='assets/images/Image_21.png'" />
                      </div>
                    </div>
                    <div class="sold-out" *ngIf="item.SoldOut">
                      <em>{{ soldOutText }}</em>
                    </div>
                    <strong [ngClass]="{ ghost: item.SoldOut }" [ngStyle]="{ color: accentColor }">
                      {{ item.Name | uppercase }}
                    </strong>
                    <p *ngIf="
                        isRemovePricingAndPayments
                          | functionCall: isPriceShow:isHideZeroPrice:item.Price
                      " [ngStyle]="{
                        color: priceColor,
                        opacity: item.SoldOut ? 0.3 : 1
                      }">
                      {{
                      ((item.ModifierCount > 0 || item.AttributeCount > 0) &&
                      hideStartAt === "False"
                      ? startsAtText
                      : "") + (item.Price | CurrencyPipe)
                      }}
                    </p>
                    <p class="calorie-text" style="
                        font-style: italic;
                        color: #9e9e9e;
                        font-size: smaller;
                        font-weight: normal;
                      " *ngIf="showCalorie === 'True' && item.Calorie !== ''">
                      {{ item.MaxCalories !=='' ? item.Calorie + ' - ' + item.MaxCalories + calorieText : item.Calorie + calorieText }}
                    </p>
                  </div>

                </ng-template>

              </div>

              <!-- <div class="menu-navigation" *ngIf="checkScrollCheck">
                <div *ngIf="!isTop" (click)="scrollTop()">
                  <i class="scroll-up fas fa-angle-double-up"></i>
                </div>
                <div *ngIf="!isBottom" (click)="scrollDown()">
                  <i class="scroll-down fas fa-angle-double-down"></i>
                </div>
              </div> -->
            </div>
          </div>
        </div>

        <!-- Menu Items End  -->
        <!--Hybrid Section-->
        <ng-container *ngIf="isHybridMode">
          <app-hybrid-section [hidden]="!isHybridMode"></app-hybrid-section>
        </ng-container>

        <!--End Hybrid Section-->
      </div>
    </div>

    <app-cart class="cart background-footer-color" (isCartOpenChange)="changeOpenCart($event)"
      [removeAlk]="removeAlcohol"></app-cart>
  </ng-container>

  <div style="display: none">
    <app-customer-login-popup [hidden]="true"></app-customer-login-popup>

    <app-timeout></app-timeout>
  </div>
</div>