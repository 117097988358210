import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrinterService } from 'src/app/services/printer.service';
import { DatabaseService } from 'src/app/services/database.service';
import { SaveSettingsService } from 'src/app/services/save-settings.service';
import { DatabaseHandler } from '../../DatabaseHandler';
import { loggingData, LogService } from '../../services/log.service';
import { PusherService } from 'src/app/services/pusher.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LoginService } from '../../services/login.service';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { CloverService } from 'src/app/services/clover.service';
import { FlexPairingService } from 'src/app/services/flex-pairing.service';

@Component({
  selector: 'app-manager-passcode',
  templateUrl: './manager-passcode.component.html',
  styleUrls: ['./manager-passcode.component.css'],
})
export class ManagerPasscodeComponent implements OnInit, OnDestroy {
  languageSub!: Subscription;

  passcode: string = '';
  passcodeCount: number = 1;

  passcode1: string = '';
  passcode2: string = '';
  passcode3: string = '';
  passcode4: string = '';

  accessCodeText: string = '';
  invalidPinText:string=''
  isChecking: boolean = false;
  constructor(
    private readonly databaseService: DatabaseService,
    private readonly zone: NgZone,
    private readonly router: Router,
    private readonly printerService: PrinterService,
    private readonly logger: LogService,
    private readonly saveSettingsService: SaveSettingsService,
    private readonly pusherService: PusherService,
    private modalService: NgbModal,
    private readonly loginService: LoginService,
    private language: LanguageService,
    private dataService: DataService,
    private clover:CloverService,
    private flexPairing:FlexPairingService
  ) {}

  ngOnInit(): void {
    //added because In 50% cases download image popup did not hide in device
    this.modalService.dismissAll();

    this.saveSettingsService.theme();

    // remove time out if it ever triggered if we are on manager login screen to ensure no unwanted redirect
    clearTimeout(this.loginService.managerPassCodeRedirectLoginRefTimeOut);
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }

  ngOnDestroy(): void {
    if (this.languageSub) this.languageSub.unsubscribe();
  }
  loadText() {
    this.accessCodeText = this.language.getTextElement('txt_enter_access_code');
    this.invalidPinText = this.language.getTextElement('txt_invalid_pin');

  }
  passcodeCallback = (tx: any, result: any): void => {
    if (result.rows.length > 0) {

      if(this.clover.isCloverConfigured()) {
        this.flexPairing.checkPairing()
      }

      this.databaseService.getDetailSetting();
      this.databaseService.getKitchenPrinters(true);
      this.pusherService.getPusherKeys(true);
      this.dataService.updateDevice()
      .toPromise()
      .then(
        (res: any) => {
          var log = new loggingData(
            'KIOSK',
            'Device Details Updated',
            'Log',
            'Device Details Updated.',
            false
          );
          this.logger.sendLogToServer(log);
        }
      );

      var log = new loggingData(
        'KIOSK',
        'Manager Login',
        'Log',
        'manager login clicked.',
        false
      );
      this.logger.sendLogToServer(log);
      setTimeout(() => {
        this.isChecking = false;
        this.zone.run(() => this.router.navigateByUrl('/touchToStart'));
      }, 0);
    } else {
      var x = document.getElementById('snackbar');
      if (x != null) {
        x.className = 'show';
        setTimeout(() => {
          if (x != null) {
            x.className = x.className.replace('show', '');
          }
        }, 1000);
      }
      setTimeout(() => {
        this.onXClick();
        this.isChecking = false;
      }, 100);
    }
  };

  errorCallback(tx: any, result: any): void {
    this.isChecking = false;
    const log = new loggingData(
      'Staff Code Error',
      result.message,
      'Staff Code Enter Error',
      'manager login clicked',
      false
    );
    this.logger.sendLogToServer(log);
  }

  checkForLength(): boolean {
    // get entire passcode
    this.passcode = '';

    const inputs = document.getElementsByTagName('input');
    for (let i = 0; i < inputs.length; i++) {
      this.passcode += inputs[i].value;
    }
    if (this.passcode.length == 4) return true;
    return false;
  }

  onCheckClick(): void {
    // get manager code from database
    const sqlString = `SELECT * FROM StaffMasters WHERE Code=?`;
    DatabaseHandler.executeSqlStatement(
      sqlString,
      [this.passcode],
      this.passcodeCallback,
      this.errorCallback
    );
  }

  onXClick(): void {
    // clear input
    var inputs = document.getElementsByTagName('input');
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].value = '';
    }

    // reset input position
    this.passcodeCount = 1;
  }

  onClick(value: any): void {
    // fill in input tag with value pressed
    const temp = 'passcode' + this.passcodeCount;
    this.passcodeCount++;

    const space = <HTMLInputElement>document.getElementById(temp);

    if (space && space !== null) {
      space.value = value.currentTarget.innerHTML;
    }

    if (this.checkForLength()) {
      this.isChecking = true;
      this.onCheckClick();
    }
  }
}
