import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleModule } from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { UserIdleModule } from 'angular-user-idle';
import { HideKeyboardModule } from 'hide-keyboard';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BranchLoginComponent } from './components/branch-login/branch-login.component';
import { CartItemsComponent } from './components/cart-items/cart-items.component';
import { CartComponent } from './components/cart/cart.component';
import { CheckoutBoxComponent } from './components/checkout-box/checkout-box.component';
import { ComboGroupContainerComponent } from './components/combo-group-container/combo-group-container.component';
import { CombogroupComponent } from './components/combo-group-container/combogroup/combogroup.component';
import { ItemsComponent } from './components/combo-group-container/combogroup/items/items.component';
import { ComboItemPopupComponent } from './components/combo-item-popup/combo-item-popup.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { AlcoholicItemsDialogComponent } from './components/dialogs/alcoholic-items-dialog/alcoholic-items-dialog.component';
import { AlcoholicItemsExceedMaxComponent } from './components/dialogs/alcoholic-items-exceed-max/alcoholic-items-exceed-max.component';
import { AlcoholicItemExceedMaxComponent } from './components/dialogs/alcoholic-item-exceed-max/alcoholic-item-exceed-max.component';
import { AlcoholicOver21DialogComponent } from './components/dialogs/alcoholic-over21-dialog/alcoholic-over21-dialog.component';
import { AreYouSureComponent } from './components/dialogs/are-you-sure/are-you-sure.component';
import { AskConfigureYourDeviceComponent } from './components/dialogs/ask-configure-your-device/ask-configure-your-device.component';
import { AskCustomerReceiptDialogComponent } from './components/dialogs/ask-customer-receipt-dialog/ask-customer-receipt-dialog.component';
import { AskDeviceNameComponent } from './components/dialogs/ask-device-name/ask-device-name.component';
import { ConnectionLossPopupComponent } from './components/dialogs/connection-loss-popup/connection-loss-popup.component';
import { CustominfoPopupComponent } from './components/dialogs/custominfo-popup/custominfo-popup.component';
import { DownloadingPopupComponent } from './components/dialogs/downloading-popup/downloading-popup.component';
import { EmailReceiptDialogComponent } from './components/dialogs/email-receipt-dialog/email-receipt-dialog.component';
import { EmailReceiptThankyouDialogComponent } from './components/dialogs/email-receipt-thankyou-dialog/email-receipt-thankyou-dialog.component';
import { ErrorMsgPopupComponent } from './components/dialogs/error-msg-popup/error-msg-popup.component';
import { ItemAskNamePopupComponent } from './components/dialogs/item-ask-name-popup/item-ask-name-popup.component';
import { ItemSwitchWarningComponent } from './components/dialogs/item-switch-warning/item-switch-warning.component';
import { Items86PopupComponent } from './components/dialogs/items86-popup/items86-popup.component';
import { OloErrorPopupComponent } from './components/dialogs/olo-error-popup/olo-error-popup.component';
import { OrderLevelUpsellDialogComponent } from './components/dialogs/order-level-upsell-dialog/order-level-upsell-dialog.component';
import { OrderTypeWarningComponent } from './components/dialogs/order-type-warning/order-type-warning.component';
import { PaymentDeviceComponent } from './components/dialogs/payment-device/payment-device.component';
import { PaymentErrorComponent } from './components/dialogs/payment-error/payment-error.component';
import { PaymentInsertComponent } from './components/dialogs/payment-insert/payment-insert.component';
import { PaymentSuccessComponent } from './components/dialogs/payment-success/payment-success.component';
import { PrintingProgressPopupComponent } from './components/dialogs/printing-progress-popup/printing-progress-popup.component';
import { PromoCodeComponent } from './components/dialogs/promo-code/promo-code.component';
import { RefreshDataLoadingComponent } from './components/dialogs/refresh-data-loading/refresh-data-loading.component';
import { ReturningCustomerDialogComponent } from './components/dialogs/returning-customer-dialog/returning-customer-dialog.component';
import { SoldOutComponent } from './components/dialogs/sold-out/sold-out.component';
import { TableTentPopupComponent } from './components/dialogs/table-tent-popup/table-tent-popup.component';
import { TaxInfoComponent } from './components/dialogs/tax-info/tax-info.component';
import { TipDialogComponent } from './components/dialogs/tip-dialog/tip-dialog.component';
import { UpdateMenuComponent } from './components/dialogs/update-menu/update-menu.component';
import { UsconnectAccountFoundComponent } from './components/dialogs/usconnect-account-found/usconnect-account-found.component';
import { UsconnectAccountNotFoundComponent } from './components/dialogs/usconnect-account-not-found/usconnect-account-not-found.component';
import { UsconnectBalanceLowComponent } from './components/dialogs/usconnect-balance-low/usconnect-balance-low.component';
import { UsconnectErrorComponent } from './components/dialogs/usconnect-error/usconnect-error.component';
import { UsconnectScanComponent } from './components/dialogs/usconnect-scan/usconnect-scan.component';
import { UsconnectSuccessComponent } from './components/dialogs/usconnect-success/usconnect-success.component';
import { LoginFormComponent } from './components/forms/login-form/login-form.component';
import { BuilderModeHeaderComponent } from './components/item-builder-mode/builder-mode-header/builder-mode-header.component';
import { ItemBuilderModeSelectionComponent } from './components/item-builder-mode/item-builder-mode-selection/item-builder-mode-selection.component';
import { ItemBuilderModeComponent } from './components/item-builder-mode/item-builder-mode.component';
import { ItemFooterComponent } from './components/item-footer/item-footer.component';
import { ItemIndividualComponent } from './components/item-individual/item-individual.component';
import { ItemNormalModeHeaderComponent } from './components/item-normal-mode/item-normal-mode-header/item-normal-mode-header.component';
import { ItemNormalModeComponent } from './components/item-normal-mode/item-normal-mode.component';
import { ItemTagsKeyComponent } from './components/item-tags-key/item-tags-key.component';
import { LastFiveOrderComponent } from './components/last-five-order/last-five-order.component';
import { LogoutConfirmationDialogComponent } from 'src/app/components/dialogs/logout-confirmation-dialog/logout-confirmation-dialog.component';
import { ManagerPasscodeComponent } from './components/manager-passcode/manager-passcode.component';
import { ManagerPinDialogComponent } from './components/manager-pin-dialog/manager-pin-dialog.component';
import { MenuComponent } from './components/menu/menu.component';
import { ModifierContainerComponent } from './components/modifier-container/modifier-container.component';
import { IngredientComponent } from './components/modifier-container/modifier/ingredient/ingredient.component';
import { ModifierComponent } from './components/modifier-container/modifier/modifier.component';
import { NestedModifierComponent } from './components/nested-modifier/nested-modifier.component';
import { OrderReviewFooterComponent } from './components/order-review/order-review-footer/order-review-footer.component';
import { OrderReviewListComponent } from './components/order-review/order-review-list/order-review-list.component';
import { OrderReviewPromotionsComponent } from './components/order-review/order-review-promotions/order-review-promotions.component';
import { OrderReviewComponent } from './components/order-review/order-review.component';
import { OutOfStockComponent } from './components/out-of-stock/out-of-stock.component';
import { PaymentPopupComponent } from './components/payment-popup/payment-popup.component';
import { SettingsDeviceinfoComponent } from 'src/app/components/settings/settings-deviceinfo/settings-deviceinfo.component';
import { SettingsGeneralsettingsComponent } from 'src/app/components/settings/settings-generalsettings/settings-generalsettings.component';
import { SettingsGrossSalesComponent } from 'src/app/components/settings/settings-gross-sales/settings-gross-sales.component';
import { SettingsItemsComponent } from 'src/app/components/settings/settings-items/settings-items.component';
import { SettingsItemwiseSalesReportComponent } from 'src/app/components/settings/settings-itemwise-sales-report/settings-itemwise-sales-report.component';
import { SettingsOrderHistoryComponent } from 'src/app/components/settings/settings-order-history/settings-order-history.component';
import { SettingsPaymentComponent } from 'src/app/components/settings/settings-payment/settings-payment.component';
import { SettingsRefreshDataComponent } from 'src/app/components/settings/settings-refresh-data/settings-refresh-data.component';
import { SettingsReportsComponent } from 'src/app/components/settings/settings-reports/settings-reports.component';
import { SettingsStoreTimingsComponent } from 'src/app/components/settings/settings-store-timings/settings-store-timings.component';
import { SettingsZReportComponent } from 'src/app/components/settings/settings-z-report/settings-z-report.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ThankyouPopupComponent } from './components/thankyou-popup/thankyou-popup.component';
import { TimeoutComponent } from './components/timeout/timeout.component';
import { TouchToStartComponent } from './components/touch-to-start/touch-to-start.component';
import { UpSellDialogComponent } from './components/dialogs/up-sell-dialog/up-sell-dialog.component'
import { CustomerLoginPopupComponent } from './components/dialogs/customer-login-popup/customer-login-popup.component';
import { VariationContainerComponent } from './components/variation-container/variation-container.component';
import { VariationOptionComponent } from 'src/app/components/variation-container/variation/variation-option/variation-option.component';
import { VariationComponent } from 'src/app/components/variation-container/variation/variation.component';
import { OLOTotalLoaderComponent } from './components/dialogs/olototal-loader/olototal-loader.component';
import { RedeemRewardComponent } from './components/dialogs/redeem-reward/redeem-reward.component';
import { SpecialRequestDialogComponent } from './components/dialogs/special-request-dialog/special-request-dialog.component';
import { UpsellComboMessageComponent } from './components/dialogs/upsell-combo-message/upsell-combo-message.component';
import { CurrencyPipe } from "src/app/CustomTools/currency.pipe";
import { DebounceClickDirective } from "./CustomTools/debounce-click.directive";
import { ItemBowlModeComponent } from './components/item-bowl-mode/item-bowl-mode.component';
import { BowlmodeSelectionComponent } from './components/item-bowl-mode/bowlmode-selection/bowlmode-selection.component';
import { NumberConvertPipe } from './CustomTools/number-convert.pipe';
import { GeneralAlertComponent } from './components/dialogs/general-alert/general-alert.component';
import { DiscountConfirmationComponent } from './components/dialogs/discount-confirmation/discount-confirmation.component';
import { RefundConfirmationComponent } from './components/dialogs/refund-confirmation/refund-confirmation.component';
import { RefundSuccessComponent } from './components/dialogs/refund-success/refund-success.component';
import { ParBrinkErrorPopUpComponent } from './components/dialogs/par-brink-error-pop-up/par-brink-error-pop-up.component'
import { OloPunchhRewardInvalidationPopupComponent } from 'src/app/components/dialogs/olo-punchh-reward-invalidation-popup/olo-punchh-reward-invalidation-popup.component';
import { FailedImagesComponent } from './components/dialogs/failed-images/failed-images.component';
import { ImagePopupComponent } from './components/dialogs/image-popup/image-popup.component';
import { BoxBuilderReviewComponent } from './components/box-builder/box-builder-review/box-builder-item-review.component';
// import { BoxBuilderItemDetail } from './components/box-builder/box-builder-item-detail/box-builder-item-detail.component';
import { BoxBuilderComponent } from './components/box-builder/box-builder.component';
import { ChooseDonutCustomizePopupComponent } from './components/dialogs/choose-donut-customize-popup/choose-donut-customize-popup.component';
import { BoxBuilderHeaderComponent } from './components/box-builder/box-builder-header/box-builder-header.component';
import { BoxBuilderFooterComponent } from './components/box-builder/box-builder-footer/box-builder-footer.component';
// import { BoxIngredientComponent } from './components/box-builder-item/box-builder-item-detail/box-ingredient/box-ingredient.component';
import { BoxIngredientComponent } from './components/box-builder/box-builder-combo/box-ingredient/box-ingredient.component';
import { OloApplyRemoveDiscountPopupComponent } from './components/dialogs/olo-apply-remove-discount-popup/olo-apply-remove-discount-popup.component';
import { BoxBuilderFullComponent } from './components/dialogs/box-builder-full/box-builder-full.component';
import { BoxBuilderComboComponent } from './components/box-builder/box-builder-combo/box-builder-combo.component';
import { BoxBuilderItemDetail } from './components/box-builder-item/box-builder-item-detail/box-builder-item-detail.component';
import { BoxBuilderItemFooterComponent } from './components/box-builder-item/box-builder-item-footer/box-builder-item-footer.component';
import { BoxBuilderItemComponent } from './components/box-builder-item/box-builder-item.component';
import { LoadingComponent } from './loading/loading.component';
import { SoftKeyboardComponent } from './components/soft-keyboard/soft-keyboard.component';
import { KeyboardDirective } from './CustomTools/keyboard.directive';
import { ShippingModalComponent } from './components/dialogs/shipping-modal/shipping-modal.component';
import { LevelUpModalComponent } from './components/dialogs/level-up-modal/level-up-modal.component'
import { OrderreviewitemListComponent } from './components/order-review/orderreviewitem-list/orderreviewitem-list.component';
import { ConcessionaireGridComponent } from './components/concessionaire-grid/concessionaire-grid.component';
import { AutofocusDirective } from './CustomTools/autofocus.directive';

import { ConcessionaireSettingComponent } from './components/settings/concessionaire-setting/concessionaire-setting.component';
import { ItemWeightComponent } from './components/dialogs/item-weight/item-weight.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ChatService } from './services/chat.service';
import { HybridSectionComponent } from './components/hybrid-section/hybrid-section.component';
import { ItemSelectionComponent } from './components/dialogs/item-selection/item-selection.component';
import { ScanningItemComponent } from './components/dialogs/scanning-item/scanning-item.component';
import { SearchScannableItemComponent } from './components/search-scannable-item/search-scannable-item.component';
import { ScannedItemAddedComponent } from './components/dialogs/scanned-item-added/scanned-item-added.component';
import { postInterceptor } from 'src/app/CustomTools/postInterceptor';
import { SharedModule } from './shared/shared.module';
import { ItemTentPopupComponent } from './components/dialogs/item-tent-popup/item-tent-popup.component';
import { CustomerWelcomeModalComponent } from './components/dialogs/customer-welcome-modal/customer-welcome-modal.component';
import { FilterConcessionaireComponent } from './components/dialogs/filter-concessionaire/filter-concessionaire.component';
import { BrandFilterComponent } from './components/dialogs/brand-filter/brand-filter.component';
import { ConcessionaireClosedComponent } from './components/dialogs/concessionaire-closed/concessionaire-closed.component';
import { OptinMarketingComponent } from './components/optin-marketing/optin-marketing.component';
import { TermsConditionsQrComponent } from './components/dialogs/terms-conditions-qr/terms-conditions-qr.component';
// This import is required to have QRCode generation
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { TermsConditionsUrlComponent } from './components/dialogs/terms-conditions-url/terms-conditions-url.component';
import { TermsConditionQrUrlBothComponent } from './components/dialogs/terms-condition-qr-url-both/terms-condition-qr-url-both.component';
import { TermsConditionsPdfComponent } from './components/dialogs/terms-conditions-pdf/terms-conditions-pdf.component';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PaymentTotalRowComponent } from './components/payment-total-row/payment-total-row.component';
import { ReplaceTextPipe } from './CustomTools/replace-text.pipe';
import { PayWithIdcardLoginComponent } from './components/dialogs/pay-with-idcard-login/pay-with-idcard-login.component';
import { FlexPairingComponent } from './components/dialogs/flex-pairing/flex-pairing.component';
import { NetworkErrorPageComponent } from './components/network-error-page/network-error-page.component';
import { SMBDiscountsComponent } from './components/order-review/smb-discounts/smb-discounts.component';
import { SMBDiscountsCodeComponent } from './components/dialogs/smb-discounts-code/smb-discounts-code.component';
import { SMBDiscountsAlertComponent } from './components/dialogs/smb-discounts-alert/smb-discounts-alert.component';
import { GeneralConfirmationComponent } from './components/dialogs/general-confirmation-alert/general-confirmation-alert.component';
import { UpgradeKioskAppComponent } from './components/dialogs/upgrade-kiosk-app/upgrade-kiosk-app.component';
import { SettingsHealthLogComponent } from './components/settings/settings-health-log/settings-health-log.component';
const config: SocketIoConfig = { url: 'http://localhost:4200', options: {} };
@NgModule({
  declarations: [
    CustomerWelcomeModalComponent,
    UpSellDialogComponent,
    CustomerLoginPopupComponent,
    AppComponent,
    BranchLoginComponent,
    ManagerPasscodeComponent,
    TouchToStartComponent,
    MenuComponent,
    CartComponent,
    CheckoutBoxComponent,
    OrderReviewComponent,
    SettingsComponent,
    ThankyouPopupComponent,
    PaymentPopupComponent,
    OrderReviewListComponent,
    CartItemsComponent,
    OrderReviewFooterComponent,
    ItemIndividualComponent,
    LogoutConfirmationDialogComponent,
    SettingsDeviceinfoComponent,
    SettingsGeneralsettingsComponent,
    SettingsReportsComponent,
    SettingsItemsComponent,
    SettingsStoreTimingsComponent,
    SettingsRefreshDataComponent,
    ManagerPinDialogComponent,
    OrderReviewPromotionsComponent,
    TipDialogComponent,
    CustominfoPopupComponent,
    LoginFormComponent,
    OrderLevelUpsellDialogComponent,
    ItemAskNamePopupComponent,
    TimeoutComponent,
    SettingsPaymentComponent,
    SettingsHealthLogComponent,
    SettingsGrossSalesComponent,
    SettingsOrderHistoryComponent,
    SettingsZReportComponent,
    SettingsItemwiseSalesReportComponent,
    PrintingProgressPopupComponent,
    PaymentDeviceComponent,
    PaymentErrorComponent,
    PaymentInsertComponent,
    PaymentDeviceComponent,
    ComingSoonComponent,
    EmailReceiptDialogComponent,
    EmailReceiptThankyouDialogComponent,
    DownloadingPopupComponent,
    OutOfStockComponent,
    TableTentPopupComponent,
    ConnectionLossPopupComponent,
    ItemTagsKeyComponent,
    PromoCodeComponent,
    PaymentSuccessComponent,
    ReturningCustomerDialogComponent,
    SoldOutComponent,
    AlcoholicItemsDialogComponent,
    LastFiveOrderComponent,
    AlcoholicItemsExceedMaxComponent,
    AlcoholicOver21DialogComponent,
    AreYouSureComponent,
    RefreshDataLoadingComponent,
    UpdateMenuComponent,
    TaxInfoComponent,
    OrderTypeWarningComponent,
    Items86PopupComponent,
    AskCustomerReceiptDialogComponent,
    ItemSwitchWarningComponent,
    UsconnectScanComponent,
    UsconnectAccountFoundComponent,
    UsconnectAccountNotFoundComponent,
    UsconnectBalanceLowComponent,
    DebounceClickDirective,
    AutofocusDirective,
    UsconnectSuccessComponent,
    UsconnectErrorComponent,
    OloErrorPopupComponent,
    AskConfigureYourDeviceComponent,
    AskDeviceNameComponent,
    ErrorMsgPopupComponent,
    ModifierContainerComponent,
    ModifierComponent,
    IngredientComponent,
    NestedModifierComponent,
    ItemFooterComponent,
    ComboGroupContainerComponent,
    CombogroupComponent,
    ItemsComponent,
    ItemBuilderModeComponent,
    BuilderModeHeaderComponent,
    ItemBuilderModeSelectionComponent,
    ComboItemPopupComponent,
    ItemNormalModeHeaderComponent,
    ItemNormalModeComponent,
    VariationContainerComponent,
    VariationOptionComponent,
    VariationComponent,
    SpecialRequestDialogComponent,
    RedeemRewardComponent,
    OLOTotalLoaderComponent,
    AlcoholicOver21DialogComponent,
    AlcoholicItemExceedMaxComponent,
    UpsellComboMessageComponent,
    CurrencyPipe,
    ItemBowlModeComponent,
    BowlmodeSelectionComponent,
    LevelUpModalComponent,
    NumberConvertPipe,
    GeneralAlertComponent,
    DiscountConfirmationComponent,
    RefundConfirmationComponent,
    RefundSuccessComponent,
    SoftKeyboardComponent,
    KeyboardDirective,
    ParBrinkErrorPopUpComponent,
    OloPunchhRewardInvalidationPopupComponent,
    FailedImagesComponent,
    ImagePopupComponent,
    BoxBuilderComponent,
    BoxBuilderReviewComponent,
    BoxBuilderItemDetail,
    ChooseDonutCustomizePopupComponent,
    BoxBuilderHeaderComponent,
    BoxBuilderFooterComponent,
    BoxIngredientComponent,
    OloApplyRemoveDiscountPopupComponent,
    BoxBuilderFullComponent,
    BoxBuilderComboComponent,
    BoxBuilderItemFooterComponent,
    BoxBuilderItemComponent,
    LoadingComponent,
    ShippingModalComponent,
    OrderreviewitemListComponent,
    ConcessionaireGridComponent,
    ConcessionaireSettingComponent,
    ItemWeightComponent,
    AutofocusDirective,
    CustomerWelcomeModalComponent,
    ConcessionaireSettingComponent,
    HybridSectionComponent,
    ItemSelectionComponent,
    ScanningItemComponent,
    SearchScannableItemComponent,
    ScannedItemAddedComponent,
    ItemTentPopupComponent,
    FilterConcessionaireComponent,
    BrandFilterComponent,
    ConcessionaireClosedComponent,
    OptinMarketingComponent,
    TermsConditionsQrComponent,
    TermsConditionsUrlComponent,
    TermsConditionQrUrlBothComponent,
    TermsConditionsPdfComponent,
    PaymentTotalRowComponent,
    ReplaceTextPipe,
    PayWithIdcardLoginComponent,
    FlexPairingComponent,
    NetworkErrorPageComponent,
    SMBDiscountsComponent,
    SMBDiscountsCodeComponent,
    SMBDiscountsAlertComponent,
    GeneralConfirmationComponent,
    UpgradeKioskAppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatTabsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSliderModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatRippleModule,
    MatNativeDateModule,
    NgxMaskModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgIdleModule.forRoot(),
    HideKeyboardModule,
    SharedModule,
    SocketIoModule.forRoot(config),
    NgxQRCodeModule,
    // PdfViewerModule
  ],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    DatePipe,
    NgbActiveModal,
    ChatService,
    { provide: HTTP_INTERCEPTORS, useClass: postInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
