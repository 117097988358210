<div class="scan-selection-bar">
    <div class="row">
        <img src="../../../assets/images/scan-item.png" class="scanimg" />
        {{orText|uppercase}}
        <img src="../../../assets/images/item-selection.png" class="scanimg" />
        <div class="scan-select-text">
            <div class="bold-text">
                <div class="col-10 col-md-10"> 
                    <p [innerHtml] ="scanItemMsg"></p>
                </div>

                <div class="col-2 help col-md-2">
                    <button id="scanitem"
                    type="button"
                    matRipple                  
                    data-toggle="scanninganitemModal"
                    data-target="#scanning-item" (click)="openscanitem()"><span class="material-icons material-icons-outlined">
                        help_outline
                    </span>
                    </button>
                </div>
            </div>
            <div class="light">
                <div class="col-1 col-md-1">
                    <img src="{{scanItemInfo}}" *ngIf="scanItemInfo.length > 0"/>
                </div>
                <div class="col-8 col-md-8" style="line-height: 135%;"><p [innerHtml] ="scanItemFooter"></p>.</div>
            </div>
        </div>
      <img src="../../assets/images/barcode-identification.png" class="barscan"  (click)="opensearchscannable()" />
    </div>
</div>
<app-scanning-item  [hidden]="true"></app-scanning-item> 
