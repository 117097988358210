import { Message, MessageType } from './../models/message';
import { EventEmitter, Injectable, NgZone, OnDestroy } from '@angular/core';
import { loggingData, LogService } from 'src/app/services/log.service';
import { Router } from '@angular/router';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { Snackbar } from 'src/app/services/common';
import { SaveSettingsService } from 'src/app/services/save-settings.service';
import { DatabaseService } from './database.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from './language.service';
import { DownloadingPopupService } from '../components/dialogs/downloading-popup/downloading-popup.service';
import { BranchLoginStreamServiceService } from './branch-login-stream-service.service';
import { TimeService } from './store-time.service';
import { ImageService } from './image.service';
import { UpdateMenuModelService } from '../components/dialogs/update-menu/update-menu-model.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService implements OnDestroy {
  ngOnDestroy() {
    if (this.languageSub) this.languageSub.unsubscribe();
  }

  branchInfo: any;
  downloadUrls = new Set<string>();
  branchDetails: any;
  user: any;
  totalDownloadSets: number = 0;
  totalDownloadSetsQueued: number = 0;
  pass: any;
  downloadCompleteCount = 0;
  modalRef!: NgbModalRef;

  isLoginStillInProgress = false;

  isDownloadComplete = false;
  invalidText: string = '';
  downloadingPopupRef!: any;
  languageSub!: Subscription;
  existingFiles = new Set<string>();
  managerPassCodeRedirectLoginRefTimeOut: any;

  public isLoading: EventEmitter<boolean> = new EventEmitter();
  isModalOpen: EventEmitter<boolean> = new EventEmitter();

  private existingFileEventListner:any = null;

  constructor(
    private router: Router,
    private logger: LogService,
    private saveSetting: SaveSettingsService,
    private language: LanguageService,
    private downloadPopupService:DownloadingPopupService,
    private branchLoginStreamService:BranchLoginStreamServiceService,
    private updateMenuService: UpdateMenuModelService,
    private db:DatabaseService,
    private timeService:TimeService,
    private imageService:ImageService
  ) {

    this.existingFileEventListner = window.addEventListener('message', this.getMessage.bind(this), false);
    this.getExistingFiles();
    
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }

  loadText() {
    this.invalidText = this.language.getTextElement(
      'txt_invalid_email_or_password'
    );
  }

  getExistingFiles() {
    const message: Message = {
      messageType: MessageType.getExistingFiles,
    };
    window.top!.postMessage(JSON.parse(JSON.stringify(message)), '*');
  }
  broadcast(a:boolean){
    this.isLoading.emit(a)
  }
  getMessage(event: any) {
    if (event.data.messageType == MessageType.ExistingFiles) {
      this.existingFiles = event.data.data;
      this.saveSetting.existingFiles = event.data.data;

      if(this.existingFileEventListner != null){
        window.removeEventListener('message',this.existingFileEventListner);
      }
    }
  }

  public async doLogin(user: string,pass: string){
    this.isLoading.emit(true);

    this.isLoading.emit(false);
    var res = await this.branchLoginStreamService.doLogin(user,pass,GeneralSetting.getSerialNo(),this.downloadPopupService);

    this.isLoading.emit(false);
    
    if(!res){
      this.isModalOpen.emit(false);
      //this.onBranchLoginError(null,res); // commented it here because it was showing invalid username and password on different error as well GBRL-178 @jay 
      return false;
    }

    GeneralSetting.setIsDataBasePresent('True');
    this.router.navigateByUrl('/passcode');

    return true;
  }

  public async doRefresh(timeOutComponent?:any){
    let username = GeneralSetting.getBranchLoginUserId();
    let password = GeneralSetting.getBranchLoginPass();

    var res = await this.branchLoginStreamService.doLogin(username,password,GeneralSetting.getSerialNo(),this.updateMenuService,true,timeOutComponent);
    
    if(!res) {

      //close All models 

      this.logger.sendLogToServer(new loggingData(
        'Refresh Data',
        'Refresh Data Api call failed',
        'Refresh Data',
        "",
        true
      ));

      Snackbar.show('Network Error', 3000);
      
      return false;
    }

    this.saveSetting.theme();

    this.db.getDetailSetting();
    this.timeService.clearStoreTime();
    this.saveSetting.reloadChildComponent();
    this.imageService.getOptions();
    this.saveSetting.setOptionSubject.next('setOptions');

    this.db.getLoyaltyProgram().then((data: any) => {
      this.saveSetting.menuSyncSubscribe.next(data);
      
      if (data) {
        GeneralSetting.setShowLoyaltyLogin('True');
      } else {
        GeneralSetting.setShowLoyaltyLogin('False');
      }

    });
    
    return true;
  }

  private onBranchLoginError(ref: any, response: any) {
    try {
      this.downloadPopupService.close();

      if(ref && ref != null){
        ref.clear();
      }
    } catch (e) {}

    this.isLoading.emit(false);
    this.isModalOpen.emit(false);

    this.logger.sendLogToServer(
      new loggingData(
        'Branch Login',
        'Branch login Api failed',
        'Branch Login',
        JSON.stringify(response),
        true
      )
    );

    Snackbar.show(this.invalidText, 3000);
  }

}
