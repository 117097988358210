import { MessageType, DownloadMessage, Message } from './../models/message';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DatabaseHandler } from 'src/app/DatabaseHandler';
import { CurrencyService } from 'src/app/services//currency.service';
import { loggingData, LogService } from 'src/app/services//log.service';
import { IsKioskService } from 'src/app/services//is-kiosk.service';
import { ImageService } from 'src/app/services/image.service';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { CommonFunctions, Snackbar } from './common';
import { PusherService } from './pusher.service';
import { environment } from 'src/environments/environment';
import { OLOService } from 'src/app/services/olo.service';
import {
  BranchLoginRes,
  DiscountMaster,
  NCRVendorIntegrations,
  OracleGeneralSettings,
  OracleMicrosIntegration,
} from '../models/BranchLoginModels';
import { BehaviorSubject, Subject } from 'rxjs';
import { FailedImagesComponent } from '../components/dialogs/failed-images/failed-images.component';
import { OracleService } from './oracle.service';
import { IntegrationService } from './integration.service';
import { CloverService } from './clover.service';
import { NcrIntegration } from './NcrIntegration';
import { LanguageService } from './language.service';
import { DatePipe } from '@angular/common';
import { DownloadingPopupService } from '../components/dialogs/downloading-popup/downloading-popup.service';
import { UpdateMenuModelService } from '../components/dialogs/update-menu/update-menu-model.service';
import { Verifone3CService } from './verifone3C.service';

@Injectable({
  providedIn: 'root',
})
export class SaveSettingsService {
  public setOptionSubject = new BehaviorSubject<String>('');
  public themeSub = new BehaviorSubject<boolean>(false);
  public reloadSelfSubject = new Subject<boolean>();
  public menuSyncSubscribe = new BehaviorSubject<boolean>(false);

  stillRunning: boolean = false;

  totalDownloadSets: number = 0;

  totalDownloadSetsQueued: number = 0;

  downloadUrls = new Set<string>();

  refreshDataCounter = 0;

  totalRefreshDataCounter = 0;

  redoCheck = 0;

  isDownloadComplete = false;

  hasFailed: boolean = false;

  isRefreshComplete = false;

  // comes from login service

  existingFiles = new Set<string>();

  failedImages = new Set<string>();

  onlineImages: string[] = [];

  failedImagesRef!: NgbModalRef;

  failedImagesComponent!: FailedImagesComponent;

  constructor(
    private logger: LogService,
    private modalService: NgbModal,
    private router: Router,
    private currencyService: CurrencyService,
    private isKioskService: IsKioskService,
    private imageService: ImageService,
    private pusherService: PusherService,
    private olo: OLOService,
    private oracle: OracleService,
    private integration: IntegrationService,
    private cloverService: CloverService,
    private ncrService: NcrIntegration,
    private language: LanguageService,
    private datePipe: DatePipe,
    private downloadPopupService: DownloadingPopupService,
    private updateMenuService: UpdateMenuModelService,
    private verifone3cService: Verifone3CService
  ) {}

  async parseBranchData(data: any[]) {
    this.totalRefreshDataCounter = 0;
    this.refreshDataCounter = 0;
    data = this.parbrinkPreProcess(data);
    const keys = Object.keys(data);

    if (keys.length == 0) return [];

    this.totalRefreshDataCounter = keys.length;

    await this.removeDataForTable(keys);

    let ImageArray = [] as any[];

    for (let i = 0; i < keys.length; i++) {
      const table: any = keys[i];

      if (data[table] == null || (data[table] as Array<object>).length <= 0) {
        continue;
      }

      const objTableData = data[table];

      let tableData: any[];

      if (!Array.isArray(objTableData)) {
        tableData = [objTableData];
      } else {
        tableData = objTableData as any[];
      }

      const firstRow = Object.values(tableData)[0];
      const allRows = Object.values(tableData);

      await this.createTableIfNotExists(table, firstRow, allRows);
      await this.modifyTableData(table, tableData);
      await this.addTableRowsToDb(table, tableData);
      let res = this.onSyncTableSpecificChanges(table, tableData);

      ImageArray = ImageArray.concat(res);
    }

    ImageArray = ImageArray.filter((x) => x && x != undefined);
    ImageArray = [...new Set(ImageArray)];
    ImageArray = ImageArray.filter(
      (x) => x.trim() != environment.imageBaseUrlRemote
    );

    this.totalDownloadSets = 0;

    await this.createAllTables();
    return ImageArray;
  }

  reloadChildComponent() {
    this.reloadSelfSubject.next(true);
  }

  private parbrinkPreProcess(data: any): any {
    if (
      data.ParBrinkIntegration &&
      data.ParBrinkIntegration.ParBrinkIntegrationID &&
      data.ParBrinkIntegration.ParBrinkIntegrationID != ''
    ) {
      if (data.OrderTypeMappingMasters && data.OrderTypeMappingMasters.length) {
        data.OrderTypeMappingMasters = data.OrderTypeMappingMasters.filter(
          (x: any) => x.RefOrderTypeID != ''
        );
      }
      return data;
    } else return data;
  }

  removeCurrentDetail(tableName: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const DataCallBack = (tx: any, results: any) => {
        resolve(true);
      };

      const ErrorCallBack = (tx: any, results: any) => {
        console.log(
          'Database Service , Error updateItemStatusById',
          results.message
        );

        resolve(true);
      };

      var sqlString = 'DELETE FROM ' + tableName;

      DatabaseHandler.executeSqlStatement(
        sqlString,
        [],
        DataCallBack,
        ErrorCallBack
      );
    });
  }

  theme(): void {
    const statements = [];
    const variables = [];
    const callbacks = [];
    const errorCallbacks = [];

    const getColorCodes = `SELECT * FROM BranchColorCodes`;
    const getBranchMasterTable = `SELECT  * FROM  BranchMasters `;
    const getModuleSettingsPage = `SELECT * FROM ModuleGeneralSettings`;
    const getStaffModuleMaster = `select * from StaffModuleMasters
    where DeviceID = '${GeneralSetting.getSerialNo()}'
    and ModuleID = '2' or ModuleID = '4'
    and BranchID = '${GeneralSetting.getBranchId()}'`;

    statements.push(getColorCodes as any);
    statements.push(getBranchMasterTable as any);
    statements.push(getModuleSettingsPage as any);
    statements.push(getStaffModuleMaster as any);

    variables.push([] as any);
    variables.push([] as any);
    variables.push([] as any);
    variables.push([] as any);

    callbacks.push(
      this.themeCallback as any,
      this.branchDataCallback as any,
      this.settingsCallback as any,
      this.staffModuleMasterCallback as any
    );

    errorCallbacks.push(this.errorCallback as any);
    errorCallbacks.push(this.errorCallback as any);
    errorCallbacks.push(this.errorCallback as any);
    errorCallbacks.push(this.errorCallback as any);


    DatabaseHandler.executeSqlTransation(
      statements as any,
      variables as any,
      callbacks as any,
      errorCallbacks as any
    );
  }

  createAllTables() {
    return new Promise<boolean>((resolve: any, reject: any) => {
      const branchModels = new BranchLoginRes();
      var totalRefreshDataCounter = 0;
      var refreshDataCounter = Object.entries(branchModels).length;

      const statements = [];
      const variables = [];
      const callbacks = [];
      const callbacksFail = [];

      const logError = (statement: any, error: any) => {
        totalRefreshDataCounter++;
        if (refreshDataCounter >= totalRefreshDataCounter) {
          resolve(false);
        }

        console.log('Save Setting Service, Error createAllTables :- ', error);

        //resolve(false);
      };

      const doNothing = () => {
        totalRefreshDataCounter++;
        if (refreshDataCounter >= totalRefreshDataCounter) {
          resolve(true);
        }
        //resolve(true);
      };

      for (const [k, v] of Object.entries(branchModels)) {
        const TableName = k;
        let create = `CREATE TABLE IF NOT EXISTS ${TableName} (`;

        if (v.length > 0) {
          const firstObj = v[0];

          for (const [l, c] of Object.entries(firstObj)) {
            create += `${l},`;
          }
        } else {
          for (const [l, c] of Object.entries(v)) {
            create += `${l},`;
          }
        }

        create = create.slice(0, -1); //remove last ", "
        create += `)`;

        statements.push(create as any);
        variables.push([] as any);
        callbacks.push(doNothing as any);
        callbacksFail.push(logError as any);
      }

      DatabaseHandler.executeSqlTransation(
        statements as any,
        variables as any,
        callbacks as any,
        callbacksFail as any
      );
    });
  }

  public createTableIfNotExists(
    tableName: object,
    firstRow: any,
    allRows?: any
  ) {
    return new Promise<boolean>((resolve: any, reject: any) => {
      this.checkAndSetTableSpecificColumnNotFound(tableName, firstRow, allRows);

      // add to fix run time error if this property is not there
      if ((tableName as any) == 'ModifierMasters') {
        ('Modifier');

        if (!firstRow.hasOwnProperty('IsSubGroup')) {
          firstRow.IsSubGroup = 'False';
        }

        if (!firstRow.hasOwnProperty('isOloOptionGroup')) {
          firstRow.isOloOptionGroup = 'False';
        }

        if (!firstRow.hasOwnProperty('MinAggregateQty')) {
          firstRow.MinAggregateQty = 'False';
        }

        if (!firstRow.hasOwnProperty('MaxAggregateQty')) {
          firstRow.MaxAggregateQty = 'False';
        }

        if (!firstRow.hasOwnProperty('MinChoiceQty')) {
          firstRow.MinChoiceQty = 'False';
        }
        if (!firstRow.hasOwnProperty('MaxChoiceQty')) {
          firstRow.MaxChoiceQty = 'False';
        }

        if (!firstRow.hasOwnProperty('ChoiceQtyIncrement')) {
          firstRow.ChoiceQtyIncrement = 'False';
        }

        if (!firstRow.hasOwnProperty('UseAdvancedModifierQuantitySettings')) {
          firstRow.UseAdvancedModifierQuantitySettings = 'False';
        }
      }

      if ((tableName as any) == 'CategoryMasters') {
        allRows.forEach((row: any) => {
          if (!row.hasOwnProperty('DisplayCategory')) {
            row.DisplayCategory = 'True';
          }
        });
      }

      var create = `CREATE TABLE IF NOT EXISTS ${tableName} (`;
      Object.keys(firstRow).forEach((key) => (create += `${key}, `));
      create = create.slice(0, -2); //remove last ", "
      create += `)`;

      const logError = (statement: any, error: any) => {
        console.log(
          'Save Setting Service, Error  createTableIfNotExists Table :- ' +
            tableName,
          error
        );

        resolve(false);
      };

      const doNothing = () => {
        resolve(true);
      };

      DatabaseHandler.executeSqlStatement(create, [], doNothing, logError);
    });
  }

  public addTableRowsToDb(tableName: object, tableData: any[]) {
    return new Promise<boolean>((resolve: any, reject: any) => {
      var totalRefreshDataCounter = 0;
      var refreshDataCounter = tableData.length;
      //this.totalRefreshDataCounter = 0;
      //this.refreshDataCounter = tableData.length;

      (async () => {
        var statements = [] as any[];
        var variables = [] as any[];
        var callbacks = [] as any[];
        var callbacksFail = [] as any[];

        for (let i = 0; i < tableData.length; i++) {
          const row = tableData[i];
          const rowKeys = Object.keys(row);
          let vars: String[] = [];
          let statement = `INSERT INTO ${tableName} (`;

          for (let j = 0; j < rowKeys.length; j++) {
            const key = rowKeys[j];
            statement += `${key}, `;
          }

          statement = statement.slice(0, -2); //remove last ", "
          statement += `) VALUES (`;

          const values: String[] = Object.values(row);

          statement += `?, `.repeat(values.length);
          statement = statement.slice(0, -2); //remove last ", "
          statement += `)`;

          vars = vars.concat(values);

          const logError = (statement: any, error: any) => {
            totalRefreshDataCounter++;

            if (refreshDataCounter >= totalRefreshDataCounter) {
              resolve(false);
            }

            'Insert Raw Error :- ' + error.message;

            //this.logger.logWebsqlErrortoDBandServer('Database sync Add table row Error', error.message);
            //resolve(false);
          };

          const doNothing = () => {
            totalRefreshDataCounter++;

            if (refreshDataCounter >= totalRefreshDataCounter) {
              resolve(true);
            }
            //resolve(true);
          };

          statements.push(statement as any);
          variables.push(vars);
          callbacks.push(doNothing as any);
          callbacksFail.push(logError as any);

          //await this.addSingleItemInDb(tableName, row);
        }

        DatabaseHandler.executeSqlTransation(
          statements,
          variables,
          callbacks,
          callbacksFail
        );

        //DatabaseHandler.executeSqlStatement(statements as any, variables, callbacks as any, callbacksFail as any);
        //resolve(true);
      })();
    });
  }

  /**
   *
   * @param tableName Insert discount start date and end date in database supported date format.
   * @param tableData
   */
  private async modifyTableData(tableName: any, tableData: any) {
    if (tableName == 'DiscountMasters') {
      tableData.forEach((discount: DiscountMaster) => {
        if (discount.StartDate) {
          let startDate = discount.StartDate.replace(/.{2}$/, ' $&');
          let formatedStartDate = this.datePipe.transform(
            startDate,
            'yyyy-MM-dd HH:mm:ss'
          );
          discount.StartDate = formatedStartDate!;
        } else {
          discount.StartDate = '';
        }
        if (discount.EndDate) {
          let endDate = discount.EndDate.replace(/.{2}$/, ' $&');
          let formatedEndDate = this.datePipe.transform(
            endDate,
            'yyyy-MM-dd HH:mm:ss'
          );
          discount.EndDate = formatedEndDate!;
        } else {
          discount.EndDate = '';
        }
      });
    } else if (tableName == 'SchedulePromoPricingMasters') {
      tableData.forEach((x: any) => {
        if (x.StartDate) {
          let startDate = x.StartDate.replace(/.{2}$/, ' $&');
          let formatedStartDate = this.datePipe.transform(
            startDate,
            'yyyy-MM-dd HH:mm:ss'
          );
          x.StartDate = formatedStartDate!;
        } else {
          x.StartDate = '';
        }
        if (x.EndDate) {
          let endDate = x.EndDate.replace(/.{2}$/, ' $&');
          let formatedEndDate = this.datePipe.transform(
            endDate,
            'yyyy-MM-dd HH:mm:ss'
          );
          x.EndDate = formatedEndDate!;
        } else {
          x.EndDate = '';
        }
      });
    }
  }

  private onSyncTableSpecificChanges(table: any, tableData: any[]) {
    let ImageArray = [] as any[];
    switch (table) {
      case 'ModuleGeneralSettings':
        let generalSettings = tableData as any;

        if (generalSettings && generalSettings.length > 0) {
          let homeBack = generalSettings[0].SeparateHomeScreenImagePath;
          ImageArray = ImageArray.concat(homeBack);
          if (
            generalSettings[0].BackgroundImage &&
            generalSettings[0].BackgroundImage != ''
          ) {
            let BackgroundImage = generalSettings[0].BackgroundImage;
            ImageArray = ImageArray.concat(BackgroundImage);
          }
          if (
            generalSettings[0].WelcomeLoyaltyLogoPath &&
            generalSettings[0].WelcomeLoyaltyLogoPath != ''
          ) {
            let WelcomeLoyaltyLogoPath =
              generalSettings[0].WelcomeLoyaltyLogoPath;
            ImageArray = ImageArray.concat(WelcomeLoyaltyLogoPath);
          }
          if (
            generalSettings[0].ImageBGKiosk &&
            generalSettings[0].ImageBGKiosk != ''
          ) {
            let ImageBGKiosk = generalSettings[0].ImageBGKiosk;

            ImageArray = ImageArray.concat(ImageBGKiosk);
          }
          if (
            generalSettings[0].TentImage &&
            generalSettings[0].TentImage != ''
          ) {
            let TentImage = generalSettings[0].TentImage;

            ImageArray = ImageArray.concat(TentImage);
          }
          if (
            generalSettings[0].ConcessionaireHeaderImage &&
            generalSettings[0].ConcessionaireHeaderImage != ''
          ) {
            let concessionaireHeaderImage =
              generalSettings[0].ConcessionaireHeaderImage;

            ImageArray = ImageArray.concat(concessionaireHeaderImage);
          }
          if (
            generalSettings[0].PlaceholderImage &&
            generalSettings[0].PlaceholderImage != ''
          ) {
            let PlaceholderImage = generalSettings[0].PlaceholderImage;

            ImageArray = ImageArray.concat(PlaceholderImage);
          }
          let TermsConditionsFile = generalSettings[0].TermsConditionsFile;
          ImageArray = ImageArray.concat(TermsConditionsFile);

          if (generalSettings[0].AllowLoyaltyLoginusingPhoneNumber) {
            GeneralSetting.setLoyaltyLoginWithPhone(
              generalSettings[0].AllowLoyaltyLoginusingPhoneNumber
            );
          }
          if (generalSettings[0].BarcodeOrQRcodeData) {
            GeneralSetting.setBarcodeOrQRCodeData(
              generalSettings[0].BarcodeOrQRcodeData
            );
          }
          if (generalSettings[0].BarcodeOrQRcodeDescription) {
            GeneralSetting.setBarcodeOrQRCodeDescription(
              generalSettings[0].BarcodeOrQRcodeDescription
            );
          }
          if (generalSettings[0].BarcodeOrQRcodeonReceipt) {
            GeneralSetting.setBarcodeOrQRCodeOnReceipt(
              generalSettings[0].BarcodeOrQRcodeonReceipt
            );
          }
          let CustomerInfoImage = generalSettings[0].CustomerInfoImage;
          ImageArray = ImageArray.concat(CustomerInfoImage);
        }

        break;
      case 'FilterMasters':
        this.totalDownloadSets++;

        let listFilterImageUrl = tableData.map((x) => {
          if (CommonFunctions.checkVariable(x.IconImage) && x.IconImage != '') {
            return x.IconImage;
          }
          return '';
        });

        ImageArray = ImageArray.concat(listFilterImageUrl);
        //this.QueueDownloadItemImagesV2("", listFilterImageUrl);

        break;
      case 'BranchMasters':
        let branchData = tableData as any;

        if (branchData && branchData.length > 0) {
          this.pusherService.branchId = branchData[0].BranchID;
        }
        this.totalDownloadSets++;

        let listBranchLogoImageUrl = tableData.map((x) => {
          if (
            CommonFunctions.checkVariable(x.BranchLogo) &&
            x.BranchLogo != ''
          ) {
            x.BranchLogo = x.BranchLogo + '?tr=w-200';
            return x.BranchLogo;
          }
        });

        ImageArray = ImageArray.concat(listBranchLogoImageUrl);

        //this.QueueDownloadItemImagesV2("", listBranchLogoImageUrl);
        this.totalDownloadSets++;
        let listHomeScreenLogoImageUrl = tableData.map((x) => {
          if (
            CommonFunctions.checkVariable(x.HomeScreenLogo) &&
            x.HomeScreenLogo != ''
          ) {
            return x.HomeScreenLogo;
          }
          return '';
        });

        ImageArray = ImageArray.concat(listHomeScreenLogoImageUrl);

        this.currencyService.setCurrencySymbol(branchData[0].CurrencySymbol);
        GeneralSetting.setCurrencySymbol(branchData[0].CurrencySymbol);
        GeneralSetting.setCountryCurrency(branchData[0].CountryCurrency);
        //this.QueueDownloadItemImagesV2("", listHomeScreenLogoImageUrl);

        break;
      case 'ScreenSaverMasters':
        this.totalDownloadSets++;

        let listScreenSaverUrl = tableData.map((x) => {
          if (
            CommonFunctions.checkVariable(x.ImagePath) &&
            x.ImagePath != '' &&
            x.ScreenSaverTypeID == '1'
          ) {
            return x.ImagePath;
          }
          return '';
        });

        ImageArray = ImageArray.concat(listScreenSaverUrl);
        //this.QueueDownloadItemImagesV2("", listScreenSaverUrl);
        this.totalDownloadSets++;
        let listScreenSaver2Url = tableData.map((x) => {
          if (
            CommonFunctions.checkVariable(x.VideoPath) &&
            x.VideoPath != '' &&
            x.ScreenSaverTypeID == '2'
          ) {
            return x.VideoPath;
          }
          return '';
        });

        ImageArray = ImageArray.concat(listScreenSaver2Url);
        //this.QueueDownloadItemImagesV2("", listScreenSaver2Url);

        break;
      case 'OloIntegrations':
        let data = tableData as any;

        if (data == null || data == undefined || data.length <= 0) {
          this.olo.isOLO = false;
        } else {
          this.olo.oloIntegrationTableData = data[0];
          this.olo.isOLO = !(
            this.olo.oloIntegrationTableData.vendorID == '' ||
            this.olo.oloIntegrationTableData.vendorID == null
          );
          if (this.olo.isOLO) {
            this.integration.setOLO();
          }
        }
        break;
      case 'PusherSettingMasters':
        let psm = tableData as any;

        if (psm.length > 0) {
          this.pusherService.pusherMaster = psm;
        }
        break;
      case 'ItemImages':
        this.totalDownloadSets++;

        let listItemImageUrl = tableData.map((x) => {
          if (CommonFunctions.checkVariable(x.ImageUrl) && x.ImageUrl != '') {
            x.ImageUrl = x.ImageUrl + '?tr=w-200';
            return x.ImageUrl;
          }
          return '';
        });

        listItemImageUrl = [...new Set(listItemImageUrl)];
        //this.QueueDownloadItemImagesV2("", listItemImageUrl);

        ImageArray = ImageArray.concat(listItemImageUrl);
        break;
      case 'CategoryImages':
        this.totalDownloadSets++;

        let listCategoryImageUrl = tableData.map((x) => {
          if (CommonFunctions.checkVariable(x.ImageUrl) && x.ImageUrl != '') {
            x.ImageUrl = x.ImageUrl + '?tr=w-200';
            return x.ImageUrl;
          }

          return '';
        });

        listCategoryImageUrl = [...new Set(listCategoryImageUrl)];
        //this.QueueDownloadItemImagesV2("", listCategoryImageUrl);
        ImageArray = ImageArray.concat(listCategoryImageUrl);
        break;
      case 'CategoryMasters':
        this.totalDownloadSets++;

        let listHeaderImageUrl = tableData.map((x) => {
          if (
            CommonFunctions.checkVariable(x.HeaderImage) &&
            x.HeaderImage != ''
          ) {
            return x.HeaderImage;
          }
          return '';
        });

        listHeaderImageUrl = [...new Set(listHeaderImageUrl)];

        ImageArray = ImageArray.concat(listHeaderImageUrl);

        //this.QueueDownloadItemImagesV2("", listHeaderImageUrl);
        this.totalDownloadSets++;
        let listCategoryIconImageUrl = tableData.map((x) => {
          if (
            CommonFunctions.checkVariable(x.CategoryIcon) &&
            x.CategoryIcon != ''
          ) {
            x.CategoryIcon = x.CategoryIcon + '?tr=w-200';
            return x.CategoryIcon;
          }
          return '';
        });

        listCategoryIconImageUrl = [...new Set(listCategoryIconImageUrl)];

        ImageArray = ImageArray.concat(listCategoryIconImageUrl);

        //this.QueueDownloadItemImagesV2("", listCategoryIconImageUrl);
        this.totalDownloadSets++;
        let listDeselectCategoryIconImageUrl = tableData.map((x) => {
          if (
            CommonFunctions.checkVariable(x.DeselectCategoryIcon) &&
            x.DeselectCategoryIcon != ''
          ) {
            x.DeselectCategoryIcon = x.DeselectCategoryIcon + '?tr=w-200';
            return x.DeselectCategoryIcon;
          }
          return '';
        });

        listDeselectCategoryIconImageUrl = [
          ...new Set(listDeselectCategoryIconImageUrl),
        ];

        ImageArray = ImageArray.concat(listDeselectCategoryIconImageUrl);

        //this.QueueDownloadItemImagesV2("", listDeselectCategoryIconImageUrl);
        this.totalDownloadSets++;
        let listBackgroundImageImageUrl = tableData.map((x) => {
          if (
            CommonFunctions.checkVariable(x.BackgroundImage) &&
            x.BackgroundImage != ''
          ) {
            return x.BackgroundImage;
          }
          return '';
        });

        listBackgroundImageImageUrl = [...new Set(listBackgroundImageImageUrl)];

        ImageArray = ImageArray.concat(listBackgroundImageImageUrl);
        //this.QueueDownloadItemImagesV2("", listBackgroundImageImageUrl);

        break;
      case 'ItemAttributeOptions':
        this.totalDownloadSets++;

        let listItemAttributeImageUrl = tableData.map((x) => {
          if (CommonFunctions.checkVariable(x.ImageUrl) && x.ImageUrl != '') {
            x.ImageUrl = x.ImageUrl + '?tr=w-200';
            return x.ImageUrl;
          }
          return '';
        });

        listItemAttributeImageUrl = [...new Set(listItemAttributeImageUrl)];

        ImageArray = ImageArray.concat(listItemAttributeImageUrl);

        break;
      case 'Items':
        this.totalDownloadSets++;

        let listItemBackgroundImageUrl = tableData.map((x) => {
          if (
            CommonFunctions.checkVariable(x.BackgroundImage) &&
            x.BackgroundImage != ''
          ) {
            return x.BackgroundImage;
          }
          return '';
        });

        listItemBackgroundImageUrl = [...new Set(listItemBackgroundImageUrl)];

        ImageArray = ImageArray.concat(listItemBackgroundImageUrl);
        //this.QueueDownloadItemImagesV2("", listItemBackgroundImageUrl);

        break;
      case 'Ingredients':
        this.totalDownloadSets++;

        let listIngredientsImageUrl = tableData.map((x) => {
          if (CommonFunctions.checkVariable(x.ImageUrl) && x.ImageUrl != '') {
            x.ImageUrl = x.ImageUrl + '?tr=w-200';
            return x.ImageUrl;
          }
          return '';
        });

        listIngredientsImageUrl = [...new Set(listIngredientsImageUrl)];

        ImageArray = ImageArray.concat(listIngredientsImageUrl);

        //this.QueueDownloadItemImagesV2("", listIngredientsImageUrl);
        this.totalDownloadSets++;
        let listDeselectImageUrlImageUrl = tableData.map((x) => {
          if (
            CommonFunctions.checkVariable(x.DeselectImageUrl) &&
            x.DeselectImageUrl != ''
          ) {
            x.DeselectImageUrl = x.DeselectImageUrl + '?tr=w-200';
            return x.DeselectImageUrl;
          }
          return '';
        });

        listDeselectImageUrlImageUrl = [
          ...new Set(listDeselectImageUrlImageUrl),
        ];

        ImageArray = ImageArray.concat(listDeselectImageUrlImageUrl);

        break;
      case 'ConcessionaireMasters':
        let listConcessionaireLogoUrl = tableData.map((x) => {
          if (CommonFunctions.checkVariable(x.Logo) && x.Logo != '') {
            return '/uploads/ConcessionaireLogo/' + x.Logo;
          }
          return '';
        });

        ImageArray = ImageArray.concat(listConcessionaireLogoUrl);

        let listConcessionaireSidebarLogoUrl = tableData.map((x) => {
          if (
            CommonFunctions.checkVariable(x.SidebarLogo) &&
            x.SidebarLogo != ''
          ) {
            return x.SidebarLogo;
          }
          return '';
        });

        ImageArray = ImageArray.concat(listConcessionaireSidebarLogoUrl);
        break;
      case 'ModifierMasters':
        this.totalDownloadSets++;

        let listModifierImageUrl = tableData.map((x) => {
          if (
            CommonFunctions.checkVariable(x.ImageUrl) &&
            x.ImageUrl != '' &&
            x.ImageUrl.trim() != '/uploads/ModifierImage/'
          ) {
            x.ImageUrl = x.ImageUrl + '?tr=w-200';
            return x.ImageUrl;
          }
          return '';
        });

        listModifierImageUrl = [...new Set(listModifierImageUrl)];

        ImageArray = ImageArray.concat(listModifierImageUrl);

        //this.QueueDownloadItemImagesV2("", listModifierImageUrl);
        this.totalDownloadSets++;

        let listModifierSelectedImage = tableData.map((x) => {
          if (
            CommonFunctions.checkVariable(x.SelectedImage) &&
            x.SelectedImage != '' &&
            x.SelectedImage.trim() != '/uploads/ModifierImage/'
          ) {
            x.SelectedImage = x.SelectedImage + '?tr=w-200';
            return x.SelectedImage;
          }
          return '';
        });

        listModifierSelectedImage = [...new Set(listModifierSelectedImage)];

        ImageArray = ImageArray.concat(listModifierSelectedImage);
        //this.QueueDownloadItemImagesV2("", listModifierSelectedImage);

        this.totalDownloadSets++;

        let listModifierDeselectedImage = tableData.map((x) => {
          if (
            CommonFunctions.checkVariable(x.DeselectedImage) &&
            x.DeselectedImage != '' &&
            x.DeselectedImage.trim() != '/uploads/ModifierImage/'
          ) {
            x.DeselectedImage = x.DeselectedImage + '?tr=w-200';
            return x.DeselectedImage;
          }

          return '';
        });

        listModifierDeselectedImage = [...new Set(listModifierDeselectedImage)];

        ImageArray = ImageArray.concat(listModifierDeselectedImage);
        //this.QueueDownloadItemImagesV2("", listModifierDeselectedImage);

        break;
      case 'CustomMessages':
        let listCCustomMessageImageUrl = tableData.map((x) => {
          if (CommonFunctions.checkVariable(x.Image) && x.Image != '') {
            return x.Image;
          }
          return '';
        });

        ImageArray = ImageArray.concat(listCCustomMessageImageUrl);
        break;
      case 'OracleGeneralSetting':
        let oracleData = tableData as OracleGeneralSettings[];
        if (oracleData.length > 0) {
          if (!oracleData[0]) {
            this.oracle.isOracle = false;
            break;
          }
          if (
            oracleData[0].GrubbrrAgentAPIHost == '' ||
            oracleData[0].SimphonyAPIHost == '' ||
            oracleData[0].TenderID == ''
          ) {
            this.oracle.isOracle = false;
            this.logger.sendLogToServer(
              new loggingData(
                'Oracle General Setting Error',
                'GrubbrrAgentAPIHost Or SimphonyAPIHost OR TenderID Not found',
                'Data Not found',
                JSON.stringify(oracleData[0]),
                true
              )
            );
            break;
          }
          this.oracle.oracleGeneralSetting = oracleData[0];
          this.oracle.isOracle = true;
          this.integration.setSymphony();
        }
        break;
      case 'OracleMicrosIntegration':
        let oracleMicrosData = tableData as OracleMicrosIntegration[];

        if (!oracleMicrosData || oracleMicrosData.length <= 0) {
          this.oracle.isOracle = false;
          return;
        }

        if (
          oracleMicrosData[0].TransactionAPIURL == '' ||
          oracleMicrosData[0].MenuLevelID == '' ||
          oracleMicrosData[0].TenderID == ''
        ) {
          this.oracle.isOracle = false;
          this.oracle.isOracleMicross = false;

          this.logger.sendLogToServer(
            new loggingData(
              'Oracle Micros Error',
              'TransactionAPIURL Or MenuLevelID OR TenderID Not found',
              'Data Not found',
              JSON.stringify(oracleMicrosData),
              true
            )
          );
          return;
        }
        this.oracle.oracleMicrosSetting = oracleMicrosData[0];
        this.oracle.isOracle = true;
        this.oracle.isOracleMicross = true;
        if (oracleMicrosData[0].ThirdPartyLoyaltyDiscountID) {
          GeneralSetting.setTPLoyaltyDiscountID(
            oracleMicrosData[0].ThirdPartyLoyaltyDiscountID
          );
        }
        if (oracleMicrosData[0].ThirdPartyLoyaltyPaymentTenderID) {
          GeneralSetting.setTPLoyaltyPayment(
            oracleMicrosData[0].ThirdPartyLoyaltyPaymentTenderID
          );
        }

        break;
      case 'CardNetworkMasters':
        if (tableData.length > 0) {
          this.oracle.cardNetWordDetails = tableData;
        }
        break;
      case 'NCRVendorIntegrations':
        let ncrData = tableData as NCRVendorIntegrations[];

        if (ncrData && ncrData.length > 0) {
          if (!ncrData[0]) {
            this.ncrService.isNcrIntegration = false;
            break;
          }

          this.ncrService.isNcrIntegration = true;
          this.ncrService.ncrData = ncrData[0];
        }

        break;
      case 'CloverIntegration':
        if (tableData && tableData.length > 0) {
          let cloverIntegrationArray = Array.from(tableData as any) as any[];
          if (cloverIntegrationArray && cloverIntegrationArray.length > 0) {
            let cloverIntegration = cloverIntegrationArray[0];
            GeneralSetting.setCloverIntegrationId(
              cloverIntegration.CloverIntegrationId
            );

            GeneralSetting.setCloverBaseURL(cloverIntegration.SiteURL);

            GeneralSetting.setApplicationId(cloverIntegration.ApplicationID);

            GeneralSetting.setMerchantId(cloverIntegration.MerchantId);

            GeneralSetting.setAccessToken(cloverIntegration.AuthToken);

            GeneralSetting.setCloverEnvironment(cloverIntegration.Environment);
          }
        }
        break;
      case 'ParBrinkIntegration':
        if (tableData) {
          let parBrinkIntegrationArray = Array.from(tableData as any) as any[];

          if (parBrinkIntegrationArray && parBrinkIntegrationArray.length > 0) {
            let parBrinkIntegration = parBrinkIntegrationArray[0];

            this.integration.setParbrink();

            GeneralSetting.setParBrinkIntegrationID(
              parBrinkIntegration.ParBrinkIntegrationID
            );

            GeneralSetting.setDefaultTenderID(parBrinkIntegration.TenderID);

            GeneralSetting.setTPLoyaltyDiscountID(
              parBrinkIntegration.ThirdPartyLoyaltyDiscountID
            );
            GeneralSetting.setTPLoyaltyDiscountName(
              parBrinkIntegration.ThirdPartyLoyaltyDiscountName
            );
            GeneralSetting.setTPLoyaltyPayment(
              parBrinkIntegration.ThirdPartyLoyaltyPaymentTenderID
            );
          }
        }

        break;
      case 'OtterIntegrations':
        if (tableData && tableData.length > 0) {
          let otterIntegrations = Array.from(tableData as any) as any[];
          if (otterIntegrations && otterIntegrations.length > 0) {
            let otterIntegration = otterIntegrations[0];
            GeneralSetting.setOtterIntegrationId(
              otterIntegration.OtterIntegrationID
            );
          }
        }
        break;
      case 'StaffMasters':
        if (tableData && tableData.length > 0) {
          let staffMaster = Array.from(tableData as any) as any[];
          if (staffMaster && staffMaster.length > 0) {
            let staffMasterSingle = staffMaster[0];
            GeneralSetting.setStaffLocationID(
              staffMasterSingle.StaffLocationID
            );
          }
        }
        break;
      case 'CompanyMasters':
        if (tableData && tableData.length > 0) {
          let companyMaster = Array.from(tableData as any) as any[];
          if (companyMaster && companyMaster.length > 0) {
            let companyMasterSingle = companyMaster[0];
            GeneralSetting.setCompanyName(companyMasterSingle.BusinessName);
          }
        }
        break;
      default:
        break;
    }

    return ImageArray.filter((x) => x != undefined && x != '');
  }

  private removeDataForTable(keys: any[]) {
    return new Promise<boolean>((resolve: any, reject: any) => {
      this.totalRefreshDataCounter = 0;
      this.refreshDataCounter = keys.length;

      (async () => {
        try {
          var statements = [] as any[];
          var variables = [] as any[];
          var callbacks = [] as any[];
          var callbacksFail = [] as any[];

          for (let i = 0; i < keys.length; i++) {
            const table: any = keys[i];

            const statement: any = `DROP TABLE IF EXISTS ${table}`;

            const logError = (statement: any, error: any) => {
              this.totalRefreshDataCounter++;

              if (this.refreshDataCounter >= this.totalRefreshDataCounter) {
                resolve(false);
              }

              console.log(
                'Save Setting Service, Error removeDataForTable :- ',
                error
              );
            };

            const doNothing = () => {
              this.totalRefreshDataCounter++;

              if (this.refreshDataCounter >= this.totalRefreshDataCounter) {
                resolve(true);
              }
            };

            statements.push(statement as any);
            variables.push([]);
            callbacks.push(doNothing as any);
            callbacksFail.push(logError as any);
            //DatabaseHandler.executeSqlStatement(drop, [], doNothing, logError);
          }

          DatabaseHandler.executeSqlTransation(
            statements,
            variables,
            callbacks,
            callbacksFail
          );
        } catch (e) {
          resolve(false);
        }
      })();
    });
  }

  private checkAndSetTableSpecificColumnNotFound(
    tableName: object,
    firstRow: any,
    allRows?: any
  ) {
    if ((tableName as any) == 'ConcessionaireMasters') {
      if (!firstRow.hasOwnProperty('ConcessionaireTypeID')) {
        firstRow.ConcessionaireTypeID = '';
      }
    }
  }

  async QueueDownloadItemImages(results: any) {
    return new Promise<any>(async (res: Function, rej: Function) => {
      if (this.isKioskService.isKiosk()) {
        for (let row of results as string[]) {
          if (row.startsWith('https:/')) {
            if (!this.existingFiles.has(row.replace('https:/', ''))) {
              this.downloadUrls.add(row);
            }
          } else if (row.startsWith('http:/')) {
            if (!this.existingFiles.has(row.replace('http:/', ''))) {
              this.downloadUrls.add(row);
            }
          } else if (row.endsWith('/')) {
            continue;
          } else {
            if (!this.existingFiles.has(row)) {
              this.downloadUrls.add(row);
            }
          }
        }

        this.totalDownloadSetsQueued++;
        // console.log("total download sets", this.totalDownloadSetsQueued, " >= ",this.totalDownloadSets)
        if (this.totalDownloadSetsQueued >= this.totalDownloadSets) {
          let index = 1;
          // window!.top!.postMessage(message, '*');
          for (let image of this.downloadUrls.values()) {
            this.downloadPopupService.setMessage('Downloading\n');

            this.downloadPopupService.setSecondMessage(
              index + ' of ' + this.downloadUrls.size
            );

            //ref.componentInstance.path = image + '...';
            let downloadResponse = await this.downloadImage(image as any);

            this.filterImages(downloadResponse);

            index++;
          }
          await this.downloadComplete();
        }
      }

      res(true);
    });
  }

  private closeModals() {
    if (this.router.url.indexOf('touchToStart') !== -1) {
      this.updateMenuService.close();
      this.downloadPopupService.close();
    } else if (this.router.url.indexOf('settings') !== -1) {
      this.updateMenuService.close();
      this.downloadPopupService.close();
    } else if (this.router.url == '/') {
      this.updateMenuService.close();
      this.downloadPopupService.close();
    }
  }

  private successfulLogin() {
    if (this.router.url == '/') {
      GeneralSetting.setIsDataBasePresent('True');
    }
  }

  private openFailModal() {
    this.failedImagesComponent = new FailedImagesComponent(
      this.modalService,
      this.language
    );
    let failedArray = Array.from(this.failedImages.values());
    this.failedImagesRef = this.failedImagesComponent.open(
      failedArray,
      this.onlineImages
    );
    this.failedImagesRef.componentInstance.ref = this.failedImagesRef;
  }

  private async handleFailure() {
    return new Promise<any>(async (res: Function, rej: Function) => {
      this.openFailModal();

      this.hasFailed = false;

      var log = new loggingData(
        'Download Failure',
        this.failedImages.size + ' images failed to download',
        this.failedImages.size + ' images failed to download',
        Array.from(this.failedImages.values()),
        true
      );

      this.logger.sendLogToServer(log);
      this.failedImagesRef.result.then(async (result: 'retry' | 'continue') => {
        if (result == 'retry') {
          this.onlineImages = [];

          await this.QueueDownloadItemImages(
            Array.from(this.failedImages.values())
          );

          this.failedImages.clear();
        } else if (result == 'continue') {
          this.closeModals();
          this.successfulLogin();
          this.failedImages.clear();
          this.onlineImages = [];
        }
        res(true);
      });
    });
  }

  private async downloadComplete() {
    return new Promise<any>(async (res: Function, rej: Function) => {
      this.downloadUrls.clear();
      this.closeModals();

      if (this.hasFailed && !this.router.url.includes('touchToStart')) {
        await this.handleFailure();
      } else {
        this.closeModals();
        this.successfulLogin();
      }

      res(true);
    });
  }

  private filterImages(downloadResponse: DownloadMessage) {
    if (downloadResponse.failed) {
      this.hasFailed = true;
      this.failedImages.add(downloadResponse.imageUrl);
      this.onlineImages.push(downloadResponse.onlineImage);
      if (downloadResponse.timedOut) {
        Snackbar.show('Download timed out...', 3000);
      }
    } else {
      this.existingFiles.add(downloadResponse.imageUrl);
    }
  }

  // downloads one image at a time and returns a promise of the response from the download.
  private downloadImage(image: string) {
    return new Promise<any>((res: any, rej: any) => {
      const message: Message = {
        messageType: MessageType.DownloadImage,
        data: image,
        baseUrl: environment.imageBaseUrlRemote,
      };
      // console.log('attempting to download image', message);
      window.top!.postMessage(message, '*');

      window.addEventListener('message', getMessage.bind(this), false);

      function getMessage(event: any) {
        if (event.data.messageType == MessageType.CompletedImage) {
          // console.log('COMPLETED IMAGE', event.data, 'original image', image);
          res(event.data);
        }
      }
    });
  }

  private themeCallback(tx: string, data: any) {
    GeneralSetting.setPrimaryColor(data.rows[0]['PrimaryColor']);
    GeneralSetting.setPriceColor(data.rows[0]['ItemPriceColor']);
    GeneralSetting.setSecondaryColor(data.rows[0]['SecondaryColor']);
    GeneralSetting.setTextColor(data.rows[0]['TextColor']);
    GeneralSetting.setPositiveColor(data.rows[0]['PositiveButtonColor']);
    GeneralSetting.setPositiveColorText(
      data.rows[0]['PositiveButtonTextColor']
    );
    GeneralSetting.setNegativeColor(data.rows[0]['NegativeButtonColor']);
    GeneralSetting.setNegativeColorText(
      data.rows[0]['NegativeButtonTextColor']
    );
    GeneralSetting.setCategoryColor(
      data.rows[0]['CategorySidebarBackgroundColor']
    );
    GeneralSetting.setFooterBackground(
      data.rows[0]['FooterOrderSummaryBackgroundColor']
    );
    GeneralSetting.setAccentColor(data.rows[0]['AccentColor']);
    GeneralSetting.setKioskBackgroundImage(data.rows[0]['ImagePath']);
    GeneralSetting.setCategoryBackgroundColor(
      data.rows[0]['CategoryBackgroundColor']
    );
    GeneralSetting.setFooterOrderSummaryBackgroundColor(
      data.rows[0]['footerOrderSummaryBackgroundColor']
    );

    GeneralSetting.set__accent_color_CSS();
    GeneralSetting.set__price_color_CSS();
    GeneralSetting.set__bold_text_color_CSS();
    GeneralSetting.set__border_color_CSS();
    GeneralSetting.set__category_background_color_CSS();
    GeneralSetting.set__category_sidebar_background_color_CSS();
    GeneralSetting.set__footer_order_summary_background_color_CSS();
    GeneralSetting.set__negative_button_color_CSS();
    GeneralSetting.set__negative_button_text_color_CSS();
    GeneralSetting.set__positive_button_color_CSS();
    GeneralSetting.set__positive_button_text_color_CSS();
    GeneralSetting.set__primary_color__CSS();
    GeneralSetting.set__secondary_color_CSS();
    GeneralSetting.set__text_color_CSS();
  }

  private branchDataCallback(tx: string, data: any) {
    if (data.rows && data.rows.length > 0) {
      GeneralSetting.setBranchName(data.rows[0].BranchName);
      GeneralSetting.setBranchName(data.rows[0].BranchName);
      GeneralSetting.setBranchLogo(data.rows[0].BranchLogo);
      GeneralSetting.setBranchAddress1(
        data.rows[0].Address1.trim() + ' ' + data.rows[0].Address2.trim()
      );
      GeneralSetting.setBranchAddress2(
        data.rows[0].CityName.trim() +
          ', ' +
          data.rows[0].StateName.trim() +
          ' ' +
          data.rows[0].ZipCode.trim()
      );
      GeneralSetting.setBranchContactNo(data.rows[0].ContactNo.trim());
      GeneralSetting.setBranchTagLine(data.rows[0].TagLine);
      GeneralSetting.setBranchId(data.rows[0].BranchID);
      GeneralSetting.setCompanyId(data.rows[0].CompanyID);
      GeneralSetting.setCountry(data.rows[0].CountryID);
      GeneralSetting.setLevelUpLocationId(data.rows[0].LevelUpLocationId);
    }
  }

  private settingsCallback = (tx: string, data: any): void => {
    if (data.rows && data.rows.length > 0) {
      GeneralSetting.setCheckoutScreenTimeoutKIOSK(
        data.rows[0]['CheckoutScreenTimeoutKIOSK']
      );
      GeneralSetting.setGeneralTimeoutKIOSK(
        data.rows[0]['GeneralTimeoutKIOSK']
      );
      GeneralSetting.setThankYouMessage(data.rows[0]['ThankYouMessage']);
      GeneralSetting.setTipHeaderText(data.rows[0]['TipHeaderText']);
      GeneralSetting.setTipDescription(data.rows[0]['TipDescription']);
      GeneralSetting.setUpSellHeaderText(data.rows[0]['UpSellHeaderText']);
      GeneralSetting.setUpSellDescription(data.rows[0]['UpSellDescription']);
      GeneralSetting.setShowTipDialog(data.rows[0]['ShowTipDialog']);
      GeneralSetting.setShowCalorieCounts(data.rows[0]['ShowCalorieCounts']);
      GeneralSetting.setDisableSpecialRequest(
        data.rows[0]['DisableItemSpecialRequestForKiosk']
      );

      //border type image
      GeneralSetting.setBorderPercentage(data.rows[0]['BorderPercentage']);
      GeneralSetting.setBorderTypeSelection(
        data.rows[0]['BorderTypeSelection']
      );
      this.borderSelection();

      //IsConcessionaire
      GeneralSetting.setIsConcessionaire(data.rows[0]['IsConcessionaire']);

      GeneralSetting.setCheckForDiscount(data.rows[0]['CheckForDiscount']);
      GeneralSetting.setHideEmailReceiptButton(
        data.rows[0]['HideEmailReceiptButton']
      );
      GeneralSetting.setHomeScreenMsg(data.rows[0]['HomeScreenMessage']);
      GeneralSetting.setWindowHeaderText(data.rows[0]['WindowHeaderText']);
      GeneralSetting.setWindowText(data.rows[0]['WindowText']);
      GeneralSetting.setWindowTitle(data.rows[0]['WindowTitle']);
      if (
        typeof data.rows[0] === 'object' &&
        !Array.isArray(data.rows[0]) &&
        data.rows[0] !== null &&
        data.rows[0].hasOwnProperty('TentImage')
      ) {
        GeneralSetting.setTentImage(data.rows[0]['TentImage']);
      }
      GeneralSetting.setTentNumberText(data.rows[0]['TentNumberText']);
      GeneralSetting.setKOTFooterMessage(data.rows[0]['KOTFooterMessage']);
      GeneralSetting.setEnableTableTents(data.rows[0]['EnableTableTents']);
      GeneralSetting.setIsTransparentDineIn(
        data.rows[0]['IsTransparentDineIn']
      );
      GeneralSetting.setTransparentButtonColor(
        data.rows[0]['TransparentButtonColor']
      );
      GeneralSetting.setShowNegativeModifiers(
        data.rows[0]['ShowNegativeModifiers']
      );
      GeneralSetting.setGeneralTimeoutKIOSK(
        data.rows[0]['GeneralTimeoutKIOSK']
      );
      GeneralSetting.setIsPrintTokenAndCustomerReceiptTogether(
        data.rows[0]['IsPrintTokenAndCustomerReceiptTogether']
      );
      GeneralSetting.setReturningCustomersFeatures(
        data.rows[0]['ReturningCustomersFeatures']
      );
      GeneralSetting.setDisableScreenSaver(data.rows[0]['DisableScreenSaver']);
      GeneralSetting.setHideStartsAtForVariablePrice(
        data.rows[0]['HideStartsAtForVariablePrice']
      );
      GeneralSetting.setHideItemTaxPrice(data.rows[0]['HideItemTaxPrice']);
      GeneralSetting.setMaxAlcoholicItemsPerOrder(
        data.rows[0]['MaxAlcoholicItemsPerOrder']
      );
      GeneralSetting.setStoreClosedTimeMessage(
        data.rows[0]['StoreClosedTimeMessage']
      );
      GeneralSetting.setSeparateHomeScreenImagePath(
        data.rows[0]['SeparateHomeScreenImagePath']
      );
      GeneralSetting.setUseSeparateImageForHomeScreen(
        data.rows[0]['UseSeparateImageforHomeScreen']
      );
      GeneralSetting.setUpChargeCardKIOSKAmount(
        data.rows[0]['UpchargeCardKIOSKAmount']
      );
      GeneralSetting.setUsePercentageCardKIOSK(
        data.rows[0]['UsePercentageCardKIOSK']
      );
      GeneralSetting.setIsmDoNotAllowAddToCartKiosk(
        data.rows[0]['IsmDoNotAllowAddtoCartKiosk']
      );
      GeneralSetting.setWelcomeLoyaltyLogoPath(
        data.rows[0]['WelcomeLoyaltyLogoPath']
      );
      GeneralSetting.setShowWelcomeLoyaltyLogo(
        data.rows[0]['ShowWelcomeLoyaltyLogo']
      );
      GeneralSetting.setWelcomeFooterText(data.rows[0]['WelcomeFooterText']);
      GeneralSetting.setIsShowSplashScreenMessage(
        data.rows[0]['IsShowSplashScreenMessage']
      );
      GeneralSetting.setSplashScreenMessage(
        data.rows[0]['SplashScreenMessage']
      );
      GeneralSetting.setIsShowHomeScreenLogo(
        data.rows[0]['IsShowHomeScreenLogo']
      );
      GeneralSetting.setHeaderTextForCustomerInKiosk(
        data.rows[0]['HeadertextForCustomerInKiosk']
      );
      GeneralSetting.setFooterTextForCustomerInKiosk(
        data.rows[0]['FootertextForCustomerInKiosk']
      );
      GeneralSetting.setHeaderTextForMerchantInKiosk(
        data.rows[0]['HeadertextForMerchantInKiosk']
      );
      GeneralSetting.setFooterTextForMerchantInKiosk(
        data.rows[0]['FootertextForMerchantInKiosk']
      );
      GeneralSetting.setHideZReport(data.rows[0]['HideZReport']);
      GeneralSetting.setCurrencyPrefix(data.rows[0]['IsCurrencyPrefixKiosk']);
      GeneralSetting.setShowItemTagImages(data.rows[0]['ShowItemTagImages']);
      GeneralSetting.setShowDefaultModifiers(
        data.rows[0]['ShowDefaultModifiers']
      );
      GeneralSetting.setShowModifierGroupName(
        data.rows[0]['ShowModifierGroupName']
      );
      GeneralSetting.setCommaSeparatedModifiers(
        data.rows[0]['CommaSeparatedModifiers']
      );
      GeneralSetting.setOrderCustomerEmailInfoHint(
        data.rows[0]['OrderCustomerEmailInfoHint']
      );
      GeneralSetting.setOrderCustomerNameInfoHeader(
        data.rows[0]['OrderCustomerNameInfoHeader']
      );
      GeneralSetting.setOrderCustomerNameInfoHint(
        data.rows[0]['OrderCustomerNameInfoHint']
      );
      GeneralSetting.setOrderCustomerPhoneInfoHint(
        data.rows[0]['OrderCustomerPhoneInfoHint']
      );

      GeneralSetting.setImageBGKiosk(data.rows[0]['ImageBGKiosk']);

      GeneralSetting.setShowSolidColorBGKiosk(
        data.rows[0]['ShowSolidColorBGKiosk']
      );

      GeneralSetting.setShowImageBGKiosk(data.rows[0]['ShowImageBGKiosk']);

      GeneralSetting.setColorCodeKiosk(data.rows[0]['ColorCodeKiosk']);

      this.currencyService.setIsPrefix(
        GeneralSetting.getCurrencyPrefix() === 'True'
      );

      GeneralSetting.setDonotPrintOrderToken(
        data.rows[0]['DonotPrintOrderToken']
      );

      GeneralSetting.setCPrintConOrderItemsKiosk(
        data.rows[0]['CPrintConOrderItemsKiosk']
      );

      GeneralSetting.setTaxesIncludedInPrice(
        data.rows[0]['IsIncludeTaxInPriceKiosk']
      );

      GeneralSetting.setTaxType(data.rows[0]['TaxBehavior']);

      GeneralSetting.setCustomerInfoImage(data.rows[0]['CustomerInfoImage']);

      GeneralSetting.setBoxBuilderFooterImage(
        data.rows[0]['BoxBuilderFooterImage']
      );

      GeneralSetting.setCollapseOptionalModifiersGroups(
        data.rows[0]['CollapseOptionalModifierGroups']
      );

      GeneralSetting.setBoxBuilderHeaderText(
        data.rows[0]['BoxBuilderHeaderText']
      );

      GeneralSetting.setCancelOrderImage(data.rows[0]['CancelOrderImage']);

      // Shipping Address
      if (data.rows[0]['EnableOrderShipment'] != null) {
        GeneralSetting.setEnableOrderShipment(
          data.rows[0]['EnableOrderShipment']
        );
        if (data.rows[0]['EnableOrderShipment'] == 'True') {
          GeneralSetting.setDomesticShippingFee(
            data.rows[0]['DomesticShippingFee']
          );
          GeneralSetting.setInternationalShippingFee(
            data.rows[0]['InternationalShippingFee']
          );
        }
      }

      GeneralSetting.setAddIDCheckForAlcohol(
        data.rows[0]['AddIDCheckForAlcohol']
      );
      // To update order type options details

      GeneralSetting.setIsRemovePricingAndPayments(
        data.rows[0]['RemovePricingAndPayments']
      );

      GeneralSetting.setWaitingStaffText(data.rows[0]['WaitingStaffText']);

      GeneralSetting.setShowReceiptItemGroupsonReceipts(
        data.rows[0]['ShowReceiptItemGroupsonReceipts']
      );

      GeneralSetting.setBackGroundImage(data.rows[0]['BackgroundImage']);

      GeneralSetting.setAllowCheckoutWithZeroAmountWithLoyalty(
        data.rows[0]['AllowCheckoutWithZeroAmountWithLoyalty']
      );

      GeneralSetting.setUseLoyaltyKiosk(data.rows[0]['UseLoyaltyKiosk']);

      GeneralSetting.setEnableOrderShipment(
        data.rows[0]['EnableOrderShipment']
      );

      GeneralSetting.setDomesticShippingFee(
        data.rows[0]['DomesticShippingFee']
      );

      GeneralSetting.setInternationalShippingFee(
        data.rows[0]['InternationalShippingFee']
      );

      GeneralSetting.setIsMinimizeSpacing(data.rows[0]['IsMinimizeSpacing']);

      GeneralSetting.setHidePriceIfBasePriceZero(
        data.rows[0]['HidePriceIfBasePriceZero']
      );

      GeneralSetting.setItemTableTentSpecialRequestPrefix(
        data.rows[0]['ItemTableTentSpecialRequestPrefix']
      );

      GeneralSetting.setConcessionaireHeaderImage(
        data.rows[0]['ConcessionaireHeaderImage']
      );

      GeneralSetting.setAllowCustomerOptInForMarketing(
        data.rows[0]['AllowCustomerOptInForMarketing']
      );
      GeneralSetting.setMarketingOptInText(data.rows[0]['MarketingOptInText']);
      GeneralSetting.setAddTermsConditions(data.rows[0]['AddTermsConditions']);
      GeneralSetting.setViewTermsAndCondition(
        data.rows[0]['ViewTermsAndCondition']
      );
      GeneralSetting.setHowToViewURLBasedTermsAndCondition(
        data.rows[0]['HowToViewURLBasedTermsAndCondition']
      );
      GeneralSetting.setTermsConditionsURL(data.rows[0]['TermsConditionsURL']);
      GeneralSetting.setTermsConditionsFile(
        data.rows[0]['TermsConditionsFile']
      );

      GeneralSetting.setHideLogo(data.rows[0]['HideLogo']);

      GeneralSetting.setShowNestedModifierInSingleView(
        data.rows[0]['Shownestedmodifiersinsingleview']
      );

      GeneralSetting.setShowItemDescriptioninNestedModifiers(
        data.rows[0]['ShowItemDescriptioninNestedModifiers']
      );

      this.imageService.getOptions();

      this.themeSub.next(true);
    }
  };

  private borderSelection() {
    GeneralSetting.setBorderTypeCategories('false');
    GeneralSetting.setBorderTypeItems('false');
    GeneralSetting.setBorderTypeVariations('false');
    GeneralSetting.setBorderTypeModifiers('false');

    let borderTypeString = GeneralSetting.getBorderTypeSelection().toString();
    var selectedTypeArray = borderTypeString.split(',');
    for (let i = 0; i <= selectedTypeArray.length; i++) {
      if (selectedTypeArray[i] == '1') {
        GeneralSetting.setBorderTypeCategories('true');
      } else if (selectedTypeArray[i] == '2') {
        GeneralSetting.setBorderTypeItems('true');
      } else if (selectedTypeArray[i] == '3') {
        GeneralSetting.setBorderTypeVariations('true');
      } else if (selectedTypeArray[i] == '4') {
        GeneralSetting.setBorderTypeModifiers('true');
      }
    }
  }

  private staffModuleMasterCallback = (tx: string, data: any): void => {

    if (!data.rows || data.rows.length == 0)
      return;

    let deviceDataRows: any = Array.from(data.rows);

    //Clover Device Setting
    this.cloverService.data = deviceDataRows.filter(
      (x: any) => x.DeviceID == GeneralSetting.getSerialNo() && x.HSN !== ''
    );

    if (this.cloverService.data && this.cloverService.data[0]) {
      //this.integration.setClover();

      GeneralSetting.setDeviceHSN(this.cloverService.data[0].HSN);

      if (
        this.cloverService.data[0] &&
        this.cloverService.data[0].FriendlyID
      ) {
        GeneralSetting.setFriendlyId(
          this.cloverService.data[0].FriendlyID.toString()
        );
      }
    }

    // STAR SP700 setting
    //Clear out existing, then map out new ones
    localStorage.removeItem('starPrinterSP700IPAddress')

    for (let i = 0; i < deviceDataRows.length; i++) {
      if (deviceDataRows[i].Name == 'STARPrinterSP700') {
        GeneralSetting.setSTARPrinterSP700IPAddress(
          deviceDataRows[i].IpAddress
        );
      }
    }

    GeneralSetting.setOrderPrefix(data.rows[0].OrderPrefix || '');
    GeneralSetting.setIsHybrid(
      data.rows[0].EnableScanOrSelectHybridMode || 'False'
    );
    GeneralSetting.setRaspberryPiIPAddress(
      data.rows[0].RaspberryPiIPAddress || ''
    );
    GeneralSetting.setIsShowAllBarcodeItemsMenu(
      data.rows[0].ShowAllBarcodeItemsMenu || 'False'
    );

    // verifone 3c device setting
    if (deviceDataRows[0].EMVTerminalID) {
      GeneralSetting.setEMVTerminalID(deviceDataRows[0].EMVTerminalID);
    }
    if (deviceDataRows[0].RequesterHardwareID) {
      GeneralSetting.setRequesterHardwareID(
        deviceDataRows[0].RequesterHardwareID
      );
    }
  };

  private errorCallback(tx: any, result: any): void {
    console.log(
      'Save Setting Service, Error  Common Error call back :- ',
      result
    );
  }
}
