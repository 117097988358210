import { Component, OnInit, ViewChild, OnDestroy, NgZone } from '@angular/core';
import { UpdateMenuComponent } from '../dialogs/update-menu/update-menu.component';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { EatOptionsService } from '../../services/eat-options.service';
import { fade, order } from 'src/app/animations';
import { StoreTime } from '../../models/storeTime';
import { eatOption } from '../../models/eat-option';
import { content } from '../../models/images';
import { ManagerPinDialogComponent } from '../manager-pin-dialog/manager-pin-dialog.component';
import { DatabaseHandler } from 'src/app/DatabaseHandler';
import { NgbCarouselConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerLoginPopupComponent } from '../../components/dialogs/customer-login-popup/customer-login-popup.component';
import { CustomerLoginModalService } from 'src/app/components/dialogs/customer-login-popup/customer-login-popup.service';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { TimeSyncService } from 'src/app/services/time-sync.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { ReturningCustomerDialogComponent } from 'src/app/components/dialogs/returning-customer-dialog/returning-customer-dialog.component';
import { PusherService } from 'src/app/services/pusher.service';
import { SaveSettingsService } from 'src/app/services/save-settings.service';
import { LoyaltyService } from '../../services/loyalty.service';
import { SetDefaultService } from '../../services/set-default.service';
import { OrderTypeWarningComponent } from '../dialogs/order-type-warning/order-type-warning.component';
import { ImageService } from '../../services/image.service';
import { DatabaseService } from '../../services/database.service';
import { GeneralSetting } from '../../services/generalSetting.service';
import { LogService, loggingData } from 'src/app/services/log.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { CommonFunctions, Snackbar } from 'src/app/services/common';
import { CartService } from 'src/app/services/cart.service';
import {
  Integration,
  IntegrationService,
} from 'src/app/services/integration.service';
import { TimeoutComponent } from '../timeout/timeout.component';
import { OloErrorPopupComponent } from 'src/app/components/dialogs/olo-error-popup/olo-error-popup.component';
import { OracleService } from 'src/app/services/oracle.service';
import { LanguageService } from 'src/app/services/language.service';
import { CustomerWelcomeModalComponent } from '../dialogs/customer-welcome-modal/customer-welcome-modal.component';
import { LoyaltyType } from 'src/app/models/loyaltyModal';
import { ParBrinkService } from 'src/app/services/par-brink.service';
import { LoginService } from 'src/app/services/login.service';
import { PaymentGatwayService } from 'src/app/services/payment-gatway.service';
import { FlexPairingService } from 'src/app/services/flex-pairing.service';
import { SMBDiscountService } from 'src/app/services/smb-discount.service';
import { LanguageName, Languages } from 'src/app/models/language';
import { KioskUpgradeService } from 'src/app/services/kiosk-upgrade.service';
import { RemoteInstallNotification } from 'src/app/services/api/Models';
@Component({
  selector: 'app-touch-to-start',
  templateUrl: './touch-to-start.component.html',
  styleUrls: ['./touch-to-start.component.css'],
  animations: [order, fade],
})
export class TouchToStartComponent implements OnInit, OnDestroy {

  swapLanguage(str: string) {
    this.isShowOverlay = false
    this.language.setLocale(str)
    this.defaultLanguage=str
    this.getOptions()
    this.KeyboardService.newBoardSubject.next('')
  }

  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }

    if (this.timeCheck) {
      clearInterval(this.timeCheck);
    }
  }

  private allSubsCription: Subscription[] = [];

  @ViewChild(ManagerPinDialogComponent) pinPadModal!: ManagerPinDialogComponent;

  @ViewChild(CustomerLoginPopupComponent)
  customerLoginPopup!: CustomerLoginPopupComponent;

  @ViewChild(ReturningCustomerDialogComponent)
  returnCustomerPopup!: ReturningCustomerDialogComponent;

  @ViewChild(OrderTypeWarningComponent)
  orderTypeWarning!: OrderTypeWarningComponent;

  @ViewChild(UpdateMenuComponent) updateMenuModal!: UpdateMenuComponent;

  @ViewChild(CustomerWelcomeModalComponent)
  welcome!: CustomerWelcomeModalComponent;

  @ViewChild('carousel') carousel: any;

  @ViewChild('videoPlayer') videoPlayer: any;

  isHidden: boolean = false;

  hoverStart: boolean = false;

  hoverReward: boolean = false;

  count: number = 0;

  isVideo = false;
  isParBrink: boolean = false;
  updateMenuModelRef!: any;

  doRefreshLater: boolean = false;

  isAlcoholicAgeLimitKnown: string | null = 'False';
  bkgdColor: string | null = '';
  optionColor: string | null = '';
  optionBorderColor: string | null = '';
  textColor: string | null = '';
  branchLogo: string | null = '';
  accentColor: string | null = '';
  storeClosedTimeMessage: string | null = '';
  showLanguagePicker = false;

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  options: eatOption[] = [];

  // imgArray: image[]= [];
  // videoArray: video[] = [];
  screenText: string | null | undefined = '';

  activeID: number = 0;

  hasContent: boolean = true;

  refreshRunning: boolean = false;

  refreshRequired: boolean = false;

  index: number = 0;

  screenArray: content[] | null = null;

  timer: any;

  entered: boolean = false;

  message: string = '';

  isShowHomeScreenLogo: boolean = false;

  currentTime: string = '';

  storeTime: StoreTime[] = [];

  storeIsClosed: boolean = false;

  dayOfWeek: number = new Date().getDay();

  /**
   * flags for screen setting
   */
  disableScreenSaver: any;

  inAccount: boolean = false;

  visitedLastFive: boolean = false;

  /**
   * loyalty variable
   */
  hasLoyaltyProgram: boolean = false;

  useLoyaltyKiosk: boolean = GeneralSetting.getUseLoyaltyKiosk() == 'True';

  showReturningDialog: boolean = false;

  separateHomeScreenImagePath: any;

  showSeparateImage: any;

  singleVideo: string = 'assets/videos/Golden Chick.mp4';

  showSlide: boolean = true;

  checkOutFromLastFive: boolean = false;

  splashScreenMessage: string | null = '';

  isShowSplashScreenMessage: boolean =
    GeneralSetting.getIsShowSplashScreenMessage() == 'True';

  isConcessionaire: boolean = false;

  showLoyaltyButton: boolean = false;

  isRemovePricingAndPayments = GeneralSetting.getIsRemovePricingAndPayments();

  @ViewChild(TimeoutComponent) timeOutComponent!: TimeoutComponent;
  rewardsAccountSignInText: string = '';
  selectLanguageText: string = '';

  isTapToOrderScreen: boolean = true;

  isRestarted = false;

  languageData: Languages[] = [];

  languageKeyValue: LanguageName = {
    en: "English",
    es: "Español",
    fr: "Français"
 }

  constructor(
    private imageService: ImageService,
    private cart: CartService,
    private router: Router,
    private readonly route: ActivatedRoute,
    private optionsService: EatOptionsService,
    private ngbconfig: NgbCarouselConfig,
    private isKioskService: IsKioskService,
    private timeSyncService: TimeSyncService,
    private loyaltyService: LoyaltyService,
    private saveSettingsService: SaveSettingsService,
    private modalService: NgbModal,
    private setDefaultService: SetDefaultService,
    private db: DatabaseService,
    private logger: LogService,
    private readonly zone: NgZone,
    private KeyboardService: KeyboardService,
    private oracle: OracleService,
    private integration: IntegrationService,
    private language: LanguageService,
    private customerLoginService: CustomerLoginModalService,
    private parBrink: ParBrinkService,
    private loginService: LoginService,
    private paymantGateWayService: PaymentGatwayService,
    private flex: FlexPairingService,
    private discount: SMBDiscountService,
    private readonly upgradeService: KioskUpgradeService
  ) {
    this.showLanguagePicker = environment.showLanguagePicker; // Hide in non local environments until we're ready
    ngbconfig.interval = 4000;
    ngbconfig.wrap = true;
    ngbconfig.animation = false;
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );

    let isRestarted = this.route.snapshot.queryParams['IsRestarted'];
    if (
      isRestarted &&
      isRestarted != 'undefined' &&
      isRestarted != null &&
      isRestarted != ''
    ) {
      this.isRestarted = true;
    }
    this.languageData = this.language.getLanguageData();
  }
  isLoyaltyButton() {
    if (this.storeIsClosed) {
      return false;
    }
    if (this.isRemovePricingAndPayments) {
      return false;
    }
    if (!this.hasLoyaltyProgram) {
      return false;
    }
    if (!this.useLoyaltyKiosk) {
      return false;
    }
    if (GeneralSetting.getUseLoyaltyKiosk() != 'True') {
      return false;
    }
    return true;
  }

  loadText() {
    this.rewardsAccountSignInText = this.language.getTextElement(
      'lbl_rewards_account_sign_in'
    );
    this.selectLanguageText = this.language.getTextElement(
      'lbl_select_language'
    );
    this.splashScreenMessage = this.language.getCustomMapValues(
      'menu_setting_home_screen__welcome_center_message'
    );
    this.message = this.language.getCustomMapValues(
      'menu_setting_home_screen__welcome_bottom_message'
    );
  }
  defaultLanguage: string = '';
  /**
   * get bkgd color, text color, branch logo from sessionStorage
   * when init this component
   */
  ngOnInit(): void {
    this.language.setLocale(this.language.defaultLanguage);
    this.defaultLanguage = this.language.defaultLanguage;
    GeneralSetting.setIsOverlayScreenShown(false);
    this.onInitFailSafes();

    this.timeSyncService.storeClosed().then((result: any) => {
      this.storeIsClosed = result;
    });

    this.showLoyaltyButton =
      this.loyaltyService.isLoyalty && this.useLoyaltyKiosk;

    this.loyaltyService.setLoyaltyType().then(() => {
      this.showLoyaltyButton = this.isLoyaltyButton();
      this.loyaltyService.isLoyalty = this.showLoyaltyButton;
      this.useLoyaltyKiosk = this.showLoyaltyButton;
    });

    this.isConcessionaire =
      GeneralSetting.getIsConcessionaire().toLowerCase() == 'true';

    this.isParBrink = GeneralSetting.getParBrinkIntegrationID() != '';

    //set flag in session
    this.db.getDetailSetting();

    this.KeyboardService.KeyboardStatus = false;

    this.getOptions();

    this.allSubsCription.push(
      this.saveSettingsService.setOptionSubject.subscribe(() => {
        this.getOptions();
      })
    );

    this.getScreenSavers();

    this.setBaseVariable();

    this.getLoyaltyProgram().then((data: any) => {
      this.hasLoyaltyProgram = data;
      if (
        this.hasLoyaltyProgram &&
        !this.storeIsClosed &&
        GeneralSetting.getUseLoyaltyKiosk() == 'True'
      ) {
        this.useLoyaltyKiosk = GeneralSetting.getUseLoyaltyKiosk() == 'True';
      }
    });

    // const useLoyalty = GeneralSetting.getUseLoyaltyKiosk();

    this.bindPusher();
    this.cart.reward = 0;
    this.cart.sub_reward.next(0);
    if (!this.loyaltyService.itemsWaiting) {
      this.setDefaultService.setDefault();
    }
    this.shouldRefreshMenu().then((data) => {
      if (!data) {
        this.getAllCategory();
      }
    });
    this.allSubsCription.push(
      this.saveSettingsService.menuSyncSubscribe.subscribe((data) => {
        this.timeSyncService.storeClosed().then((result: any) => {
          this.storeIsClosed = result;
          this.loyaltyService.setLoyaltyType().then(() => {
            this.showLoyaltyButton = this.isLoyaltyButton();
          });
          const returnCustomer = GeneralSetting.getReturningCustomersFeatures();
          this.showReturningDialog = returnCustomer === 'True';
        });
        this.hasLoyaltyProgram = data;
        this.getOptions();
        this.getAllCategory();
        this.setBaseVariable();
        this.getScreenSavers();

        this.isRemovePricingAndPayments =
          GeneralSetting.getIsRemovePricingAndPayments();
      })
    );

    if (
      this.setDefaultService.redirectToEatOptions != undefined &&
      this.setDefaultService.redirectToEatOptions
    ) {
      this.isHidden = true;
      this.setDefaultService.redirectToEatOptions = false;
    } else {
      // this.isHidden = false;
    }
    // Added to update store timing after menu refresh
    this.allSubsCription.push(
      this.saveSettingsService.reloadSelfSubject.subscribe(() => {
        this.timeSyncService.storeClosed().then((result: any) => {
          this.storeIsClosed = result;
        });
      })
    );

    try {
      this.allSubsCription.push(
        this.saveSettingsService.themeSub.subscribe(() => {
          this.isConcessionaire =
            GeneralSetting.getIsConcessionaire().toLowerCase() == 'true';

          this.isParBrink = GeneralSetting.getParBrinkIntegrationID() != '';
        })
      );
    } catch (e) {
      console.log(e);
    }

    // Added to check Order Type screen timer
    setTimeout(() => {
      if (this.disableScreenSaver === 'False') {
        // time out popup will not start if disableScreenSaver is enable GBREL-15 @jay
        this.setTimerForOrderType();
      }
    }, 0);
    this.allSubsCription.push(
      this.cart.closeOrderType.subscribe((data) => {
        this.ngOnDestroy();
        this.ngOnInit();
        this.inAccount = false;
      })
    );

    //Otter if kiosk restarted then refresh menu

    if (this.isRestarted) {
      setTimeout(() => {
        this.db.MenuRefresh.next(true);
      }, 100);
    }
  }
  isShowOverlay: boolean = false;
  showOverlay() {
    this.isShowOverlay = true;
  }
  getScreenSavers() {
    this.imageService.getScreen().then((screenArr) => {
      this.screenArray = screenArr;
      // console.log('screen array', this.screenArray);
      this.showSlide = true;

      if (this.screenArray.length > 1) {
        this.showSlide = true;
      } else if (this.screenArray.length == 1) {
        if (this.screenArray[0].video && this.screenArray[0].VideoPath) {
          this.singleVideo = this.screenArray[0].VideoPath;
          this.showSlide = false;
        }
      } else {
        //this.isHidden = true;
      }

      for (let i = 0; i < this.screenArray.length; i++) {
        const isVideo = this.screenArray[i].video.toString();

        if (isVideo.toLowerCase() == 'true') {
          this.screenArray[i].video = true;
          this.singleVideo = this.screenArray[i].VideoPath as string;
        } else {
          this.screenArray[i].video = false;
        }
      }

      //this.showSlide = this.imageService.showSlide;
      //this.singleVideo = this.imageService.singleVideo;
      this.screenText = this.imageService.screentext;
      this.activeID = this.imageService.activeID;
      //this.splashScreenMessage = GeneralSetting.getSplashScreenMessage();
      this.isShowSplashScreenMessage =
        GeneralSetting.getIsShowSplashScreenMessage() == 'True'; // to sync up with latest value after update in backend
    });
  }

  getOptions() {
    const dineInLabel = this.language.getCustomMapValues(
      'menu_setting_general__order_type_options_dine_in'
    );
    const takeOutLabel = this.language.getCustomMapValues(
      'menu_setting_general__order_type_options_take_out'
    );

    if (this.imageService.options.length > 0) {
      this.options = this.imageService.options;
      this.updateEatOptionLanguage(dineInLabel, takeOutLabel);
    } else {
      this.imageService.getOptions().then((options) => {
        this.options = options;
        this.setDefaultService.optionsLength = options.length;

        this.updateEatOptionLanguage(dineInLabel, takeOutLabel);

        if (options.length == 1) {
          this.optionsService.setEatOption(options[0]);
        }
      });
    }
  }

  private updateEatOptionLanguage(dineInLabel: string, takeOutLabel: string) {
    for (let i = 0; i < this.options.length; i++) {
      const eatOption = this.options[i];
      if (eatOption.Name.includes('Dine In')) {
        this.options[i].DisplayLabel = dineInLabel;
      }
      if (eatOption.Name.includes('Take Out')) {
        this.options[i].DisplayLabel = takeOutLabel;
      }
    }
  }

  private onInitFailSafes() {
    var messageType = 'stopScanner';
    var message = {
      messageType: messageType,
    };
    window.top!.postMessage(message, '*');
  }

  private bindPusher() {
    this.allSubsCription.push(
      this.db.MenuRefresh.subscribe(async (x) => {
        if (x) {
          if (!this.refreshRunning) {
            await this.refreshDB();
            // await this.flex.checkPairing()
          } else {
            this.refreshRequired = true;
          }
        }
      })
    );

    this.allSubsCription.push(
      this.upgradeService.KioskUpgradeEvents.subscribe(async (packageNotification: RemoteInstallNotification) => {
        if (packageNotification?.kioskReleaseInformation?.PackageUrl) {
          if (!this.refreshRunning) {
             this.refreshRunning = true;
             let upgradeResponse = await this.upgradeService.kioskAppPusherUpgrade(packageNotification.kioskReleaseInformation.PackageUrl, packageNotification);
             if(!upgradeResponse) {
              this.refreshRunning = false;
             }
          }
        }
      })
    );
  }

  private async isrefreshRequired() {
    if (this.refreshRequired) {
      await this.refreshDB();
      this.refreshRequired = false;
    }
  }

  private async refreshDB() {
    this.refreshRunning = true;
    this.logger.sendLogToServer(
      new loggingData(
        'Touch To Start Pusher',
        'Touch To Start Received',
        'Confirmation Status Pusher',
        `Pusher Menu Refresh Received`,
        true
      )
    );

    if (
      !this.saveSettingsService.stillRunning &&
      this.router.url.includes('touchToStart')
    ) {
      this.zone.run(async () => {
        await this.loginService.doRefresh().then(() => {
          this.language.setLocale(this.language.defaultLanguage);
          this.defaultLanguage = this.language.defaultLanguage;
          this.languageData = this.language.getLanguageData();
          this.getOptions();

          this.getLoyaltyProgram().then((data: any) => {
            this.hasLoyaltyProgram = data;
            if (this.hasLoyaltyProgram && !this.storeIsClosed) {
              this.useLoyaltyKiosk =
                GeneralSetting.getUseLoyaltyKiosk() == 'True';
            }
          });
          this.upgradeService.kioskAppUpgradeACK();
        });
        this.refreshRunning = false;
        this.isrefreshRequired();
      });
    } else {
      this.doRefreshLater = true;
    }
  }

  /**
   * click anywhere on the screen to start
   * @param event
   */
  // @HostListener('document:click', ['$event'])
  public documentClick(clickEvent: any): void {
    if (!this.options || this.storeIsClosed) return;
    // this.options = [];

    if (this.options.length == 0) {
      this.orderTypeWarning.open();
    } else if (
      !this.isHidden &&
      !(clickEvent.target.id == 'logoImg') &&
      !(
        clickEvent.target.id == 'language' ||
        clickEvent.target.id == 'language1' ||
        clickEvent.target.id == 'language2' || 
        clickEvent.target.id == 'language3'
      )
    ) {
      if (this.isShowOverlay) {
        this.isShowOverlay = false;
        return;
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        if (
          this.count !== 5 &&
          !(clickEvent.target.id == 'logoImg') &&
          !this.inAccount
        ) {
          //event.preventDefault();
          //open the dialog when show return == true && (loyalty program == false || use loyalty == false);
          if (!(await this.checkConcessioanireActive())) {
            let error = this.language.getTextElement(
              'all_concessionaire_are_closed'
            );
            Snackbar.show(error, 2500);
            return;
          }

          if (
            this.showReturningDialog &&
            !this.visitedLastFive &&
            (!this.hasLoyaltyProgram || !this.useLoyaltyKiosk)
          ) {
            this.openCustomerAccount(clickEvent);
          } else {
            if (this.options!.length >= 1) {
              await this.ShowOracleErrorMessage();

              this.isHidden = !this.isHidden;
              var log = new loggingData(
                'Tap to order pressed',
                'Tap to order pressed',
                'touch-to-start.component.ts',
                `"Tap to order" pressed: ${this.options}`,
                true
              );
              this.logger.sendLogToServer(log);
              this.setTimerForOrderType(true);
            } else {
              await this.ShowOracleErrorMessage();

              this.selectLocation(this.options![0].Name, this.options![0]);
            }
          }
        }
      }, 200);
    }
  }

  private checkConcessioanireActive() {
    return new Promise<boolean>(async (resolve) => {
      if (this.isConcessionaire) {
        let AllCon = (await this.db.getAllConcessionaireFromSql()).filter(
          (x) => x.IsActive && x.IsClosed != true
        );
        if (AllCon.length > 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      } else {
        resolve(true);
      }
    });
  }

  private async ShowOracleErrorMessage() {
    if (this.oracle.isOracle) {
      if (
        (this.oracle.isOracleMicross &&
          this.oracle.oracleMicrosSetting.TenderID == '0') ||
        (this.oracle.oracleGeneralSetting &&
          this.oracle.oracleGeneralSetting.TenderID == '0') ||
        (GeneralSetting.getShowTipDialog().toLowerCase() == 'true' &&
          this.oracle.isOracleMicross &&
          this.oracle.oracleMicrosSetting.TipTenderID == '0') ||
        (this.oracle.oracleGeneralSetting &&
          this.oracle.oracleGeneralSetting.TipTenderID == '0')
      ) {
        let err = this.language.getTextElement('tender_type_not_cofigured');
        let ok = this.language.getTextElement('lbl_ok');
        let oepc = OloErrorPopupComponent.open(
          this.modalService,
          err,
          '',
          ok,
          true
        );

        await oepc.result;
      }
    } else if (this.integration.integration == Integration.Parbrink) {
      let res = this.parBrink.isValidConfiguration(
        this.isLoyaltyButton(),
        this.loyaltyService.loyaltyType == LoyaltyType.LevelUp
      );
      if (res != 0) {
        let err;
        if (res == 1) {
          err = this.language.getTextElement('tender_type_not_configured');
        } else if (res == 2) {
          err = this.language.getTextElement('loyalty_discount_not_configured');
        } else if (res == 3) {
          err = this.language.getTextElement('loyalty_payment_not_configured');
        }
        let ok = this.language.getTextElement('lbl_ok');
        let oepc = OloErrorPopupComponent.open(
          this.modalService,
          err,
          '0',
          ok,
          true
        );

        await oepc.result;
      }
    }
  }

  private setBaseVariable() {
    this.discount.getDiscounts();
    GeneralSetting.setIsAlcoholicAgeLimitKnown('False');
    this.branchLogo = GeneralSetting.getBranchLogo();
    // this.message =
    //   GeneralSetting.getHomeScreenMsg() != ''
    //     ? GeneralSetting.getHomeScreenMsg().trim()
    //     : '';
    this.isShowHomeScreenLogo =
      GeneralSetting.getIsShowHomeScreenLogo() == 'True';

    this.currentTime = formatDate(Date.now(), 'HH:mm', 'en-US');

    this.bkgdColor = GeneralSetting.getPrimaryColor();

    this.optionColor =
      (GeneralSetting.getIsTransparentDineIn() == 'True' &&
        'rgb(0,0,0, 0.6)') ||
      GeneralSetting.getPrimaryColor();

    this.optionBorderColor =
      GeneralSetting.getIsTransparentDineIn() == 'True'
        ? GeneralSetting.getTransparentButtonColor()
        : '';

    this.textColor = GeneralSetting.getPositiveColorText();

    this.accentColor = GeneralSetting.getAccentColor();

    this.storeClosedTimeMessage =
      GeneralSetting.getStoreClosedTimeMessage() == '' ||
      GeneralSetting.getStoreClosedTimeMessage()!.length == 0
        ? 'Store is closed'
        : GeneralSetting.getStoreClosedTimeMessage();

    // set default home screen msg here
    this.screenText =
      GeneralSetting.getIsShowSplashScreenMessage() == 'True'
        ? GeneralSetting.getSplashScreenMessage().trim() == ''
          ? 'Welcome'
          : GeneralSetting.getSplashScreenMessage().trim()
        : 'Welcome';

    this.disableScreenSaver = GeneralSetting.getDisableScreenSaver();
    if (this.disableScreenSaver === 'True') {
      this.isHidden = true;
    } else {
      this.isHidden = false;
    }

    const returnCustomer = GeneralSetting.getReturningCustomersFeatures();
    if (returnCustomer === 'True') {
      this.showReturningDialog = true;
    }

    this.separateHomeScreenImagePath =
      GeneralSetting.getSeparateHomeScreenImagePath();

    this.showSeparateImage = GeneralSetting.getUseSeparateImageForHomeScreen();

    const lastFiveOrderChosen = GeneralSetting.getLastFiveOrderChosen();

    if (lastFiveOrderChosen && lastFiveOrderChosen === 'true')
      this.visitedLastFive = true;

    if (this.visitedLastFive) {
      //this.isHidden = true;
      this.setTimerForOrderType();
    }

    if (lastFiveOrderChosen && lastFiveOrderChosen === 'false') {
      GeneralSetting.setCurrentCountofAlkol('0');
    }

    this.isShowHomeScreenLogo =
      GeneralSetting.getIsShowHomeScreenLogo() == 'True';

    const checkout = GeneralSetting.getCheckOutAtLastFive();

    if (checkout && checkout === 'true') this.checkOutFromLastFive = true;
  }

  private async getAllCategory() {
    if (
      (!this.db.hasCategory() || this.db.isDoRefresh) &&
      this.updateMenuModelRef == undefined
    ) {
      this.updateMenuModelRef = this.modalService.open(UpdateMenuComponent, {
        backdrop: 'static',
        size: 'lg',
        centered: true,
      });

      setTimeout(() => {
        this.updateMenuModelRef?.dismiss();
      }, 300000);
    }

    const categoryDetail = await this.db.getPersistentCategoriesAsync(
      this.db.isDoRefresh
    );
    this.db.isDoRefresh = false;
    if (categoryDetail) {
      this.updateMenuModelRef?.dismiss();
      this.updateMenuModelRef = undefined;
    }
  }

  shouldRefreshMenu(): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      if (
        (this.doRefreshLater || this.db.isDoRefresh) &&
        this.router.url === '/touchToStart'
      ) {
        this.doRefreshLater = false;
        this.db.isDoRefresh = false;
        if (!this.saveSettingsService.stillRunning) {
          if (!this.db.isRefreshedinSettings) {
            this.refreshRunning = true;
            await this.loginService.doRefresh(this.timeOutComponent);
            this.refreshRunning = false;
            this.isrefreshRequired();
            // if there is auto refresh from backend in /settings then it will not refresh in /touchToStart 2nd time. issue SK-2270 @jay vachhani
          }
          this.timeSyncService.storeClosed().then((result: any) => {
            this.storeIsClosed = result;
          });
          resolve(true);
        } else {
          //Added this comment for ticket SK-1820 by Yash Patel
          console.log(
            'On menu sync Launch kiosk and refresh DB got stuck. line 718-touch to start component'
          );
          resolve(false);
        }
      }
      resolve(false);
    });
  }

  /* check every 10 seconds if store is closed */
  timeCheck: any = setInterval(() => {
    this.timeSyncService.checkStoreTiming().then((result: any) => {
      this.storeIsClosed = result;
    });
    this.setDefaultService.resetOrderInvoiceNo();
  }, 10000);

  /**
   * click the logo 5 times and direct to Setting page
   */
  enterSettings(event: any): void {
    //console.log(this.baseImageUrl, this.branchLogo);
    this.entered = true;

    this.count++;
    if (this.count === 5) {
      //this.isHidden = !this.isHidden;
      // prompt manager pin pad
      this.pinPadModal.open();
      event.stopPropagation();
      this.pinPadModal.modalRef.result.then(
        () => {
          setTimeout(() => {
            this.count = 0;
            this.entered = false;
          }, 260);
        },
        () => {}
      );
    }
  }

  openCustomerAccount(event: any) {
    if (!this.storeIsClosed) {
      this.inAccount = true;
      this.timeOutComponent.stillHere(true);
      let rcp = this.returnCustomerPopup.open();
      this.customerLoginService.returningpopuprequired.next(true);
      this.returnCustomerPopup.modalReference.result.then(
        async () => {
          if (this.options!.length > 1) {
            await this.ShowOracleErrorMessage();
            this.isHidden = !this.isHidden;
            this.setTimerForOrderType();
          } else {
            await this.ShowOracleErrorMessage();

            this.selectLocation(this.options![0].Name, this.options![0]);
          }
        },
        () => {
          this.inAccount = false;
        }
      );
    }
  }

  /**
   * Get the info of selected orderType option
   * and store relevant information into sessionStorage
   * @param location
   * @param orderTypeId: ID of the orderType
   */

  async selectLocation(location: any, option: any) {
    if (
      this.disableScreenSaver === 'True' &&
      this.showReturningDialog &&
      (!this.hasLoyaltyProgram || !this.useLoyaltyKiosk)
    ) {
      this.returnCustomerPopup.open();
      this.returnCustomerPopup.modalReference.result.then(async () => {
        this.storeSelectedType(location, option);
        this.optionsService.setEatOption(option);
        if (this.loyaltyService.itemsWaiting) {
          await this.loyaltyService.addToCart();
        }
      });
    } else if (
      GeneralSetting.getLastFiveOrderChosen() == 'true' &&
      GeneralSetting.getCheckOutAtLastFive() == 'true'
    ) {
      this.storeSelectedType(location, option);
      this.optionsService.setEatOption(option);
      if (this.loyaltyService.itemsWaiting) {
        await this.loyaltyService.addToCart();
      }
      this.router.navigateByUrl('/orderReview').then(() => {
        GeneralSetting.setCheckOutAtLastFive('false');
      });
    } else {
      this.storeSelectedType(location, option);
      this.optionsService.setEatOption(option);
      if (this.loyaltyService.itemsWaiting) {
        await this.loyaltyService.addToCart();
      }
    }
    this.setTimerForOrderType();
  }

  async storeSelectedType(location: any, orderType: eatOption) {
    GeneralSetting.setLocationPreference(
      location.text || this.options![0].Name
    );
    GeneralSetting.setDidDiscount('false');
    GeneralSetting.setPaymentAmount('');
    GeneralSetting.setPaymentType('');
    GeneralSetting.setIsTipSelected('false');
    GeneralSetting.setTableTentNumber('');
    GeneralSetting.setLastFiveOrderChosen('false');
    GeneralSetting.setOrderTypeId(orderType.OrderTypeID.toString());

    GeneralSetting.setMenuLevelID(orderType.OPMID);

    if (this.isParBrink) {
      GeneralSetting.setRefOrderTypeID(orderType.RefOrderTypeID);
    }

    this.paymantGateWayService.doConfigurePaymentDevice();

    if (!(await this.checkConcessioanireActive())) {
      let error = this.language.getTextElement('all_concessionaire_are_closed');
      Snackbar.show(error, 2500);
      return;
    }

    if (this.isConcessionaire) {
      this.router.navigateByUrl('/ConcessionaireGrid');
      return;
    }

    var log = new loggingData(
      'Order Type',
      'Order Type',
      'touch-to-start.component.ts',
      `Order Type: ${(this.options![0].Name) ? this.options![0].Name : ''}`,
      true
    );
    this.logger.sendLogToServer(log);
    this.router.navigateByUrl('/menu');
  }

  /**
   * get all the orderType options from database via optionsService
   */

  /**
   * Set te hover style of buttons for eat options
   * @param value
   */
  styleHoverOption(value: any) {
    value.style.color = this.optionColor;
    value.style.backgroundColor = this.optionBorderColor || this.textColor;
  }

  /**
   * Set the un hover style of buttons for eat options
   * @param value
   */
  styleUnHoverOption(value: any) {
    value.style.color = this.optionBorderColor || this.textColor;
    value.style.backgroundColor = this.optionColor;
  }

  /**
   * get loyalty program setting from database
   */
  getLoyaltyProgram() {
    return new Promise<Boolean>((resolve) => {
      var sqlStr =
        'SELECT IsActive FROM LoyaltyPrograms WHERE IsActive = "True"';

      const callback = (tx: string, data: any) => {
        if (data.rows.length > 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      const error = (err: any) => {
        var log = new loggingData(
          'Loyalty Program Error',
          'Loyalty Program Encountered Error Fetching data',
          'Loyalty Error',
          `Loyalty Program Encountered Error: ${err}`,
          true
        );
        this.logger.sendLogToServer(log);
        resolve(false);
      };

      DatabaseHandler.executeSqlStatement(sqlStr, [], callback, error);
    });
  }

  /**
   * play the video
   * @param e
   */
  onCanPlay(e: any) {
    e.target.play();
  }

  /**
   * on video play:
   * pause the carousel and wait until video ends
   */
  videoPlay(event: any) {
    this.carousel.pause();
  }

  /**
   * after video ended
   * show next carousel
   * and start cycle
   */
  videoEnded(e: any) {
    this.carousel.next();
    this.carousel.cycle();
  }

  /**
   * slide event handler to enable slider text change dynamically
   * @param data
   */
  onSlide(data: any) {
    var id = data.current;
    var content = this.screenArray!.find((e) => e.ScreenSaverID == id);

    if (content) {
      if (
        content.SliderText &&
        content.SliderText != null &&
        content.SliderText != undefined &&
        content.SliderText.trim() != ''
      ) {
        this.screenText = content.SliderText;
      }
      this.activeID = id;
    }
  }

  onRewardButtonClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.ShowOracleErrorMessage().then(() => {
      this.timeOutComponent.stillHere(true);
      this.customerLoginPopup.open();
      this.customerLoginService.keyboardStatus.next(true);
      this.customerLoginPopup.modalReference.result.then(
        (data: any) => {
          if (data != 'canceled') {
            if (data == 'welcome') {
              let ref = this.welcome.open(true);
              ref?.result.then(
                () => {
                  this.handleOptionsAfterModal();
                },
                () => {
                  this.handleOptionsAfterModal();
                }
              );
            } else {
              this.handleOptionsAfterModal();
            }
          }
          // if data == canceled then execution flow should end with no change
        },
        () => {}
      );
    });
  }
  handleOptionsAfterModal() {
    if (this.options.length == 1) {
      this.selectLocation(this.options![0].Name, this.options![0]);
    } else {
      this.isHidden = true;
      this.setTimerForOrderType();
    }
  }
  // Check to show Timer based on Order Type screen visibility

  private setTimerForOrderType(orderType: boolean = false) {
    if (this.timeOutComponent) {
      if (this.isHidden) {
        this.timeOutComponent.stillHere(true, orderType);
      } else {
        this.timeOutComponent.stopTimer();
      }
    }
  }
}
