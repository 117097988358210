import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-branch-login',
  templateUrl: './branch-login.component.html',
  styleUrls: ['./branch-login.component.css'],
})
export class BranchLoginComponent implements OnInit {
  languageSub!:Subscription
  defaultLag = GeneralSetting.getLocale() || 'en';
  constructor(
    private language:LanguageService,
    private KeyboardService:KeyboardService,
     ) {}
  descriptionText:string=''
  loadText(){
    this.descriptionText=this.language.getTextElement('self_ordering_kiosk')
  }
  ngOnInit(): void {
    this.languageSub=this.language.localeCommunicator.subscribe((val)=>{
      this.loadText()
    })
  }
  isShowOverlay: boolean = false
  showOverlay() {
    this.isShowOverlay = true
    this.KeyboardService.KeyboardStatus = false;
  }
  swapLanguage(str: string) {
    this.isShowOverlay = false
    this.defaultLag = str;
    this.language.setLocale(str)
    this.KeyboardService.newBoardSubject.next('')
  }
  ngOnDestroy(){
    if(this.languageSub) this.languageSub.unsubscribe()
  }
}
