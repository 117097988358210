import { ItemFooterComponent } from 'src/app/components/item-footer/item-footer.component';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { VariationOption, Variation } from 'src/app/models/Variations';
import { PusherService } from 'src/app/services/pusher.service';
import { ItemTransferService } from 'src/app/services/item-transfer.service';
import { Subscription } from 'rxjs';
import {
  ItemV2,
  ModifierV2,
  ComboGroupV2 as ComboGroup,
} from 'src/app/models/item';
import { Category } from 'src/app/models/category';
import { ModifierContainerComponent } from 'src/app/components/modifier-container/modifier-container.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatabaseService } from 'src/app/services/database.service';
import { ComboGroupContainerComponent } from 'src/app/components/combo-group-container/combo-group-container.component';
import { ItemBuilderModeSelectionComponent } from './item-builder-mode-selection/item-builder-mode-selection.component';
import { LogService } from 'src/app/services/log.service';
import { ChangeDetectorRef } from '@angular/core';
import { ItemService } from '../../services/ItemService';
import { LoaderService } from 'src/app/services/loader.service';
import { Integration, IntegrationService } from 'src/app/services/integration.service';
import { ParBrinkService } from 'src/app/services/par-brink.service';

@Component({
  selector: 'app-item-builder-mode',
  templateUrl: './item-builder-mode.component.html',
  styleUrls: ['./item-builder-mode.component.css'],
})
export class ItemBuilderModeComponent implements OnInit {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  constructor(
    private readonly itemTransferService: ItemTransferService,
    private readonly route: ActivatedRoute,
    private readonly cartService: CartService,
    private readonly pusherService: PusherService,
    private readonly db: DatabaseService,
    private readonly modalService: NgbModal,
    private readonly logger: LogService,
    private readonly cdr: ChangeDetectorRef,
    private readonly itemService: ItemService,
    private loader: LoaderService,
    private parBrink:ParBrinkService,
    private integration:IntegrationService
  ) {
    this.item = null;
    this.variations = [] as Variation[];
    this.modifiers = new Array<ModifierV2>();
    this.ItemID = this.route.snapshot.queryParams['id'];
    this.CategoryID = this.route.snapshot.queryParams['category'];
    this.guid = this.route.snapshot.queryParams['guid'];
    this.isOrderReviewEdit = this.route.snapshot.queryParams['reviewEdit'];
    this.isLoyalty = this.route.snapshot.queryParams['loyalty'];
    this.concessionaireId = this.route.snapshot.queryParams['concessionaireId'];
  }

  isLoyalty: string;

  modalReference: any;

  numInvisibleGroups: number = 0;

  ItemID: string;

  CategoryID: string;

  isReady: boolean = false;

  item: ItemV2 | null;

  @ViewChild(ModifierContainerComponent)
  modifierContainer!: ModifierContainerComponent;

  @ViewChild(ComboGroupContainerComponent)
  comboGroupContainer!: ComboGroupContainerComponent;

  @Output()
  @ViewChild(ItemBuilderModeSelectionComponent)
  builderModeSelection!: ItemBuilderModeSelectionComponent;

  isModifiersValid!: boolean;

  isVariationsValid!: boolean;

  isComboGroupValid!: boolean;

  setItemTags() {
    setTimeout(() => {
      this.itemService.setItemTagsSub.next(true);
    }, 1);
  }

  //mods selected and methods
  setSelectedModifiers(values: {
    inputModifier: ModifierV2[];
    isValid: boolean;
  }) {
    this._selectedModifiers = Array.from(values.inputModifier);
    this.isModifiersValid = values.isValid;
    this.checkIsReady();
  }

  //comboGroup selected and methods
  setSelectedComboGroup(values: {
    inputComboGroup: ComboGroup[];
    isValid: boolean;
  }) {
    this._selectedComboGroup = Array.from(values.inputComboGroup);
    this.isComboGroupValid = values.isValid;
    this.setItemTags();
    this.checkIsReady();
  }

  //variations selected and methods
  setSelectedVariations(values: VariationOption[]) {
    this._selectedVariations = values;

    if (this.item && this.item.Variations && this.item.Variations.length > 0) {
      for (let i = 0; i < this.item.Variations.length; i++) {
        let variation = this.item.Variations[i];

        for (let j = 0; j < variation.variationOptions.length; j++) {
          if (
            variation.variationOptions[j] &&
            variation.variationOptions[j].ItemAttributeOptionID ==
              values[0].ItemAttributeOptionID
          ) {
            variation.IsSelected = true;
            variation.variationOptions[j].isSelected = true;
          } else {
            variation.variationOptions[j].isSelected = false;
          }
        }
      }
    }

    this.checkIsReady();
  }

  headerQuantityChange(quantity: number) {
    this.quantity = quantity;
    this.item!.Quantity = String(this.quantity);
  }

  modifiers: ModifierV2[] = [] as ModifierV2[];

  variations: Variation[] = [] as Variation[];

  comboGroup: ComboGroup[] = [] as ComboGroup[];

  _selectedModifiers: ModifierV2[] = [];

  _selectedVariations: VariationOption[] = [];

  _selectedComboGroup: ComboGroup[] = [];

  setModifiersValid(isValid: boolean) {
    this.isModifiersValid = isValid;
    this.checkIsReady();
  }

  setVariationsValid(isValid: boolean) {
    this.isVariationsValid = isValid;
    this.checkIsReady();
  }

  setComboGroupValid(isValid: boolean) {
    this.isComboGroupValid = isValid;
    this.checkIsReady();
  }

  // For Edit Item Feature
  guid: string = '';

  isOrderReviewEdit = false;
  // For Edit Item Feature end

  totalCost: number = 0;

  //For Combo Only to open same component as model
  isComboItem = false;

  category: Category = {} as Category;

  quantity: number = 1;

  cartQuantity: number = 0;

  isConcessionaire: boolean = false;

  concessionaireId: string = '';

  ngOnInit(): void {
    this.isConcessionaire =
      GeneralSetting.getIsConcessionaire().toLowerCase() == 'true';
    this.initializePage();
    this.BindPusher();
  }

  private BindPusher() {
    this.allSubsCription.push(
      this.pusherService.CommonModifier86.subscribe((x) => {
        if (x && Object.keys(x).length > 0) {
          this.db.updateItem86Status(
            this.item as ItemV2,
            x.ItemId,
            x.Status,
            true,
            false,
            true
          );
          if (this.builderModeSelection)
            this.builderModeSelection.checkModifierValid();
        }
      })
    );

    this.allSubsCription.push(
      this.pusherService.Ingredient86.subscribe((x) => {
        if (x && Object.keys(x).length > 0) {
          this.db.updateItem86Status(
            this.item as ItemV2,
            x.ItemId,
            x.Status,
            false,
            true,
            true
          );
          if (this.builderModeSelection)
            this.builderModeSelection.checkModifierValid();
        }
      })
    );

    this.allSubsCription.push(
      this.pusherService.Item86ModifierDetails.subscribe((x) => {
        if (x && Object.keys(x).length > 0) {
          this.db.updateItem86Status(
            this.item as ItemV2,
            x.ItemId,
            x.Status,
            false,
            true,
            true
          );
          if (this.builderModeSelection)
            this.builderModeSelection.checkModifierValid();
        }
      })
    );
  }

  private checkIsReady() {
    let isValid = false;

    if (
      this.item &&
      (!this.item.ComboGroup || this.item.ComboGroup.length <= 0) &&
      (!this.item.Modifiers || this.item.Modifiers.length <= 0) &&
      (!this.item.Variations || this.item.Variations.length <= 0)
    ) {
      isValid = true;
    } else {
      if (this.comboGroup && this.comboGroup.length > 0) {
        isValid = this.isComboGroupValid;
      } else {
        if (this.modifiers) {
          if (this.isModifiersValid == undefined) {
            isValid = true;
          } else {
            isValid = this.isModifiersValid;
          }
        }
        
        if (
          isValid &&
          this.item?.Variations &&
          this.item.Variations.length > 0 &&
          this.variations &&
          this.variations.length > 0
        ) {
          isValid = this.isVariationsValid;
        }
      }
    }
    this.isReady = isValid;
    this.setItemTags();
  }

  private initializePage() {
    if (!this.db.hasCategory()) {
      this.db.getPersistentCategoriesAsync(true);
    }

    if (this.isComboItem) {
      if (this.item) {
        this.setDetailByItem(this.item);
        this.numInvisibleGroups = this.item.ComboGroup.filter(
          (x) => x.isHidden
        ).length;
      }
      this.checkIsReady();
      return;
    }

    const item = this.itemTransferService.getItem();
    
    if (this.guid) {
      this.setEditItemDetail();
      this.numInvisibleGroups = this.item!.ComboGroup.filter(
        (x) => x.isHidden
      ).length;

      return;
    }

    this.allSubsCription.push(
      this.db.categorySubject.subscribe((categories) => {
        if (!(categories && categories.length > 0)) return;

        const category = categories.find(
          (x) => Number(x.CategoryID) == Number(this.CategoryID)
        );

        this.category = category ?? ({} as Category);
      })
    );

    if (item) {
      this.setDetailByItem(item);
      this.checkIsReady();
    } else {
      if (this.isConcessionaire) {
        this.db.getAllConcessionaireFromSql().then((conList) => {});
      }

      this.db
        .getItemsByCategoryIdFromSql(
          this.CategoryID,
          this.isConcessionaire,
          this.concessionaireId,
          true,
          this.ItemID,
          0,
          0,
          '',
          '',
          GeneralSetting.getIsShowAllBarcodeItemsMenu()
        )
        .then((items) => {
          if (items.length > 0) {
            this.itemTransferService.snapshotItem(items[0]);
            this.initializePage();
          }
        });
    }
  }

  private setEditItemDetail() {
    const cartItem = this.cartService
      .getCartItems()
      .find((x: any) => x.guid == this.guid);

    if (cartItem && cartItem != null) {
      const item = JSON.parse(JSON.stringify(cartItem)) as ItemV2;

      if (item != undefined) {
        if (item.Modifiers && item.Modifiers.length > 0) {
          this.modifiers = item.Modifiers;
        } else {
          this.getAndSetModifier(item.ItemID);
        }

        if (item.Variations && item.Variations.length > 0) {
          this.variations = item.Variations;
        } else {
          this.getAndSetVariations(item.ItemID);
        }
      }

      if (item && item != undefined && item.IsCombo) {
        if (item.ComboGroup && item.ComboGroup.length > 0) {
          this.comboGroup = item.ComboGroup;
        } else {
          this.getAndSetComboDetail(item.ItemID);
        }
      }

      this.item = item;

      if (!this.item || this.item == null) return;

      this.itemTransferService.snapshotItemV2(this.item);

      this.cartQuantity = parseInt(
        (this.item ? Number(this.item.Quantity) : 1).toString()
      );

      this.quantity = this.item ? Number(this.item.Quantity) : 1;

      const cartItems = this.cartService
        .getCartItems()
        .filter((x) => x.ItemID == item.ItemID);

      if (cartItems && cartItems.length > 0) {
        const cartItemsQuantity = Number(
          cartItems.map((x) => Number(x.Quantity)).reduce((a, b) => a + b, 0)
        );

        this.cartQuantity = cartItemsQuantity;
      }

      this.cartQuantity = this.quantity - this.cartQuantity;
    }

    this.checkIsReady();
    return;
  }

  // on getting item from category subscription set all detail in component
  private setDetailByItem(categoryItem: ItemV2) {
    if (!categoryItem) return;

    const item = JSON.parse(JSON.stringify(categoryItem)) as ItemV2;
    
    if (!item) return;

    if (item.Modifiers && item.Modifiers.length > 0) {
      this.modifiers = item.Modifiers;
    } else {
      this.getAndSetModifier(item.ItemID);
    }

    if (item.Variations && item.Variations.length > 0) {
      this.variations = item.Variations;
    } else {
      this.getAndSetVariations(item.ItemID);
    }

    if (item.IsCombo) {
      if (item.ComboGroup && item.ComboGroup.length > 0) {
        this.comboGroup = item.ComboGroup;
      } else {
        this.getAndSetComboDetail(item.ItemID);
      }
    }

    if (
      this.itemTransferService.itemV2?.OldPrice &&
      this.itemTransferService.itemV2?.OldPrice != undefined
    ) {
      item.Price = this.itemTransferService.itemV2?.Price;
      item.OldPrice = this.itemTransferService.itemV2?.OldPrice;
    }

    this.quantity = item?.OrderMinimumQuantity || 1;

    this.itemTransferService.snapshotItemV2(item);

    this.item = item;
  }

  private getAndSetVariations(itemId: string) {
    this.db.getPersistentVariationsAsync(itemId).then((variations) => {
      this.loader.openLoader();
      this.variations = variations;

      if (this.item) {
        this.item.Variations = JSON.parse(JSON.stringify(variations));
      }
      this.loader.closeLoader();
      if (this.builderModeSelection) {
        this.builderModeSelection.isFirstTimeLoad = true;
        this.builderModeSelection.variations = variations;
        this.builderModeSelection.init();
      }
    });
  }

  private getAndSetModifier(itemId: string) {
    //const modPer = performance.now();

    //console.log("modifier Performance :- " + modPer)

    this.db
      .getModifierByItemIDFromSql(itemId)
      .then(async (allModifier: ModifierV2[]) => {
        this.loader.openLoader();

        this.modifiers = await this.itemService.getAllModifierIngredients(
          allModifier
        );
        if(this.integration.integration==Integration.Parbrink){
          let parBrinkMods:any =await this.db.parBrinkOverride(this.item,this.modifiers)
          this.modifiers = this.parBrink.parBrinkModifierOverride(parBrinkMods, this.modifiers)
        }

        //const modPerEnd = performance.now();

        //console.log("modifier Performance End :- " + modPerEnd);

        //console.log("Category Per End :- " + ((modPerEnd - modPer) / 1000));

        if (this.item) {
          this.item.Modifiers = this.modifiers;
        }

        this.loader.closeLoader();

        if (this.builderModeSelection) {
          this.cdr.detectChanges();
          this.builderModeSelection.ngOnInit();
        }
      });
  }

  private getAndSetComboDetail(itemId: string) {
    let comboItemPer = performance.now();

    //console.log("Combo Item Performance :- " + comboItemPer);

    this.db
      .getAllComboGroupsFromSQl(itemId)
      .then(async (comboGroup: ComboGroup[]) => {
        this.comboGroup = comboGroup;

        this.loader.openLoader();

        let comboGroupList = await this.itemService.getAllComboGroupItems(
          comboGroup,
          itemId
        );

        const comboItemPerEnd = performance.now();

        //console.log("Combo Item Performance :End- " + comboItemPerEnd);

        //console.log("Combo Item Performance End :- " + ((comboItemPerEnd - comboItemPer) / 1000));

        this.comboGroup = JSON.parse(JSON.stringify(comboGroupList));

        this.loader.closeLoader();

        if (this.item) {
          this.item.ComboGroup = this.comboGroup;
          this.numInvisibleGroups = this.item.ComboGroup.filter(
            (x) => x.isHidden
          ).length;
        }

        if (this.builderModeSelection) {
          this.cdr.detectChanges();
          this.builderModeSelection.ngOnInit();
        }
      });
  }

  //OpenItem For Combo Item
  open(item: ItemV2) {
    const modalReference = this.modalService.open(ItemBuilderModeComponent, {
      backdrop: 'static',
      windowClass: 'comboItemModal',
      modalDialogClass: 'comboItemModal',
      centered: true,
      size: 'xl',
    });

    this.ItemID = item.ItemID;
    this.CategoryID = '0';
    this.isComboItem = true;
    this.item = item;
    this.modalReference = modalReference;
    this.modalReference.componentInstance.item = item;
    this.modalReference.componentInstance.CategoryID = '0';
    this.modalReference.componentInstance.ItemID = item.ItemID;
    this.modalReference.componentInstance.isComboItem = true;
    this.modalReference.componentInstance.modalReference = this.modalReference;

    return this.modalReference;
  }

  // for combo mode close signal
  // data type any because it can be a message or data
  closeModel(data: any) {
    this.modalReference.close(data);
  }
}
