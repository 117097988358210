<ng-container *ngIf="!isHybrid">
  <div
    [@cartSlideIn]
    *ngFor="let cartItem of cartItems; let i = index"
    class="cart-item"
    style="max-height: 11.5vh"
    [ngClass]="{ 'col-md-6 col-6': isCartOpen, '': !isCartOpen }"
    id="1"
  >
    <div class="closed-images" *ngIf="!isCartOpen">
      <img
        (click)="editItem(cartItem)"
        src="{{ getFormattedURL(cartItem) }}"
        onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
        class="closed-image img-responsive"
        [ngStyle]="{ 'border-radius': bordersTypeItem ? imageRadius : '0%' }"
      />
    </div>
    <div class="row vertical-center" id="{{ cartItem.guid }}">
      <div class="col-md-4 col-4" [ngClass]="{ open: !isCartOpen }">
        <img
          *ngIf="isCartOpen"
          (click)="editItem(cartItem)"
          src="{{ getFormattedURL(cartItem) }}"
          onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
          class="img-responsive"
          [ngStyle]="{ 'border-radius': bordersTypeItem ? imageRadius : '0%' }"
        />
      </div>
      <div class="col-md-8 col-8 footercart" *ngIf="isCartOpen">
        <div class="row col-md-12 col-12">
          <div
            (click)="editItem(cartItem)"
            class="col-md-10 col-10 text-ellipses flex-down lh14"
          >
            <strong
              style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
              class="bold"
              >{{ cartItem.CategoryName | slice: 0:16 }}</strong
            >
            <strong
              style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
              class="medium"
              >{{ cartItem.Name }}</strong
            >
          </div>
          <div class="col-md-2 col-2 p-0">
            <img
              src="../../../assets/images/delete.svg"
              class="delete-icon"
              (click)="removeFromCart(cartItem)"
              onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-12 p-0">
            <div class="number-counter">
              <div
                class="number-content"
                [ngStyle]="{
                  margin:
                    cartItem.AllowQuantitySelection == 'True'
                      ? '3rem 0 0 0 '
                      : '0'
                }"
                *ngIf="
                  !(
                    cartItem.EnablePricebyWeight &&
                    cartItem.EnablePricebyWeight == 'True'
                  );
                  else weightLayout
                "
              >
                <span
                  [ngStyle]="{
                    visibility:
                      cartItem.AllowQuantitySelection == 'True'
                        ? 'visible'
                        : 'hidden'
                  }"
                  id="{{ cartItem.guid }}"
                  class="input-number-decrement JS_ItemDecrement"
                  data-itemId="1"
                  (click)="decreaseAmount(cartItem, i)"
                >
                  <img
                    src="../../../assets/images/minus.svg"
                    onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
                  />
                </span>
                <input
                  id="{{ cartItem.guid }}"
                  class="input-number-2 JS_ItemQuantity"
                  disabled="disabled"
                  data-itemId="1"
                  id="1"
                  type="text"
                  value="{{ cartItem.Quantity }}"
                  min="0"
                  max=""
                />
                <span
                  [ngStyle]="{
                    visibility:
                      cartItem.AllowQuantitySelection == 'True'
                        ? 'visible'
                        : 'hidden'
                  }"
                  id="{{ cartItem.guid }}"
                  class="input-number-increment JS_ItemIncrement"
                  data-itemId="1"
                  (click)="increaseAmount(cartItem)"
                >
                  <img
                    src="../../../assets/images/plus.svg"
                    onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
                  />
                </span>
              </div>
              <ng-template #weightLayout>
                <div class="weightlayout" [ngStyle]="{ color: priceColor }">
                  {{ cartItem.Weight }}
                  <p [ngSwitch]="cartItem.WeightUnitID">
                    <span *ngSwitchCase="0">oz.</span>
                    <span *ngSwitchCase="1">lb.</span>
                    <span *ngSwitchCase="2">g.</span>
                    <span *ngSwitchCase="3">kg.</span>
                    <span *ngSwitchDefault></span>
                  </p>
                </div>
              </ng-template>

              <p
              class="text-danger no-margin font-size4 lh10"
              *ngIf="cartItem.IsLoyaltyDiscountItem"
            >
              {{rewardItemText}}
            </p>
            <p
            class="text-danger no-margin font-size4 lh10"
            *ngIf="
              !cartItem.IsLoyaltyDiscountItem &&
              !(cartItem.AllowQuantitySelection == 'True')
            "
          >
            {{upsellItemText}}
          </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            (click)="editItem(cartItem)"
            [style.color]="textColor"
            class="col-md-12 col-12"
            [ngStyle]="{ color: priceColor }"
          >
            <strong *ngIf="!isRemovePricingAndPayments">{{ cartItem.totalPrice | number: "1.2-2" | CurrencyPipe }}</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isHybrid && cartItems.length > 0">
  <div class="addtocart-section">
    <div class="section-title">{{yourItemsText}}</div>
    <div class="cart-list " id="cart-list">
      <div
        [@cartSlideIn]
        *ngFor="let cartItem of cartItems; let i = index"
        class="each-item"
        id="1"
      >
        <div class="col-md-9 col-9">
          <div class="item-name">
            <strong
              style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
              >{{cartItem.Name}}</strong
            >
          </div>
          <div class="item-name">
            <strong *ngIf="!isRemovePricingAndPayments">{{cartItem.totalPrice | number: "1.2-2" | CurrencyPipe}}</strong>
          </div>
          <div class="qty">
            <div class="qty-btn">
              <button class="btn btn-minus opacity-5">
                <span
                  class="material-icons material-icons-outlined"
                  [style.color]="primaryColor"
                  [ngStyle]="{
                    visibility:
                      cartItem.AllowQuantitySelection == 'True'
                        ? 'visible'
                        : 'hidden'
                  }"
                  id="{{ cartItem.guid }}"
                  data-itemId="1"
                  (click)="decreaseAmount(cartItem, i)"
                >
                  remove_circle_outline</span
                >
              </button>
              <div
                id="{{ cartItem.guid }}"
                disabled="disabled"
                data-itemId="1"
                type="text"
                min="0"
                max=""
              >
                {{ cartItem.Quantity }}
              </div>
              <button class="btn btn-plus">
                <span
                  class="material-icons material-icons-outlined"
                  [style.color]="primaryColor"
                  [ngStyle]="{
                    visibility:
                      cartItem.AllowQuantitySelection == 'True'
                        ? 'visible'
                        : 'hidden'
                  }"
                  id="{{ cartItem.guid }}"
                  data-itemId="1"
                  (click)="increaseAmount(cartItem)"
                >
                  add_circle_outline
                </span>
              </button>
            </div>
            <div class="col-md-2 col-2 p-0">
              <img
                src="../../../assets/images/delete.svg"
                class="delete-icon"
                (click)="removeFromCart(cartItem)"
                onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

