import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Languages } from '../models/language';
import { GeneralSetting } from './generalSetting.service';

export enum LanguageChoice {
  None = 0,
  English = 1,
  Spanish = 2,
  French = 3,
}

type stringsObject = {
  menu_setting_advanced__loyalty_welcome_footer_text: string;
  menu_setting_display__confirm_email_modal_finish_text: string;
  menu_setting_display__confirm_email_modal_header_text: string;
  menu_setting_display__confirm_email_modal_main_text: string;
  menu_setting_display__confirm_email_modal_secondary_text: string;
  menu_setting_display__confirm_order_modal_header_text: string;
  menu_setting_display__confirm_order_modal_main_text: string;
  menu_setting_display__confirm_order_modal_secondary_text: string;
  menu_setting_display__customer_email_info_hint: string;
  menu_setting_display__customer_name_info_header: string;
  menu_setting_display__customer_name_info_hint: string;
  menu_setting_display__customer_phone_info_hint: string;
  menu_setting_display__item_table_tent_body_text: string;
  menu_setting_display__item_table_tent_header_text: string;
  menu_setting_display__item_table_tent_special_request_text: string;
  menu_setting_display__order_table_tent_body_text: string;
  menu_setting_display__order_table_tent_header_text: string;
  menu_setting_display__order_table_tent_number_text: string;
  menu_setting_display__order_table_tent_title: string;
  menu_setting_display__special_request_button_text: string;
  menu_setting_display__special_request_header_text: string;
  menu_setting_display__tip_modal_header_text: string;
  menu_setting_display__tip_modal_main_text: string;
  menu_setting_display__upsell_modal_header_text: string;
  menu_setting_display__upsell_modal_main_text: string;
  menu_setting_general__order_type_options_dine_in: string;
  menu_setting_general__order_type_options_take_out: string;
  menu_setting_home_screen__welcome_bottom_message: string;
  menu_setting_home_screen__welcome_center_message: string;
  menu_setting_receipt__KOT_Footer_Message: string;
  menu_setting_receipt__customize_message_footer_text: string;
  menu_setting_receipt__customize_message_header_text: string;
  menu_setting_receipt__email_receipt_button_text: string;
  menu_setting_receipt__email_receipt_footer_text: string;
  menu_setting_receipt__email_receipt_header_text: string;
  menu_setting_receipt__message_text_after_link: string;
  menu_setting_receipt__message_text_before_link: string;
  menu_setting__store_closed_time_message: string;
};
@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  locale = GeneralSetting.getLocale() == '' ? 'en' : GeneralSetting.getLocale();
  languageChoice: number = 0;
  private path = 'assets/languages/';

  private localizationMap: Map<String, String> = new Map<String, String>();

  localeCommunicator = new BehaviorSubject(0);
  defaultLanguage: string = localStorage.getItem('defaultLanguage') ?? 'en';
  customMaps: Map<string, stringsObject> = new Map();
  constructor(private http: HttpClient) {
    this.setLanguageChoice();
    this.setLocale(this.defaultLanguage);
    this.initializeLocalizationTable();
  }
  async createMaps(kioskLocalization: any) {
    if (kioskLocalization.AgeOfMajority && kioskLocalization.CurrencySymbol) {
      GeneralSetting.setAgeOfMajority(kioskLocalization.AgeOfMajority);
      GeneralSetting.setCurrencySymbol(kioskLocalization.CurrencySymbol);
    }

    if (!kioskLocalization.Languages.length) {
      // we didn't get any back
      return;
    }

    let arr = this.storeMapData(kioskLocalization.Languages);
    let maps: Map<string, stringsObject> = new Map();

    //@ts-ignore
    arr.forEach((element) => {
      let k = Object.keys(element.Strings);
      let v = Object.values(element.Strings);
      let obj = {};
      for (let i = 0; i < k.length; i++) {
        //@ts-ignore
        obj[`${k[i]}`] = v[i];
      }
      if (element.isDefault) {
        localStorage.setItem('defaultLanguage', element.Code);
        this.defaultLanguage = element.Code;
      }
      maps.set(element.Code, obj as stringsObject);
    });

    this.customMaps = maps;

    this.setLocale(this.defaultLanguage);
  }
  getCustomMapValues(x: string) {
    if (
      this.customMaps?.size == 0 &&
      localStorage.getItem('languageMaps') != undefined
    ) {
      this.rebuildMapData();
    }

    //@ts-ignore
    return this.customMaps.get(this.locale)[x];
  }

  storeMapData(locales: any) {
    localStorage.removeItem('languageMaps');
    localStorage.setItem('languageMaps', JSON.stringify(locales));
    return JSON.parse(localStorage.getItem('languageMaps') ?? '');
  }

  getLanguageData(): Languages[] {
    let getLanguageMaps = localStorage.getItem('languageMaps');
    let languageMaps = (getLanguageMaps) ? JSON.parse(getLanguageMaps) : [];
    return languageMaps && languageMaps.length > 0 && languageMaps.filter( (a: any) => a.isEnabled)
  }

  rebuildMapData() {
    var arr = JSON.parse(localStorage.getItem('languageMaps') ?? '');
    let maps: Map<string, stringsObject> = new Map();
    this.localizationMap = new Map<String, String>();

    arr.forEach((element: any) => {
      let k = Object.keys(element.Strings);
      let v = Object.values(element.Strings);

      for (let i = 0; i < k.length; i++) {
        //@ts-ignore
        this.localizationMap.set(k[i], v[i]);
      }
    });

    //@ts-ignore
    arr.forEach((element) => {
      let k = Object.keys(element.Strings);
      let v = Object.values(element.Strings);
      let obj = {};
      for (let i = 0; i < k.length; i++) {
        //@ts-ignore
        obj[`${k[i]}`] = v[i];
      }
      if (element.isDefault) {
        localStorage.setItem('defaultLanguage', element.Code);
        this.defaultLanguage = element.Code;
      }
      maps.set(element.Code, obj as stringsObject);
    });

    this.customMaps = maps;
  }

  private setLanguageChoice() {
    this.languageChoice =
      this.locale == 'en'
        ? LanguageChoice.English
        : this.locale == 'es'
        ? LanguageChoice.Spanish
        : this.locale == 'fr'
        ? LanguageChoice.French
        : LanguageChoice.None;
  }

  setLocale(lid: any): Promise<any> {
    return new Promise<any>((res: any, rej: any) => {
      this.locale = lid;
      this.setLanguageChoice();
      GeneralSetting.setLocale(lid);
      this.initializeLocalizationTable().then((data: any) => {
        res(data);
      });
    });
  }

  private initializeLocalizationTable(): Promise<any> {
    return new Promise<any>((res: any, rej: any) => {
      this.localizationMap = new Map<String, String>();
      this.http
        .get(this.path + this.locale + '.json')
        .subscribe((data: any) => {
          data.string.forEach((element: any) => {
            this.localizationMap.set(element.name, element.text);
          });
          this.localeCommunicator.next(this.languageChoice);
          res(this.localizationMap);
        });
    });
  }

  getTextElement(name: any): string {
    if (
      this.localizationMap?.size == 0 &&
      localStorage.getItem('languageMaps') != undefined
    ) {
      this.rebuildMapData();
    }

    return (this.localizationMap.get(name) as string) || '';
  }
}
