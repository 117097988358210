import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KeyboardService {
  private ChangeSubject = new BehaviorSubject([0, 0, 0]);
  manual:boolean=false
  maxLength:number=0
  newBoardSubject=new Subject<string>()
  ObserveInputChange = this.ChangeSubject.asObservable();
  stringSub=new Subject<string>()
  clearSub=new Subject<void>()
  buttonclicked = new Subject<string>()
  handleupperlowercase = new Subject<boolean>()
  _KeyboardOpen = false;
  Keyboard: any;

  set KeyboardStatus(status: boolean) {
    this._KeyboardOpen = status;
  }

  get IsOpen() {
    return this._KeyboardOpen == true;
  }

  ToggleKeyboard(input: any, layout: any, length: any, override: any = undefined): void {
    this._KeyboardOpen = (override != undefined && override) || !this._KeyboardOpen;
    this.ChangeSubject.next([input, layout, length]);
  }

open(str:string){
  //if(str=='numeric'){
    this.newBoardSubject.next(str)
  //}
  this._KeyboardOpen =true
  this.stringSub.next(str)
}
  constructor() { }
}
