import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FlexPairingComponent } from '../components/dialogs/flex-pairing/flex-pairing.component';
import { CloverService } from './clover.service';
import { LanguageService } from './language.service';
import { loggingData, LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})

export class FlexPairingService {
  constructor(
    private clover: CloverService,
    private modal: NgbModal,
    private logging: LogService, 
    private language:LanguageService
  ) {}
  private flexPairingModal!: FlexPairingComponent | null;
  state: '1' | '2' | '3' | '4' = '1';
  //1 = unset
  //2 = success
  //3 = failure
  private flexPairingModalRef: any | null;
  openFlexPairingModal() {
    this.flexPairingModalRef = FlexPairingComponent.open(this.modal);
  }
  closeFlexPairingModal() {
    this.flexPairingModalRef.close();
  }
  setWaiting() {
    this.state = '1';
    if (this.flexPairingModal)
      this.flexPairingModal.title = this.language.getTextElement('pairing_flex');
    if (this.flexPairingModal) 
      this.flexPairingModal.bodya = this.language.getTextElement('pairing_bodya');
    if (this.flexPairingModal)
      this.flexPairingModal.bodyb = this.language.getTextElement('pairing_bodyb')
    if (this.flexPairingModal)
      this.flexPairingModal.imageSrc =
        '../../../../assets/images/flex/pairing.gif';
    if (this.flexPairingModal) this.flexPairingModal.buttonOneText = '';
    if (this.flexPairingModal) this.flexPairingModal.buttonTwoText = '';
  }
  setSuccess() {
    this.state = '2';
    if (this.flexPairingModal)
      this.flexPairingModal.title = this.language.getTextElement('pairing_success')
    if (this.flexPairingModal)
      this.flexPairingModal.bodya = this.language.getTextElement('pairing_info')
    if (this.flexPairingModal)
      this.flexPairingModal.bodyb = this.language.getTextElement('pairing_success').toLocaleLowerCase()
    if (this.flexPairingModal)
      this.flexPairingModal.imageSrc =
        '../../../../assets/images/flex/success.png';
    if (this.flexPairingModal) this.flexPairingModal.buttonOneText = this.language.getTextElement('lbl_continue').toLocaleUpperCase();
    if (this.flexPairingModal) this.flexPairingModal.buttonTwoText = '';
  }
  setFailure(message: string) {
    this.state = '3';
    if (this.flexPairingModal)
      this.flexPairingModal.title = this.language.getTextElement('pairing_flex')
    if (this.flexPairingModal)
      this.flexPairingModal.bodya = this.language.getTextElement('pairing_error')
    if (this.flexPairingModal)
      this.flexPairingModal.bodyb = `${this.language.getTextElement('error').toLocaleUpperCase()}: ${message}`;
    if (this.flexPairingModal)
      this.flexPairingModal.imageSrc =
        '../../../../assets/images/flex/unLinked.png';
    if (this.flexPairingModal)
      this.flexPairingModal.buttonOneText = this.language.getTextElement('try_again').toLocaleUpperCase()
    if (this.flexPairingModal) this.flexPairingModal.buttonTwoText =this.language.getTextElement('skip').toLocaleUpperCase()
  }

  setNotLinked() {
    this.state = '4';
    if (this.flexPairingModal)
      this.flexPairingModal.title = this.language.getTextElement('pairing_flex')
    if (this.flexPairingModal)
      this.flexPairingModal.bodya =this.language.getTextElement('flex_next_stepsa')
        ;
    if (this.flexPairingModal)
      this.flexPairingModal.bodyb =this.language.getTextElement('flex_next_stepsb')
    if (this.flexPairingModal)
      this.flexPairingModal.imageSrc =
        '../../../../assets/images/flex/unLinked.png';
    if (this.flexPairingModal) this.flexPairingModal.buttonOneText = this.language.getTextElement('lbl_continue').toLocaleUpperCase()
    if (this.flexPairingModal) this.flexPairingModal.buttonTwoText = this.language.getTextElement('skip').toLocaleUpperCase()
  }

  checkPairing() {
    this.openFlexPairingModal();
    this.callPing();
  }

  async callPing() {
    this.flexPairingModal = this.flexPairingModalRef.componentInstance;
    if (!this.clover.isLinkedToDevice()) {
      this.setNotLinked();
      return;
    }

    this.setWaiting();
    let res: any = await this.clover.ping();
    if (res.status == 'success') {
      this.setSuccess();
      const errorLog = new loggingData(
        'Payment device : Clover Flex serial attached with the Kiosk',
        'Clover Flex serial attached with the Kiosk in: ' + location,
        'clover.service.ts',
        'Clover Flex serial attached with the Kiosk',
        true
      );
      this.logging.sendLogToServer(errorLog);
      
    } else {
      this.setFailure(res.message);
      const errorLog = new loggingData(
        'Flex Pair Error',
        'Flex Pair failed',
        'Flex Pair',
        '\n' + 'Error recieved: ' + JSON.stringify(res),
        true
      );

      this.logging.sendLogToServer(errorLog);
    }
  }
}
