import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AskConfigureDevicePopupServiceService } from '../components/dialogs/ask-configure-your-device/ask-configure-device-popup-service.service';
import { AskDeviceNamePopupService } from '../components/dialogs/ask-device-name/ask-device-name-popup.service';
import { DownloadingPopupService } from '../components/dialogs/downloading-popup/downloading-popup.service';
import { IUpdateModalService } from '../models/IUpdateModelService';
import { CloudOrderService } from './cloud-order.service';
import { Snackbar } from './common';
import { CurrencyService } from './currency.service';
import { DataService } from './data.service';
import { DatabaseService } from './database.service';
import { GeneralSetting } from './generalSetting.service';
import { HttpConnection, IHttpHeaders } from './http.service';
import { IsKioskService } from './is-kiosk.service';
import { KeyboardService } from './keyboard.service';
import { LanguageService } from './language.service';
import { loggingData, LogService } from './log.service';
import { LoginService } from './login.service';
import { PusherService } from './pusher.service';
import { SaveSettingsService } from './save-settings.service';
import { TimeService } from './store-time.service';

@Injectable({
  providedIn: 'root'
})
export class BranchLoginStreamServiceService {

  isKiosk: boolean = true;

  baseUrl: string = environment.apiUrl;
  //baseUrl: string = "http://localhost:63492/api/";

  private allImageArray = [] as any[];

  private moduleDetail = [] as any[];

  private popUpModel: IUpdateModalService = {} as IUpdateModalService;
  private startingDownloadsString = ''
  private buildingMenuString = ''
  private fetchingBranchDataString = ''

  constructor(private http: HttpClient,
    private logger: LogService,
    private isKioskService: IsKioskService,
    private keyboardService: KeyboardService,
    private saveSettingService: SaveSettingsService,
    private askConfigDevicePopupService: AskConfigureDevicePopupServiceService,
    private askDeviceNamePopupService: AskDeviceNamePopupService,
    private pusherService: PusherService,
    private dataSerive: DataService,
    private downloadImagePopup: DownloadingPopupService,
    private databaseSerive: DatabaseService,
    private timeService: TimeService,
    private cloud:CloudOrderService,
    private language:LanguageService,
    private currency:CurrencyService,
    
  ) {

    this.isKiosk = isKioskService.isKiosk();
    //this.isKiosk = true;
  }

  public async doLogin(username: string, password: string, deviceId: string, modalRef: IUpdateModalService, isRefresh?: boolean,timeOutComponent?:any) {
    if (this.keyboardService._KeyboardOpen) {
      this.keyboardService.KeyboardStatus = false;
    }
    this.popUpModel = modalRef;
    if (isRefresh) {
      if(timeOutComponent){
        timeOutComponent.stillHere();
      }
      this.popUpModel.open();
    }
    var commonData = await this.doBranchLogin(username, password, deviceId);
    this.startingDownloadsString = this.language.getTextElement('starting_downloads')
    this.buildingMenuString = this.language.getTextElement('building_menu');
    this.fetchingBranchDataString = this.language.getTextElement('fetching_branch_data');

if(commonData.data.KioskLocalization)
    await this.language.createMaps(commonData.data.KioskLocalization)
    this.currency.setCurrencySymbol(GeneralSetting.getCurrencySymbol())
    
    if (commonData.IsError) {
      this.popUpModel.close();
      this.onInvalidCredentials(null,!commonData.IsError); // used on invalid username and password GBRL-13 @Jay
      return false;
    } else {
      if(!isRefresh){
        this.popUpModel.open();
      }
    }
    this.popUpModel.setMessage(this.fetchingBranchDataString);

    await this.setCommonBranchData(commonData.data);
    let branchDetail = commonData.data.BranchMasters;

    if (!branchDetail || branchDetail == null) {
      this.popUpModel.close();
      return false;
    }

    let isConcessionaire = false;
    if (commonData.data?.ModuleGeneralSettings.length > 0) {
      isConcessionaire = commonData.data.ModuleGeneralSettings[0].IsConcessionaire.toString().toLowerCase() == 'true';
    }

    var categoryDetailRes = await this.doGetCategoryDetail(username, password, deviceId);
    if (categoryDetailRes.IsError) {
      this.popUpModel.close();
      return false;
    }
    await this.setDataToDB(categoryDetailRes.data);

    var itemDetailRes = await this.doGetItemDetail(username, password, deviceId);
    if (itemDetailRes.IsError) {
      this.popUpModel.close();
      return false;
    }

    await this.setDataToDB(itemDetailRes.data);

    var ComboDetailRes = await this.doGetComboDetail(username, password, deviceId);
    if (ComboDetailRes.IsError) {
      this.popUpModel.close();
      return false;
    }

    await this.setDataToDB(ComboDetailRes.data);

    var modifierDetailRes = await this.doGetModifierDetail(username, password, deviceId);
    if (modifierDetailRes.IsError) {
      this.popUpModel.close();
      return false;
    }

    await this.setDataToDB(modifierDetailRes.data);

    if (isConcessionaire) {
      var concessionaireDetailRes = await this.doGetConcessionaireDetail(username, password, deviceId);
      if (concessionaireDetailRes.IsError) {
        this.popUpModel.close();
        return false;
      }

      await this.setDataToDB(concessionaireDetailRes.data);
    }

    var taxDetailRes = await this.doGetTaxDetail(username, password, deviceId);
    if (taxDetailRes.IsError) {
      this.popUpModel.close();
      return false;
    }

    await this.setDataToDB(taxDetailRes.data);

    var discountDetailRes = await this.doGetDiscountDetail(username, password, deviceId);
    if (discountDetailRes.IsError) {
      this.popUpModel.close();
      return false;
    }

    await this.setDataToDB(discountDetailRes.data);

    var integrationDetailRes = await this.doGetIntegrationDetail(username, password, deviceId);
    if (integrationDetailRes.IsError) {
      this.popUpModel.close();
      return false;
    }

    await this.setDataToDB(integrationDetailRes.data);

    var kitchenDetailRes = await this.doGetKitchenDetail(username, password, deviceId);
    if (kitchenDetailRes.IsError) {
      this.popUpModel.close();
      return false;
    }

    await this.setDataToDB(kitchenDetailRes.data);

    this.popUpModel.setMessage(this.buildingMenuString);

    this.popUpModel.setMessage(this.startingDownloadsString);

    this.popUpModel.close();

    this.downloadImagePopup.open();

    //this.allImageArray.push(environment.imageBaseUrlRemote);

    await this.saveSettingService.QueueDownloadItemImages(
      this.allImageArray
    );
    this.downloadImagePopup.close();

    this.popUpModel.open();

    this.popUpModel.setMessage(this.buildingMenuString);

    await this.saveSettingService.createAllTables();

    this.pusherService.getPusherKeys(true);

    this.saveSettingService.theme();

    this.databaseSerive.getDetailSetting();

    await this.timeService.getStoreTimes();

    this.saveSettingService.reloadChildComponent();

    var registerRes = await this.doGetDeviceNameAndRegister(branchDetail, username, password, isRefresh);

    if (!registerRes) {
      return false;
    }

    GeneralSetting.setIsDataBasePresent('True');

    this.popUpModel.close();
    await this.cloud.handleOloDataFetch()
    return true;
  }

  private doBranchLogin(username: string, password: string, deviceId: string) {
    return new Promise<{ IsError: boolean, data: any }>((resolve) => {

      GeneralSetting.setSyncDate(new Date().toLocaleString());

      var headers = [] as IHttpHeaders[];
      headers.push({ key: "Version", value: "1.0" } as IHttpHeaders);
      headers.push({ key: "Content-Type", value: "application/json" } as IHttpHeaders);

      var body = {
        Username: username,
        Password: password,
        AppVersion: environment.Version,
        DeviceType: '1',
        DeviceID: deviceId,
        ModuleID: '2',
        FCMID: 'Pusher',
        AppID: GeneralSetting.getSerialNo()
      };

      HttpConnection.Connection(this.http, this.isKiosk)
        .addUrl(this.baseUrl + "KioskLogin")
        .addMethod("POST")
        .addHeader(headers)
        .addBody(body)
        .post().toPromise().then(data => {
          var res = {
            IsError: false,
            data: data
          }

          if (data.statusCode && data.statusCode != 200) {

            res.IsError = true;
            resolve(res);
          }
          else {
            GeneralSetting.setBranchLoginPass(password);

            GeneralSetting.setBranchLoginUserId(username);

            GeneralSetting.setIsSelectAllCategory('False');

            resolve(res);
          }
        }, error => {

          var res = {
            IsError: true,
            data: error
          }

          resolve(res);
        })
    });
  }

  private async setCommonBranchData(val: any) {
    if (
      val.ModuleGeneralSettings != null &&
      val.ModuleGeneralSettings.length
    ) {
      this.moduleGeneralSettingsSetter(val.ModuleGeneralSettings[0]);
    } else {
      Snackbar.show('Module General Settings is not set up', 30000);
      return;
    }

    //this.isModalOpen.emit(true);

    let images = await this.saveSettingService.parseBranchData(JSON.parse(JSON.stringify(val)));

    this.allImageArray = this.allImageArray.concat(images);

    this.pusherService.getPusherKeys(true);

    if (val.StaffModuleMasters && val.StaffModuleMasters.length > 0) {
      if (val.BranchMasters) {
        this.moduleDetail = val.StaffModuleMasters.filter(
          (x: any) =>
            x.BranchID == val.BranchMasters.BranchID &&
            x.DeviceID == GeneralSetting.getSerialNo() &&
            x.ModuleID == '2'
        );
      }

      if (this.moduleDetail.length > 0) {
        var info = this.moduleDetail[0];
        if(info.Name){
          GeneralSetting.setDeviceName(info.Name)
        }
        GeneralSetting.setDeviceHSN(info.HSN)
        GeneralSetting.setAppVersion(info.AppVersion);
        GeneralSetting.setBrandName(info.BrandName);
        GeneralSetting.setDeviceModel(info.ModelName);
        GeneralSetting.setOsversion(info.OSVersion);
        
        GeneralSetting.setUseApiPrinting(info.UseApiPrinting);

        // console.log('the info is', info.StaffModuleID);
        GeneralSetting.setStaffModuleID(info.StaffModuleID);
        GeneralSetting.setBranchId(info.BranchID);
        GeneralSetting.setCompanyId(info.CompanyID);
        GeneralSetting.setOrderPrefix(info.OrderPrefix || '');
        GeneralSetting.setIsAssignSpecificConcessionaire(info.IsAssignSpecificConcessionaire);
      }
    }



  }

  private moduleGeneralSettingsSetter(data: any) {
    if (data.AllowCheckoutWithZeroAmountWithLoyalty != null) {
      GeneralSetting.setAllowCheckoutWithZeroAmountWithLoyalty(
        data.AllowCheckoutWithZeroAmountWithLoyalty
      );
    }
    if (data.UseLoyaltyKiosk) {
      GeneralSetting.setUseLoyaltyKiosk(data.UseLoyaltyKiosk);
    }

    if (data.BarcodeOrQRcodeData) {
      GeneralSetting.setBarcodeOrQRCodeData(data.BarcodeOrQRcodeData);
    }

    if (data.BarcodeOrQRcodeDescription) {
      GeneralSetting.setBarcodeOrQRCodeDescription(data.BarcodeOrQRcodeDescription);
    }

    if (data.BarcodeOrQRcodeonReceipt) {
      GeneralSetting.setBarcodeOrQRCodeOnReceipt(data.BarcodeOrQRcodeonReceipt);
    }


    if (data.IsShowPaymentScreen) {
      GeneralSetting.setShowPaymentScreen(data.IsShowPaymentScreen);
    }
    if (data.ShowSurveyLinkOnReceipt) {
      GeneralSetting.setShowSurveyLinkOnReceipt(data.ShowSurveyLinkOnReceipt);
    }
    if (data.StoreID) {
      GeneralSetting.setStoreID(data.StoreID);
    }
    if (data.SurveyLink) {
      let res = data.SurveyLink.replace(/\r\n/g, '\n');
      GeneralSetting.setSurveyLink(res);
    }
    if (data.AllowLoyaltyLoginusingPhoneNumber) {
      GeneralSetting.setLoyaltyLoginWithPhone(
        data.AllowLoyaltyLoginusingPhoneNumber
      );
    }
    if (data.EnableOrderShipment != null) {
      GeneralSetting.setEnableOrderShipment(data.EnableOrderShipment);
      if (data.EnableOrderShipment == 'True') {
        GeneralSetting.setDomesticShippingFee(data.DomesticShippingFee);
        GeneralSetting.setInternationalShippingFee(
          data.InternationalShippingFee
        );
        if (data.EnableOrderShipment == 'True') {
          GeneralSetting.setDomesticShippingFee(data.DomesticShippingFee);
          GeneralSetting.setInternationalShippingFee(
            data.InternationalShippingFee
          );
        }
      }
    }
    if (data.ItemModifierImages != null) {
      GeneralSetting.setPlaceHolderImageFlag(data.ItemModifierImages);
      GeneralSetting.setPlaceHolderImage(data.PlaceholderImage);
    }
  }

  private doGetCategoryDetail(username: string, password: string, deviceId: string) {
    return new Promise<{ IsError: boolean, data: any }>((resolve) => {

      GeneralSetting.setSyncDate(new Date().toLocaleString());

      var headers = [] as IHttpHeaders[];
      headers.push({ key: "Version", value: "1.0" } as IHttpHeaders);
      headers.push({ key: "Content-Type", value: "application/json" } as IHttpHeaders);
      headers.push({ key: "branchId", value: GeneralSetting.getBranchId() } as IHttpHeaders);
      headers.push({ key: "CompanyId", value: GeneralSetting.getCompanyId() } as IHttpHeaders);

      var body = {
        Username: username,
        Password: password,
        AppVersion: environment.Version,
        DeviceType: '1',
        DeviceID: deviceId,
        ModuleID: '2',
        FCMID: 'Pusher',
        DeviceName: "Samsung Kiosk",
        AppID: GeneralSetting.getSerialNo()
      };

      HttpConnection.Connection(this.http, this.isKiosk)
        .addUrl(this.baseUrl + "KioskCategoryDetail")
        .addMethod("POST")
        .addHeader(headers)
        .addBody(body)
        .post().toPromise().then(data => {
          var res = {
            IsError: false,
            data: data
          }

          if (data.statusCode && data.statusCode != 200) {

            res.IsError;
            resolve(res);
          }
          else {
            resolve(res);
          }
        }, error => {

          var res = {
            IsError: true,
            data: error
          }

          resolve(res);
        })
    });
  }

  private async setDataToDB(val: any) {
    let images = await this.saveSettingService.parseBranchData(JSON.parse(JSON.stringify(val)));

    this.allImageArray = this.allImageArray.concat(images);
  }

  private doGetItemDetail(username: string, password: string, deviceId: string) {
    return new Promise<{ IsError: boolean, data: any }>((resolve) => {

      GeneralSetting.setSyncDate(new Date().toLocaleString());

      var headers = [] as IHttpHeaders[];
      headers.push({ key: "Version", value: "1.0" } as IHttpHeaders);
      headers.push({ key: "Content-Type", value: "application/json" } as IHttpHeaders);
      headers.push({ key: "branchId", value: GeneralSetting.getBranchId() } as IHttpHeaders);
      headers.push({ key: "CompanyId", value: GeneralSetting.getCompanyId() } as IHttpHeaders);

      var body = {
        Username: username,
        Password: password,
        AppVersion: environment.Version,
        DeviceType: '1',
        DeviceID: deviceId,
        ModuleID: '2',
        FCMID: 'Pusher',
        DeviceName: "Samsung Kiosk",
        AppID: GeneralSetting.getSerialNo()
      };

      HttpConnection.Connection(this.http, this.isKiosk)
        .addUrl(this.baseUrl + "KioskItemDetail")
        .addMethod("POST")
        .addHeader(headers)
        .addBody(body)
        .post().toPromise().then(data => {
          var res = {
            IsError: false,
            data: data
          }

          if (data.statusCode && data.statusCode != 200) {

            res.IsError;
            resolve(res);
          }
          else {
            resolve(res);
          }
        }, error => {

          var res = {
            IsError: true,
            data: error
          }

          resolve(res);
        })
    });
  }

  private doGetComboDetail(username: string, password: string, deviceId: string) {
    return new Promise<{ IsError: boolean, data: any }>((resolve) => {

      GeneralSetting.setSyncDate(new Date().toLocaleString());

      var headers = [] as IHttpHeaders[];
      headers.push({ key: "Version", value: "1.0" } as IHttpHeaders);
      headers.push({ key: "Content-Type", value: "application/json" } as IHttpHeaders);
      headers.push({ key: "branchId", value: GeneralSetting.getBranchId() } as IHttpHeaders);
      headers.push({ key: "CompanyId", value: GeneralSetting.getCompanyId() } as IHttpHeaders);

      var body = {
        Username: username,
        Password: password,
        AppVersion: environment.Version,
        DeviceType: '1',
        DeviceID: deviceId,
        ModuleID: '2',
        FCMID: 'Pusher',
        DeviceName: "Samsung Kiosk",
        AppID: GeneralSetting.getSerialNo()
      };

      HttpConnection.Connection(this.http, this.isKiosk)
        .addUrl(this.baseUrl + "KioskComboDetail")
        .addMethod("POST")
        .addHeader(headers)
        .addBody(body)
        .post().toPromise().then(data => {
          var res = {
            IsError: false,
            data: data
          }

          if (data.statusCode && data.statusCode != 200) {

            res.IsError;
            resolve(res);
          }
          else {
            resolve(res);
          }
        }, error => {

          var res = {
            IsError: true,
            data: error
          }

          resolve(res);
        })
    });
  }

  private doGetModifierDetail(username: string, password: string, deviceId: string) {
    return new Promise<{ IsError: boolean, data: any }>((resolve) => {

      GeneralSetting.setSyncDate(new Date().toLocaleString());

      var headers = [] as IHttpHeaders[];
      headers.push({ key: "Version", value: "1.0" } as IHttpHeaders);
      headers.push({ key: "Content-Type", value: "application/json" } as IHttpHeaders);
      headers.push({ key: "branchId", value: GeneralSetting.getBranchId() } as IHttpHeaders);
      headers.push({ key: "CompanyId", value: GeneralSetting.getCompanyId() } as IHttpHeaders);

      var body = {
        Username: username,
        Password: password,
        AppVersion: environment.Version,
        DeviceType: '1',
        DeviceID: deviceId,
        ModuleID: '2',
        FCMID: 'Pusher',
        DeviceName: "Samsung Kiosk",
        AppID: GeneralSetting.getSerialNo()
      };

      HttpConnection.Connection(this.http, this.isKiosk)
        .addUrl(this.baseUrl + "KioskModifierDetail")
        .addMethod("POST")
        .addHeader(headers)
        .addBody(body)
        .post().toPromise().then(data => {
          var res = {
            IsError: false,
            data: data
          }

          if (data.statusCode && data.statusCode != 200) {

            res.IsError;
            resolve(res);
          }
          else {
            resolve(res);
          }
        }, error => {

          var res = {
            IsError: true,
            data: error
          }

          resolve(res);
        })
    });
  }

  private doGetConcessionaireDetail(username: string, password: string, deviceId: string) {
    return new Promise<{ IsError: boolean, data: any }>((resolve) => {

      GeneralSetting.setSyncDate(new Date().toLocaleString());

      var headers = [] as IHttpHeaders[];
      headers.push({ key: "Version", value: "1.0" } as IHttpHeaders);
      headers.push({ key: "Content-Type", value: "application/json" } as IHttpHeaders);
      headers.push({ key: "branchId", value: GeneralSetting.getBranchId() } as IHttpHeaders);
      headers.push({ key: "CompanyId", value: GeneralSetting.getCompanyId() } as IHttpHeaders);

      var body = {
        Username: username,
        Password: password,
        AppVersion: environment.Version,
        DeviceType: '1',
        DeviceID: deviceId,
        ModuleID: '2',
        FCMID: 'Pusher',
        DeviceName: "Samsung Kiosk",
        AppID: GeneralSetting.getSerialNo()
      };

      HttpConnection.Connection(this.http, this.isKiosk)
        .addUrl(this.baseUrl + "KioskConcessionaireDetail")
        .addMethod("POST")
        .addHeader(headers)
        .addBody(body)
        .post().toPromise().then(data => {
          var res = {
            IsError: false,
            data: data
          }

          if (data.statusCode && data.statusCode != 200) {

            res.IsError;
            resolve(res);
          }
          else {
            resolve(res);
          }
        }, error => {

          var res = {
            IsError: true,
            data: error
          }

          resolve(res);
        })
    });
  }

  private doGetTaxDetail(username: string, password: string, deviceId: string) {
    return new Promise<{ IsError: boolean, data: any }>((resolve) => {

      GeneralSetting.setSyncDate(new Date().toLocaleString());

      var headers = [] as IHttpHeaders[];
      headers.push({ key: "Version", value: "1.0" } as IHttpHeaders);
      headers.push({ key: "Content-Type", value: "application/json" } as IHttpHeaders);
      headers.push({ key: "branchId", value: GeneralSetting.getBranchId() } as IHttpHeaders);
      headers.push({ key: "CompanyId", value: GeneralSetting.getCompanyId() } as IHttpHeaders);

      var body = {
        Username: username,
        Password: password,
        AppVersion: environment.Version,
        DeviceType: '1',
        DeviceID: deviceId,
        ModuleID: '2',
        FCMID: 'Pusher',
        DeviceName: "Samsung Kiosk",
        AppID: GeneralSetting.getSerialNo()
      };

      HttpConnection.Connection(this.http, this.isKiosk)
        .addUrl(this.baseUrl + "KioskTaxDetail")
        .addMethod("POST")
        .addHeader(headers)
        .addBody(body)
        .post().toPromise().then(data => {
          var res = {
            IsError: false,
            data: data
          }

          if (data.statusCode && data.statusCode != 200) {

            res.IsError;
            resolve(res);
          }
          else {
            resolve(res);
          }
        }, error => {

          var res = {
            IsError: true,
            data: error
          }

          resolve(res);
        })
    });
  }

  private doGetIntegrationDetail(username: string, password: string, deviceId: string) {
    return new Promise<{ IsError: boolean, data: any }>((resolve) => {

      GeneralSetting.setSyncDate(new Date().toLocaleString());

      var headers = [] as IHttpHeaders[];
      headers.push({ key: "Version", value: "1.0" } as IHttpHeaders);
      headers.push({ key: "Content-Type", value: "application/json" } as IHttpHeaders);
      headers.push({ key: "branchId", value: GeneralSetting.getBranchId() } as IHttpHeaders);
      headers.push({ key: "CompanyId", value: GeneralSetting.getCompanyId() } as IHttpHeaders);

      var body = {
        Username: username,
        Password: password,
        AppVersion: environment.Version,
        DeviceType: '1',
        DeviceID: deviceId,
        ModuleID: '2',
        FCMID: 'Pusher',
        DeviceName: "Samsung Kiosk",
        AppID: GeneralSetting.getSerialNo()
      };

      HttpConnection.Connection(this.http, this.isKiosk)
        .addUrl(this.baseUrl + "KioskIntegrationDetail")
        .addMethod("POST")
        .addHeader(headers)
        .addBody(body)
        .post().toPromise().then(data => {
          var res = {
            IsError: false,
            data: data
          }

          if (data.statusCode && data.statusCode != 200) {

            res.IsError;
            resolve(res);
          }
          else {
            resolve(res);
          }
        }, error => {

          var res = {
            IsError: true,
            data: error
          }

          resolve(res);
        })
    });
  }

  private doGetKitchenDetail(username: string, password: string, deviceId: string) {
    return new Promise<{ IsError: boolean, data: any }>((resolve) => {

      GeneralSetting.setSyncDate(new Date().toLocaleString());

      var headers = [] as IHttpHeaders[];
      headers.push({ key: "Version", value: "1.0" } as IHttpHeaders);
      headers.push({ key: "Content-Type", value: "application/json" } as IHttpHeaders);
      headers.push({ key: "branchId", value: GeneralSetting.getBranchId() } as IHttpHeaders);
      headers.push({ key: "CompanyId", value: GeneralSetting.getCompanyId() } as IHttpHeaders);

      var body = {
        Username: username,
        Password: password,
        AppVersion: environment.Version,
        DeviceType: '1',
        DeviceID: deviceId,
        ModuleID: '2',
        FCMID: 'Pusher',
        DeviceName: "Samsung Kiosk",
        AppID: GeneralSetting.getSerialNo()
      };

      HttpConnection.Connection(this.http, this.isKiosk)
        .addUrl(this.baseUrl + "KioskKitchenDetail")
        .addMethod("POST")
        .addHeader(headers)
        .addBody(body)
        .post().toPromise().then(data => {
          var res = {
            IsError: false,
            data: data
          }

          if (data.statusCode && data.statusCode != 200) {

            res.IsError;
            resolve(res);
          }
          else {
            resolve(res);
          }
        }, error => {

          var res = {
            IsError: true,
            data: error
          }

          resolve(res);
        })
    });
  }

  private doGetDiscountDetail(username: string, password: string, deviceId: string) {
    return new Promise<{ IsError: boolean, data: any }>((resolve) => {

      GeneralSetting.setSyncDate(new Date().toLocaleString());

      var headers = [] as IHttpHeaders[];
      headers.push({ key: "Version", value: "1.0" } as IHttpHeaders);
      headers.push({ key: "Content-Type", value: "application/json" } as IHttpHeaders);
      headers.push({ key: "branchId", value: GeneralSetting.getBranchId() } as IHttpHeaders);
      headers.push({ key: "CompanyId", value: GeneralSetting.getCompanyId() } as IHttpHeaders);

      var body = {
        Username: username,
        Password: password,
        AppVersion: environment.Version,
        DeviceType: '1',
        DeviceID: deviceId,
        ModuleID: '2',
        FCMID: 'Pusher',
        DeviceName: "Samsung Kiosk",
        AppID: GeneralSetting.getSerialNo()
      };

      HttpConnection.Connection(this.http, this.isKiosk)
        .addUrl(this.baseUrl + "KioskDiscountDetail")
        .addMethod("POST")
        .addHeader(headers)
        .addBody(body)
        .post().toPromise().then(data => {
          var res = {
            IsError: false,
            data: data
          }

          if (data.statusCode && data.statusCode != 200) {

            res.IsError;
            resolve(res);
          }
          else {
            resolve(res);
          }
        }, error => {

          var res = {
            IsError: true,
            data: error
          }

          resolve(res);
        })
    });
  }

  private async doGetDeviceNameAndRegister(
    branchDetail: any[],
    userName: string,
    password: string,
    isRefresh?: boolean
  ) {
    this.popUpModel.close();
    if (branchDetail) {
      if (GeneralSetting.getDeviceName()) {
        const des = `Branch Login: ${userName}`;
        try {
          const log = new loggingData(
            'Branch Login',
            des,
            'Branch Login',
            JSON.stringify(branchDetail),
            false
          );
          this.logger.sendLogToServer(log);
        } catch (e: any) {
          if (e.message.includes('btoa')) {
            const log = new loggingData(
              'Branch Login Logging Error',
              des,
              'Branch Login Logging Error',
              `Error Logging "JSON.stringify(this.branchInfo)" ${e.message}`,
              false
            );
            this.logger.sendLogToServer(log);
          } else {
            const log = new loggingData(
              'Branch Login Unknown Error',
              des,
              'Branch Login Unknown Error',
              `Unknown Error Logging "JSON.stringify(this.branchInfo)" ${e.message}`,
              false
            );
            this.logger.sendLogToServer(log);
          }
        }
        var resetDate = GeneralSetting.getOrderInvoiceNoResetDate();
        if (!isRefresh || !resetDate) {
          let currentDate = formatDate(Date.now(), 'MM/dd/YYYY', 'en-US');
          GeneralSetting.setOrderInvoiceNo('0');
          GeneralSetting.setOrderInvoiceNoResetDate(currentDate);
        }
        GeneralSetting.setIsDataBasePresent('True');

        // this.router.navigateByUrl('/passcode');
        return true;
      } else {
        // device not in database
        let askConfigDevicePopupRes = 'yes'

        if (askConfigDevicePopupRes == 'yes') {
          await this.askDeviceNamePopupService.open().result.then();

          let registerRes = await this.doRegisterDevice(userName, password, GeneralSetting.getDeviceName());

          return registerRes;
          // res(true);
        } else if (askConfigDevicePopupRes == 'no') {
          return false
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  private doRegisterDevice(
    userName: string,
    pass: string,
    deviceName: any
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let currentDate = formatDate(Date.now(), 'MM/dd/YYYY', 'en-US');
      if (deviceName == '') {
        deviceName = 'Samsung Kiosk';
      }
      this.dataSerive
        .registerDevice(userName, pass, GeneralSetting.getSerialNo(), '2', deviceName)
        .toPromise()
        .then(
          (data) => {

            if (data && data.statusCode && data.statusCode == 409) {
              Snackbar.show(data.body, 3000);
              resolve(false);
              return;
            }

            if (data && data.statusCode && data.statusCode != '200') {
              Snackbar.show(data.body, 3000);
              GeneralSetting.setOrderInvoiceNo('0');
              GeneralSetting.setOrderInvoiceNoResetDate(currentDate);
              resolve(false);
              return;
            }
            var value: { [key: string]: any } = {
              StaffModuleID: null,
            };

            value = data;

            GeneralSetting.setStaffModuleID(value.StaffModuleID);
            Snackbar.show('Your Device Register Successfully!!!', 2000);

            GeneralSetting.setOrderInvoiceNo('0');
            GeneralSetting.setOrderInvoiceNoResetDate(currentDate);
            resolve(true);
          },
          (error) => {
            var log = new loggingData(
              'Register Device',
              'Register Device Api failed',
              'Register Device',
              error.message,
              true
            );
            this.logger.sendLogToServer(log);

            Snackbar.show(error.error, 3000);

            GeneralSetting.setOrderInvoiceNo('0');
            GeneralSetting.setOrderInvoiceNoResetDate(currentDate);

            resolve(false);
          }
        );
    });
  }

  private onInvalidCredentials(ref: any, response: any) {
    try {
      if(ref && ref != null){
        ref.clear();
      }
    } catch (e) {}

    this.logger.sendLogToServer(
      new loggingData(
        'Branch Login',
        'Branch login Api failed',
        'Branch Login',
        JSON.stringify(response),
        true
      )
    );

    Snackbar.show("Invalid email or password…", 3000);
  }

}
