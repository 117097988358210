
import { Component } from '@angular/core';
import { OfflineOrderService } from "./services/offline-order.service";
import { RouterOutlet } from '@angular/router';
import { slideInAnimation } from './animations';
import { loggingData, LogService } from 'src/app/services/log.service';
import { environment } from 'src/environments/environment';
import { MessageType } from './models/message';
import { GeneralSetting } from "./services/generalSetting.service";
import { ChatService } from './services/chat.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [slideInAnimation],
})
export class AppComponent {
  title = 'shopping-cart';

  private offlineLoopRunning: boolean = false;

  constructor(private readonly offlineOrderService: OfflineOrderService,
    private logger: LogService,
    private chat: ChatService
  ) {

  }

  ngOnInit() {

    this.getSerialNoEvent();

    this.chat.sendMessage("startOfflineTimeOut", "start");

    this.chat.offlineTimeOut.subscribe(() => {

      if (!this.offlineLoopRunning) {
        this.setBackTimerForOfflineOrderUP();
      }
      //this.chat.sendMessage("startOfflineTimeOut","start");
    });

    //Device status update on every 2 mmins
    this.chat.sendMessage("startGettingDeviceStatus", "start");

    this.chat.deviceStatus.subscribe(() => {
      this.logger.updateDeviceStatus("1");

      /**
 * Check added for kiosk login @nilesh
 */
      if (GeneralSetting.getBranchId() != '' && GeneralSetting.getCompanyId() != '')
        this.logger.updateDeviceStatus("1");
    });

    //@Rekha: subscribe starPrinterACK to get Start printer print status
    this.chat.starPrinterACK.subscribe((data) => {
      this.logger.sendLogToServer(new loggingData(
        'Star Printer',
        'Star Printer KOT Status',
        'Star Printer',
        data,
        false
      ));
    });
    
    //this.setConsoleLog();
  }

  
  setConsoleLog() {
    if (environment.IsShowLog) {
      window.console.log = () => { };
      window.console.error = () => { };
      window.console.warn = () => { };
      window.console.info = () => { };
      window.console.debug = () => { };
      window.console.assert = () => { };
    }
  }

  setBackTimerForOfflineOrderUP() {
    this.offlineLoopRunning = false;
    const log = new loggingData(
      'Offline Order',
      'Offline Order loop called',
      `Set Interval Loop Called After 30 minutes`,
      ``,
      true
    );

    this.logger.sendLogToServer(log);
    if (!window.location.href.includes('orderReview')) {
      this.offlineOrderService.sendOfflineOrderToBackend();
    }
    else {
      this.offlineLoopRunning = true;
      setTimeout(() => {
        this.setBackTimerForOfflineOrderUP();
      }, 300000);
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation
    );
  }

  private getSerialNoEvent() {

    var listener = (event: any) => {
      if (event.data.messageType == MessageType.SerialNoReturn || event.data.messageType == MessageType.getSerialNo) {
        if (!(event.data.data == undefined ||
          event.data.data.length == 0 ||
          event.data.data.serialNo == '')
        ) {
          GeneralSetting.setSerialNo(event.data.data.serialNo);
          GeneralSetting.setMacAddress(event.data.data.macAddr);
          GeneralSetting.setIpAddress(event.data.data.ipAddr);
          GeneralSetting.setWifi(event.data.data.wifi);
        }
        else {
          GeneralSetting.setSerialNo("No Serial");
          GeneralSetting.setMacAddress("");
          GeneralSetting.setIpAddress("");
          GeneralSetting.setWifi("");
        }

        window.removeEventListener('message', listener, false);
      }
    }

    window.addEventListener('message', listener, false);

    const message = {
      messageType: MessageType.getSerialNo,
    };

    window.top!.postMessage(message, '*');
  }


  changeOfRoutes() {
    if (!environment.IsShowLog) {
      window.console.clear();
    }
  }


}