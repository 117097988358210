import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Input,
  OnDestroy,
} from '@angular/core';
import { LoadingComponent } from '../../loading/loading.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { DatabaseService } from 'src/app/services/database.service';
import {
  CategorySalesType,
  CommonFunctions,
  Snackbar,
} from 'src/app/services/common';
import { CartService } from 'src/app/services/cart.service';
import { ItemV2 } from 'src/app/models/item';
import { LoyaltyService } from 'src/app/services/loyalty.service';
import { AlcoholicItemsExceedMaxComponent } from '../dialogs/alcoholic-items-exceed-max/alcoholic-items-exceed-max.component';
import { ItemTransferService } from 'src/app/services/item-transfer.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { ScannerHybridModeServiceService } from 'src/app/services/scanner-hybrid-mode-service.service';
import { AlcoholicOver21DialogComponent } from '../dialogs/alcoholic-over21-dialog/alcoholic-over21-dialog.component';
import { OloErrorPopupComponent } from '../dialogs/olo-error-popup/olo-error-popup.component';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-search-scannable-item',
  templateUrl: './search-scannable-item.component.html',
  styleUrls: ['./search-scannable-item.component.css'],
})
export class SearchScannableItemComponent implements OnInit,OnDestroy {
  modalReference: any;

  primaryColor: string = '';
  positiveColor: string = '';
  positiveColorText: string = '';
  searchableItems: ItemV2[] = [] as ItemV2[];
  searchitem: string = '';
  textColor: string = '';
  priceColor: string = '';
  cartItems: ItemV2[] = [];

  removeAlcohol: boolean = false;
  languageSub!:Subscription
  searchScannableText:string=''
  searchResultsText:string=''
  backText:string=''
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private database: DatabaseService,
    private cartService: CartService,
    private readonly loyaltyService: LoyaltyService,
    private itemTransferService: ItemTransferService,
    private keyboardService: KeyboardService,
    private scannerService: ScannerHybridModeServiceService,
    private language:LanguageService
  ) {}
  ngOnDestroy(): void {
    if(this.languageSub){
      this.languageSub.unsubscribe()
    }
  }
  loadText(){
  this.searchScannableText=this.language.getTextElement('search_for_scannable_items')
  this.searchResultsText=this.language.getTextElement('search_results')
  this.backText=this.language.getTextElement('back')
  }
  ngOnInit(): void {
    this.languageSub=this.language.localeCommunicator.subscribe((val)=>{
      this.loadText()
    })
    this.positiveColor = GeneralSetting.getPositiveColor();
    this.positiveColorText = GeneralSetting.getPositiveColorText();
    this.primaryColor = GeneralSetting.getPrimaryColor();
    this.textColor = GeneralSetting.getTextColor();
    this.priceColor = GeneralSetting.getPriceColor();
    this.getSearchableItemList();
    this.getItems();
    this.KeyboardPopup();
  }
  // add click event on first input field @jay
  KeyboardPopup(){
    let searchitemElement: HTMLElement = document.getElementById('searchitem') as HTMLElement;
    searchitemElement.click();
  }

  loading!: LoadingComponent;
  LoadingComponent: any;
  modalRef!: NgbModalRef;

  backtomenu(): void {
    this.router.navigateByUrl('/menu');
  }

  async getSearchableItemList() {
    this.searchableItems = await this.database.getItemsByCategoryIdFromSql(
      '0',
      false,
      '0',
      false,
      '',
      0,
      1
    );
  }

  async searchThis() {
    this.searchableItems = await this.database.getItemsByCategoryIdFromSql(
      '0',
      false,
      '0',
      false,
      '',
      0,
      2,
      this.searchitem
    );
  }

  async onItemClick(item: ItemV2, event: any) {
    this.keyboardService.KeyboardStatus = false;
    if (item.ModifierCount && item.ModifierCount > 0) {
      this.searchError();
    } else {
      this.addToCart(item);
    }
  }

  private async addToCart(item: ItemV2) {
    const objCartItem = await this.scannerService.createCartItem(
      item,
      this.database
    );
    const direction = CommonFunctions.getItemType(item);

    if (objCartItem.Variations && objCartItem.Variations.length > 0) {
      this.redirectVariationItem(objCartItem, direction);
    } else {
      if (
        item.ItemCategorySalesTypeID === CategorySalesType.Alcohol.toString()
      ) {
        //path of functions to be called when item is alcoholic
        this.AlcoholicItemChecks(objCartItem, direction);
      } else {
        this.normalItemAddToCart(objCartItem);
      }
    }
  }

  private async normalItemAddToCart(item: ItemV2) {
    let res = await this.cartService.addToCartAsync(item);

    if (!res.status) {
      if (res.error == 'alcohol') {
        Snackbar.show('Exceeded maximum alcohol quantity for this item', 3000);
      } else if (res.error == 'stock') {
        Snackbar.show('Item is sold out', 3000);
      }
      return;
    }
  }

  ngOnChanges(): void {
    this.getItems();
  }

  ngAfterViewInit() {
    this.getItems();
  }

  getItems() {
    const cartItems = this.cartService.getCartItems();
    for (let ciI = 0; ciI < cartItems.length; ciI++) {
      const item = cartItems[ciI];
      item.totalPrice =
        CommonFunctions.getItemPrice(item) * Number(item.Quantity);
    }

    this.cartItems = cartItems;
    this.setSearchListHeight();
  }

  redirectVariationItem(item: ItemV2, direction: string) {
    this.itemTransferService.snapshotItem(item);

    if (item.ItemCategorySalesTypeID === CategorySalesType.Alcohol.toString()) {
      //path of functions to be called when item is alcoholic
      this.AlcoholicItemChecks(item, direction);
    } else if (direction == 'menu') {
      // Only Normal Item is added directly to cart
      this.normalItemAddToCart(item);

      this.cartService.doScrollCart.next(true);

      this.navigateToItemPage(item, direction);
    } else if (direction != 'itemDescription') {
      this.navigateToItemPage(item, direction);
    } else if (direction == 'itemDescription') {
      this.navigateToItemPage(item, direction);
    }
  }

  private navigateToItemPage(item: ItemV2, direction: string) {
    this.itemTransferService.snapshotItemV2(item);
    this.router.navigate([`/${direction}`], {
      queryParams: {
        id: item.ItemID,
        category: item.CategoryID,
        isScannedItem: true,
      },
    });
  }

  clearSearch() {
    this.keyboardService.KeyboardStatus = false;
    this.searchitem = '';
    this.searchThis();
  }

  /**
   * }
   * @implements hard-coded age of majority, to be changed in future versions
   * @description checks if the user has exceeded current amount of alcoholic items allowed per order, and checks user age
   * @returns {Promise<string>} string data detailing whether user age is over/under legal drinking age. Currently hard-coded 21, soon to be localized
   */
  private AlcoholicItemChecks(item: ItemV2, direction: string) {
    let max: number = Number(
      GeneralSetting.getMaxAlcoholicItemsPerOrder() || 0
    );
    if (max > 0) {
      const current: number = Number(GeneralSetting.getCurrentCountofAlkol());
      if (current === max) {
        AlcoholicItemsExceedMaxComponent.open(this.modalService);
      } else {
        AlcoholicOver21DialogComponent.open(this.modalService).result.then(
          (data: any) => {
            if (data === 'over') {
              if (direction == 'menu') {
                this.normalItemAddToCart(item);
              } else {
                this.navigateToItemPage(item, direction);
              }
            } else {
              this.removeAlcohol = true;
            }
          }
        );
      }
    }
  }

  setSearchListHeight(): Object {
    const cartItems = this.cartService.getCartItems();
    if (cartItems.length > 0) {
      return {
        height: '53vh',
      };
    }
    return { height: '67vh' };
  }

  private searchError() {
    this.scannerService.stopBarcodeScanner();
    OloErrorPopupComponent.open(
      this.modalService,
      'Item not recognized, please try again or you can contact store manager for assistance. ',
      '',
      'OK'
    );
  }
}
