import { RemoteInstallACKNotification } from "./api/Models";



export class GeneralSetting {
  // static getPriceColor(): any {
  //   throw new Error('Method not implemented.');
  // }
  static setLocale(Locale: any) {
    localStorage.setItem('Locale', Locale);
  }
  static getLocale() {
    const Locale = localStorage.getItem('Locale');
    if (Locale == undefined || Locale == null) {
      return '';
    }
    return Locale.toString();
  }
  static setLoyaltyLoginWithPhone(loyaltyLoginWithPhone: any) {
    localStorage.setItem('loyaltyLoginWithPhone', loyaltyLoginWithPhone);
  }
  static getLoyaltyLoginWithPhone() {
    const loyaltyLoginWithPhone = localStorage.getItem('loyaltyLoginWithPhone')
    if (loyaltyLoginWithPhone == undefined || loyaltyLoginWithPhone == null) {
      return '';
    }
    return loyaltyLoginWithPhone.toString();
  }
  static setStoreID(storeID: any) {
    sessionStorage.setItem('storeID', storeID);
  }
  static getStoreID() {
    const storeID = sessionStorage.getItem('storeID');
    if (storeID == undefined || storeID == null) {
      return '';
    }
    return storeID.toString();
  }

  static setSurveyLink(surveyLink: any) {
    localStorage.setItem('surveyLink', surveyLink);
  }
  static getSurveyLink() {
    const surveyLink = localStorage.getItem('surveyLink');
    if (surveyLink == undefined || surveyLink == null) {
      return '';
    }
    return surveyLink.toString();
  }

  static setAgeOfMajority(ageOfMajority: any) {
    localStorage.setItem('ageOfMajority', ageOfMajority);
  }

  static getAgeOfMajority() {
    const ageOfMajority = localStorage.getItem('ageOfMajority');

    if (ageOfMajority == undefined || ageOfMajority == null) {
      return '21';
    }

    return ageOfMajority.toString();
  }

  static setShowSurveyLinkOnReceipt(ShowSurveyLinkOnReceipt: any) {
    sessionStorage.setItem('ShowSurveyLinkOnReceipt', ShowSurveyLinkOnReceipt);
  }
  static getShowSurveyLinkOnReceipt() {
    const ShowSurveyLinkOnReceipt = sessionStorage.getItem(
      'ShowSurveyLinkOnReceipt'
    );
    if (ShowSurveyLinkOnReceipt == undefined || ShowSurveyLinkOnReceipt == null) {
      return '';
    }
    return ShowSurveyLinkOnReceipt.toString();
  }

  static setBackGroundImage(BackGroundImage: any) {
    localStorage.setItem('BackGroundImage', BackGroundImage);
  }
  static getBackGroundImage() {
    const BackGroundImage = localStorage.getItem('BackGroundImage');
    if (BackGroundImage == undefined || BackGroundImage == null) {
      return '';
    }
    return BackGroundImage.toString();
  }
  static setTPLoyaltyDiscountName(TPLoyaltyDiscountName: any) {
    localStorage.setItem('TPLoyaltyDiscountName', TPLoyaltyDiscountName);
  }
  static getTPLoyaltyDiscountName() {
    const TPLoyaltyDiscountName = localStorage.getItem('TPLoyaltyDiscountName');
    if (TPLoyaltyDiscountName == undefined || TPLoyaltyDiscountName == null) {
      return '';
    }
    return TPLoyaltyDiscountName.toString();
  }
  static setTPLoyaltyPayment(TPLoyaltyPayment: any) {
    localStorage.setItem('TPLoyaltyPayment', TPLoyaltyPayment);
  }
  static getTPLoyaltyPayment() {
    const TPLoyaltyPayment = localStorage.getItem('TPLoyaltyPayment');
    if (TPLoyaltyPayment == undefined || TPLoyaltyPayment == null) {
      return '';
    }
    return TPLoyaltyPayment.toString();
  }
  static setTPLoyaltyDiscountID(TPLoyaltyDiscountID: any) {
    localStorage.setItem('TPLoyaltyDiscountID', TPLoyaltyDiscountID);
  }
  static getTPLoyaltyDiscountID() {
    const TPLoyaltyDiscountID = localStorage.getItem('TPLoyaltyDiscountID');
    if (TPLoyaltyDiscountID == undefined || TPLoyaltyDiscountID == null) {
      return '';
    }
    return TPLoyaltyDiscountID.toString();
  }
  static setUserHasPaid(userHasPaid: any) {
    sessionStorage.setItem('UserHasPaid', userHasPaid);
  }

  static getUserHasPaid() {
    const userHasPaid = sessionStorage.getItem('UserHasPaid');
    if (userHasPaid == undefined || userHasPaid == null) {
      return '';
    }
    return userHasPaid.toString();
  }
  static setLevelUpLocationId(levelUpLocationId: any) {
    localStorage.setItem('LevelUpLocationId', levelUpLocationId);
  }

  static getLevelUpLocationId() {
    const levelUpLocationId = localStorage.getItem('LevelUpLocationId');
    if (levelUpLocationId == undefined || levelUpLocationId == null) {
      return '';
    }
    return levelUpLocationId.toString();
  }
  static setCountry(country: any) {
    localStorage.setItem('Country', country);
  }

  static getCountry() {
    const country = localStorage.getItem('Country');
    if (country == undefined || country == null) {
      return '';
    }
    return country.toString();
  }
  static setFriendlyId(friendlyId: any) {
    sessionStorage.setItem('friendlyId', friendlyId);
  }

  static getFriendlyId() {
    const friendlyId = sessionStorage.getItem('friendlyId');
    if (friendlyId == undefined || friendlyId == null) {
      return '';
    }
    return friendlyId.toString();
  }
  static setIntegrationId(integrationId: any) {
    localStorage.setItem('integrationId', integrationId);
  }

  static getIntegrationId(): any {
    const integrationId = localStorage.getItem('integrationId');
    if (integrationId == undefined || integrationId == null) {
      return '';
    }
    return integrationId.toString();
  }
  static setCollapseOptionalModifiersGroups(
    CollapseOptionalModifiersGroups: any
  ) {
    localStorage.setItem(
      'CollapseOptionalModifiersGroups',
      CollapseOptionalModifiersGroups
    );
  }

  static getCollapseOptionalModifiersGroups() {
    const CollapseOptionalModifiersGroups = localStorage.getItem(
      'CollapseOptionalModifiersGroups'
    );

    if (
      CollapseOptionalModifiersGroups == undefined ||
      CollapseOptionalModifiersGroups == null
    )
      return '2';

    return CollapseOptionalModifiersGroups.toString();
  }

  // STAR Printer SP700 Integration
  static setSTARPrinterSP700KitchenName(kitchenName: string) {
    localStorage.setItem('starPrinterSP700KitchenName', kitchenName);
  }

  static getSTARPrinterSP700KitchenName() {
    const starPrinterSP700KitchenName = localStorage.getItem('starPrinterSP700KitchenName');
    if (starPrinterSP700KitchenName == undefined || starPrinterSP700KitchenName == null) {
      return '';
    }

    return starPrinterSP700KitchenName.toString();
  }

  // DAVE - STAR PRINTER CHANGES
  static setSTARPrinterSP700IPAddress(starPrinterSP700IPAddress: string) {
    localStorage.setItem('starPrinterSP700IPAddress', starPrinterSP700IPAddress);
  }

  static getSTARPrinterSP700IPAddress() {
    const starPrinterSP700IPAddress = localStorage.getItem('starPrinterSP700IPAddress');
    if (starPrinterSP700IPAddress == undefined || starPrinterSP700IPAddress == null) {
      return '';
    }

    return starPrinterSP700IPAddress.toString();
  }

  // Verifone 3C Integration
  // prettier-ignore
  static setEMVTerminalID(emvTerminalID: any) {
    localStorage.setItem('emvTerminalID', emvTerminalID)
  }

  // prettier-ignore
  static getEMVTerminalID() {
    const emvTerminalID = localStorage.getItem('emvTerminalID')
    if (emvTerminalID == undefined || emvTerminalID == null) {
      return ''
    }
    return emvTerminalID
  }

  // prettier-ignore
  static setRequesterHardwareID(requesterHardwareID: any) {
    localStorage.setItem('requesterHardwareID', requesterHardwareID)
  }

  // prettier-ignore
  static getRequesterHardwareID() {
    const requesterHardwareID = localStorage.getItem('requesterHardwareID')
    if (requesterHardwareID == undefined || requesterHardwareID == null) {
      return ''
    }
    return requesterHardwareID
  }

  // Clover Integration
  static setCloverEnvironment(environment: any) {
    localStorage.setItem('cloverEnvironment', environment);
  }

  static getCloverEnvironment() {
    const cloverEnvironment = localStorage.getItem('cloverEnvironment');
    if (cloverEnvironment == undefined || cloverEnvironment == null) {
      return '';
    }
    return cloverEnvironment.toString();
  }

  static setAccessToken(accessToken: any) {
    localStorage.setItem('accessToken', accessToken);
  }

  static getAccessToken() {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken == undefined || accessToken == null) {
      return '';
    }
    return accessToken.toString();
  }

  static setMerchantId(merchantId: any) {
    localStorage.setItem('merchantId', merchantId);
  }

  static getMerchantId() {
    const merchantId = localStorage.getItem('merchantId');
    if (merchantId == undefined || merchantId == null) {
      return '';
    }
    return merchantId.toString();
  }

  static setDeviceHSN(deviceId: any) {
    localStorage.setItem('deviceHSN', deviceId);
  }

  static getDeviceHSN() {
    const deviceId = localStorage.getItem('deviceHSN');
    if (deviceId == undefined || deviceId == null) {
      return '';
    }
    return deviceId.toString();
  }

  static setApplicationId(applicationId: any) {
    localStorage.setItem('applicationId', applicationId);
  }

  static getApplicationId() {
    const applicationId = localStorage.getItem('applicationId');
    if (applicationId == undefined || applicationId == null) {
      return '';
    }
    return applicationId.toString();
  }

  static setCloverBaseURL(baseURL: any) {
    localStorage.setItem('baseURL', baseURL);
  }

  static getCloverBaseURL() {
    const baseURL = localStorage.getItem('baseURL');
    if (baseURL == undefined || baseURL == null) {
      return '';
    }
    return baseURL.toString();
  }

  static setCloverIntegrationId(cloverIntegrationId: any) {
    localStorage.setItem('cloverIntegrationId', cloverIntegrationId);
  }

  static getCloverIntegrationId() {
    const cloverIntegrationId = localStorage.getItem('cloverIntegrationId');
    if (cloverIntegrationId == undefined || cloverIntegrationId == null) {
      return '';
    }
    return cloverIntegrationId.toString();
  }

  //Otter Intgrations
  static setOtterIntegrationId(otterIntegrationId: any) {
    localStorage.setItem('otterIntegrationId', otterIntegrationId);
  }

  static getOtterIntegrationId() {
    const otterIntegrationId = localStorage.getItem('otterIntegrationId');
    if (otterIntegrationId == undefined || otterIntegrationId == null) {
      return '';
    }
    return otterIntegrationId.toString();
  }

  static getIsOtterIntegration() {

    if (GeneralSetting.getOtterIntegrationId() &&
      GeneralSetting.getOtterIntegrationId() != null &&
      GeneralSetting.getOtterIntegrationId() != '') {
      return true;
    }

    return false;
  }

  //Staffmaster
  static setStaffLocationID(staffLocationID: any) {
    sessionStorage.setItem('staffLocationID', staffLocationID);
  }

  static getstaffLocationID() {
    const staffLocationID = sessionStorage.getItem('staffLocationID');
    if (staffLocationID == undefined || staffLocationID == null) {
      return '';
    }
    return staffLocationID.toString();
  }


  //Theme Getter and setter
  static setPrimaryColor(color: any) {
    sessionStorage.setItem('primaryColor', color);
  }

  static setSecondaryColor(color: any) {
    sessionStorage.setItem('secondaryColor', color);
  }

  static setPriceColor(color: any) {
    sessionStorage.setItem('priceColor', color);
  }

  static setTextColor(color: any) {
    sessionStorage.setItem('textColor', color);
  }

  static setPositiveColor(color: any) {
    sessionStorage.setItem('positiveColor', color);
  }

  static setPositiveColorText(color: any) {
    sessionStorage.setItem('positiveColorText', color);
  }

  static setNegativeColor(color: any) {
    sessionStorage.setItem('negativeColor', color);
  }

  static setNegativeColorText(color: any) {
    sessionStorage.setItem('negativeColorText', color);
  }

  static setCategoryColor(color: any) {
    sessionStorage.setItem('categoryColor', color);
  }

  static setFooterBackground(color: any) {
    sessionStorage.setItem('footerBackground', color);
  }

  static setAccentColor(color: any) {
    sessionStorage.setItem('accentColor', color);
  }

  static setKioskBackgroundImage(url: any) {
    sessionStorage.setItem('imagePath', url);
  }

  static setCategoryBackgroundColor(color: any) {
    sessionStorage.setItem('CategoryBackgroundColor', color);
  }

  static setFooterOrderSummaryBackgroundColor(color: any) {
    sessionStorage.setItem('footerOrderSummaryBackgroundColor', color);
  }

  static set__price_color_CSS() {
    document.documentElement.style.setProperty(
      '--price-color',
      this.getPriceColor()
    );
  }

  static set__accent_color_CSS() {
    document.documentElement.style.setProperty(
      '--accent-color',
      this.getAccentColor()
    );
  }

  static set__bold_text_color_CSS() {
    document.documentElement.style.setProperty(
      '--bold-text-color',
      sessionStorage.getItem('boldTextColor')
    );
  }

  static set__border_color_CSS() {
    document.documentElement.style.setProperty(
      '--border-color',
      sessionStorage.getItem('borderColor')
    );
  }

  static set__category_background_color_CSS() {
    document.documentElement.style.setProperty(
      '--category-background-color',
      this.getCategoryBackgroundColor()
    );
  }

  static set__category_sidebar_background_color_CSS() {
    document.documentElement.style.setProperty(
      '--category-sidebar-background-color',
      this.getCategoryColor()
    );
  }

  static set__footer_order_summary_background_color_CSS() {
    document.documentElement.style.setProperty(
      '--footer-order-summary-background-color',
      this.getFooterBackground()
    );
  }

  static set__negative_button_color_CSS() {
    document.documentElement.style.setProperty(
      '--negative-button-color',
      this.getNegativeColor()
    );
  }

  static set__negative_button_text_color_CSS() {
    document.documentElement.style.setProperty(
      '--negative-button-text-color',
      this.getNegativeColorText()
    );
  }

  static set__positive_button_color_CSS() {
    document.documentElement.style.setProperty(
      '--positive-button-color',
      this.getPositiveColor()
    );
  }

  static set__positive_button_text_color_CSS() {
    document.documentElement.style.setProperty(
      '--positive-button-text-color',
      this.getPositiveColorText()
    );
  }

  static set__primary_color__CSS() {
    document.documentElement.style.setProperty(
      '--primary-color',
      this.getPrimaryColor()
    );
  }

  static set__secondary_color_CSS() {
    document.documentElement.style.setProperty(
      '--secondary-color',
      this.getSecondaryColor()
    );
  }

  static set__text_color_CSS() {
    document.documentElement.style.setProperty(
      '--text-color',
      this.getTextColor()
    );
  }

  static getPriceColor() {
    const color = sessionStorage.getItem('priceColor');

    if (color == undefined || color == null) return '';

    return color.toString() ?? '';
  }

  static getAccentColor() {
    const color = sessionStorage.getItem('accentColor');

    if (color == undefined || color == null) return '';

    return color.toString() ?? '';
  }

  static getKioskBackgroundImage() {
    const url = sessionStorage.getItem('imagePath');

    if (url == undefined || url == null) return '';

    return url.toString() ?? '';
  }

  static getPrimaryColor() {
    const color = sessionStorage.getItem('primaryColor');

    if (color == undefined || color == null) return '';

    return color.toString() ?? '';
  }

  static getSecondaryColor() {
    const color = sessionStorage.getItem('secondaryColor');

    if (color == undefined || color == null) return '';

    return color.toString() ?? '';
  }

  static getTextColor() {
    const color = sessionStorage.getItem('textColor');

    if (color == undefined || color == null) return '';

    return color.toString() ?? '';
  }

  static getPositiveColor() {
    const color = sessionStorage.getItem('positiveColor');

    if (color == undefined || color == null) return '';

    return color.toString() ?? '';
  }

  static getPositiveColorText() {
    const color = sessionStorage.getItem('positiveColorText');

    if (color == undefined || color == null) return '';

    return color.toString() ?? '';
  }

  static getNegativeColor() {
    const color = sessionStorage.getItem('negativeColor');

    if (color == undefined || color == null) return '';

    return color.toString() ?? '';
  }

  static getNegativeColorText() {
    const color = sessionStorage.getItem('negativeColorText');

    if (color == undefined || color == null) return '';

    return color.toString() ?? '';
  }

  static getCategoryColor() {
    const color = sessionStorage.getItem('categoryColor');

    if (color == undefined || color == null) return '';

    return color.toString() ?? '';
  }

  static getFooterBackground() {
    const color = sessionStorage.getItem('footerBackground');

    if (color == undefined || color == null) return '';

    return color.toString() ?? '';
  }

  static getCategoryBackgroundColor() {
    const color = sessionStorage.getItem('CategoryBackgroundColor');

    if (color == undefined || color == null) return '';

    return color.toString() ?? '';
  }

  static getFooterOrderSummaryBackgroundColor() {
    const color = sessionStorage.getItem('footerOrderSummaryBackgroundColor');

    if (color == undefined || color == null) return '';

    return color.toString() ?? '';
  }

  // Theme Getter And Setter End

  //branch Data Getter And Setter

  static setBranchName(branchName: any) {
    sessionStorage.setItem('branchName', branchName);
  }

  static setBranchLogo(branchLogo: any) {
    sessionStorage.setItem('branchLogo', branchLogo);
  }

  static setCustomerInfoImage(customerInfoImage: any) {
    sessionStorage.setItem('customerInfoImage', customerInfoImage);
  }

  static setCancelOrderImage(cancelOrderImage: any) {
    sessionStorage.setItem('cancelOrderImage', cancelOrderImage);
  }

  static setBranchAddress1(branchAddress1: any) {
    sessionStorage.setItem('branchAddress1', branchAddress1);
  }

  static setBranchAddress2(branchAddress2: any) {
    sessionStorage.setItem('branchAddress2', branchAddress2);
  }

  static setBranchContactNo(branchContactNo: any) {
    sessionStorage.setItem('branchContactNo', branchContactNo);
  }

  static setBranchTagLine(branchTagLine: any) {
    sessionStorage.setItem('branchTagline', branchTagLine);
  }

  static setBranchId(branchId: any) {
    localStorage.setItem('branchId', branchId);
  }

  static setCompanyId(companyId: any) {
    localStorage.setItem('companyId', companyId);
  }

  static getBranchName() {
    const branchName = sessionStorage.getItem('branchName');

    if (branchName == undefined || branchName == null) return '';

    return branchName.toString() ?? '';
  }

  static getBranchLogo() {
    const branchLogo = sessionStorage.getItem('branchLogo');

    if (branchLogo == undefined || branchLogo == null) return '';

    return branchLogo.toString() ?? '';
  }

  static getCustomerInfoImage() {
    const customerInfoImage = sessionStorage.getItem('customerInfoImage');

    if (customerInfoImage == undefined || customerInfoImage == null) return '';

    return customerInfoImage.toString() ?? '';
  }

  static getCancelOrderImage() {
    const cancelOrderImage = sessionStorage.getItem('cancelOrderImage');

    if (cancelOrderImage == undefined || cancelOrderImage == null) return '';

    return cancelOrderImage.toString() ?? '';
  }

  static setBorderPercentage(borderPercentage: any) {
    sessionStorage.setItem('borderPercentage', borderPercentage);
  }

  static getBorderPercentage() {
    const borderPercentage = sessionStorage.getItem('borderPercentage');

    if (borderPercentage == undefined || borderPercentage == null) return '0%';

    return (borderPercentage.toString() ?? '0') + '%';
  }

  static setBorderTypeSelection(borderTypeSelection: any) {
    sessionStorage.setItem('borderTypeSelection', borderTypeSelection);
  }

  static getBorderTypeSelection() {
    const borderTypeSelection = sessionStorage.getItem('borderTypeSelection');

    if (borderTypeSelection == undefined || borderTypeSelection == null)
      return '';

    return borderTypeSelection.toString() ?? '';
  }

  static setBorderTypeCategories(borderTypeCategories: any) {
    sessionStorage.setItem('borderTypeCategories', borderTypeCategories);
  }

  static getBorderTypeCategories() {
    const borderTypeCategories = sessionStorage.getItem('borderTypeCategories');

    if (borderTypeCategories == undefined || borderTypeCategories == null)
      return 'false';

    return borderTypeCategories.toString() ?? 'false';
  }

  static setBorderTypeItems(borderTypeItems: any) {
    sessionStorage.setItem('borderTypeItems', borderTypeItems);
  }

  static getBorderTypeItems() {
    const borderTypeItems = sessionStorage.getItem('borderTypeItems');

    if (borderTypeItems == undefined || borderTypeItems == null) return 'false';

    return borderTypeItems.toString() ?? 'false';
  }

  static setBorderTypeVariations(borderTypeVariations: any) {
    sessionStorage.setItem('borderTypeVariations', borderTypeVariations);
  }

  static getBorderTypeVariations() {
    const borderTypeVariations = sessionStorage.getItem('borderTypeVariations');

    if (borderTypeVariations == undefined || borderTypeVariations == null)
      return 'false';

    return borderTypeVariations.toString() ?? 'false';
  }

  static setBorderTypeModifiers(borderTypeModifiers: any) {
    sessionStorage.setItem('borderTypeModifiers', borderTypeModifiers);
  }

  static getBorderTypeModifiers() {
    const borderTypeModifiers = sessionStorage.getItem('borderTypeModifiers');

    if (borderTypeModifiers == undefined || borderTypeModifiers == null)
      return 'false';

    return borderTypeModifiers.toString() ?? 'false';
  }

  static getBranchAddress1() {
    const branchAddress1 = sessionStorage.getItem('branchAddress1');

    if (branchAddress1 == undefined || branchAddress1 == null) return '';

    return branchAddress1.toString() ?? '';
  }

  static getBranchAddress2() {
    const branchAddress2 = sessionStorage.getItem('branchAddress2');

    if (branchAddress2 == undefined || branchAddress2 == null) return '';

    return branchAddress2.toString() ?? '';
  }

  static getBranchContactNo() {
    const branchContactNo = sessionStorage.getItem('branchContactNo');

    if (branchContactNo == undefined || branchContactNo == null) return '';

    return branchContactNo.toString() ?? '';
  }

  static getBranchTagLine() {
    const branchTagLine = sessionStorage.getItem('branchTagline');

    if (branchTagLine == undefined || branchTagLine == null) return '';

    return branchTagLine.toString() ?? '';
  }

  static getBranchId() {
    const branchId = localStorage.getItem('branchId');

    if (branchId == undefined || branchId == null) return '';

    return branchId.toString() ?? '0';
  }

  static getCompanyId() {
    const companyId = localStorage.getItem('companyId');

    if (companyId == undefined || companyId == null) return '';

    return companyId.toString() ?? '0';
  }

  //branch Data Getter And Setter End

  //general settings Getter And Setter

  static setShowLoyaltyLogin(showLoyaltyLogin: any) {
    sessionStorage.setItem('showLoyaltyLogin', showLoyaltyLogin);
  }

  static getShowLoyaltyLogin() {
    const showLoyaltyLogin = sessionStorage.getItem('showLoyaltyLogin');

    if (showLoyaltyLogin == undefined || showLoyaltyLogin == null) return '';

    return showLoyaltyLogin.toString() ?? '';
  }

  static setIsConcessionaire(IsConcessionaire: any) {
    localStorage.setItem('IsConcessionaire', IsConcessionaire);
  }

  static getIsConcessionaire() {
    const IsConcessionaire = localStorage.getItem('IsConcessionaire');

    if (IsConcessionaire == undefined || IsConcessionaire == null) return '';

    return IsConcessionaire.toString() ?? '';
  }

  static setCheckoutScreenTimeoutKIOSK(checkoutScreenTimeoutKiosk: any) {
    sessionStorage.setItem(
      'CheckoutScreenTimeoutKIOSK',
      checkoutScreenTimeoutKiosk
    );
  }

  static getCheckoutScreenTimeoutKIOSK() {
    const CheckoutScreenTimeoutKIOSK = sessionStorage.getItem(
      'CheckoutScreenTimeoutKIOSK'
    );

    if (
      CheckoutScreenTimeoutKIOSK == undefined ||
      CheckoutScreenTimeoutKIOSK == null
    )
      return '';

    return CheckoutScreenTimeoutKIOSK.toString() ?? '';
  }

  static setGeneralTimeoutKIOSK(GeneralTimeoutKIOSK: any) {
    sessionStorage.setItem('GeneralTimeoutKIOSK', GeneralTimeoutKIOSK);
  }

  static getGeneralTimeoutKIOSK() {
    const GeneralTimeoutKIOSK = sessionStorage.getItem('GeneralTimeoutKIOSK');

    if (GeneralTimeoutKIOSK == undefined || GeneralTimeoutKIOSK == null)
      return '';

    return GeneralTimeoutKIOSK.toString() ?? '';
  }

  static setThankYouMessage(thankYouMessage: any) {
    sessionStorage.setItem('thankYouMessage', thankYouMessage);
  }

  static getThankYouMessage() {
    const thankYouMessage = sessionStorage.getItem('thankYouMessage');

    if (thankYouMessage == undefined || thankYouMessage == null) return '';

    return thankYouMessage.toString() ?? '';
  }

  static setTipHeaderText(tipHeaderText: any) {
    sessionStorage.setItem('tipHeaderText', tipHeaderText);
  }

  static getTipHeaderText() {
    const tipHeaderText = sessionStorage.getItem('tipHeaderText');

    if (tipHeaderText == undefined || tipHeaderText == null) return '';

    return tipHeaderText.toString() ?? '';
  }

  static setTipDescription(tipDescription: any) {
    sessionStorage.setItem('tipDescription', tipDescription);
  }

  static getTipDescription() {
    const tipDescription = sessionStorage.getItem('tipDescription');

    if (tipDescription == undefined || tipDescription == null) return '';

    return tipDescription.toString() ?? '';
  }

  static setUpSellHeaderText(data: any) {
    sessionStorage.setItem('upsellHeaderText', data);
  }

  static getUpSellHeaderText() {
    const data = sessionStorage.getItem('upsellHeaderText');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setUpSellDescription(data: any) {
    sessionStorage.setItem('upsellDescription', data);
  }

  static getUpSellDescription() {
    const data = sessionStorage.getItem('upsellDescription');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setShowTipDialog(data: any) {
    sessionStorage.setItem('showTipDialog', data);
  }

  static getShowTipDialog() {
    const data = sessionStorage.getItem('showTipDialog');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setShowCalorieCounts(data: any) {
    localStorage.setItem('showCalorieCounts', data);
  }

  static getShowCalorieCounts() {
    const data = localStorage.getItem('showCalorieCounts');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setDisableSpecialRequest(data: any) {
    sessionStorage.setItem('disableSpecialRequest', data);
  }

  static getDisableSpecialRequest() {
    const data = sessionStorage.getItem('disableSpecialRequest');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setCheckForDiscount(data: any) {
    sessionStorage.setItem('checkForDiscount', data);
  }

  // static getCheckForDiscount() {
  //   const data = sessionStorage.getItem("checkForDiscount");

  //   if (data == undefined || data == null) return "";

  //   return data.toString() ?? "";
  // }

  static setHideEmailReceiptButton(data: any) {
    sessionStorage.setItem('hideEmailReceiptButton', data);
  }

  static getHideEmailReceiptButton() {
    const data = sessionStorage.getItem('hideEmailReceiptButton');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setHomeScreenMsg(data: any) {
    if (!data.toString().replace(/\s/g, '').length) {
      sessionStorage.setItem('homeScreenMsg', '');
    } else {
      sessionStorage.setItem('homeScreenMsg', data.toString());
    }
  }

  static getHomeScreenMsg() {
    const data = sessionStorage.getItem('homeScreenMsg');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setWindowHeaderText(data: any) {
    sessionStorage.setItem('windowHeaderText', data);
  }

  static getWindowHeaderText() {
    const data = sessionStorage.getItem('windowHeaderText');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setWindowText(data: any) {
    sessionStorage.setItem('windowText', data);
  }

  static getWindowText() {
    const data = sessionStorage.getItem('windowText');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setWindowTitle(data: any) {
    sessionStorage.setItem('windowTitle', data);
  }

  static setTentImage(data: any) {

    sessionStorage.setItem('tentImage', data);
  }

  static getTentImage(): string {
    const data = sessionStorage.getItem('tentImage');
    if (data == undefined || data == null) return '';
    return data.toString() ?? '';
  }

  static getWindowTitle() {
    const data = sessionStorage.getItem('windowTitle');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setTentNumberText(data: any) {
    sessionStorage.setItem('tentNumberText', data);
  }

  static getTentNumberText() {
    const data = sessionStorage.getItem('tentNumberText');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setKOTFooterMessage(data: any) {
    sessionStorage.setItem('KOTFooterMessage', data);
  }

  static getKOTFooterMessage() {
    const data = sessionStorage.getItem('KOTFooterMessage');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setEnableTableTents(data: any) {
    sessionStorage.setItem('enableTableTents', data);
  }

  static getEnableTableTents() {
    const data = sessionStorage.getItem('enableTableTents');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setIsTransparentDineIn(data: any) {
    sessionStorage.setItem('IsTransparentDineIn', data);
  }

  static getIsTransparentDineIn() {
    const data = sessionStorage.getItem('IsTransparentDineIn');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setTransparentButtonColor(data: any) {
    sessionStorage.setItem('TransparentButtonColor', data);
  }

  static getTransparentButtonColor() {
    const data = sessionStorage.getItem('TransparentButtonColor');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setShowNegativeModifiers(data: any) {
    sessionStorage.setItem('ShowNegativeModifiers', data);
  }

  static getShowNegativeModifiers() {
    const data = sessionStorage.getItem('ShowNegativeModifiers');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setShowDefaultModifiers(data: any) {
    sessionStorage.setItem('ShowDefaultModifiers', data);
  }

  static getShowDefaultModifiers() {
    const data = sessionStorage.getItem('ShowDefaultModifiers');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setIsPrintTokenAndCustomerReceiptTogether(data: any) {
    sessionStorage.setItem('tokenTogether', data);
  }

  static getIsPrintTokenAndCustomerReceiptTogether() {
    const data = sessionStorage.getItem('tokenTogether');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setReturningCustomersFeatures(data: any) {
    localStorage.setItem('returningCustomersFeatures', data);
  }

  static getReturningCustomersFeatures() {
    const data = localStorage.getItem('returningCustomersFeatures');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setDisableScreenSaver(data: any) {
    sessionStorage.setItem('disableScreenSaver', data);
  }

  static getDisableScreenSaver() {
    const data = sessionStorage.getItem('disableScreenSaver');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }
  static setHideStartsAtForVariablePrice(data: any) {
    sessionStorage.setItem('hideStartsAtForVariablePrice', data);
  }

  static getHideStartsAtForVariablePrice() {
    const data = sessionStorage.getItem('hideStartsAtForVariablePrice');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setHideItemTaxPrice(data: any) {
    sessionStorage.setItem('hideItemTaxPrice', data);
  }

  static getHideItemTaxPrice() {
    const data = sessionStorage.getItem('hideItemTaxPrice');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setStoreClosedTimeMessage(data: any) {
    sessionStorage.setItem('storeClosedTimeMessage', data);
  }

  static getStoreClosedTimeMessage() {
    const data = sessionStorage.getItem('storeClosedTimeMessage');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setSeparateHomeScreenImagePath(data: any) {
    sessionStorage.setItem('separateHomeScreenImagePath', data);
  }

  static getSeparateHomeScreenImagePath() {
    const data = sessionStorage.getItem('separateHomeScreenImagePath');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setUseSeparateImageForHomeScreen(data: any) {
    sessionStorage.setItem('useSeparateImageforHomeScreen', data);
  }

  static getUseSeparateImageForHomeScreen() {
    const data = sessionStorage.getItem('useSeparateImageforHomeScreen');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setUpChargeCardKIOSKAmount(data: any) {
    sessionStorage.setItem('UpchargeCardKIOSKAmount', data);
  }

  static getUpChargeCardKIOSKAmount() {
    const data = sessionStorage.getItem('UpchargeCardKIOSKAmount');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setUsePercentageCardKIOSK(data: any) {
    sessionStorage.setItem('UsePercentageCardKIOSK', data);
  }

  static getUsePercentageCardKIOSK() {
    const data = sessionStorage.getItem('UsePercentageCardKIOSK');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setIsmDoNotAllowAddToCartKiosk(data: any) {
    sessionStorage.setItem('IsmDoNotAllowAddtoCartKiosk', data);
  }

  static getIsmDoNotAllowAddToCartKiosk() {
    const data = sessionStorage.getItem('IsmDoNotAllowAddtoCartKiosk');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setWelcomeLoyaltyLogoPath(data: any) {
    localStorage.setItem('WelcomeLoyaltyLogoPath', data);
  }

  static getWelcomeLoyaltyLogoPath() {
    const data = localStorage.getItem('WelcomeLoyaltyLogoPath');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setShowWelcomeLoyaltyLogo(data: any) {
    localStorage.setItem('ShowWelcomeLoyaltyLogo', data);
  }

  static getShowWelcomeLoyaltyLogo() {
    const data = localStorage.getItem('ShowWelcomeLoyaltyLogo');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setWelcomeFooterText(data: any) {
    sessionStorage.setItem('WelcomeFooterText', data);
  }

  static getWelcomeFooterText() {
    const data = sessionStorage.getItem('WelcomeFooterText');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setIsShowSplashScreenMessage(data: any) {
    sessionStorage.setItem('IsShowSplashScreenMessage', data);
  }

  static getIsShowSplashScreenMessage() {
    const data = sessionStorage.getItem('IsShowSplashScreenMessage');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setSplashScreenMessage(data: any) {
    sessionStorage.setItem('SplashScreenMessage', data);
  }

  static getSplashScreenMessage() {
    const data = sessionStorage.getItem('SplashScreenMessage');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setIsShowHomeScreenLogo(data: any) {
    sessionStorage.setItem('IsShowHomeScreenLogo', data);
  }

  static getIsShowHomeScreenLogo() {
    const data = sessionStorage.getItem('IsShowHomeScreenLogo');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setHeaderTextForCustomerInKiosk(data: any) {
    sessionStorage.setItem('HeadertextForCustomerInKiosk', data);
  }

  static getHeaderTextForCustomerInKiosk() {
    const data = sessionStorage.getItem('HeadertextForCustomerInKiosk');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setFooterTextForCustomerInKiosk(data: any) {
    sessionStorage.setItem('FootertextForCustomerInKiosk', data);
  }

  static getFooterTextForCustomerInKiosk() {
    const data = sessionStorage.getItem('FootertextForCustomerInKiosk');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setHeaderTextForMerchantInKiosk(data: any) {
    sessionStorage.setItem('HeadertextForMerchantInKiosk', data);
  }

  static getHeaderTextForMerchantInKiosk() {
    const data = sessionStorage.getItem('HeadertextForMerchantInKiosk');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setFooterTextForMerchantInKiosk(data: any) {
    sessionStorage.setItem('FootertextForMerchantInKiosk', data);
  }

  static getFooterTextForMerchantInKiosk() {
    const data = sessionStorage.getItem('FootertextForMerchantInKiosk');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setCurrencyPrefix(data: any) {
    sessionStorage.setItem('CurrencyPrefix', data);
  }

  static getCurrencyPrefix() {
    const data = sessionStorage.getItem('CurrencyPrefix');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setCurrencySymbol(data: any) {
    localStorage.setItem('CurrencySymbol', data);
  }

  static getCurrencySymbol() {
    const data = localStorage.getItem('CurrencySymbol');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setSyncDate(data: any) {
    localStorage.setItem('syncDate', data);
  }

  static getSyncDate() {
    const data = localStorage.getItem('syncDate');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setCountryCurrency(data: any) {
    localStorage.setItem('CountryCurrency', data);
  }

  static getCountryCurrency() {
    const data = localStorage.getItem('CountryCurrency');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setShowItemTagImages(data: any) {
    sessionStorage.setItem('ShowItemTagImages', data);
  }

  static getShowItemTagImages() {
    const data = sessionStorage.getItem('ShowItemTagImages');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }
  static setShowPaymentScreen(data: any) {
    localStorage.setItem('showPaymentScreen', data);
  }

  static getShowPaymentScreen() {
    const data = localStorage.getItem('showPaymentScreen');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }
  static setUseLoyaltyKiosk(data: any) {
    localStorage.setItem('useLoyaltyKiosk', data);
  }

  static getUseLoyaltyKiosk() {
    const data = localStorage.getItem('useLoyaltyKiosk');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setSerialNo(data: any) {
    localStorage.setItem('serialNo', data);
  }

  static getSerialNo() {
    const data = localStorage.getItem('serialNo');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setAppVersion(data: any) {
    localStorage.setItem('appVersion', data);
  }

  static getAppVersion() {
    const data = localStorage.getItem('appVersion');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setDeviceName(data: any) {
    localStorage.setItem('deviceName', data);
  }

  static getDeviceName() {
    const data = localStorage.getItem('deviceName');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setBrandName(data: any) {
    localStorage.setItem('brandName', data);
  }

  static getBrandName() {
    const data = localStorage.getItem('brandName');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setDeviceModel(data: any) {
    localStorage.setItem('deviceModel', data);
  }

  static getDeviceModel() {
    const data = localStorage.getItem('deviceModel');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setUseApiPrinting(data: any) {
    localStorage.setItem('apiPrinting', data);
  }

  static getUseApiPrinting() {
    const data = localStorage.getItem('apiPrinting');

    if (data == 'undefined' || data == undefined || data == null) return false;

    return data.toLowerCase() == 'true';
  }

  static setOsversion(data: any) {
    localStorage.setItem('osversion', data);
  }

  static getOsversion() {
    const data = localStorage.getItem('osversion');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setIpAddress(data: any) {
    localStorage.setItem('ipAddress', data);
  }

  static getIpAddress() {
    const data = localStorage.getItem('ipAddress');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setMacAddress(data: any) {
    localStorage.setItem('macAddress', data);
  }

  static getMacAddress() {
    const data = localStorage.getItem('macAddress');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setWifi(data: any) {
    localStorage.setItem('wifi', data);
  }

  static getWifi() {
    const data = localStorage.getItem('wifi');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setStaffModuleID(data: any) {
    localStorage.setItem('StaffModuleID', data);
  }

  static getStaffModuleID() {
    const data = localStorage.getItem('StaffModuleID');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setOrderPrefix(data: any) {
    localStorage.setItem('OrderPrefix', data);
  }

  static getOrderPrefix() {
    const data = localStorage.getItem('OrderPrefix');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setIsAssignSpecificConcessionaire(data: any) {
    localStorage.setItem('IsAssignSpecificConcessionaire', data);
  }

  static getIsAssignSpecificConcessionaire() {
    const data = localStorage.getItem('IsAssignSpecificConcessionaire');

    if (data == 'undefined' || data == undefined || data == null) return false;

    return data.toLowerCase() == 'true';
  }

  //general settings Getter And Setter end

  // unique component Getter And Setter
  static setCurrentCountofAlkol(currentCountofAlkol: any) {
    if (Number(currentCountofAlkol >= 0)) {
      sessionStorage.setItem('currentCountofAlkol', currentCountofAlkol);
    }
  }

  static getCurrentCountofAlkol() {
    const currentCountofAlkol = sessionStorage.getItem('currentCountofAlkol');

    if (currentCountofAlkol == undefined || currentCountofAlkol == null)
      return '0';

    return currentCountofAlkol.toString() ?? '0';
  }

  static decreaseAlcohol() {
    if (Number(this.getCurrentCountofAlkol()) > 0) {
      sessionStorage.setItem(
        'currentCountofAlkol',
        (Number(this.getCurrentCountofAlkol()) - 1).toString()
      );
    }
  }

  static increaseAlcohol(count: number) {
    sessionStorage.setItem('currentCountofAlkol', (Number(this.getCurrentCountofAlkol()) + count).toString());
  }

  static setMaxAlcoholicItemsPerOrder(maxAlcoholicItemsPerOrder: any) {
    if (maxAlcoholicItemsPerOrder) {
      if (maxAlcoholicItemsPerOrder == '0') {
        maxAlcoholicItemsPerOrder = 99999;
      }
    }

    sessionStorage.setItem(
      'maxAlcoholicItemsPerOrder',
      maxAlcoholicItemsPerOrder
    );
  }

  static getMaxAlcoholicItemsPerOrder() {
    const maxAlcoholicItemsPerOrder = sessionStorage.getItem(
      'maxAlcoholicItemsPerOrder'
    );

    if (
      maxAlcoholicItemsPerOrder == undefined ||
      maxAlcoholicItemsPerOrder == null
    )
      return null;

    return maxAlcoholicItemsPerOrder.toString();
  }

  static setLastFiveOrderChosen(lastfiveorderChosen: any) {
    sessionStorage.setItem('lastfiveorderChosen', lastfiveorderChosen);
  }
  static getLastFiveOrderChosen() {
    const data = sessionStorage.getItem('lastfiveorderChosen');
    if (data == undefined || data == null) return null;
    return data.toString();
  }
  static removeLastFiveOrderChosen() {
    sessionStorage.removeItem('lastfiveorderChosen');
  }
  static setIntoCombo(lastfiveorderChosen: any) {
    sessionStorage.setItem('intocombo', lastfiveorderChosen);
  }

  static getIntoCombo() {
    const data = sessionStorage.getItem('intocombo');

    if (data == undefined || data == null) return null;

    return data.toString();
  }

  static setOrderTypeId(orderTypeId: any) {
    sessionStorage.setItem('orderTypeId', orderTypeId);
  }

  static getRedirectToMain() {
    const data = sessionStorage.getItem('redirectToMain');

    if (data == undefined || data == null) return null;

    return data.toString();
  }

  static setRedirectToMain(orderTypeId: any) {
    sessionStorage.setItem('redirectToMain', orderTypeId);
  }

  static removeOrderTypeId() {
    sessionStorage.removeItem('orderTypeId');
  }

  static getOrderTypeId() {
    const data = sessionStorage.getItem('orderTypeId');

    if (data == undefined || data == null) return '';

    return data.toString();
  }
  static setRefOrderTypeID(refOrderTypeID: any) {
    sessionStorage.setItem('RefOrderTypeID', refOrderTypeID);
  }

  static getRefOrderTypeID() {
    const data = sessionStorage.getItem('RefOrderTypeID');

    if (data == undefined || data == null) return '';

    return data.toString();
  }
  static setTPTenderId(TPTenderId: any) {
    sessionStorage.setItem('TPTenderId', TPTenderId);
  }

  static getTPTenderId() {
    const data = sessionStorage.getItem('TPTenderId');

    if (data == undefined || data == null) return '';

    return data.toString();
  }

  static setInvoiceNoToDb(invoiceNotodb: any) {
    sessionStorage.setItem('invoiceNotodb', invoiceNotodb);
  }

  static getInvoiceNoToDb() {
    const data = sessionStorage.getItem('invoiceNotodb');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setOrderInvoiceNo(orderInvoiceNo: any) {
    localStorage.setItem('orderInvoiceNo', orderInvoiceNo);
  }

  static getOrderInvoiceNo() {
    const data = localStorage.getItem('orderInvoiceNo');

    if (data == undefined || data == null) return '';

    return data.toString();
  }

  static setOrderInvoiceNoResetDate(OrderInvoiceNoResetDate: any) {
    localStorage.setItem('OrderInvoiceNoResetDate', OrderInvoiceNoResetDate);
  }

  static getOrderInvoiceNoResetDate() {
    const data = localStorage.getItem('OrderInvoiceNoResetDate');

    if (data == undefined || data == null) return '';

    return data.toString();
  }
  static setCustomerId(customerId: any) {
    sessionStorage.setItem('customerId', customerId);
  }

  static getCustomerId() {
    const data = sessionStorage.getItem('customerId');

    if (data == undefined || data == null) return '';

    return data.toString();
  }
  static setMemberId(memberId: any) {
    sessionStorage.setItem('memberId', memberId);
  }

  static getMemberId() {
    const data = sessionStorage.getItem('memberId');

    if (data == undefined || data == null) return '';

    return data.toString();
  }

  static setCustomerLoginName(CustomerLoginName: any) {
    sessionStorage.setItem('CustomerLoginName', CustomerLoginName);
  }

  static getCustomerLoginName() {
    const data = sessionStorage.getItem('CustomerLoginName');

    if (data == undefined || data == null) return '';

    return data.toString();
  }

  static setTableTentNumber(tableTentNumber: any) {
    sessionStorage.setItem('tableTentNumber', tableTentNumber);
  }

  static getTableTentNumber() {
    const data = sessionStorage.getItem('tableTentNumber');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setPaymentTypeID(paymentTypeID: any) {
    sessionStorage.setItem('paymentTypeID', paymentTypeID);
  }
  static removePaymentTypeID() {
    sessionStorage.removeItem('paymentTypeID');
  }

  static getPaymentTypeID() {
    const data = sessionStorage.getItem('paymentTypeID');

    if (data == undefined || data == null) return '';

    return data.toString();
  }
  static removePaymentMethod() {
    sessionStorage.removeItem('paymentMethod');
  }
  static setPaymentMethod(paymentMethod: any) {
    sessionStorage.setItem('paymentMethod', paymentMethod);
  }

  static getPaymentMethod() {
    const data = sessionStorage.getItem('paymentMethod');

    if (data == undefined || data == null) return '';

    return data.toString();
  }

  static setCardType(paymentMethod: any) {
    sessionStorage.setItem('CardType', paymentMethod);
  }

  static getCardType() {
    const data = sessionStorage.getItem('CardType');

    if (data == undefined || data == null) return '';

    return data.toString();
  }

  static setRetRef(retRef: any) {
    sessionStorage.setItem('retRef', retRef);
  }

  static getRetRef() {
    const data = sessionStorage.getItem('retRef');

    if (data == undefined || data == null) return '';

    return data.toString();
  }

  static setVerifoneCtrouid(retRef: any) {
    sessionStorage.setItem('VerifoneCtrouid', retRef);
  }

  static getVerifoneCtrouid() {
    const data = sessionStorage.getItem('VerifoneCtrouid');

    if (data == undefined || data == null) return '';

    return data.toString();
  }

  static setCustomerLoginEmail(CustomerLoginEmail: any) {
    sessionStorage.setItem('CustomerLoginEmail', CustomerLoginEmail);
  }

  static getCustomerLoginEmail() {
    const data = sessionStorage.getItem('CustomerLoginEmail');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setCustomerLoginMobile(CustomerLoginMobile: any) {
    sessionStorage.setItem('CustomerLoginMobile', CustomerLoginMobile);
  }

  static getCustomerLoginMobile() {
    const data = sessionStorage.getItem('CustomerLoginMobile');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }
  static removeCustomerLoginMobile() {
    sessionStorage.removeItem('CustomerLoginMobile');
  }
  static setIsItemDetailScreenShowKiosk(IsItemDetailScreenShowKiosk: any) {
    sessionStorage.setItem('IsItemDetailScreenShowKiosk', IsItemDetailScreenShowKiosk);
  }

  static getIsItemDetailScreenShowKiosk() {
    const data = sessionStorage.getItem('IsItemDetailScreenShowKiosk');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setIsMinimizeSpacing(IsMinimizeSpacing: any) {
    sessionStorage.setItem('IsMinimizeSpacing', IsMinimizeSpacing);
  }

  static getIsMinimizeSpacing() {
    const data = sessionStorage.getItem('IsMinimizeSpacing');

    if (data == 'undefined' || data == undefined || data == null) return false;

    return data.toString().toLowerCase() == 'true';
  }

  static setCustomerName(customerName: any) {
    sessionStorage.setItem('customerName', customerName);
  }

  static getCustomerName() {
    const data = sessionStorage.getItem('customerName');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setCustomerPhone(customerPhone: any) {
    sessionStorage.setItem('customerPhone', customerPhone);
  }
  static removeCustomerPhone() {
    sessionStorage.removeItem('customerPhone');
  }
  static getCustomerPhone() {
    const data = sessionStorage.getItem('customerPhone');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setCustomerEmail(customerEmail: any) {
    sessionStorage.setItem('customerEmail', customerEmail);
  }

  static getCustomerEmail() {
    const data = sessionStorage.getItem('customerEmail');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setLocationPreference(locationPreference: any) {
    sessionStorage.setItem('location-preference', locationPreference);
  }

  static getLocationPreference() {
    const data = sessionStorage.getItem('location-preference');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setHasUpSold(hasUpsold: any) {
    sessionStorage.setItem('hasUpsold', hasUpsold);
  }

  static getHasUpSold() {
    const data = sessionStorage.getItem('hasUpsold');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setDidDiscount(didDiscount: any) {
    sessionStorage.setItem('didDiscount', didDiscount);
  }

  static getDidDiscount() {
    const data = sessionStorage.getItem('didDiscount');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setHPercentDiscount(discount: any) {
    sessionStorage.setItem('HPercentDiscount', discount);
  }

  static getHPercentDiscount() {
    const data = sessionStorage.getItem('HPercentDiscount');
    if (data == 'undefined' || data == undefined || data == null) return '';
    return data.toString();
  }

  static setPaymentResponse(paymentResponse: any) {
    sessionStorage.setItem('paymentResponse', paymentResponse);
  }

  static getPaymentResponse() {
    const data = sessionStorage.getItem('paymentResponse');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setIsTipSelected(isTipSelected: any) {
    sessionStorage.setItem('isTipSelected', isTipSelected);
  }

  static getIsTipSelected() {
    const data = sessionStorage.getItem('isTipSelected');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setIsDataBasePresent(IsDataBasePresent: any) {
    localStorage.setItem('IsDataBasePresent', IsDataBasePresent);
  }

  static getIsDataBasePresent() {
    const data = localStorage.getItem('IsDataBasePresent');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static removeFromMenuLogin() {
    sessionStorage.removeItem('frommenuLogin');
  }

  static setFromMenuLogin(IsDataBasePresent: any) {
    sessionStorage.setItem('frommenuLogin', IsDataBasePresent);
  }

  static getFromMenuLogin() {
    const data = sessionStorage.getItem('frommenuLogin');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setBaudRate(IsDataBasePresent: any) {
    localStorage.setItem('BaudRate', IsDataBasePresent);
  }

  static getBaudRate() {
    const data = localStorage.getItem('BaudRate');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setCheckOutAtLastFive(CheckOutAtLastFive: any) {
    sessionStorage.setItem('checkoutatLastfive', CheckOutAtLastFive);
  }

  static getCheckOutAtLastFive() {
    const data = sessionStorage.getItem('checkoutatLastfive');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }
  static removeCheckOutAtLastFive() {
    sessionStorage.removeItem('checkoutatLastfive');
  }
  static setPaymentAmount(paymentAmount: any) {
    sessionStorage.setItem('paymentAmount', paymentAmount);
  }

  static getPaymentAmount() {
    const data = sessionStorage.getItem('paymentAmount');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setPaymentType(paymentType: any) {
    sessionStorage.setItem('paymentType', paymentType);
  }

  static getPaymentDisplayName(paymentDisplayName: any) {
    sessionStorage.getItem('PaymentDisplayName');
  }

  static setPaymentDisplayName(paymentDisplayName: any) {
    sessionStorage.setItem('PaymentDisplayName', paymentDisplayName);
  }

  static getPaymentType() {
    const data = sessionStorage.getItem('paymentType');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setAskCustomerIfTheyWantReceipt(AskCustomerIfTheyWantReceipt: any) {
    sessionStorage.setItem(
      'AskCustomerIfTheyWantReceipt',
      AskCustomerIfTheyWantReceipt
    );
  }

  static getAskCustomerIfTheyWantReceipt() {
    const data = sessionStorage.getItem('AskCustomerIfTheyWantReceipt');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setStartTime(StartTime: any) {
    sessionStorage.setItem('Start Time', StartTime);
  }

  static getStartTime() {
    const data = sessionStorage.getItem('Start Time');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setEndTime(EndTime: any) {
    sessionStorage.setItem('End Time', EndTime);
  }

  static getEndTime() {
    const data = sessionStorage.getItem('End Time');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setEnableOrderShipment(enableOrderShipment: any) {
    sessionStorage.setItem('EnableOrderShipment', enableOrderShipment);
  }
  static getEnableOrderShipment(): string {
    const data = sessionStorage.getItem('EnableOrderShipment');

    if (data == 'undefined' || data == undefined || data == null)
      return 'False';

    return data.toString();
  }

  static setDomesticShippingFee(domesticShippingFee: any) {
    localStorage.setItem('DomesticShippingFee', domesticShippingFee);
  }

  static getDomesticShippingFee(): string {
    const data = localStorage.getItem('DomesticShippingFee');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setInternationalShippingFee(internationalShippingFee: any) {
    localStorage.setItem(
      'InternationalShippingFee',
      internationalShippingFee
    );
  }

  static getInternationalShippingFee(): string {
    const data = localStorage.getItem('InternationalShippingFee');

    if (data == 'undefined' || data == undefined || data == null) return '0.00';

    return data.toString();
  }

  static setAllowCheckoutWithZeroAmountWithLoyalty(
    AllowCheckoutWithZeroAmountWithLoyalty: any
  ) {
    sessionStorage.setItem(
      'AllowCheckoutWithZeroAmountWithLoyalty',
      AllowCheckoutWithZeroAmountWithLoyalty
    );
  }
  /**
   *
   * @returns the empty string if !(AllowCheckoutWithZeroAmountWithLoyalty) else returns AllowCheckoutWithZeroAmountWithLoyalty
   */
  static getAllowCheckoutWithZeroAmountWithLoyalty(): string {
    const data = sessionStorage.getItem(
      'AllowCheckoutWithZeroAmountWithLoyalty'
    );

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }
  static removeAllowCheckoutWithZeroAmountWithLoyalty() {
    if (this.getAllowCheckoutWithZeroAmountWithLoyalty() != '') {
      sessionStorage.removeItem('AllowCheckoutWithZeroAmountWithLoyalty');
    }
  }
  static setParBrinkIntegrationID(ParBrinkIntegrationID: any) {
    localStorage.setItem('ParBrinkIntegrationID', ParBrinkIntegrationID);
  }
  /**
   *
   * @returns the empty string if !(parbrink integration) else returns ParBrinkIntegrationID
   */
  static getParBrinkIntegrationID(): string {
    const data = localStorage.getItem('ParBrinkIntegrationID');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }
  static removeParBrinkIntegrationID() {
    if (this.getParBrinkIntegrationID() != '') {
      localStorage.removeItem('ParBrinkIntegrationID');
    }
  }
  static setCustomerReceiptChoice(CustomerReceiptChoice: any) {
    sessionStorage.setItem('CustomerReceiptChoice', CustomerReceiptChoice);
  }

  static getCustomerReceiptChoice(): string {
    const data = localStorage.getItem('CustomerReceiptChoice');
    if (data == 'undefined' || data == undefined || data == null) return '';
    return data.toString();
  }

  static setDefaultTenderID(DefaultTenderID: any) {
    localStorage.setItem('DefaultTenderID', DefaultTenderID);
  }

  static getDefaultTenderID() {
    const data = localStorage.getItem('DefaultTenderID');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setBranchLoginUserId(branchLoginUserId: any) {
    localStorage.setItem('branchLoginUserId', branchLoginUserId);
  }

  static getBranchLoginUserId() {
    const data = localStorage.getItem('branchLoginUserId');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setIsOverlayScreenShown(isShown: any) {
    localStorage.setItem('isOverlayScreenShown', isShown);
  }

  static getIsOverlayScreenShown() {
    const data = localStorage.getItem('isOverlayScreenShown');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setBranchLoginPass(branchLoginPass: any) {
    localStorage.setItem('branchLoginPass', branchLoginPass);
  }

  static getBranchLoginPass() {
    const data = localStorage.getItem('branchLoginPass');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setIsOlo(isOlo: any) {
    localStorage.setItem('isOlo', isOlo);
  }

  static getIsOlo() {
    const data = localStorage.getItem('isOlo');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data.toString();
  }

  static setShowModifierGroupName(data: any) {
    sessionStorage.setItem('ShowModifierGroupName', data);
  }

  static getShowModifierGroupName() {
    const data = sessionStorage.getItem('ShowModifierGroupName');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setTaxesIncludedInPrice(data: any) {
    sessionStorage.setItem('TaxesIncludedInPrice', data);
  }

  static getTaxesIncludedInPrice() {
    const data = sessionStorage.getItem('TaxesIncludedInPrice');

    if (data == undefined || data == null) return false;

    return data.toLowerCase() == 'true';
  }

  static setTaxType(data: any) {
    sessionStorage.setItem('TaxType', data);
  }

  static getTaxType() {
    const data = sessionStorage.getItem('TaxType');

    if (data == undefined || data == null || data == 'undefined') return '';

    return data.toString() ?? '';
  }

  static setCommaSeparatedModifiers(data: any) {
    sessionStorage.setItem('CommaSeparatedModifiers', data);
  }

  static getCommaSeparatedModifiers() {
    const data = sessionStorage.getItem('CommaSeparatedModifiers');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setOrderCustomerEmailInfoHint(data: any) {
    sessionStorage.setItem('OrderCustomerEmailInfoHint', data);
  }

  static getOrderCustomerEmailInfoHint() {
    const data = sessionStorage.getItem('OrderCustomerEmailInfoHint');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setOrderCustomerNameInfoHeader(data: any) {
    sessionStorage.setItem('OrderCustomerNameInfoHeader', data);
  }

  static getOrderCustomerNameInfoHeader() {
    const data = sessionStorage.getItem('OrderCustomerNameInfoHeader');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setOrderCustomerNameInfoHint(data: any) {
    sessionStorage.setItem('OrderCustomerNameInfoHint', data);
  }

  static getOrderCustomerNameInfoHint() {
    const data = sessionStorage.getItem('OrderCustomerNameInfoHint');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setOrderCustomerPhoneInfoHint(data: any) {
    sessionStorage.setItem('OrderCustomerPhoneInfoHint', data);
  }

  static getOrderCustomerPhoneInfoHint() {
    const data = sessionStorage.getItem('OrderCustomerPhoneInfoHint');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setShowSolidColorBGKiosk(data: any) {
    sessionStorage.setItem('ShowSolidColorBGKiosk', data);
  }

  static getShowSolidColorBGKiosk() {
    const data = sessionStorage.getItem('ShowSolidColorBGKiosk');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setColorCodeKiosk(data: any) {
    sessionStorage.setItem('ColorCodeKiosk', data);
  }

  static getColorCodeKiosk() {
    const data = sessionStorage.getItem('ColorCodeKiosk');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setImageBGKiosk(data: any) {
    sessionStorage.setItem('ImageBGKiosk', data);
  }

  static getImageBGKiosk() {
    const data = sessionStorage.getItem('ImageBGKiosk');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setShowImageBGKiosk(data: any) {
    sessionStorage.setItem('ShowImageBGKiosk', data);
  }

  static getShowImageBGKiosk() {
    const data = sessionStorage.getItem('ShowImageBGKiosk');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setHideZReport(data: any) {
    sessionStorage.setItem('HideZReport', data);
  }

  static getHideZReport() {
    const data = sessionStorage.getItem('HideZReport');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setDonotPrintOrderToken(data: any) {
    sessionStorage.setItem('DonotPrintOrderToken', data);
  }

  static getDonotPrintOrderToken() {
    const data = sessionStorage.getItem('DonotPrintOrderToken');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static removeCustomerId() {
    sessionStorage.removeItem('customerId');
  }



  static removeCustomerLoginName() {
    sessionStorage.removeItem('CustomerLoginName');
  }

  static removeCustomerLoginEmail() {
    sessionStorage.removeItem('CustomerLoginEmail');
  }

  static setAllowCash(data: any) {
    sessionStorage.setItem('allowcash', data);
  }

  static getAllowCash() {
    const data = sessionStorage.getItem('allowcash');

    if (data == undefined || data == 'undefined' || data == null) return '';

    return data.toString() ?? '';
  }

  static setRoundUpTotal(data: any) {
    sessionStorage.setItem('rounduptotal', data);
  }

  static getRoundUpTotal() {
    const data = sessionStorage.getItem('rounduptotal');

    if (data == undefined || data == 'undefined' || data == null) return '';

    return data.toString() ?? '';
  }

  static setVerifonePublicKey(data: any) {
    localStorage.setItem('verifonePublicKey', data);
  }

  static getVerifonePublicKey() {
    const data = localStorage.getItem('verifonePublicKey');

    if (data == undefined || data == 'undefined' || data == null) return '';

    return data.toString() ?? '';
  }

  static setVerifonePrivateKey(data: any) {
    localStorage.setItem('verifonePrivateKey', data);
  }

  static getVerifonePrivateKey() {
    const data = localStorage.getItem('verifonePrivateKey');

    if (data == undefined || data == 'undefined' || data == null) return '';

    return data.toString() ?? '';
  }

  static setVerifoneRegisterDevice(data: any) {
    localStorage.setItem('VerifoneRegisterDevice', data);
  }

  static getVerifoneRegisterDevice() {
    const data = localStorage.getItem('VerifoneRegisterDevice');

    if (data == undefined || data == 'undefined' || data == null) return '';

    return data.toString() ?? '';
  }

  static setVerifoneMacKey(data: any) {
    localStorage.setItem('VerifoneMacKey', data);
  }

  static getVerifoneMacKey() {
    const data = localStorage.getItem('VerifoneMacKey');

    if (data == undefined || data == 'undefined' || data == null) return '';

    return data.toString() ?? '';
  }

  static setVerifoneDecryptedMessage(data: any) {
    localStorage.setItem('VerifoneDecryptedMessage', data);
  }

  static getVerifoneDecryptedMessage() {
    const data = localStorage.getItem('VerifoneDecryptedMessage');

    if (data == undefined || data == 'undefined' || data == null) return '';

    return data.toString() ?? '';
  }

  static setVerifonePaymentCounter(data: any) {
    localStorage.setItem('VerifonePaymentCounter', data);
  }

  static getVerifonePaymentCounter() {
    const data = localStorage.getItem('VerifonePaymentCounter');

    if (data == undefined || data == 'undefined' || data == null) return '';

    return data.toString() ?? '';
  }

  static setVerifoneUsedCounterList(data: any) {
    localStorage.setItem('VerifoneUsedCounterList', JSON.stringify(data));
  }

  static getVerifoneUsedCounterList() {
    const data = localStorage.getItem('VerifoneUsedCounterList');

    if (data == undefined || data == 'undefined' || data == null) return null;

    return JSON.parse(data);
  }

  static setVerifoneMacLabel(data: any) {
    localStorage.setItem('VerifoneMacLabel', data);
  }

  static getVerifoneMacLabel() {
    const data = localStorage.getItem('VerifoneMacLabel');

    if (data == undefined || data == 'undefined' || data == null) return '';

    return data.toString() ?? '';
  }

  static setVerifonePosIp(data: any) {
    localStorage.setItem('VerifonePosIp', data);
  }

  static getVerifonePosIp() {
    const data = localStorage.getItem('VerifonePosIp');

    if (data == undefined || data == 'undefined' || data == null) return '';

    return data.toString() ?? '';
  }

  static getIsHasVerifoneDetailes() {
    if (
      GeneralSetting.getVerifoneMacLabel() == '' ||
      GeneralSetting.getVerifoneMacKey() == '' ||
      GeneralSetting.getVerifoneDecryptedMessage() == ''
    ) {
      return false;
    }

    return true;
  }



  static setIsVerifoneOfflineOrder(data: any) {
    localStorage.setItem('VerifoneOfflineOrder', data);
  }

  static getIsVerifoneOfflineOrder() {
    const data = localStorage.getItem('VerifoneOfflineOrder');

    if (data == undefined || data == 'undefined' || data == null) return '';

    return data.toString() ?? '';
  }

  static getThirdPartyOrderId() {
    const data = sessionStorage.getItem('ThirdPartyOrderId');

    if (data == undefined || data == null) return '';

    return data.toString() ?? '';
  }

  static setThirdPartyOrderId(data: any) {
    sessionStorage.setItem('ThirdPartyOrderId', data);
  }

  static getIsSelectAllCategory() {
    const data = sessionStorage.getItem('IsSelectAllCategory');

    if (data == undefined || data == null) return 'False';

    return data ?? 'False';
  }

  static setIsSelectAllCategory(data: any) {
    sessionStorage.setItem('IsSelectAllCategory', data);
  }

  static getBoxBuilderFooterImage() {
    const data = sessionStorage.getItem('BoxBuilderFooterImage');

    if (data == undefined || data == null) return '';

    return data ?? '';
  }

  static setBoxBuilderFooterImage(data: any) {
    sessionStorage.setItem('BoxBuilderFooterImage', data);
  }

  static getBoxBuilderHeaderText() {
    const data = sessionStorage.getItem('BoxBuilderHeaderText');

    if (data == undefined || data == null) return '';

    return data ?? '';
  }

  static getCPrintConOrderItemsKiosk() {
    const data = sessionStorage.getItem('CPrintConOrderItemsKiosk');

    if (data == undefined || data == 'undefined' || data == null) return false;

    if (data.toString().toLowerCase() == 'true') {
      return true;
    }

    return false;
  }

  static setCPrintConOrderItemsKiosk(data: any) {
    sessionStorage.setItem('CPrintConOrderItemsKiosk', data);
  }

  static setBoxBuilderHeaderText(data: any) {
    sessionStorage.setItem('BoxBuilderHeaderText', data);
  }

  static setIsAlcoholicAgeLimitKnown(data: any) {
    sessionStorage.setItem('IsAlcoholicAgeLimitKnown', data);
  }

  static getIsAlcoholicAgeLimitKnown() {
    const data = sessionStorage.getItem('IsAlcoholicAgeLimitKnown');

    if (data == undefined || data == 'undefined' || data == null)
      return 'False';

    return data ?? 'False';
  }

  static setOverOrUnderAlcoholicAge(data: any) {
    sessionStorage.setItem('OverOrUnderAge', data);
  }

  static getOverOrUnderAlcoholicAge() {
    const data = sessionStorage.getItem('OverOrUnderAge');

    if (data == undefined || data == 'undefined' || data == null) return '';

    return data;
  }

  //AddIDCheckForAlcohol
  static setAddIDCheckForAlcohol(data: any) {
    sessionStorage.setItem('AddIDCheckForAlcohol', data);
  }

  static getAddIDCheckForAlcohol() {
    const data = sessionStorage.getItem('AddIDCheckForAlcohol');

    if (data == undefined || data == 'undefined' || data == null) return '';

    return data;
  }

  static setAcloholicVerify(data: any) {
    sessionStorage.setItem('is_alcoholic_verify', data);
  }

  static getAlcoholicVerify() {
    const data = sessionStorage.getItem('is_alcoholic_verify');

    if (data == undefined || data == 'undefined' || data == null) return '';

    return data;
  }

  // Added Hybrid check
  static setIsHybrid(data: any) {
    localStorage.setItem('IsHybrid', data);
  }

  static getIsHybrid() {
    const data = localStorage.getItem('IsHybrid');

    if (data == 'undefined' || data == undefined || data == null) return false;

    if (data.toString().toLowerCase() == 'true') {
      return true;
    }

    return false;
  }

  // Added RaspberryPiIPAddress
  static setRaspberryPiIPAddress(data: any) {
    localStorage.setItem('RaspberryPiIPAddress', data);
  }

  static getRaspberryPiIPAddress() {
    const data = localStorage.getItem('RaspberryPiIPAddress');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data;
  }
  static setBarcodeOrQRCodeData(data: any) {
    localStorage.setItem('BarcodeOrQRCodeData', data);
  }

  static getBarcodeOrQRCodeData() {
    const data = localStorage.getItem('BarcodeOrQRCodeData');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data;
  }
  static setBarcodeOrQRCodeDescription(data: any) {
    localStorage.setItem('BarcodeOrQRCodeDescription', data);
  }

  static getBarcodeOrQRCodeDescription() {
    const data = localStorage.getItem('BarcodeOrQRCodeDescription');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data;
  }
  static setBarcodeOrQRCodeOnReceipt(data: any) {
    localStorage.setItem('BarcodeOrQRCodeOnReceipt', data);
  }

  static getBarcodeOrQRCodeOnReceipt() {
    const data = localStorage.getItem('BarcodeOrQRCodeOnReceipt');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data;
  }

  static setIsRemovePricingAndPayments(data: any) {
    localStorage.setItem('IsRemovePricingAndPayments', data);
  }

  static getIsRemovePricingAndPayments() {
    const data = localStorage.getItem('IsRemovePricingAndPayments');

    if (data == 'undefined' || data == undefined || data == null) return false;

    if (data.toString().toLowerCase() == 'true') {
      return true;
    }

    return false;
  }

  static setWaitingStaffText(data: any) {
    localStorage.setItem('WaitingStaffText', data);
  }

  static getWaitingStaffText() {
    const data = localStorage.getItem('WaitingStaffText');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data;
  }

  static setShowReceiptItemGroupsonReceipts(data: any) {
    localStorage.setItem('ShowReceiptItemGroupsonReceipts', data);
  }

  static getShowReceiptItemGroupsonReceipts() {
    const data = localStorage.getItem('ShowReceiptItemGroupsonReceipts');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data;
  }

  /**
   *
   * @param data Set flag for hide zero price lable from item @nilesh
   */
  static setHidePriceIfBasePriceZero(data: any) {
    localStorage.setItem('HidePriceIfBasePriceZero', data);
  }

  static getHidePriceIfBasePriceZero() {
    const data = localStorage.getItem('HidePriceIfBasePriceZero');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data;
  }

  static setItemTableTentSpecialRequestPrefix(data: any) {
    localStorage.setItem('ItemTableTentSpecialRequestPrefix', data);
  }

  static getItemTableTentSpecialRequestPrefix() {
    const data = localStorage.getItem('ItemTableTentSpecialRequestPrefix');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data;
  }

  static setItemTableTentHeaderText(data: any) {
    localStorage.setItem('ItemTableTentHeaderText', data);
  }

  static getItemTableTentHeaderText() {
    const data = localStorage.getItem('ItemTableTentHeaderText');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data;
  }

  static setItemTableTentBodyText(data: any) {
    localStorage.setItem('ItemTableTentBodyText', data);
  }

  static getItemTableTentBodyText() {
    const data = localStorage.getItem('ItemTableTentBodyText');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data;
  }

  static setIsItemTableTentAdded(data: any) {
    localStorage.setItem('IsItemTableTentAdded', data);
  }

  static getIsItemTableTentAdded() {
    const data = localStorage.getItem('IsItemTableTentAdded');

    if (data == 'undefined' || data == undefined || data == null) return false;

    return data.toLowerCase() == "true";
  }

  static clearSession() {
    sessionStorage.clear();
    localStorage.clear();
  }

  static setConcessionaireHeaderImage(data: any) {
    localStorage.setItem('ConcessionaireHeaderImage', data);
  }

  static getConcessionaireHeaderImage() {
    const data = localStorage.getItem('ConcessionaireHeaderImage');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return (this.getIsConcessionaire().toLowerCase() == "true") ? data : '';
  }

  static setHideLogo(data: any) {
    localStorage.setItem('HideLogo', data);
  }

  static getHideLogo() {
    const data = localStorage.getItem('HideLogo');

    if (data == 'undefined' || data == undefined || data == null) return false;

    return data.toLowerCase() == "true";
  }

  // Added to show barcode item in the regular menu
  static setIsShowAllBarcodeItemsMenu(data: any) {
    localStorage.setItem('IsShowAllBarcodeItemsMenu', data);
  }

  static getIsShowAllBarcodeItemsMenu() {
    const data = localStorage.getItem('IsShowAllBarcodeItemsMenu');

    if (data == 'undefined' || data == undefined || data == null) return false;

    if (data.toString().toLowerCase() == 'true') {
      return true;
    }

    return false;
  }


  //From backend
  static setAllowCustomerOptInForMarketing(data: any) {
    localStorage.setItem('AllowCustomerOptInForMarketing', data);
  }

  static getAllowCustomerOptInForMarketing() {
    const data = localStorage.getItem('AllowCustomerOptInForMarketing');

    if (data == 'undefined' || data == undefined || data == null) return false;

    if (data.toString().toLowerCase() == 'true') {
      return true;
    }

    return false;
  }

  static setMarketingOptInText(data: any) {
    localStorage.setItem('MarketingOptInText', data);
  }

  static getMarketingOptInText() {
    const data = localStorage.getItem('MarketingOptInText');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data;
  }

  static setAddTermsConditions(data: any) {
    localStorage.setItem('AddTermsConditions', data);
  }

  static getAddTermsConditions() {
    const data = localStorage.getItem('AddTermsConditions');

    if (data == 'undefined' || data == undefined || data == null) return false;

    if (data.toString().toLowerCase() == 'true') {
      return true;
    }

    return false;
  }

  //   ViewTermsAndCondition - > 0 in url
  //   ViewTermsAndCondition - > 1 in pdf
  static setViewTermsAndCondition(data: any) {
    localStorage.setItem('ViewTermsAndCondition', data);
  }

  static getViewTermsAndCondition() {
    const data = localStorage.getItem('ViewTermsAndCondition');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data;
  }

  // In a popup: 1
  //   Show QR Code: 2
  //   Both: 3
  static setHowToViewURLBasedTermsAndCondition(data: any) {
    localStorage.setItem('HowToViewURLBasedTermsAndCondition', data);
  }

  static getHowToViewURLBasedTermsAndCondition() {
    const data = localStorage.getItem('HowToViewURLBasedTermsAndCondition');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data;
  }

  static setTermsConditionsURL(data: any) {
    localStorage.setItem('TermsConditionsURL', data);
  }

  static getTermsConditionsURL() {
    const data = localStorage.getItem('TermsConditionsURL');
    if (data == 'undefined' || data == undefined || data == null) return '';
    return data;
  }

  static setMenuLevelID(data: any) {
    localStorage.setItem('MenuLevelID', data);
  }

  static getMenuLevelID() {
    const data = localStorage.getItem('MenuLevelID');

    if (data == 'undefined' || data == undefined || data == null) return false;

    return data;
  }


  static setShowNestedModifierInSingleView(data: any) {
    localStorage.setItem('ShowNestedModifierInSingleView', data);
  }

  static getShowNestedModifierInSingleView() {
    const data = localStorage.getItem('ShowNestedModifierInSingleView');

    if (data == 'undefined' || data == undefined || data == null) return false;

    return data.toLowerCase() == "true";
  }

  static setShowItemDescriptioninNestedModifiers(showItemDescriptioninNestedModifiers: any) {
    sessionStorage.setItem('ShowItemDescriptioninNestedModifiers', showItemDescriptioninNestedModifiers);
  }

  static getShowItemDescriptioninNestedModifiers() {
    const data = sessionStorage.getItem('ShowItemDescriptioninNestedModifiers');

    if (data == 'undefined' || data == undefined || data == null) return false;

    return data.toString().toLowerCase() == 'true';
  }

  static setPlaceHolderImageFlag(data: any) {
    if (data == "1") {
      localStorage.setItem('PlaceHolderImageFlag', 'true');
    } else {
      localStorage.setItem('PlaceHolderImageFlag', 'false');
    }
  }

  static getPlaceHolderImageFlag() {
    const data = localStorage.getItem('PlaceHolderImageFlag');

    if (data == 'undefined' || data == undefined || data == null) return false;

    return JSON.parse(data);
  }

  static setPlaceHolderImage(data: any) {
    localStorage.setItem('PlaceHolderImage', data);
  }

  static getPlaceholderImage() {
    const data = localStorage.getItem('PlaceHolderImage');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data;
  }


  static setTermsConditionsFile(data: any) {
    localStorage.setItem('TermsConditionsFile', data);
  }

  static getTermsConditionsFile() {
    const data = localStorage.getItem('TermsConditionsFile');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data;
  }


  //For our use
  static setIsAllowForMarketingCommunication(data: any) {
    localStorage.setItem('IsAllowForMarketingCommunication', data);
  }

  static getIsAllowForMarketingCommunication() {
    const data = localStorage.getItem('IsAllowForMarketingCommunication');

    if (data == 'undefined' || data == undefined || data == null) return '';

    return data;
  }

  static getCompanyName() {
    const companyName = sessionStorage.getItem('companyName');

    if (companyName == undefined || companyName == null) return '';

    return companyName.toString() ?? '';
  }

  static setCompanyName(companyName: any) {
    sessionStorage.setItem('companyName', companyName);
  }

  static getHealthLogs() {
    const healthLogs = localStorage.getItem('healthLogs');
    if (healthLogs == undefined || healthLogs == 'undefined' || healthLogs == null) return null;
    
    return JSON.parse(healthLogs);
  }

  static setHealthLogs(healthLogs: any) {
    localStorage.setItem('healthLogs', JSON.stringify(healthLogs));
  }

  static setKioskInstallationInformation(data: RemoteInstallACKNotification) {
    localStorage.setItem('isProcessStarted', JSON.stringify(data));
  }

  static getKioskInstallationInformation() {
    const data = localStorage.getItem('isProcessStarted');

    if (data == undefined || data == null) return null;

    return (data && JSON.parse(data)) ?? null;
  }

  static removeKioskInstallationInformation() {
    localStorage.removeItem('isProcessStarted');
  }

}
