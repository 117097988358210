<ng-container *ngIf="item">

  <div id="comboItemModifier">
    <div class="modal-body comboItemModifier-modal">
      <div class="content">
        <div class="flexbox">

          <div class="header row">
            <div class="col-md-3 col-3">
              <img [src]="(item.ImageUrl && item.ImageUrl != '') ? baseImageUrl + item.ImageUrl : placeholderImage !='' ? baseImageUrl+placeholderImage : 'assets/images/Image_21.png'" onerror="this.onerror = null;this.src = 'assets/images/Image_21.png';" class="img-fluid" alt="" [ngStyle]="{'border-radius': bordersTypeItem ? imageRadius : '0%'}"/>
            </div>
            <div class="col-md-9 col-9 vertical-center">
              <h3 class="text-uppercase">{{customizeText|uppercase}}</h3>
              <h2> {{ item.Name }} </h2>
            </div>
          </div>

          <div class="h-78vh">

            <ng-container *ngIf="ItemType == 2">
              <app-item-builder-mode-selection class="w-100 selection"
                                               [modifiers]="modifiers"
                                               [variations]="variations"
                                               [isCombo]="item.IsCombo"
                                               (emitSelectedModifier)="setSelectedModifiers($event)"
                                               (emitSelectedVariation)="setSelectedVariations($event)"
                                               (setVariationsValid)="setVariationsValid($event)"
                                               [ItemName]="item.Name == undefined ? '' : item.Name"
                                               [ItemDescription]="(item.FullDescription && item.FullDescription != '') ? item.FullDescription : 
                                               (item.ShortDescription && item.ShortDescription != '') ? item.ShortDescription : ''"
                                               [ItemId]="item.ItemID">
              </app-item-builder-mode-selection>

            </ng-container>

            <ng-container *ngIf="ItemType == 1">
              <ng-container *ngIf="variations.length > 0">

                <app-variation-container (variationOptionSelectedEmiter)="setSelectedVariations($event)"
                                         [ItemID]="ItemID"
                                         [inputVariations]="variations"
                                         (isValidEmitter)="setVariationsValid($event)">
                </app-variation-container>

              </ng-container>

              <ng-container *ngIf="modifiers.length > 0">

                <app-modifier-container [inputModifier]="modifiers"
                                        (modifierEmitter)="setSelectedModifiers($event)"
                                        (isValidEmitter)="setModifiersValid($event)">
                </app-modifier-container>

              </ng-container>
            </ng-container>


          </div>

          <div class="item-footer vertical-center">
            <div class="row">
              <div class="col-4">
                <button class="plain-btn negative-button-text-color background-negative-button-color border-negative-button-color" (click)="closeModel('cancel')">Cancel</button>
              </div>
              <div class="col-4">
                <button *ngIf="isReady; else prompt"
                        [style.backgroundColor]="positiveColor"
                        [style.borderColor]="positiveColor"
                        class="green-btn positive-button-text-color"
                        (click)="addToCart()">
                  {{updateText}}
                </button>
                <ng-template #prompt>
                  <button style="background-color: gray; border-color: gray"
                          class="green-btn"
                          (click)="modifierAnimation()">
                    {{updateText}}
                  </button>
                </ng-template>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>


</ng-container>
