import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { KeyboardService } from '../services/keyboard.service';
import { BehaviorSubject } from 'rxjs';
@Directive({
  selector: '[KeyboardCustom]'
})
export class KeyboardDirective {
  // private ChangeSubject = new BehaviorSubject(0);
  // ObserveInputChange = this.ChangeSubject.asObservable();
  @Input() layout: String = "shift"
  @Input() customLength: String = "50"
  constructor(private el: ElementRef, public KeyboardService: KeyboardService) {
  }
  @HostListener('mouseenter') onMouseEnter() {
  }
  @HostListener('blur') onFocusOut() {
    // this.KeyboardService.KeyboardStatus = false;
  }
  @HostListener('click', ['$event.target']) onClick(bt: any) {
    // this.ChangeSubject.next(bt);
    this.KeyboardService.ToggleKeyboard(bt, this.layout, this.customLength, true);
  }
}