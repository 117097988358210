import { formatDate } from "@angular/common";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  HttpClient,
} from "@angular/common/http";
import { GeneralSetting } from "./generalSetting.service";
import { IHttpHeaders, HttpConnection } from './http.service';
import { IsKioskService } from './is-kiosk.service';
import { Subject, Subscription } from "rxjs";
import { LanguageService } from "./language.service";
import { GeneralConfirmationComponent } from "../components/dialogs/general-confirmation-alert/general-confirmation-alert.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UpgradeKioskAppComponent } from "../components/dialogs/upgrade-kiosk-app/upgrade-kiosk-app.component";
import { DataService } from "./data.service";
import { timeout } from "rxjs/operators";
import { GeneralAlertComponent } from "../components/dialogs/general-alert/general-alert.component";
import { InstallationACKType, RemoteInstallACKNotification, RemoteInstallNotification } from "./api/Models";

@Injectable({
  providedIn: "root",
})
export class KioskUpgradeService {
  
  WaitingReports: any[] = [];
  listener: EventListener | undefined;
  listeners: EventListener[] = [];
  languageSub!: Subscription;
  public KioskUpgradeEvents: Subject<RemoteInstallNotification> = new Subject<RemoteInstallNotification>();
  upgradeTimeOut: any;

  constructor(private language: LanguageService, private readonly modalService: NgbModal, private dataService: DataService,
    private readonly isKioskService: IsKioskService) {
  }

  ngOnDestroy(): void {
    this.removeAllListeners();
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  kioskAppUpgradeACK() {
    let getKioskInstallationProcess: RemoteInstallACKNotification = GeneralSetting.getKioskInstallationInformation();
    if(getKioskInstallationProcess?.status === InstallationACKType.INSTALLING && getKioskInstallationProcess?.KioskReleaseInformation?.version) {
      let getAppVersion = GeneralSetting.getAppVersion();
      if(getKioskInstallationProcess?.KioskReleaseInformation?.version === getAppVersion) {
          let noficationACK = getKioskInstallationProcess
          noficationACK.status = InstallationACKType.SUCCESS;
          this.dataService.postInstallationACK(noficationACK).toPromise().then();
          GeneralSetting.removeKioskInstallationInformation();
      } else {
          let noficationACK = getKioskInstallationProcess
          noficationACK.status = InstallationACKType.ERROR;
          this.dataService.postInstallationACK(noficationACK).toPromise().then();
          GeneralSetting.removeKioskInstallationInformation();
      }
    }
  }
  
  kioskAppUpgrade(tizenURL: string) {
    if(this.isKioskService.isKiosk()) {
      const extension = tizenURL.split('.').pop()
      if(extension === "zip") {
        let title = this.language.getTextElement('lbl_upgrade_now');
        let message = this.language.getTextElement('lbl_are_you_sure_you_want_upgrade');
        let ccm = GeneralConfirmationComponent.open(this.modalService, title, message, 'Yes', 'No');
        ccm.result.then(
          (data: string) => {
            if (data === 'Yes') {
              const tizenPathName = new URL(tizenURL)
              if(tizenPathName?.pathname) {
                let modalRef = UpgradeKioskAppComponent.open(this.modalService);
                this.dataService
                .IsOnlineCheckSK()
                .pipe(timeout(50000))
                .toPromise()
                .then(
                  (data) => {
                      var messageType = 'upgradeKioskApp';
                      var message = {
                        messageType: messageType,
                        data: tizenPathName.pathname
                      };
                      window.top!.postMessage(message, '*');
                      this.listener = (event: any) => {
                        if (event.data.messageType === 'errorOnUpgradeKioskApp') {
                          clearTimeout(this.upgradeTimeOut);
                          let title = this.language.getTextElement('titile_uh_oh');
                          let subTitle = this.language.getTextElement('lbl_error_on_installation_process');
                          GeneralAlertComponent.open(this.modalService, title, subTitle);
                          modalRef.close()
                          this.removeAllListeners();
                        }
                      };
                      this.upgradeTimeOut = setTimeout( ()=>{
                        modalRef.close()
                        this.removeAllListeners();
                        let title = this.language.getTextElement('titile_uh_oh');
                        let subTitle = this.language.getTextElement('the_application_failed_to_upgrade');
                        GeneralAlertComponent.open(this.modalService, title, subTitle);
                      }, 2 * 60 * 1000)
                      if (this.listeners.length == 0) {
                        this.listeners.push(this.listener);
                        window.addEventListener('message', this.listener);
                      }       
                  }, error  => {
                    modalRef.close()
                    let title = this.language.getTextElement('titile_uh_oh');
                    let subTitle = this.language.getTextElement('device_offline_error');
                    GeneralAlertComponent.open(this.modalService, title, subTitle);
                  })
                }
                }
              });
      } else {
        let title = this.language.getTextElement('titile_uh_oh');
        let subTitle = this.language.getTextElement('lbl_invalid_tizen_application_extension');
        GeneralAlertComponent.open(this.modalService, title, subTitle);
      }
    } else {
      let title = this.language.getTextElement('titile_uh_oh');
      let subTitle = this.language.getTextElement('application_upgrade_is_not_supported_in_the_web_browser');
      GeneralAlertComponent.open(this.modalService, title, subTitle);
    }
  }

  async kioskAppPusherUpgrade(tizenURL: string, packageNotification: RemoteInstallNotification) {
    return new Promise((resolve, reject) => {
      let getSerialNo = GeneralSetting.getSerialNo();
      let locationId = GeneralSetting.getBranchId();
      let noficationACK: RemoteInstallACKNotification  = {
        status: "",
        deviceId: getSerialNo,
        locationId: locationId,
        KioskReleaseInformation: {
          name: packageNotification.kioskReleaseInformation.Name,
          version: packageNotification.kioskReleaseInformation.Version,
          releaseNotes: packageNotification.kioskReleaseInformation.ReleaseNotes,
          packageUrl: packageNotification.kioskReleaseInformation.PackageUrl
        }
      }
      if(this.isKioskService.isKiosk()) {
        const extension = tizenURL.split('.').pop()
        if(extension === "zip") {
          const tizenPathName = new URL(tizenURL)
          if(tizenPathName?.pathname) {
            let modalRef = UpgradeKioskAppComponent.open(this.modalService);
            this.dataService
            .IsOnlineCheckSK()
            .pipe(timeout(50000))
            .toPromise()
            .then(
              (data) => {
                noficationACK.status = InstallationACKType.INSTALLING
                this.dataService.postInstallationACK(noficationACK).toPromise().then();
                GeneralSetting.setKioskInstallationInformation(noficationACK);
                  var messageType = 'upgradeKioskApp';
                  var message = {
                    messageType: messageType,
                    data: tizenPathName.pathname
                  };
                  window.top!.postMessage(message, '*');
                  this.listener = (event: any) => {
                    if (event.data.messageType === 'errorOnUpgradeKioskApp') {
                      clearTimeout(this.upgradeTimeOut);
                      noficationACK.status = InstallationACKType.ERROR;
                      this.dataService.postInstallationACK(noficationACK).toPromise().then();
                      let title = this.language.getTextElement('titile_uh_oh');
                      let subTitle = this.language.getTextElement('lbl_error_on_installation_process');
                      GeneralAlertComponent.open(this.modalService, title, subTitle);
                      modalRef.close()
                      this.removeAllListeners();
                      resolve(false)
                    }
                  };
                 this.upgradeTimeOut = setTimeout( ()=>{
                    modalRef.close()
                    this.removeAllListeners();
                    let title = this.language.getTextElement('titile_uh_oh');
                    let subTitle = this.language.getTextElement('the_application_failed_to_upgrade');
                    GeneralAlertComponent.open(this.modalService, title, subTitle);
                    noficationACK.status = InstallationACKType.ERROR;
                    this.dataService.postInstallationACK(noficationACK).toPromise().then();
                    resolve(false)
                  }, 2 * 60 * 1000)
                  if (this.listeners.length == 0) {
                    this.listeners.push(this.listener);
                    window.addEventListener('message', this.listener);
                  }       
              }, error  => {
                modalRef.close()
                let title = this.language.getTextElement('titile_uh_oh');
                let subTitle = this.language.getTextElement('device_offline_error');
                GeneralAlertComponent.open(this.modalService, title, subTitle);
                noficationACK.status = InstallationACKType.ERROR;
                this.dataService.postInstallationACK(noficationACK).toPromise().then();
                resolve(false)
              })
            }
        } else {
          let title = this.language.getTextElement('titile_uh_oh');
          let subTitle = this.language.getTextElement('lbl_invalid_tizen_application_extension');
          noficationACK.status = InstallationACKType.ERROR;
          this.dataService.postInstallationACK(noficationACK).toPromise().then();
          GeneralAlertComponent.open(this.modalService, title, subTitle);
          resolve(false)
        }
      } else {
        let title = this.language.getTextElement('titile_uh_oh');
        let subTitle = this.language.getTextElement('application_upgrade_is_not_supported_in_the_web_browser');
        GeneralAlertComponent.open(this.modalService, title, subTitle);
        noficationACK.status = InstallationACKType.ERROR;
        this.dataService.postInstallationACK(noficationACK).toPromise().then();
        resolve(false)
      }
    })
  }

  removeAllListeners() {
    if (this.listeners.length) {
      this.listeners.forEach((x: EventListener) => {
        window.removeEventListener('message', x);
      });
    }
    this.listeners = [];
  }
  
}
