import { RefundSuccessComponent } from './../../dialogs/refund-success/refund-success.component';
import { PaymentErrorComponent } from 'src/app/components/dialogs/payment-error/payment-error.component';
import { PaymentInsertComponent } from 'src/app/components/dialogs/payment-insert/payment-insert.component';
import { DatabaseHandler } from 'src/app/DatabaseHandler';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RefundConfirmationComponent } from './../../dialogs/refund-confirmation/refund-confirmation.component';
import { CommonFunctions, Snackbar } from 'src/app/services/common';
import { grubbrrPayCredentials } from 'src/app/models/grubbrrPayCredentials';
import { DejavooService } from 'src/app/services/dejavoo.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { from, Subscription } from 'rxjs';
import { order } from 'src/app/models/order';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { OrderHistoryService } from 'src/app/services/order-history.service';
import { loggingData, LogService } from 'src/app/services/log.service';
import { receipt } from 'src/app/models/receipt';
import { VerifoneService } from 'src/app/services/verifone.service';
import {
  IVerifoneGeneralRes,
  IVerifoneRefund,
} from 'src/app/models/VerifoneResponse';
import { LoadingComponent } from 'src/app/loading/loading.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EatOptionsService } from 'src/app/services/eat-options.service';
import { CurrencyService } from 'src/app/services/currency.service';
import { CurrencyPipe } from 'src/app/CustomTools/currency.pipe';
import { LanguageService } from 'src/app/services/language.service';
import { ItemV2 } from '../../../models/item';
import { DatabaseService } from '../../../services/database.service';
import { RefundOrderSplitBill } from 'src/app/models/RefundOrderSplitBill';
import { OfflineOrderService } from 'src/app/services/offline-order.service';
import { LoaderService } from 'src/app/services/loader.service';
import { Verifone3CService } from 'src/app/services/verifone3C.service';
import { EPaymentType } from 'src/app/models/payment-type';
import { PaymentGatwayService } from 'src/app/services/payment-gatway.service';

@Component({
  selector: 'app-settings-order-history',
  templateUrl: './settings-order-history.component.html',
  styleUrls: ['./settings-order-history.component.css'],
})
export class SettingsOrderHistoryComponent implements OnInit, OnDestroy {

  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }
  private allSubsCription: Subscription[] = [];

  dpipe = new DecimalPipe('en-US');

  cpipe = new CurrencyPipe(this.currencyService);

  paymentInsert!: PaymentInsertComponent;

  orders: order[] = [];

  dt: Date = new Date();

  gpCreds: grubbrrPayCredentials = {} as grubbrrPayCredentials;

  orderHistoryText: string = '';
  tokenNoText: string = '';
  refOrderText: string = '';
  invoiceText: string = '';
  orderTotalText: string = '';
  paymentTypeText: string = '';
  statusText: string = '';
  cashText: string = '';
  houseAccountText: string = '';
  cardText: string = '';
  printText: string = '';
  giftCardText: string = '';
  refundText: string = '';
  refundedText: string = '';
  payWithLoyaltyText: string = '';
  fullDiscountText: string = '';
  languageSub!: Subscription;
  statusSuccess: string = '';
  statusFailed: string = '';
  @Output() refershZzReportEvent = new EventEmitter<any>();

  constructor(
    private orderHistoryService: OrderHistoryService,
    private datePipe: DatePipe,
    private dejavooService: DejavooService,
    private logger: LogService,
    private verifoneService: VerifoneService,
    private modalService: NgbModal,
    private eatOptions: EatOptionsService,
    private currencyService: CurrencyService,
    private language: LanguageService,
    private db: DatabaseService,
    private offlineOrderService: OfflineOrderService,
    private loader: LoaderService,
    private verifone3CService: Verifone3CService,
    private paymentGateWay: PaymentGatwayService
  ) {
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }

  loadText() {
    this.orderHistoryText = this.language.getTextElement(
      'lbl_reports_order_history'
    );
    this.tokenNoText = this.language.getTextElement('txt_token_no');
    this.printText = this.language.getTextElement('lbl_print');
    this.refundedText = this.language.getTextElement('refunded');
    this.refundText = this.language.getTextElement('refund');
    this.refOrderText = this.language.getTextElement('txt_reference_order_no');
    this.invoiceText = this.language.getTextElement('txt_invoice_no');
    this.orderTotalText = this.language.getTextElement('txt_order_total');
    this.paymentTypeText = this.language.getTextElement('lbl_payment_type');
    this.statusText = this.language.getTextElement('title_status');
    this.cashText = this.language.getTextElement('text_cash');
    this.houseAccountText = this.language.getTextElement('house_account');
    this.cardText = this.language.getTextElement('text_card');
    this.giftCardText = this.language.getTextElement('gift_card');
    this.payWithLoyaltyText = this.language.getTextElement('loyalty_app_payment');
    this.fullDiscountText = this.language.getTextElement('text_discount_reward');
    this.statusSuccess = this.language.getTextElement('text_success');
    this.statusFailed = this.language.getTextElement('text_failed');
  }

  ngOnInit(): void {
    this.orderHistoryReport();
  }

  private orderHistoryReport() {
    // const date = new Date().getUTCMonth().toString()
    const date = this.datePipe.transform(new Date(), 'MMM d YYYY');
    this.orderHistoryService
      .getOrders(date!.toString())
      .then((orders: order[]) => {
        // console.log('orders', orders);
        this.orders = orders;


      });
  }

  private refundOrder(order: order) {

    // prettier-ignore
    // if (order.ThirdPartyPaymentID == '14') {
    //   // temporarily for verifone 3C payment 
    //   // console.log("refundOrder for 3C");

    //   this.loader.openLoader(false, true, 60000);

    //   switch (this.determineStatus().toString()) {
    //     case 'Success':
    //       // console.log("Status == success");
    //       let verifone3CRefundRequest = this.verifone3CService.sendRefundToTerminal(order);
    //       const observableVerifone3CRefundRequest = from(verifone3CRefundRequest);
    //       observableVerifone3CRefundRequest
    //         .toPromise()
    //         .then((response) => {
    //           // console.log("response from refund observable", response);
    //           if (response == true) {
    //             // when refund is completed
    //             this.loader.closeLoader();
    //             RefundSuccessComponent.open(this.modalService);
    //             // change status to 'refund completed'
    //           }
    //           else {
    //             // error or exception from sendRefundToTerminal (ref: error getting device info)
    //             // checkpoint
    //             this.loader.closeLoader();
    //             Snackbar.show("Refund Declined", 2500);
    //           }
    //         })

    //       break;

    //     default:
    //       // status which is not able to refund
    //       this.loader.closeLoader();
    //       Snackbar.show("Refund Declined: You can only get refund for successful orders", 2500);
    //       break;
    //   }
    // }

    let transactionId = "";

    switch (order.ThirdPartyPaymentID) {
      case "24":
        transactionId = order.AuthorizationID;
        break;
      case "17":
        transactionId = order.AuthorizationID;
        break;
    }

    if (order.ThirdPartyPaymentID == '24' || order.ThirdPartyPaymentID == "17") {
      this.paymentGateWay.doRefund(Number(order.OrderTotal), transactionId, order.ThirdPartyPaymentID, order.OrderInvoiceNo)
        .then(res => {
          if (res.isSuccess) {
            this.successfulRefund(res, order);
          }
          else {
            this.verifoneRefundError(order.OrderInvoiceNo, res);
          }
        }, error => {
          this.verifoneRefundError(order.OrderInvoiceNo, error);
        });
    }
    else {
      this.doDejavooRefund(order);
    }
  }

  private verifoneRefundError(InvoiceNo: string, refundRes: any) {
    try {
      this.logger.sendLogToServer(
        new loggingData(
          'Verifone Refund Error',
          'Verifone Refund Order Invoice no :- ' + InvoiceNo,
          'Verifon Refund Error',
          JSON.stringify(refundRes),
          true
        )
      );
    } catch (e) { }
    let title = this.language.getTextElement('error_refunding_payment')
    let message = this.language.getTextElement('result_code_failure')
    this.refundError(title, message);
    this.loader.closeLoader();
  }


  private doDejavooRefund(order: order) {
    // find the payment device by deviceID

    this.dejavooService
      .getGrubbrrPayDevice(GeneralSetting.getSerialNo())
      .toPromise()
      .then(
        (data: any) => {
          // if array length is 0 device is not set up
          if (data.length == 0) {
            let title = this.language.getTextElement('error_reaching_device')
            let message = this.language.getTextElement('device_not_setup')
            this.refundError(title, message);
            // otherwise find device status
          } else {
            this.gpCreds = data[0];
            this.dejavooService
              .getDeviceStatus(this.gpCreds.TPNNumber)
              .toPromise()
              .then(
                (deviceStatus: any) => {
                  // if device is online start refund process
                  if (deviceStatus == 'Online') {
                    this.paymentInsert = PaymentInsertComponent.open(this.modalService, this.language, false, true).componentInstance;
                    this.dejavooService
                      .doTransaction(
                        this.gpCreds,
                        Number(order.OrderTotal).toFixed(2),
                        'refund',
                        order.AppRefID
                      )
                      .toPromise()
                      .then(
                        (transaction: any) => {
                          this.dejavooTransactionRes(transaction, order);
                        },
                        (err: any) => {
                          let error = this.language.getTextElement('unknown_error')
                          this.refundError(error, err.message);
                        }
                      );
                  } else {
                    let text = this.language.getTextElement('device_offline')
                    this.refundError(text, text);
                  }
                },
                (err: any) => {
                  let error = this.language.getTextElement('unknown_error')
                  this.refundError(error, err.message);
                }
              );
          }
        },
        (err: any) => {
          let title = this.language.getTextElement('error_reaching_device')
          this.refundError(title, err.message);
        }
      );
  }

  private dejavooTransactionRes(transaction: any, order: order) {
    let parser = new DOMParser();

    let xmlResponse = parser.parseFromString(transaction, 'text/xml');

    let resultCode = '';
    try {
      resultCode = xmlResponse
        .getElementsByTagName('ResultCode')[0]
        .childNodes[0].nodeValue!.toString();
    } catch (e) {
      let title = this.language.getTextElement('error_taking_payment')
      let message = this.language.getTextElement('result_code_failure')
      this.refundError(title, message);
      return;
    }

    if (resultCode != '0') {
      let title = this.language.getTextElement('error_taking_payment')
      let message = this.language.getTextElement('result_code_failure')
      this.refundError(title, message);
    } else {
      // SUCCESSFUL REFUND!!!
      this.successfulRefund(transaction, order);
    }
  }

  private async successfulRefund(transaction: any, order: order) {
    this.paymentInsert?.close();

    this.loader.closeLoader();

    await this.updateOrder(order);

    sessionStorage.setItem('paymentResponse', JSON.stringify(transaction));

    RefundSuccessComponent.open(this.modalService);

    this.printOrder(order, true);
  }

  private async getOrderRefundSplitBillDetail(OrderInvoiceNo: string) {
    let concessionaireData =
      await this.orderHistoryService.getConcesisonaireOrderSplitBillByInvoiceNoFromSql(
        '',
        OrderInvoiceNo
      );
    if (concessionaireData && concessionaireData.length > 0) {
      let refOrderList = [] as RefundOrderSplitBill[];

      for (let i = 0; i < concessionaireData.length; i++) {
        let order = concessionaireData[i];

        let refOrder = {
          OrderSplitBillID: order.OrderSplitBillID,
          OrderID: order.OrderID,
          AppRefID: order.AppRefID,
          PaymentAmount: Number(order.PaymentAmount),
          TenderedAmount: Number(order.TenderedAmount),
          ChangedAmount: Number(order.ChangedAmount),
          TipAmount: Number(order.TipAmount),
          SubTotal: Number(order.SubTotal),
          OrderTax: Number(order.OrderTax),
          OrderDiscount: Number(order.OrderDiscount),
          ExtraCharge: Number(order.ExtraCharge),
          OtherCharges: Number(order.ExtraCharge),
          SplitAmount: Number(order.SplitAmount),
          AutoGratuityTip: Number(order.AutoGratuityTip),
          RefundedAmount: Number(order.PaymentAmount),
          RefundedComment: '',
          RefundType: 0,
          AdjustCheckAmountType: 0,
          AdjustCheckAmount: 0,
          AdjustTipAmountType: 0,
          AdjustTipAmount: Number(order.TipAmount),
          UpchargeCardAmount: Number(order.UpchargeCardAmount),
          OrderCashDiscount: Number(order.OrderCashDiscount),
          IsRefunded: true,
          IsAdjusted: false,
        } as RefundOrderSplitBill;

        refOrderList.push(refOrder);
      }

      return refOrderList;
    }

    return [] as RefundOrderSplitBill[];
  }

  private async updateOrder(order: order) {
    let date = new Date().toLocaleString('en-US');
    let index = this.orders.findIndex((x: order) => x.OrderID == order.OrderID);
    if (index != -1) {
      let OrderInvoiceNo = this.orders[index].OrderInvoiceNo;

      this.orders[index].IsRefunded = 'True';
      this.orders[index].RefundedDate = date;
      this.orders[index].RefundedAmount = order.OrderTotal;
      this.orders[index].RefundType = '1';
      this.orders[index].OrderTotal = '0.00';
      this.orders[index].Subtotal = '0.00';
      this.orders[index].OrderTip = '0.00';
      this.orders[index].OrderTax = '0.00';
      this.orders[index].OrderCashDiscount = '0.00';
      this.orders[index].OrderDiscount = '0.00';
      this.orders[index].ExtraCharge = '0.00';
      this.orders[index].UpChargeAmountCard = '0.00';
      this.orders[index].AutoGratuityTip = '0.00';
      this.orders[index].OtherCharges = '0.00';
      this.orders[index].AdjustTipAmount = '0.00';

      /**
       * for full refund this is not required, below is refund type @nilesh
       * // Full Refund - 1
         // Refund By Item - 2
         // Refund By Amount - 3
         // Adjust Check Amount - 4
         // Adjust Tip Amount - 5
       */

      /**
       * for full refund this is not required, below is refund type @nilesh
       * // Full Refund - 1
         // Refund By Item - 2
         // Refund By Amount - 3
         // Adjust Check Amount - 4
         // Adjust Tip Amount - 5
       */

      let refOrderList = await this.getOrderRefundSplitBillDetail(
        OrderInvoiceNo
      );

      let refOrderItem = await this.offlineOrderService.getOrderItemByAppRefId(
        this.orders[index].AppRefID
      );
      refOrderItem.forEach((element) => {
        element.IsRefunded = 'True';
        element.RefundedAmount = element.ItemTotal;
      });

      this.orders[index].RefundOrderItems = refOrderItem;

      this.orders[index].RefundOrderSplitBills = refOrderList;
      // if (refOrderList.length > 0) {
      //   this.orders[index].RefundType = '2';
      //   this.orders[index].IsSplitBill = 'True';
      // }

      this.orderHistoryService
        .sendRefundOrderAPI(this.orders[index])
        .toPromise()
        .then((data: any) => {
          this.refershZzReportEvent.emit();
        });
      this.orders = this.orders.slice();
    }

    return new Promise<any>((res: any, rej: any) => {
      // TODO find out what other refund data to fill in
      let sqlString = `UPDATE OrderMasters
                      SET RefundedDate = '${date}', RefundedAmount = '${order.OrderTotal}', IsRefunded = 'True', RefundType = '1'
                      WHERE OrderID = '${order.OrderID}'

      `;

      const callback = (tx: string, data: string) => {
        // console.log('update order success', data);
        res(data);
      };

      const errorCallback = (tx: string, data: any) => {
        // console.log('update order error', data);
        res(data);
      };

      DatabaseHandler.executeSqlStatement(
        sqlString,
        [],
        callback,
        errorCallback
      );
    });
  }

  private refundError(errorTitle: string, errorMessage: string) {
    // error!! payment did not go through
    this.paymentInsert?.close();

    this.loader.closeLoader();

    // get message and display

    this.logger.sendLogToServer(new loggingData(
      'GrubbrrPay Refund Error',
      `GrubbrrPay Encountered ${errorTitle}`,
      'Error Refund GrubbrrPay',
      `GrubbrrPay Encountered Error: ${errorMessage}`,
      true
    ));

    PaymentErrorComponent.open(this.modalService, this.language, false, errorTitle, true);
  }

  private async getConcessionaireTotal(
    items: ItemV2[],
    orderId: string,
    invoicNo: string
  ) {
    let orderSplitBillData =
      await this.orderHistoryService.getConcesisonaireOrderSplitBillByInvoiceNoFromSql(
        orderId,
        invoicNo
      );

    let concessionaireDetail = await this.db.getAllConcessionaireFromSql();

    var allConcessionaire: any[] = [];
    var concessionaireTotal: any[] = [];

    //let conDetail = this.cartService.conDetailSub.value;

    items.forEach(function (x) {
      allConcessionaire.push({
        ConcessionaireId: x.ConcessionaireId,
        ConcessionaireName: x.ConcessionaireName,
        ConcessionaireColorCode: x.ConcessionaireColorCode,
      });
    });

    let uniqueCon = [
      ...new Map(
        allConcessionaire.map((item) => [item['ConcessionaireId'], item])
      ).values(),
    ];

    for (let con of uniqueCon) {
      let conDiscount = 0;
      let conTax = 0;
      let conTip = 0;
      let storeTotal = 0;
      let conTotal = 0;
      let SplitInvoiceNo = '';

      let conItems = items.filter(
        (x) => x.ConcessionaireId == con.ConcessionaireId
      );
      //Get Data from ConcessionaireOrderSplitBill
      let concessionaireOrderSplitBill = orderSplitBillData.find(
        (x: any) => x.BranchSectionConcessionaireID == con.ConcessionaireId
      );

      let concessionaireData = concessionaireDetail.find(
        (x) => x.ConcessionaireID == con.ConcessionaireId
      );
      if (concessionaireData) {
        conItems.forEach((x) => {
          x.ConcessionaireName = concessionaireData?.Name ?? '';
          x.ConcessionaireColorCode = concessionaireData?.ColorCode ?? '';
        });
      }

      if (concessionaireOrderSplitBill) {
        conDiscount = Number(concessionaireOrderSplitBill.OrderDiscount);
        conTax = Number(concessionaireOrderSplitBill.OrderTax);
        conTip = Number(concessionaireOrderSplitBill.TipAmount);
        conTotal = Number(concessionaireOrderSplitBill.SubTotal);
        
        storeTotal = conTotal - conDiscount + conTax;

        if (GeneralSetting.getIsOtterIntegration()) {
          SplitInvoiceNo = concessionaireOrderSplitBill?.SplitInvoiceNo;
        }

        concessionaireTotal.push({
          ConcessionaireId: con.ConcessionaireId,
          ConcessionaireTotal: CommonFunctions.roundDigit(conTotal, 2).toFixed(
            2
          ),
          Tax: CommonFunctions.roundDigit(conTax, 2).toFixed(2),
          Tip: CommonFunctions.roundDigit(conTip, 2).toFixed(2),
          Discount: CommonFunctions.roundDigit(conDiscount, 2).toFixed(2),
          StoreTotal: CommonFunctions.roundDigit(storeTotal, 2).toFixed(2),
          ThirdPartOrderId: SplitInvoiceNo,
        });
      }
    }

    return concessionaireTotal;
  }

  private getPaymentDisplayName(paymentTypeID: string) {
    let paymentType = "";

    switch (paymentTypeID) {
      case EPaymentType.notPaid.toString():
        paymentType = "Pay with Cash";
        break;
      case EPaymentType.Card.toString():
        paymentType = "Pay with Card";
        break;
      case EPaymentType.Cash.toString():
        paymentType = "Pay with Cash";
        break;
      case EPaymentType.USConnect.toString():
        paymentType = "USConnect";
        break;
      case EPaymentType.HouseCredit.toString():
        paymentType = "Pay With HouseCredit";
        break;
      case EPaymentType.GiftCard.toString():
        paymentType = "Pay With GiftCard";
        break;
      case EPaymentType.LevelUp.toString():
        paymentType = "Pay With Loyalty App";
        break;
      case EPaymentType.IDCard.toString():
        paymentType = "Pay With ID Card";
        break;
      case EPaymentType.RFID.toString():
        paymentType = "Pay With RFID";
        break;
      default:
        return "";
    }
    return paymentType;
  }

  async printOrder(order: order, isRefund: boolean = false) {
    let eatOptions = await this.eatOptions.getEatOptionsForOrderTypeIdFromSql(
      order.OrderTypeID
    );
    let orderTypeName =
      eatOptions.length > 0 ? eatOptions[0].DisplayLabel.toString() : '';
    let isTableTent = eatOptions.length > 0 ? eatOptions[0].KioskTableTent : '';
    let cardType = '';
    let cardNumber = '';
    let rewardRedeemTemp = '';
    let rewardRedeemDiscountAmountTemp = '';
    let rewardBottomTextEarnPointTemp = '';
    let rewardBottomTextRedeemPointsTemp = '';
    let rewardBottomTextTotalPointsTemp = '';

    //if (order.IsDiscount && order.IsDiscount == 'True') {
    rewardRedeemTemp =
      order.RefRedeemedPoints &&
        order.RefRedeemedPoints != '' &&
        Number(order.RefRedeemedPoints) > 0
        ? this.language.getTextElement('print_points_redeemed').replace('%d', order.RefRedeemedPoints)
        : '';

    rewardRedeemDiscountAmountTemp =
      Number(order.RefRedeemedPoints) > 0 && Number(order.OrderDiscount) > 0
        ? '' +
        this.cpipe.transform(
          this.dpipe.transform(order.OrderDiscount, '1.2-2')
        )
        : '';
    rewardBottomTextEarnPointTemp =
      order.RefEarnedPoints &&
        order.RefEarnedPoints != '' &&
        Number(order.RefEarnedPoints) > 0
        ? this.language.getTextElement('print_points_earned').replace('%d', order.RefEarnedPoints)
        : '';

    rewardBottomTextRedeemPointsTemp =
      order.RefRedeemedPoints &&
        order.RefRedeemedPoints != '' &&
        Number(order.RefRedeemedPoints) > 0
        ? this.language.getTextElement('print_you_redeemed_points').replace('%d', order.RefRedeemedPoints)
        : '';

    rewardBottomTextTotalPointsTemp =
      order.RefTotalPoints &&
        order.RefTotalPoints != '' &&
        Number(order.RefTotalPoints) > 0
        ? this.language.getTextElement('print_your_points').replace('%d', order.RefTotalPoints)
        : '';
    //}

    let orderPaymentSucceess = await this.db.getOrderPaymentSuccess(
      order.OrderInvoiceNo
    );
    if (orderPaymentSucceess && orderPaymentSucceess.length > 0) {
      cardType = orderPaymentSucceess[0].cardNetwork;
      cardNumber = orderPaymentSucceess[0].cardNumber;
    }
    // if (GeneralSetting.getPaymentMethod() === '17') {
    //   cardType = GeneralSetting.getPaymentResponse() != '' ? JSON.parse(GeneralSetting.getPaymentResponse()).PAYMENT_MEDIA : '';
    //   cardNumber = GeneralSetting.getPaymentResponse() != '' ? JSON.parse(GeneralSetting.getPaymentResponse()).ACCT_NUM : '';
    // } else if (GeneralSetting.getPaymentMethod() === '13') {
    //   if (GeneralSetting.getPaymentResponse() != '') {
    //     let paymentResponse = JSON.parse(GeneralSetting.getPaymentResponse());
    //     cardType = paymentResponse.payment.cardTransaction.cardType;
    //     cardNumber = paymentResponse.payment.cardTransaction.first6 + "******" + paymentResponse.payment.cardTransaction.last4;
    //   }
    // }

    let concessionaireTotal: any[] = [];
    let isConcessionaire = false;
    if (order.items && order.items.length > 0) {
      let isCondata = order.items.find(
        (x) =>
          x.ConcessionaireId &&
          x.ConcessionaireId != '' &&
          x.ConcessionaireId != '0'
      );
      if (isCondata) {
        isConcessionaire = true;
        concessionaireTotal = await this.getConcessionaireTotal(
          order.items as ItemV2[],
          order.OrderID,
          order.OrderInvoiceNo
        );
      }
    }

    if (order.items && order.items.length > 0) {
      for (let item of order.items) {
        if (item.IsLoyaltyItem!.toString() == "True") {
          item.IsLoyaltyDiscountItem = true;
        }
      }
    }

    let paymentDisplayName = this.getPaymentDisplayName(order.PaymentTypeID);

    //console.log("Order history print order",order.items);   // added for easy debugging
    var surcharge = 0
    if(order.OrderSurcharge) {
      surcharge = Number(order.OrderSurcharge)
    }
    let data: receipt = {
      text: {
        vat: this.language.getTextElement('vat'),
        tax: 'Taxes and fees:',//this.language.getTextElement('lbl_taxes'),
        itemTax: this.language.getTextElement('txt_item_tax'),
        shippingFeeLabel: 'Credit card surcharge:',
        specialNotesLabel: this.language.getTextElement('lbl_special_notes'),
        customerNameLabel: this.language.getTextElement('txt_customer_name'),
        nonCashPlaceholder: this.language.getTextElement('non_cash_fees_replace'),
        nonCash: this.language.getTextElement('non_cash_fees'),
        tableTentLabel: this.language.getTextElement('table_tent_label'),
        orderTokenLabel: this.language.getTextElement('order_token_label'),
        phoneLabel: this.language.getTextElement('txt_phone_s'),
        tipLabel: this.language.getTextElement('lbl_tip'),
        serviceFeeLabel: this.language.getTextElement('lbl_service_fee'),
        //shippingFeeLabel: this.language.getTextElement('lbl_shipping_fee'),
        tentText: this.language.getTextElement('printing_tent'),
        tpInvoiceNumber: this.language.getTextElement('tp_invoice_number'),
        refund: this.language.getTextElement('refund'),
        customerName: this.language.getTextElement('txt_customer_name'),
        guest: this.language.getTextElement('txt_guest'),
        dateLabel: this.language.getTextElement('lbl_date'),
        paymentTypeLabel: this.language.getTextElement('lbl_payment_type'),
        orderTypeLabel: this.language.getTextElement('txt_order_type'),
        invoiceNumber: this.language.getTextElement('invoice_number'),
        discount: this.language.getTextElement('lbl_discount'),
        purchase: this.language.getTextElement('purchase'),
        qty: this.language.getTextElement('txt_qty'),
        item: this.language.getTextElement('item').toLocaleUpperCase(),
        each: this.language.getTextElement('text_each').toLocaleUpperCase(),
        total: this.language.getTextElement('lbl_total').toLocaleUpperCase(),
        subtotalLabel: this.language.getTextElement('lbl_subtotal'),
        storeTotalLabel: this.language.getTextElement('lbl_store_total'),
        grandTotalLabel: this.language.getTextElement('lbl_grand_total'),
        totalLabel: this.language.getTextElement('text_total'),
        rewardsLabel: this.language.getTextElement('rewards')
      },
      baudRate: GeneralSetting.getBaudRate(),
      subtotal: Number(order.Subtotal),
      ServiceFee: Number(order.OtherCharges),
      total: Number(order.OrderTotal) + surcharge,
      tax: Number(order.OrderTax),
      promo: Number(order.OrderDiscount),
      reward: order.LevelUpUuid != '' ? Number(order.OrderDiscount) : 0,
      promotionPercent: Number(order.OrderDiscount),
      promotionIsTotal:
        order.OrderDiscountTypeID == '1' || order.OrderDiscountTypeID == '2',
      tip: Number(order.OrderTip),
      discount: order.LevelUpUuid != '' ? 0 : Number(order.OrderDiscount),
      items: JSON.stringify(order.items),
      address1:
        GeneralSetting.getBranchAddress1() !== ''
          ? GeneralSetting.getBranchAddress1()!.trim()
          : null,
      address2:
        GeneralSetting.getBranchAddress2() !== ''
          ? GeneralSetting.getBranchAddress2()!.trim()
          : null,
      branchContactNo: GeneralSetting.getBranchContactNo().trim(),
      branchTagline: GeneralSetting.getBranchTagLine().trim(),
      paymentType: order.PaymentTypeID,
      orderType: orderTypeName,
      thankYouMessage: GeneralSetting.getThankYouMessage(),
      branchName: GeneralSetting.getCompanyName(),
      PaymentDisplayName: paymentDisplayName,
      invoiceNotodb: order.OrderInvoiceNo,
      OrderNo: order.T_TokenCount,
      customerName: order.CustomerName,
      customerPhone:
        order.CustomerPhone && order.CustomerPhone != '0000000000'
          ? order.CustomerPhone
          : '',
      customerEmail: GeneralSetting.getCustomerEmail(),
      headerText: GeneralSetting.getHeaderTextForCustomerInKiosk(),
      footerText: GeneralSetting.getFooterTextForCustomerInKiosk(),
      prefix: GeneralSetting.getOrderPrefix(),
      together: GeneralSetting.getIsPrintTokenAndCustomerReceiptTogether(),
      isTableTent: isTableTent,
      tableTentNumber: order.TableID,
      CreatedDate: order.CreatedDateFormat,
      copyType: isRefund ? this.language.getTextElement('refund').toLocaleUpperCase() : this.language.getTextElement('duplicate_copy').toLocaleUpperCase(),
      shouldPrintCustomerPrint: true,
      isRefund: isRefund,
      refundedAmount: order.RefundedAmount,
      // TODO this is empty for now until I find out what this needs
      rewardRedeem: rewardRedeemTemp,
      rewardRedeemDiscountAmount: rewardRedeemDiscountAmountTemp,
      rewardBottomTextEarnPoint: rewardBottomTextEarnPointTemp,
      rewardBottomTextVisitCount: '',
      rewardBottomTextRedeemPoints: rewardBottomTextRedeemPointsTemp,
      rewardBottomTextTotalPoints: rewardBottomTextTotalPointsTemp,
      ShowModifierGroupName: GeneralSetting.getShowModifierGroupName(),
      CommaSeparatedModifiers: GeneralSetting.getCommaSeparatedModifiers(),
      date: order.CreatedDate,
      doNotPrintOrderToken: 'False',
      thirdPartyOrderId: order.TPOrderID,
      IsConcessionaire: isConcessionaire,
      CPrintConOrderItemsKiosk: GeneralSetting.getCPrintConOrderItemsKiosk(),
      concessionaireTotal: concessionaireTotal,
      isRemovePricingAndPayments:
        GeneralSetting.getIsRemovePricingAndPayments(),
      footerTextMerchant: GeneralSetting.getFooterTextForMerchantInKiosk(),
      BarcodeOrQRcodeData: GeneralSetting.getBarcodeOrQRCodeData(),
      BarcodeOrQRcodeDescription: GeneralSetting.getBarcodeOrQRCodeDescription(),
      BarcodeOrQRcodeonReceipt: GeneralSetting.getBarcodeOrQRCodeOnReceipt(),
      ShowSurveyLinkOnReceipt:
        GeneralSetting.getShowSurveyLinkOnReceipt() == 'True',
      StoreID: GeneralSetting.getStoreID(),
      SurveyLink: GeneralSetting.getSurveyLink(),
      SurveyCode: '',
      currencySymbol: GeneralSetting.getCurrencySymbol(),
      cardType: cardType,
      cardNumber: cardNumber,
      ReceiptItemGroups: [],
      ShowReceiptItemGroupsonReceipts:
        GeneralSetting.getShowReceiptItemGroupsonReceipts(),
      taxType: GeneralSetting.getTaxType(),
      isTaxHidden: GeneralSetting.getHideItemTaxPrice() == 'True',
      showNegativeModifiers: GeneralSetting.getShowNegativeModifiers(),
      showDefaultModifiers: GeneralSetting.getShowDefaultModifiers(),
      IsUpChargeCard: order.IsUpChargeCard == 'True',
      IsPercentageCard: order.IsPercentageCard == 'True',
      PercentageOrAmounCard: Number(order.PercentageOrAmounCard),
      UpChargeAmountCard: Number(order.UpChargeAmountCard),
      isParbrink: GeneralSetting.getParBrinkIntegrationID() != '',

      shippingFee: Number(order.OrderSurcharge)
    };
    let message = {
      messageType: 'Print',
      data: data,
    };
    
    window.top!.postMessage(message, '*');

    window.onmessage = (event: any) => {
      //console.log(`PrintCustomer Recieved message: ${JSON.stringify(event.data)}`);
      if (event.data.messageType == 'CustomerPrinterReturn') {
        var receiptType = isRefund ? this.language.getTextElement('refund').toLocaleUpperCase() : this.language.getTextElement('duplicate_copy').toLocaleUpperCase();
        var log = new loggingData(
          `${receiptType} Receipt`,
          `${receiptType} Receipt Print Status`,
          'Print Confirmation Status',
          `Receipt Print Status is ${event.data.result} for Invoice ${order.OrderInvoiceNo}`,
          true
        );
        this.logger.sendLogToServer(log);
      }
    };
  }

  determineStatus(): string {
    return this.language.getTextElement('text_success');
  }

  setHistoryDate(event: any) {
    this.dt = event;
    const date = this.datePipe.transform(this.dt, 'MMM d YYYY');
    this.orderHistoryService
      .getOrders(date!.toString())
      .then((orders: order[]) => {
        // console.log('orders', orders);
        this.orders = orders;
        //console.log(this.orders);
      });
  }

  startRefund(order: order) {
    let rcm = RefundConfirmationComponent.open(this.modalService);

    rcm.result.then((result: string) => {
      if (result == 'refund') {
        this.refundOrder(order);
      }
    });
  }

  //#region > Process Offline Transaction < 
  private isDoProcesssOfflineOrderCalled = false;

  private async setisDoProcesssOfflineOrderCalled(value: boolean) {
    this.isDoProcesssOfflineOrderCalled = value;

    await CommonFunctions.delay(5000);

    this.isDoProcesssOfflineOrderCalled = false;
  }

  doProcessOfflineTransaction() {
    if (this.isDoProcesssOfflineOrderCalled) {
      return;
    }

    this.setisDoProcesssOfflineOrderCalled(true);

    this.logger.sendLogToServer(new loggingData(
      'Process Offline Order Manual',
      'Process Offline Order Manual',
      `Process Offline Order Manual Called`,
      ``,
      true
    ));

    this.loader.openLoader(false, true, 120000)

    this.offlineOrderService.sendOfflineOrderToBackend();
  }

  //#endregion

}
