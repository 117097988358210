import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { DatabaseHandler } from '../DatabaseHandler';
import { ItemV2 as Item } from '../models/item';
import { LogService } from 'src/app/services/log.service';
import { CartService } from './cart.service';
import { CourseMaster } from '../models/BranchLoginModels';
import { CommonFunctions } from './common';
import { GeneralSetting } from './generalSetting.service';

@Injectable({
  providedIn: 'root',
})
export class UpsellService {
  constructor(private logger: LogService, private cartService: CartService) {}

  async getUpsellItem() {
    let course = await this.getMissingCourse();
    if (course != undefined) {
      if (GeneralSetting.getIsConcessionaire().toLowerCase() == 'true') {
        return this.getOrderLevelConcessionaireUpSellItemFromSql(
          course.CourseID
        );
      } else {
        return this.getOrderLevelUpsellItemSql(course.CourseID);
      }
    } else return undefined;
  }

  async getMissingCourse() {
    var MissingCourse: any;
    let map = await this.getCourseMap();
    let courses = await this.getCoursesFromSQL();
    for(let i=0;i<courses.length;i++){
      if(courses[i].CourseID!=="0"){
        MissingCourse=courses[i]
      }
    }
    // courses.forEach((course) => {
    //   if (map.get(course.CourseID) == undefined) {
    //     if (
    //       MissingCourse &&
    //       MissingCourse.CourseName.localeCompare(course.CourseName) == 1
    //     ) {
    //       MissingCourse = course;
    //     } else if (!MissingCourse) {
    //       MissingCourse = course;
    //     }
    //   }
    // });
    return MissingCourse;
  }

  //get all possible courses from the database
  getCoursesFromSQL() {
    return new Promise<CourseMaster[]>((resolve: any) => {
      const sqlCourses = `select CourseID as CourseID,
      CourseName as CourseName,
      CompanyID as CompanyID,
      BranchID as BranchID,
      IsActive as IsActive
      from CourseMasters c WHERE C.IsActive = 'True'`;

      const errorCallback = (statement: any, error: any) => {
        console.log('Upsell Service, Error getCoursesFromSQL :- ', error);
      };

      const showCategories = (tx: string, results: any) => {
        var data: CourseMaster[] = Array.from(results.rows);
        resolve(data);
      };
      DatabaseHandler.executeSqlStatement(
        sqlCourses,
        [],
        showCategories,
        errorCallback
      );
    });
  }

  itemsMapToCourses() {
    //get all items, mapped to their respective course
    return new Promise<any[]>((resolve: any) => {
      const sqlCourses = `select c.CourseID, i.ItemID from CourseMasters c LEFT JOIN Items i ON c.CourseID = i.CourseID GROUP BY i.ItemID `;

      const errorCallback = (statement: any, error: any) => {
        console.log('Upsell Service, Error itemsMapToCourses :- ', error);
      };

      const showCategories = (tx: string, results: any) => {
        var data: any[] = Array.from(results.rows);
        resolve(data);
      };
      DatabaseHandler.executeSqlStatement(
        sqlCourses,
        [],
        showCategories,
        errorCallback
      );
    });
  }

  async getCourseMap(): Promise<Map<string, boolean>> {
    //Set the course map, to determine if a particular course is in the cart
    let CourseMap: Map<string, boolean> = new Map<string, boolean>();
    var cartItems = this.cartService.getCartItems(); //get all cart items (cartitem doesn't have a CourseID property, so we must map)
    let arr = await this.itemsMapToCourses();
    //map
    for (let i = 0; i < cartItems.length; i++) {
      var mapped = arr.find((item) => item.ItemID == cartItems[i].ItemID);
      if (mapped && mapped != undefined) {
        CourseMap.set(mapped.CourseID, true);
      }
    }
    return CourseMap;
  }

  getOrderLevelUpsellItemSql(CID: any) {
    let currentTime = CommonFunctions.getCurrentTime();
    let dayId = new Date().getDay();
    if (dayId == 0) {
      dayId = 7;
    }
    let currentDate = CommonFunctions.getCurrentformatedtDate(new Date());

    return new Promise<Item>((resolve: any) => {
      const sqlCourseNo = `SELECT
                          I.ItemID as ItemID,
                          I.Sku,
                          I.IsItem86,
                          I.Name,
                          I.DisplayName,
                          I.KOTorKDSDisplayName AS KOTDisplayName,
                          I.OrderReviewDisplayName,
                          I.NameLang,
                          I.IsBuilderMode,
                          I.FullDescription,
                          I.ShortDescription,
                          I.IsAskForName,
                          I.ItemCategorySalesTypeID,
                          I.OrderMaximumQuantity,
                          I.OrderMinimumQuantity,
                          I.IsItemDetailScreenShowKiosk AS IsItemDetailScreenShowKiosk,
                          I.Calorie AS Calorie,
                          I.MaxCalories AS MaxCalories,
                          I.ItemCategorySalesTypeID AS ItemCategorySalesTypeID,
                          I.RefItemId,
                          I.IsAskForName,
                          I.IsCombo,
                          I.Price AS Price,
                          cmi.DisplayOrder as DisplayOrder,
                          I.EnablePricebyWeight as EnablePricebyWeight,
                          CategoryMasters.CategoryID AS CategoryID,
                          Case when CategoryMasters.DisplayName = '' then CategoryMasters.Name else CategoryMasters.DisplayName end AS CategoryName,
                          (
                           select  Imageurl
                             from  ItemImages
                            where  ItemImages.ItemId == I.ItemId
                            limit  1
                           ) as ImageUrl,
                          RIS.LowThreshold as LowThreshold,
                          RIS.CurrentStock as CurrentStock
                      FROM
                          Items I
                          LEFT JOIN Item_Category_Mappings on I.ItemID = Item_Category_Mappings.ItemID
                          left join CategoryMasters on CategoryMasters.categoryId = Item_Category_Mappings.categoryid AND CategoryMasters.IsShowOnKiosk = 'True' AND CategoryMasters.IsActive = 'True'
                          LEFT JOIN ItemImages ON I.ItemID = ItemImages.ItemID
                          LEFT JOIN ReadyItemStock as RIS ON I.ItemID = RIS.ItemID
                          inner JOIN ItemDayWiseMasters IDWM ON IDWM.ItemID = I.ItemID and IDWM.DayID = '${dayId}'
                          and IDWM.StartTime <= time('${currentTime}')
                          AND IDWM.EndTime >= time('${currentTime}')
                          AND IDWM.IsActive ='True'
                          Inner join CategoryDayWiseMasters CD ON CategoryMasters.CategoryID = CD.CategoryID AND CD.DayID = '${dayId}'
                          AND CD.StartTime <= time('${currentTime}')
                          AND CD.EndTime >= time('${currentTime}')
                          AND CD.IsActive = 'True'
                          Inner join CourseMastersIndex cmi on cmi.CourseID = I.CourseID and cmi.ItemID = I.ItemId
                      WHERE
                          I.CourseID = '${CID}'
                          and cmi.CourseID = '${CID}'
                          and I.IsActive = 'True'
                          and (I.StartDate <= '${currentDate}' OR I.StartDate = '')
                          AND (I.EndDate >= '${currentDate}' OR I.EndDate = '')
                      ORDER BY cast(cmi.DisplayOrder as int) 
                      `;

      // need to check exect functionality
      //  AND (
      //    RIS.CurrentStock > RIS.LowThreshold
      //  OR Items.IsItemReady = 'False'
      //)

      console.log('sqlCourseNo', sqlCourseNo)

      const errorCallback = (statement: any, error: any) => {
        console.log(
          'Upsell Service, Error getOrderLevelUpsellItemSql :- ',
          error
        );
      };
      /**
       * @param
       * @param results returned from database, with IsCombo field transformed to fit Boolean datatype
       */
      const showCategories = (tx: string, results: any) => {
        if (results.rows.length > 0) {
          let items = this.cartService.getAllCartItemIds()
          let item: any;
          for(let i=0;i<results.rows.length;i++){
            if(!(items.find((x)=>{return x==results.rows[i].ItemID}))){
              item=results.rows[i]
              break
            }
          }
          if(!item){
            item=results.rows[0]
          }
          if (item.IsCombo == 0) {
            item.IsCombo = false;
          } else {
            item.IsCombo = true;
          }
          const data: Item = item;
          data.SoldOut = CommonFunctions.isItemSoldOut(data);
          data.OrderMaximumQuantity = 99999;
          data.OrderMinimumQuantity = 1;
          data.AllowQuantitySelection = 'True';
          data.OrderMaximumQuantity = 99999;
          data.OrderMinimumQuantity = 1;
          data.Quantity = data.OrderMinimumQuantity.toString();
          resolve(data);
        }

        resolve(null);
      };

      DatabaseHandler.executeSqlStatement(
        sqlCourseNo,
        [],
        showCategories,
        errorCallback
      );
    });
  }

  getOrderLevelConcessionaireUpSellItemFromSql(courseID: string) {
    return new Promise<Item>((resolve: any, reject: any) => {
      let currentTime = CommonFunctions.getCurrentTime();
      let dayId = new Date().getDay();
      if (dayId == 0) {
        dayId = 7;
      }

      let currentDate = CommonFunctions.getCurrentformatedtDate(new Date());

      let getUpsellItems = `
            select DISTINCT

              items.ItemID,
              case when items.displayname = '' then items.name
                                 else items.displayname
                             end as Name,
              case when items.displayname = '' then items.name
                    else items.displayname
                end as DisplayName,
              items.KOTorKDSDisplayName AS KOTDisplayName,
              items.FullDescription,
              items.IsItemDetailScreenShowKiosk AS  IsItemDetailScreenShowKiosk,
              items.fulldescriptionlang as FullDescriptionLang,
              items.ShortDescription,
              items.shortdescriptionlang as ShortDescriptionLang,
              items.taxgroupid as TaxGroupId,

              items.price as Price,
              items.calorie as Calorie,
              items.MaxCalories AS MaxCalories,
              items.itemcategorysalestypeid as ItemCategorySalesTypeID,
              (
                select  imageurl
                  from  itemimages
                  where  itemimages.itemid == items.itemid
                  limit  1
               ) as ImageUrl,
              Case when CategoryMasters.DisplayName = '' then CategoryMasters.Name else CategoryMasters.DisplayName end AS CategoryName,
              items.IsBuilderMode,
              items.isboxbuildermode as IsBoxBuilderMode,
              items.IsAskForName,
              items.RefItemId,
              items.orderminimumquantity as OrderMinimumQuantity,
              items.ordermaximumquantity as OrderMaximumQuantity,
              items.IsCombo,
              items.lowstockactivity as LowStockActivity,
              items.EnablePricebyWeight as EnablePricebyWeight,
              RIS.CurrentStock AS CurrentStock,
              RIS.LowThreshold AS LowThreshold,
              (SELECT GROUP_CONCAT(DISTINCT(FM.IconImage)) FROM Items AS I
                  JOIN FilterMasters AS FM
                    ON FM.FilterID = IFM.FilterID
                    AND I.IsDeleted = 'False'
                  JOIN Item_Filter_Mappings as IFM
                    ON IFM.ItemID = I.ItemID
                    WHERE I.IsActive = 'True' AND items.ItemID = I.ItemID
                    GROUP BY I.ItemID) as ItemTags,
              CategoryMasters.CategoryID AS CategoryID,
              mm.modifiercount as ModifierCount,
              ia.attributecount as AttributeCount,
              cim.ConcessionaireID as ConcessionaireId,
              conM.Name as ConcessionaireName,
              conM.ColorCode as ConcessionaireColorCode

          from
              Concessionaire_Item_Mappings as cim 
              inner join ConcessionaireMasters as conM on conM.ConcessionaireID = cim.ConcessionaireID 
              inner join ConcessionaireDeviceMappings as cdm on cdm.ConcessionaireID = conM.ConcessionaireID `;

      if (GeneralSetting.getIsAssignSpecificConcessionaire()) {
        getUpsellItems += ` and cdm.staffModuleId = '${GeneralSetting.getStaffModuleID()}'`;
      }

      getUpsellItems += ` Inner JOIN items ON items.ItemID = cim.ItemID
                    and items.isdeleted = 'False'
                    and items.IsActive = 'True'
                    and  items.isshowonkiosk = 'True'
                    and  items.iscomboitemonly = 'False'
                    and (items.StartDate <= '${currentDate}' OR items.StartDate = '')
                    AND(items.EndDate >= '${currentDate}' OR items.EndDate = '')
              Inner JOIN Item_Category_Mappings ON Item_Category_Mappings.itemID = items.ItemID
              Inner join CategoryMasters on CategoryMasters.categoryId = Item_Category_Mappings.categoryid
                      AND CategoryMasters.isdeleted = 'False' and CategoryMasters.isactive = 'True'
                      AND CategoryMasters.IsShowOnKiosk = 'True'
              Inner join CategoryDayWiseMasters CD ON CategoryMasters.CategoryID = CD.CategoryID AND CD.DayID = '${dayId}'
                      AND CD.StartTime <= time('${currentTime}')
                      AND CD.EndTime >= time('${currentTime}')
                      AND CD.IsActive = 'True'                      
              inner JOIN ItemDayWiseMasters IDWM ON IDWM.ItemID = items.ItemID and IDWM.DayID = '${dayId}'
                      and IDWM.StartTime <= time('${currentTime}')
                      AND IDWM.EndTime >= time('${currentTime}')
                      AND IDWM.IsActive ='True'
              LEFT JOIN ReadyItemStock as RIS ON items.ItemID = RIS.ItemID
               left  join (select distinct  itemId,case when modifierCount > 0 then 1 else 0 end as modifiercount from (select mm.itemId,
                                    count(mm.itemid) as modifiercount
                                  from modifiermasters as mm
                                  group by mm.itemid
                                  union
                                  select cmim.itemid, count(cmim.itemid) as modifiercount
                                  from commonmodifier_item_mappings as cmim
                                    inner join modifiermasters as mm on mm.ModifierId = cmim.ModifierId
                                  group by cmim.itemId)
                                  where modifiercount > 0) as mm on mm.itemid = items.itemid
                      left  join  (
                             select  case when ia.itemid = '0' then caim.itemid
                                          else ia.itemid
                                      end as itemid
                                    ,count(ia.itemid) as attributecount
                               from  itemattributes as ia
                               left  join  commonattribute_item_mappings as caim
                                 on  caim.itemattributeid = ia.itemattributeid
                              group  by  ia.itemid
                                    ,caim.itemid
                             ) as ia
                        on  ia.itemid = items.itemid
                   where
                  Items.CourseID = '${courseID}'
      `;

      const errorCallback = (statement: any, error: any) => {
        console.log(
          'Database Service , Error get Concessionaire UpSellItemsByItemId from sql',
          error.message
        );

        resolve(null);
      };

      const setModifiers = (tx: string, results: any) => {
        if (results.rows.length > 0) {
          if (results.rows[0].IsCombo == '0') {
            results.rows[0].IsCombo === false;
          } else {
            results.rows[0].IsCombo === true;
          }

          results.rows[0].AllowQuantitySelection = 'True';
          results.rows[0].SoldOut = CommonFunctions.isItemSoldOut(
            results.rows[0]
          );
          results.rows[0].IconImages =
            results.rows[0].ItemTags?.toString().split(',');
          results.rows[0].Quantity = '1';

          const data: Item = results.rows[0];
          data.OrderMaximumQuantity = 99999;
          data.OrderMinimumQuantity = 0;
          resolve(data);
        }

        resolve(null);
      };
      DatabaseHandler.executeSqlStatement(
        getUpsellItems,
        [],
        setModifiers,
        errorCallback
      );
    });
  }
}
