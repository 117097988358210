import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  CloudOrderSubmitRequest,
  CloudOrderValidateRequest,
  CloudRewardsToApply,
  CloudOrderProduct,
  CloudOrderChoice,
  CloudOrderCart,
  CloudOrderCustomer,
  CloudOrderPayment
} from "../models/CloudOrderProductReq";
import { environment } from "../../environments/environment";
import { DatabaseHandler } from "../DatabaseHandler";
import { OloIntegration } from "../models/BranchLoginModels";
//import { OrderTypeEnum } from "../models/OrderType";
import { CloudOrderSubmitResponse, CloudOrderValidateResponse } from "../models/CloudOrderResponse";
import { ItemV2, ModifierV2, ModifierIngredientV2 } from "../models/item";
import { GeneralSetting } from "src/app/services/generalSetting.service";
import { loggingData, LogService } from "src/app/services/log.service";
import { Coupon, OLODiscountRequest } from "../models/OLODiscount";
import { CustomFields } from "../models/BranchLoginModels";
import { CustomField } from "../models/OLOProductReq";
import { Observable, of } from "rxjs";
import _, { chain, forEach } from "lodash";
import { SMBDiscount } from "./smb-discount.service"



@Injectable({
  providedIn: "root",
})
export class CloudOrderService {
  // validationRes = {} as CloudOrderValidateResponse;

  // orderRes = {} as CloudOrderSubmitResponse;

  oloIntegrationTableData = {} as OloIntegration;

  //isOLO: boolean = false;

  //isCalculationByOLO: boolean = false;

  apiUrl: string;

  currentCloudOrderId: string | null = null;

  constructor(
    private readonly http: HttpClient,
    private readonly logger: LogService,
  ) {
    this.apiUrl = environment.apiUrl;
this.handleOloDataFetch()

  }
 public async handleOloDataFetch(){
  this.getOloIntegrationData()
  .toPromise()
  .then((data) => {
    if (data == null || data == undefined || data.length <= 0) {
      //this.isOLO = false;
      return;
    }

    this.oloIntegrationTableData = data[0];
    // this.isOLO = !(!this.oloIntegrationTableData.vendorID || this.oloIntegrationTableData.vendorID == null ||
    //   this.oloIntegrationTableData.vendorID.trim() === ""
    // );
  });
 }

  // TODO: call this when an order is restarted, so we clear out the current state
  public RestartOrder() {
    this.currentCloudOrderId = null;
  }

  public async ValidateOrder(
    locationId: string,
    cart : CloudOrderCart,
    customer: CloudOrderCustomer,
    orderInvoiceNo: string,
  ) : Promise<CloudOrderValidateResponse> {

    const url = this.apiUrl + "v1/CloudOrderCalculate";
    const validationData = this.getCloudValidationRequest(locationId, cart, customer, orderInvoiceNo);

    // if (basketId && basketId != "") {
    //   validationData.orderId = orderId;
    // }

    this.logger.sendLogToServer(new loggingData(
      "Cloud Validation Request ",
      `Cloud Validation Request CloudOrderValidate
        Invoice No :- ${orderInvoiceNo}
      `,
      "Request Log",
      JSON.stringify(validationData),
      true
    ));

    var response = await this.http.post<CloudOrderValidateResponse>(url, validationData)
                      .toPromise()
                      .then(r => {
                          this.currentCloudOrderId = r.orderId;
                          return r;
                      });

    if(response?.result === "success") {
      this.logger.sendLogToServer(new loggingData(
        "Cloud Validation Resposne Success",
        `Cloud Validation Resposne CloudOrderValidate
          Invoice No :- ${orderInvoiceNo}
        `,
        "cloud-order.service.ts",
        JSON.stringify(validationData),
        true
      ));
    } else {
      this.logger.sendLogToServer(new loggingData(
        "Cloud Validation Resposne Error",
        `Cloud Validation Resposne CloudOrderValidate
          Invoice No :- ${orderInvoiceNo}
        `,
        "cloud-order.service.ts",
        JSON.stringify(validationData),
        true
      ));
    }
    return response;
  }

  public async SubmitOrder(
    locationId: string,
    orderInvoiceNo: string,
    orderToken: string,
    payment : CloudOrderPayment,
    customer? : CloudOrderCustomer,
    // customerName: string,
    // customerEmail: string,
    // paymentTypeId: number,
    // thirdPartyTypeId: number,
    // creditCardType: string | null,
    // orderTotal: number,
    // orderTip: number | null
    //auth: string
  ) : Promise<CloudOrderSubmitResponse> {

    const url = this.apiUrl + "v1/CloudOrderSubmit";

    const orderSubmitReq = {
        locationId : locationId,
        invoiceNo: orderInvoiceNo,
        orderToken: orderToken,
        orderId: this.currentCloudOrderId,
        payment: payment,
        customer: customer
    } as CloudOrderSubmitRequest;

    this.logger.sendLogToServer(new loggingData(
      "Status of Order sent to Clover:  Request ",
      `Status of Order sent to Clover:  Request
        Invoice No :- ${orderInvoiceNo}
      `,
      "cloud-order.service.ts",
      JSON.stringify(orderSubmitReq),
      true
    ));

    const cloudResponse = await this.http.post<CloudOrderSubmitResponse>(url, orderSubmitReq)
                    .toPromise()
                    .then(resp =>  {
                        return resp;
                    });

    if(cloudResponse?.result === "success") {
      this.logger.sendLogToServer(new loggingData(
        "Status of Order sent to Clover:  Resposne Success",
        `Cloud Validation Resposne CloudOrderValidate
          Invoice No :- ${orderInvoiceNo}
        `,
        "cloud-order.service.ts",
        JSON.stringify(cloudResponse),
        true
      ));
    } else {
      this.logger.sendLogToServer(new loggingData(
        "Status of Order sent to Clover Resposne  Error",
        `Cloud Validation Resposne CloudOrderValidate
          Invoice No :- ${orderInvoiceNo}
        `,
        "cloud-order.service.ts",
        JSON.stringify(cloudResponse),
        true
      ));
    }
    return cloudResponse
  }

  private getOloIntegrationData() {
    const query = `select * from oloIntegrations where branchId ='${GeneralSetting.getBranchId()}'`;

    return DatabaseHandler.getDataFromQuery<OloIntegration[]>(query);
  }

  private getCloudValidationRequest(
    locationId: string,
    cart : CloudOrderCart,
    customer: CloudOrderCustomer,
    orderInvoiceNo: string,
    //auth: string
  ) : CloudOrderValidateRequest {
    const validationData = {} as CloudOrderValidateRequest;

    const orderType: any = GeneralSetting.getOrderTypeId();
    validationData.orderType = orderType;

    validationData.existingOrderId = this.currentCloudOrderId; // will usually be null;
    validationData.locationId = locationId;
    validationData.cart = cart;
    validationData.customer = customer

    //validationData.authtoken = auth;
    //validationData.orderId = this.currentOrder.OrderId;
    //this.validationRes.data ? (this.validationRes.data.basketid ? this.validationRes.data.basketid : "") : "";

    //validationData.customfields = await this.getCustomFields();

    const cloudRewardsToApply = {
      membershipid: "0",
      references: [] as string[],
    } as CloudRewardsToApply;
    validationData.rewards = cloudRewardsToApply;

    return validationData;
  }

  public GetCart(cartItems: ItemV2[], tip:number, discount?:SMBDiscount) : CloudOrderCart {
    const items = this.getValidationProductData(cartItems);
    let cart = new CloudOrderCart(items, tip, discount);
    return cart;
  }

  private async getCustomFields() {
    const allFiled = await this.getCustomFieldsFromSQl();
    const customFields: CustomField[] = [];

    for (let i = 0; i < allFiled.length; i++) {
      const field = allFiled[i];

      // NOTE: this is broken with the new Portal - OrderTypeId isn't a hard-coded number.
      // we should be looking at tag maybe/
      if (field.qualificationcriteria == "DineInOrdersOnly" && GeneralSetting.getOrderTypeId() == "1" && GeneralSetting.getTableTentNumber() != "") {

        const customField: CustomField = {
          id: Number(field.id),
          value: GeneralSetting.getTableTentNumber()
        };
        customFields.push(customField);
      }
    }

    return customFields;
  }

  private getSpecialRequestItem(specialRequest: string) {
    let sr: string;

    if (this.oloIntegrationTableData &&
      Object.entries(this.oloIntegrationTableData).length > 0 &&
      this.oloIntegrationTableData.ThirdPartyPrefixForAllItemSpecialRequests.toLowerCase() == "true"
    ) {
      sr = (this.oloIntegrationTableData.PrefixText ?? "") + (specialRequest ? "_" + specialRequest : "");
    } else {
      sr = specialRequest ?? "";
    }

    return sr ?? "";
  }

  private getValidationProductData(
      cartItems: ItemV2[]
      ) : CloudOrderProduct[] {

    const listOLOProduct = [] as CloudOrderProduct[];
    for(let cartItem of cartItems) {

      const product = {} as CloudOrderProduct;
      product.itemId = cartItem.ItemID;
      product.quantity = isNaN(Number(cartItem.Quantity)) ? 1 : Number(cartItem.Quantity);
      product.note = cartItem.specialRequest;
      
      //oloProduct.specialinstructions = this.getSpecialRequestItem(objCartItem.specialRequest);
      product.choices = [] as CloudOrderChoice[];
      var selectedMods = cartItem.Modifiers.filter((x) => x.IsSelected);

      for(var modCat of selectedMods) {
        var choices = this.mapSelectionsToChoices(modCat.ModifierID, modCat.Ingredients);

        choices.forEach((c) => {
          product.choices.push(c);
        });
      }

      listOLOProduct.push(product);
    }

    return listOLOProduct;
  }

  private mapSelectionsToChoices(
            parentModGroupId: string,
            mods: (ModifierV2 | ModifierIngredientV2)[]
  ): CloudOrderChoice[] {

    const choiceList = [] as CloudOrderChoice[];

    var selectedMods = mods.filter((x) => x.IsSelected);

    for(let objMod of selectedMods) {
      // const objProjectModifier = {} as CloudOrderChoice;
      // objProjectModifier.quantity = isNaN(Number(objMod.Quantity)) ? 1 : Number(objMod.Quantity);
      // objProjectModifier.choices = [] as CloudOrderChoice[];

      if (objMod.IsIngredient) {
        var choice = this.ingredientToChoice(parentModGroupId, objMod as ModifierIngredientV2);
        choiceList.push(choice);
      } else {
        const modifier = objMod as ModifierV2;

        if (modifier.isOloOptionGroup.toLowerCase() != "true") {
          // this is the nested modifier path.  The ingredient selected is
          // a modifier.
          var choice = this.nestedModifierSelectionToChoice(parentModGroupId, modifier);
          choiceList.push(choice);
        } else {
          const choices = this.mapSelectionsToChoices(
            modifier.ModifierID,
            modifier.Ingredients
          );

          choices.forEach((c) => choiceList.push(c));
        }
      }
    }
    return choiceList;
  }

  private ingredientToChoice(
            modifierGroupId: string,
            ing : ModifierIngredientV2
  ) : CloudOrderChoice {

    const choice = {} as CloudOrderChoice;
    choice.quantity = isNaN(Number(ing.Quantity)) ? 1 : Number(ing.Quantity);
    choice.choices = [] as CloudOrderChoice[];
    choice.optionGroupId = modifierGroupId;
    choice.choiceId = ing.IngredientID;
    return choice;
  }
  private nestedModifierSelectionToChoice(
            modifierGroupId: string,
            modifier: ModifierV2
  ) : CloudOrderChoice  {

    const choice = {} as CloudOrderChoice;
    choice.quantity = isNaN(Number(modifier.Quantity)) ? 1 : Number(modifier.Quantity);
    choice.choices = [] as CloudOrderChoice[];

    choice.optionGroupId = modifierGroupId;
    choice.choiceId = modifier.ModifierID;
    choice.choices = this.mapSelectionsToChoices(
      modifier.ModifierID,
      modifier.Ingredients
    );
    return choice;
  }

  public GetCustomer(customerName: string, email: string) : CloudOrderCustomer {

    let customer = new CloudOrderCustomer();
    customer.emailaddress = email;
    customer.firstname = this.getCustomerName(customerName);
    customer.lastname = this.getCustomerLastName();
    customer.phone = GeneralSetting.getCustomerPhone();
    return customer;
  }

  private getCustomerName(customerName: string) {
    let name: string = "";
    const orderType: any = GeneralSetting.getOrderTypeId();

    if (!customerName) {
      customerName = "Guest";
    }
    if (
      !this.oloIntegrationTableData ||
      Object.entries(this.oloIntegrationTableData).length <= 0
    ) {
      name += orderType + "-" + customerName;
      return name;
    }

    if (this.oloIntegrationTableData.CustomerNamePrefix) {
      name += this.oloIntegrationTableData.CustomerNamePrefix;
    }
    if (this.oloIntegrationTableData.IsAppendOrderTypeName) {
      name += orderType
    }

    if(name && !name.endsWith("-")) {
      name += "-";
    }

    name += customerName;
    return name;
  }

  private getCustomerLastName() {
    let name = "";

    if (
      this.oloIntegrationTableData.IsShowTableTentPrefix.toLowerCase() == "true"
    ) {
      const tableTentNumber = GeneralSetting.getTableTentNumber();
      if (tableTentNumber) {
        name = 'Table Tent: ' + GeneralSetting.getTableTentNumber();
      }    }

    return name;
  }

  private getCustomFieldsFromSQl() {
    return new Promise<CustomFields[]>((resolve: any) => {
      const getModifier = `
        select * from customFields
      `;

      const logError = () => {
        resolve([] as CustomFields[]);
      };

      const setItems = (transaction: String, results: any) => {

        if (results && results.rows.length > 0) {

          resolve(results.rows as CustomFields[]);
        }
        else {
          resolve([] as CustomFields[]);
        }
      };

      DatabaseHandler.executeSqlStatement(getModifier, [], setItems, logError);
    });
  }



}
