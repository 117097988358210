import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Concessionaire } from 'src/app/models/BranchLoginModels';
import { Guid } from 'src/app/models/Guid';
import {
  ComboGroupV2,
  ItemV2,
  ModifierIngredientV2,
  ModifierV2,
} from 'src/app/models/item';
import { CartService } from 'src/app/services/cart.service';
import { CategorySalesType, CommonFunctions, Snackbar } from 'src/app/services/common';
import { DataService } from 'src/app/services/data.service';
import { DatabaseService } from 'src/app/services/database.service';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { ItemTransferService } from 'src/app/services/item-transfer.service';
import { LanguageService } from 'src/app/services/language.service';
import { LastfiveorderService } from 'src/app/services/lastfiveorder.service';
import { environment } from 'src/environments/environment';
import { BoxBuilderItemComponent } from '../../box-builder-item/box-builder-item.component';
import { UpSellDialogComponent } from '../../dialogs/up-sell-dialog/up-sell-dialog.component';
// import { BoxBuilderComponent } from '../box-builder.component';

@Component({
  selector: 'app-box-builder-review',
  templateUrl: './box-builder-item-review.component.html',
  styleUrls: ['./../box-builder.component.css'],
})
export class BoxBuilderReviewComponent implements OnInit, OnDestroy {
  selectedCat!: string;
  cat!: string;
  parentCat!: string;
  ref: any;
  comboItemFlags: any[] = [];
  item: ItemV2 = new ItemV2();
  selectedCount: number = 0;
  comboGroups: ComboGroupV2[] = [];
  boxBuilder: any;
  itemImageList: any[] = [];
  selectedItemList: ItemV2[] = [];
  @Input() numInvisibleGroups: number = 0;
  requisitesForCartItem: any;
  allSubscription: Subscription[] = [];
  concessionaireId: string = '';
  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  reviewBoxText: string = '';
  editText: string = '';
  placeholderImage: string = '';
  addRemainingAText: string = ''
  addRemainingBText: string = ''
  addToCartText: string = ''
  donutsText: string = ''
  selectedText: string = ''
  isConcessionaire: boolean = false;
  concessionaire: Concessionaire = {} as Concessionaire;
  upSellModelRef: any;

  upSellLength: number = 0;

  upSellCartItems = [] as ItemV2[];
  quantityWhenAdd: number = 1;
  constructor(
    private readonly itemTransferService: ItemTransferService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly modalService: NgbModal,
    private readonly isKioskService: IsKioskService,
    private readonly db: DatabaseService,
    private readonly cartService: CartService,
    private readonly lastfiveorderService: LastfiveorderService,
    private language: LanguageService,
    private readonly dataService: DataService
  ) {
    this.concessionaireId = route.snapshot.queryParams['concessionaireId'];
    this.allSubscription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    )
  }
  ngOnDestroy() {
    if (this.allSubscription.length > 0) {
      for (let i = 0; i < this.allSubscription.length; i++) {
        this.allSubscription[i].unsubscribe();
      }
    }
  }
  loadText() {
    this.reviewBoxText = this.language.getTextElement('review_your_box');
    this.editText = this.language.getTextElement('edit');
    this.addRemainingAText = this.language.getTextElement('add_remaining_a');
    this.addRemainingBText = this.language.getTextElement('add_remaining_b');
    this.addToCartText = this.language.getTextElement('lbl_add_to_cart');
    this.donutsText = this.language.getTextElement('add_remaining_b');
    this.selectedText = this.language.getTextElement('add_remaining_b');

  }

  open(
    selectedItemCount: number,
    item: ItemV2,
    comboItemFlags: any[],
    itemImageList: any[],
    requisitesForCartItem: any,
    numInvisibleGroups: number,
    isConcessionaire: boolean,
    concessionaire: any
  ) {
    var ref = this.modalService.open(BoxBuilderReviewComponent, {
      backdrop: 'static',
      windowClass: 'don-list-modal',
      modalDialogClass: 'itemModal',
      centered: true,
      size: 'xl',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.comboItemFlags = comboItemFlags;
    ref.componentInstance.item = item;
    ref.componentInstance.selectedCount = selectedItemCount;
    ref.componentInstance.comboGroups = item.ComboGroup;
    ref.componentInstance.itemImageList = itemImageList;
    ref.componentInstance.requisitesForCartItem = requisitesForCartItem;
    ref.componentInstance.numInvisibleGroups = numInvisibleGroups;
    ref.componentInstance.isConcessionaire = isConcessionaire;
    ref.componentInstance.concessionaire = concessionaire;
    this.ref = ref;
  }
  ngOnInit(): void {
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.selectedCat = this.route.snapshot.queryParams['selectedCat'];
    this.cat = this.route.snapshot.queryParams['cat'];
    this.parentCat = this.route.snapshot.queryParams['parentCat'];
    this.setConsolidateItems();
  }

  setConsolidateItems() {
    this.selectedItemList = [];
    this.comboGroups.forEach((comboGroup) => {
      if (!comboGroup.isHidden) {
        comboGroup.Items.forEach((comboItem) => {
          if (comboItem.isSelected) {
            if (
              this.selectedItemList.find(
                (x) => x.BoxBuilderItemGUID == comboItem.BoxBuilderItemGUID
              ) == undefined
            ) {
              this.selectedItemList.push(comboItem);
            }
          }
        });
      }
    });
    if (this.ref) {
      this.ref.selectedItemList = this.selectedItemList;
    }
  }

  cancel(msg: string = '') {
    if (msg == 'Add_Remaining') {
      this.updateItemImageList();
      let res = {
        message: 'Add_Remaining',
        data: this.comboGroups,
        itemImageList: this.itemImageList,
      };
      this.ref.close(res);
    } else {
      this.updateItemImageList();
      this.ref.close(msg);
    }
  }

  increaseQuantity(comboItemObj: ItemV2) {
    if (
      this.selectedCount <
      this.comboGroups.length - this.numInvisibleGroups
    ) {
      // let pos = 0;
      // for (let i = 0; i < this.comboItemFlags.length; i++) {
      //   const element = this.comboItemFlags[i];
      //   if (element. == comboItemObj) {
      //     pos = i; break;
      //   }
      // }
      let quantity = comboItemObj.ItemConsolidateQuantity + 1;
      let itemNew = JSON.parse(JSON.stringify(comboItemObj)) as ItemV2;
      this.deselectItem(comboItemObj as ItemV2);
      this.setItemQuantitySelection(itemNew as ItemV2, quantity);
    }
  }

  decreaseQuantity(comboItemObj: ItemV2) {
    // let pos = 0;
    // for (let i = 0; i < this.comboItemFlags.length; i++) {
    //   const element = this.comboItemFlags[i];
    //   if (element == comboItemObj) {
    //     pos = i; break;
    //   }
    // }
    let quantity = comboItemObj.ItemConsolidateQuantity - 1;
    let itemNew = JSON.parse(JSON.stringify(comboItemObj)) as ItemV2;
    this.deselectItem(comboItemObj as ItemV2);
    this.setItemQuantitySelection(itemNew as ItemV2, quantity);
    if (this.selectedCount == 0) {
      this.cancel('Add_Remaining');
    }
  }

  private hasItemSelected(comboGroup: ComboGroupV2): boolean {
    var selectedCount = 0;
    for (let i = 0; i < comboGroup.Items.length; i++) {
      const item = comboGroup.Items[i];
      if (item.isSelected) {
        selectedCount++;
      }
    }
    return selectedCount == Number(comboGroup.CountOption);
  }

  private selectedItemCount() {
    this.selectedCount = 0;
    // this.itemImageList = [];
    for (let i = 0; i < this.comboGroups.length; i++) {
      if (!this.comboGroups[i].isHidden) {
        const items = this.comboGroups[i].Items;
        for (let j = 0; j < items.length; j++) {
          if (items[j].isSelected) {
            // this.itemImageList.push({ 'image': this.baseImageUrl + items[j].ImageUrl, 'selected': true });
            this.selectedCount++;
          }
        }
      }
    }
    this.setConsolidateItems();
  }

  private setItemQuantitySelection(
    item: ItemV2,
    quantity: number,
    itemPos?: number
  ) {
    var count = 0;
    if (this.comboGroups && this.comboGroups.length > 0) {
      for (let i = 0; i < this.comboGroups.length; i++) {
        if (!this.comboGroups[i].isHidden) {
          let items = this.comboGroups[i].Items;
          if (this.hasItemSelected(this.comboGroups[i])) {
            continue;
          } else {
            for (let j = 0; j < items.length; j++) {
              const comboItem = items[j];
              if (comboItem.ItemID == item.ItemID) {
                count++;
                if (count <= quantity) {
                  comboItem.isSelected = true;
                  comboItem.Quantity = '1';
                  comboItem.Modifiers = item.Modifiers;
                  comboItem.guid = item.guid;
                  comboItem.BoxBuilderItemGUID = item.BoxBuilderItemGUID;
                  comboItem.ItemConsolidateQuantity = quantity;
                  this.comboGroups[i].Items[j] = comboItem;

                  // item.ItemConsolidateQuantity = count;
                  // this.comboGroups[0].Items[itemPos] = item;
                  if (count == quantity) {
                    // comboItem.ItemConsolidateQuantity = count;
                    this.updateComboItemFlag(comboItem, true);
                  }
                  break;
                }
              }
            }
          }
        }
      }
    }
    this.selectedItemCount();
  }
  deselectItem(item: ItemV2, itemPos?: number) {
    var count = 0;
    var quantity: number = item.ItemConsolidateQuantity;
    if (this.comboGroups && this.comboGroups.length > 0) {
      for (let i = 0; i < this.comboGroups.length; i++) {
        let items = this.comboGroups[i].Items;
        if (this.hasItemSelected(this.comboGroups[i])) {
          for (let j = 0; j < items.length; j++) {
            const comboItem = items[j];
            if (
              comboItem.ItemID == item.ItemID &&
              comboItem.BoxBuilderItemGUID == item.BoxBuilderItemGUID &&
              comboItem.isSelected
            ) {
              count++;
              if (count <= quantity) {
                comboItem.isSelected = false;
                comboItem.Quantity = '1';
                comboItem.ItemConsolidateQuantity = 0;
                let itemNew = JSON.parse(JSON.stringify(item)) as ItemV2;
                //New function
                if (itemNew.Modifiers && itemNew.Modifiers.length > 0) {
                  this.setModifierDefaultSelected(itemNew.Modifiers);
                }

                comboItem.Modifiers = itemNew.Modifiers;
                this.comboGroups[i].Items[j] = comboItem;
                this.updateComboItemFlag(comboItem, false);

                // break;
              }
            }
          }
        }
      }
    }
    this.selectedItemCount();
  }
  setModifierDefaultSelected(modifiers: (ModifierV2 | ModifierIngredientV2)[]) {
    for (let i = 0; i < modifiers.length; i++) {
      const modifier = modifiers[i];
      if (modifier.IsModifier) {
        let modGroup = modifier as ModifierV2;
        modGroup.IsSelected = false;
        this.setModifierDefaultSelected(modGroup.Ingredients);
      } else {
        let ingredient = modifier as ModifierIngredientV2;
        ingredient.IsSelected =
          ingredient.IsDefault == 'True' && ingredient.IsIngredient86 == 'True';
      }
    }
  }
  updateComboItemFlag(comboItem: ItemV2, flag: boolean) {
    for (let i = 0; i < this.comboItemFlags.length; i++) {
      const element = this.comboItemFlags[i];
      if (element.ItemID == comboItem.ItemID) {
        element.selected = flag;
        element.item = comboItem;
        this.comboItemFlags[i] = element;
      }
    }
  }
  deleteItem(comboItem: ItemV2) {
    this.deselectItem(comboItem);
    if (this.selectedCount == 0) {
      this.cancel('Add_Remaining');
    }
  }

  // addToCart() {
  //   this.ref.close("addToCart");
  // }

  editItemV2(item: ItemV2, pos: number, isCombo: boolean) {
    this.updateItemImageList();
    this.boxBuilder = new BoxBuilderItemComponent(
      this.itemTransferService,
      this.router,
      this.route,
      this.db,
      this.modalService,
      this.isKioskService,
      this.cartService
    );
    const modalReference = this.boxBuilder.open(
      item,
      isCombo,
      true,
      this.itemImageList,
      this.comboGroups.length,
      this.selectedCount,
      this.numInvisibleGroups,
      true
    );
    let oldQuantity = item.ItemConsolidateQuantity;
    modalReference.result.then((result: ItemV2 | null) => {
      if (result) {
        if (this.comboGroups && this.comboGroups.length > 0) {
          const resItem = result as ItemV2;
          resItem.isSelected = true;
          const quantity = isNaN(resItem.ItemConsolidateQuantity as any)
            ? 1
            : resItem.ItemConsolidateQuantity;
          // this.setItemQuantitySelection(resItem, quantity, pos);
          // update quantity in review screen

          let itemNew = JSON.parse(JSON.stringify(result)) as ItemV2;
          result.ItemConsolidateQuantity = oldQuantity;
          this.deselectItem(result);
          let updatedQuantity = itemNew.ItemConsolidateQuantity;
          // this.setItemQuantitySelection(itemNew, updatedQuantity);
          this.setItemQuantitySelection(resItem, updatedQuantity, pos);
        }
      } else {
      }
    });
  }

  isAdding: boolean = false

  async addToCart() {
    if (this.isAdding) return
    this.isAdding = true
    if (this.item.guid && this.item.guid != '') {
      this.addItemsToCart();
    }
    else {
      let data = await this.db.getUpSellItemsByItemId(
        this.item.ItemID,
        this.isConcessionaire,
        this.concessionaire?.ConcessionaireID
      );

      this.upSellLength = data.length;

      if (this.upSellLength == 0) {
        this.addItemsToCart();
      } else {
        const currentCountofAlkol = Number(
          GeneralSetting.getCurrentCountofAlkol()
        );
        const maxOrder = Number(GeneralSetting.getMaxAlcoholicItemsPerOrder());
        if (
          currentCountofAlkol == maxOrder &&
          !CommonFunctions.isNonAlcoholicItemAvail(data)
        ) {
          this.addItemsToCart();
        } else {
          var currentItemQty =
            this.item.Quantity == undefined ? 1 : this.item.Quantity;
          if (
            Number(currentItemQty) + currentCountofAlkol === maxOrder &&
            this.item.ItemCategorySalesTypeID ===
            CategorySalesType.Alcohol.toString() &&
            !CommonFunctions.isNonAlcoholicItemAvail(data)
          ) {
            this.addItemsToCart();
          } else {
            this.openUpSellModel();
          }
        }
      }
    }


    // let res = await this.cartService
    //   .addToCartAsync(this.createCartCombo(this.requisitesForCartItem))
    //   .then(() => {
    //     this.redirectToPageAfterAddToCart();
    //     this.ref.close();
    //   }).finally(()=>{
    //     this.isAdding = false
    //   })
  }

  private openUpSellModel() {
    const itemTypeStr = CommonFunctions.getItemTypeV2(this.item);
    let itemType: number = 1;

    if (itemTypeStr == 'ItemBuilderMode') {
      itemType = 2;
    }

    let usdc = UpSellDialogComponent.open(
      this.modalService,
      this.language,
      this.item.ItemID,
      this.quantityWhenAdd,
      itemType,
      this.item
    );

    usdc.result.then((result: any) => {
      const objItem = this.createCartCombo(this.requisitesForCartItem);

      if (result != 'cancel') {
        this.upSellCartItems = result;
        const upSellItems: ItemV2[] = [];

        for (let upSellItem of this.upSellCartItems) {
          upSellItem.IsUpSellItem = true;
          upSellItem.UpSellItemID = objItem.ItemID;
          upSellItem.UpSellItemGuid = objItem.guid;
          upSellItem.UpSellItemPrice = CommonFunctions.getItemPrice(objItem);
          upSellItem.totalPrice = CommonFunctions.getItemPrice(upSellItem);

          if (this.isConcessionaire) {
            if (
              this.concessionaire &&
              Object.entries(this.concessionaire).length > 0
            ) {
              upSellItem.ConcessionaireId =
                this.concessionaire.ConcessionaireID;
              upSellItem.ConcessionaireName = this.concessionaire.Name;
              upSellItem.ConcessionaireColorCode =
                this.concessionaire.ColorCode;
            }
          }

          upSellItems.push(upSellItem);
        }

        this.addItemsToCart(upSellItems);
      } else {
        this.addItemsToCart();
      }
    });
  }

  async addItemsToCart(upSellItems: ItemV2[] = []) {
    let res = await this.cartService
      .addToCartAsync(this.createCartCombo(this.requisitesForCartItem))
      .then(() => {
        if (upSellItems.length > 0) {
          for (let i = 0; i < upSellItems.length; i++) {
            upSellItems[i].UpSellItemGuid = this.item.guid;
            this.cartService.addToCartAsync(upSellItems[i]);
          }
        }
        this.redirectToPageAfterAddToCart();
        this.ref.close();
      }).finally(() => {
        this.isAdding = false
      })
  }
  private createCartCombo(requisitesForCartItem?: any): ItemV2 {
    const cartItem = JSON.parse(JSON.stringify(this.item)) as ItemV2;
    cartItem.guid = this.item.guid ? this.item.guid : Guid.newGuid();
    cartItem.Modifiers = requisitesForCartItem.modifiers;
    cartItem.VariationOption = requisitesForCartItem.variations;
    cartItem.ComboGroup = this.item.ComboGroup;
    cartItem.Quantity = requisitesForCartItem.quantityWhenAdd.toString();
    cartItem.AllowQuantitySelection =
      cartItem.AllowQuantitySelection == ''
        ? 'True'
        : cartItem.AllowQuantitySelection;
    cartItem.GuestName = requisitesForCartItem.itemGuestName;
    cartItem.specialRequest = requisitesForCartItem.specialRequest;
    cartItem.totalPrice =
      CommonFunctions.getItemPrice(cartItem) * Number(cartItem.Quantity);
    return cartItem;
  }

  private redirectToPageAfterAddToCart() {
    if (this.checkIfFromLastFivePageForCombo()) {
      this.router.navigateByUrl('/lastfiveorder').then(() => {
        this.lastfiveorderService.updateitemList(
          this.requisitesForCartItem.itemID,
          this.requisitesForCartItem.variations,
          this.requisitesForCartItem.modifiers
        );
      });
      GeneralSetting.setIntoCombo('false');
    } else {
      if (this.getParameterByName('isFromReview') === 'true') {
        this.router.navigateByUrl('/orderReview');
      } else {
        this.router.navigate(['/menu'], {
          queryParams: {
            category: this.item.CategoryID,
            id: this.item.ItemID,
            concessionaireId: this.concessionaireId,
          },
        });
      }
    }
  }

  private checkIfFromLastFivePageForCombo() {
    const checkIfLastfiveorder = GeneralSetting.getLastFiveOrderChosen();
    const intocombo = GeneralSetting.getIntoCombo();

    if (
      checkIfLastfiveorder &&
      checkIfLastfiveorder === 'true' &&
      intocombo &&
      intocombo === 'true'
    )
      return true;

    return false;
  }

  getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  updateItemImageList() {
    this.selectedCount = 0;
    this.itemImageList = [];
    for (let i = 0; i < this.comboGroups.length; i++) {
      if (!this.comboGroups[i].isHidden) {
        const items = this.comboGroups[i].Items;
        for (let j = 0; j < items.length; j++) {
          if (items[j].isSelected) {
            this.itemImageList.push({
              image: this.baseImageUrl + items[j].ImageUrl,
              selected: true,
            });
            this.selectedCount++;
          }
        }
      }
    }

    var itemImageLength = this.itemImageList.length;
    for (
      let i = 0;
      i < this.comboGroups.length - itemImageLength - this.numInvisibleGroups;
      i++
    ) {
      this.itemImageList.push({
        image: '../../../assets/images/box-builder/plain.png',
        selected: false,
      });
    }
  }
  getPrimaryColor() {
    return GeneralSetting.getPrimaryColor();
  }
  getAccentColor() {
    return GeneralSetting.getAccentColor();
  }
}
