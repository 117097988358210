<div *ngIf="discounts.length" class="discounts">
  <button
    *ngIf="!selectedDiscount"
    class="discount-button background-positive-button-color positive-button-text-color"
    (click)="selectDiscountIter3()"
  >
     {{couponCodeText}}
  </button>
  <div *ngIf="selectedDiscount" class="discount-removal-row">
    <div class="discount-text">
      "{{ selectedDiscount!.Name }}" {{discountAppliedText}}
    </div>
    <button class="discount-button background-negative-button-color negative-button-text-color" (click)="selectDiscountIter3()">
     {{removeDiscountText}}
    </button>
  </div>
  <!-- <p class="discount-display">Available Discounts</p>
  <div class="discount-section">
    <div *ngFor="let discount of discounts">
      <div [ngStyle]="{
          borderColor:
          (selectedDiscount&&discount.DiscountID == selectedDiscount.DiscountID)
              ? 'green'
              : ''
        }" (click)="selectDiscount2(discount)" class="discount-box">
        <img class="discount-image" src="assets/images/ic_discount_offer.png"
          onerror="this.onerror=null; this.src='assets/images/Image_21.png'" alt="" />
        <p class="discount-name">{{ discount.Name }}</p>
        <p class="discount-price" 
            *ngIf="discount.DiscountAmount != '0' && discount.UsePercentage == 'False'"
        >
            {{ discount.DiscountAmount | number: "1.2" | CurrencyPipe }}
        </p>
        <p class="discount-price" *ngIf="discount.UsePercentage== 'True'">
          {{ discount.DiscountAmount }}%
        </p>
      </div>
    </div>
  </div> -->
</div>
<!-- -->
