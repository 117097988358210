import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { VariationOption, Variation } from '../../models/Variations';
import { IsKioskService } from '../../services/is-kiosk.service';
import { ModifierV2, ItemV2 } from '../../models/item';
import { ModifierContainerComponent } from '../modifier-container/modifier-container.component';
import { Guid } from '../../models/Guid';
import { CartService } from '../../services/cart.service';
import { GeneralSetting } from '../../services/generalSetting.service';

import { CommonFunctions } from '../../services/common';

import { PusherService } from 'src/app/services/pusher.service';
import { DatabaseService } from 'src/app/services/database.service';
import { loggingData, LogService } from 'src/app/services/log.service';
import { ItemService } from '../../services/ItemService';
import { ItemBuilderModeSelectionComponent } from '../item-builder-mode/item-builder-mode-selection/item-builder-mode-selection.component';
import { LoadingComponent } from '../../loading/loading.component';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { LoaderService } from 'src/app/services/loader.service';
import { Integration, IntegrationService } from 'src/app/services/integration.service';
import { ParBrinkService } from 'src/app/services/par-brink.service';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-combo-item-popup',
  templateUrl: './combo-item-popup.component.html',
  styleUrls: ['./combo-item-popup.component.css'],
})
export class ComboItemPopupComponent implements OnInit {
  ngOnDestroy() {
    for(let i = 0; i < this.allSubsCription.length; i++) {
      this.allSubsCription[i].unsubscribe();
    }
  }

  allSubsCription:Subscription[] = [];

  modalReference: any;

  ItemID: string = '0';

  CategoryID: string = '0';

  isReady: boolean = false;

  item: ItemV2 | null = null;

  ItemType: number = 2;

  ItemName: string = '';

  @ViewChild(ModifierContainerComponent)
  modifierContainer!: ModifierContainerComponent;

  @ViewChild(ItemBuilderModeSelectionComponent)
  builderModeSelection!: ItemBuilderModeSelectionComponent;

  isModifiersValid!: boolean;

  isVariationsValid!: boolean;

  isComboGroupValid!: boolean;

  isSubCateogryItem = false;
  //mods selected and methods

  customizeText: string = '';
  updateText: string = '';
  setSelectedModifiers(values: {
    inputModifier: ModifierV2[];
    isValid: boolean;
  }) {
    this._selectedModifiers = Array.from(values.inputModifier);
    this.isModifiersValid = values.isValid;
    this.checkIsReady();
  }

  //variations selected and methods
  setSelectedVariations(values: VariationOption[]) {
    this._selectedVariations = values;

    if (this.item && this.item.Variations && this.item.Variations.length > 0) {
      for (let i = 0; i < this.item.Variations.length; i++) {
        let variation = this.item.Variations[i];

        for (let j = 0; j < variation.variationOptions.length; j++) {
          if (
            variation.variationOptions[j] &&
            variation.variationOptions[j].ItemAttributeOptionID ==
              values[0].ItemAttributeOptionID
          ) {
            variation.IsSelected = true;
            variation.variationOptions[j].isSelected = true;
          } else {
            variation.variationOptions[j].isSelected = false;
          }
        }
      }
    }

    this.checkIsReady();
  }

  modifiers: ModifierV2[] = [] as ModifierV2[];

  variations: Variation[] = [] as Variation[];

  _selectedModifiers: ModifierV2[] = [];

  _selectedVariations: VariationOption[] = [];

  setModifiersValid(isValid: boolean) {
    this.isModifiersValid = isValid;
    this.checkIsReady();
  }

  setVariationsValid(isValid: boolean) {
    this.isVariationsValid = isValid;
    this.checkIsReady();
  }

  positiveColor: string = '';

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;
  
  placeholderImage : string = '';

  constructor(
    private readonly modalService: NgbModal,
    private readonly isKioskService: IsKioskService,
    private readonly cartService: CartService,
    private readonly pusherService: PusherService,
    private readonly db: DatabaseService,
    private logger: LogService,
    private readonly cdr: ChangeDetectorRef,
    private readonly itemService: ItemService,
    private language: LanguageService,
    private loader:LoaderService,
    private parBrink:ParBrinkService,
    private integration:IntegrationService,
    private readonly dataService : DataService
  ) {}

  bordersTypeItem: boolean = false;
  
  imageRadius: string = '';
  
  loadText() {
    this.customizeText = this.language.getTextElement('txt_customize');
    this.updateText = this.language.getTextElement('lbl_select');
  }

  ngOnInit(): void {
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.allSubsCription.push(this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    }));

    this.positiveColor = GeneralSetting.getPositiveColor();

    if (this.item) this.setDetailByItem(this.item);

    this.imageRadius = GeneralSetting.getBorderPercentage();
    this.bordersTypeItem =
      GeneralSetting.getBorderTypeItems().toLowerCase() == 'true';
    this.BindPusher();
  }

  private BindPusher() {
    this.allSubsCription.push(
      this.pusherService.CommonModifier86.subscribe((x) => {
        if(x && Object.keys(x).length > 0){
          this.db.updateItem86Status(
            this.item as ItemV2,
            x.ItemId,
            x.Status,
            true,
            false,
            true
          );
        }
      })
    );

    this.allSubsCription.push(
      this.pusherService.Ingredient86.subscribe((x) => {
        if(x && Object.keys(x).length > 0){
          this.db.updateItem86Status(
            this.item as ItemV2,
            x.ItemId,
            x.Status,
            false,
            true,
            true
          );
          if (this.builderModeSelection) this.builderModeSelection.checkModifierValid();
        }
      })
    );

    this.allSubsCription.push(
      this.pusherService.Item86ModifierDetails.subscribe((x) => {
        if(x && Object.keys(x).length > 0){
          this.db.updateItem86Status(
            this.item as ItemV2,
            x.ItemId,
            x.Status,
            false,
            true,
            true
          );
          if (this.builderModeSelection) this.builderModeSelection.checkModifierValid();
        }
      })
    );
  }

  // on getting item from category subscription set all detail in component
  private setDetailByItem(categoryItem: ItemV2) {
    if (!categoryItem) return;

    const item = JSON.parse(JSON.stringify(categoryItem)) as ItemV2;

    if (!item) return;

    if (item.Modifiers && item.Modifiers.length > 0) {
      this.modifiers = item.Modifiers;

      const check = (el: any, parent86: boolean = false) => {
        if (el.IsModifier) {
          if (el.Ingredients && el.Ingredients.length) {
            for (let i = 0; i < el.Ingredients.length; i++) {
              check(el.Ingredients[i], el.IsModifier86 == 'False');
            }
          }
        } else {
          if (parent86 || el.isIngredient86 == 'False') {
            el.IsSelected = false;
            if (el.IsDefault) {
              el.isNegativeModifier = true;
            }
          }
        }
      };
      for (let i = 0; i < this.modifiers.length; i++) {
        check(this.modifiers[i], this.modifiers[i].IsModifier86 == 'False');
      }
    } else {
      this.getAndSetModifier(item.ItemID);
    }

    if (item.Variations && item.Variations.length > 0) {
      this.variations = item.Variations;
    } else {
      this.db.getPersistentVariationsAsync(item.ItemID).then((variations) => {
        this.variations = variations;

        if (this.item) {
          this.item.Variations = JSON.parse(JSON.stringify(variations));

          if (this.builderModeSelection) {
            this.builderModeSelection.isFirstTimeLoad = true;
            this.builderModeSelection.variations = variations;
            this.builderModeSelection.ngOnInit();
          }
        }
      });
    }

    this.item = item;
  }

  private getAndSetModifier(itemId: string) {
    //const modPer = performance.now();

    //console.log("modifier Performance :- " + modPer)

    this.db
      .getModifierByItemIDFromSql(itemId)
      .then(async (allModifier: ModifierV2[]) => {
        this.loader.openLoader();

        this.modifiers = await this.itemService.getAllModifierIngredients(
          allModifier
        );
        if(this.integration.integration==Integration.Parbrink){
          let parBrinkMods:any =await this.db.parBrinkOverride(this.item,this.modifiers)
          this.modifiers = this.parBrink.parBrinkModifierOverride(parBrinkMods, this.modifiers)
        }
        //const modPerEnd = performance.now();

        //console.log("modifier Performance End :- " + modPerEnd);

        //console.log("Category Per End :- " + ((modPerEnd - modPer) / 1000));

        if (this.item) {
          this.item.Modifiers = JSON.parse(JSON.stringify(this.modifiers));
        }

        this.loader.closeLoader();

        if (this.builderModeSelection) {
          this.cdr.detectChanges();
          this.builderModeSelection.ngOnInit();
        }
      });
  }

  private checkIsReady() {
    let isValid = false;

    if (
      this.item &&
      (!this.item.Modifiers || this.item.Modifiers.length <= 0) &&
      (!this.item.Variations || this.item.Variations.length <= 0)
    ) {
      isValid = true;
    } else {
      if (this.modifiers) {
        if (this.isModifiersValid == undefined) {
          if (
            this.item &&
            this.item.Modifiers &&
            this.item.Modifiers.length > 0
          ) {
            isValid = false;
          } else {
            isValid = true;
          }
        } else {
          isValid = this.isModifiersValid;
        }
      }

      if (
        isValid &&
        this.item?.Variations &&
        this.item.Variations.length > 0 &&
        this.variations &&
        this.variations.length > 0
      ) {
        isValid = this.isVariationsValid;
      }
    }

    // just to avoid having expressing change error in log
    // setTimeout(() => {
      this.isReady = isValid;
    // }, 500);
  }

  public static open(modalService:NgbModal,item: ItemV2, itemType: number = 1, isSubCategory: boolean = false) {
    let modalReference = modalService.open(ComboItemPopupComponent, {
      backdrop: 'static',
      windowClass: 'itemModal nestedItemModel',
      modalDialogClass: 'itemModal',
      centered: true,
      size: 'xl',
    });

    if (
      !item.IsCombo ||
      item.IsCombo.toString() == '0' ||
      item.IsCombo.toString() == ''
    ) {
      item.IsCombo = false;
    }

    modalReference.componentInstance.item = item;
    modalReference.componentInstance.CategoryID = '0';
    modalReference.componentInstance.ItemID = item.ItemID;
    modalReference.componentInstance.isComboItem = true;
    modalReference.componentInstance.ItemType = itemType;
    modalReference.componentInstance.isSubCateogryItem = isSubCategory;
    modalReference.componentInstance.modalReference = modalReference;
    return modalReference;
  }

  open(item: ItemV2, itemType: number = 1, isSubCategory: boolean = false) {
    const modalReference = this.modalService.open(ComboItemPopupComponent, {
      backdrop: 'static',
      windowClass: 'itemModal nestedItemModel',
      modalDialogClass: 'itemModal',
      centered: true,
      size: 'xl',
    });

    this.ItemID = item.ItemID;
    this.CategoryID = '0';

    if (
      !item.IsCombo ||
      item.IsCombo.toString() == '0' ||
      item.IsCombo.toString() == ''
    ) {
      item.IsCombo = false;
    }

    this.item = item;
    this.ItemType = itemType;
    this.modalReference = modalReference;
    this.modalReference.componentInstance.item = item;
    this.modalReference.componentInstance.CategoryID = '0';
    this.modalReference.componentInstance.ItemID = item.ItemID;
    this.modalReference.componentInstance.isComboItem = true;
    this.modalReference.componentInstance.ItemType = itemType;
    this.modalReference.componentInstance.isSubCateogryItem = isSubCategory;
    this.modalReference.componentInstance.modalReference = this.modalReference;
    return this.modalReference;
  }

  closeModel(data: any) {
    this.modalReference.close(data);
  }

  addToCart() {
    const item = this.createCartItem();

    this.closeModel(item);
  }

  private createCartItem(): ItemV2 {
    const cartItem = JSON.parse(JSON.stringify(this.item)) as ItemV2;

    cartItem.guid = Guid.newGuid();
    cartItem.Modifiers = this.modifiers;
    cartItem.VariationOption = this._selectedVariations;
    cartItem.ComboGroup = [];
    cartItem.Quantity = this.item!.Quantity ?? "1";
    cartItem.IsCombo = !(
      cartItem.IsCombo.toString() == '0' ||
      cartItem.IsCombo.toString().toLowerCase() == 'false'
    );
    cartItem.AllowQuantitySelection =
      cartItem.AllowQuantitySelection == ''
        ? 'True'
        : cartItem.AllowQuantitySelection;
    // cartItem.IsCategory = this.isSubCateogryItem;
    cartItem.totalPrice =
      CommonFunctions.getItemPrice(cartItem) * Number(cartItem.Quantity);

    return cartItem;
  }

  modifierAnimation() {
    if (this.modifierContainer) this.modifierContainer.modifierScroll();
  }
}
