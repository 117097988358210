import { SMBDiscount } from "../services/smb-discount.service"

export interface CloudOrderValidateRequest {

  locationId: string;
  existingOrderId: string | null;
  cart: CloudOrderCart;

  orderType: string;
  customer?: CloudOrderCustomer

  //invoice: string; 
  // firstname: string;
  // lastname: string;
  // emailaddress: string;
  //tip?: string;
  //customfields: CustomField[];
  products: CloudOrderProduct[];
  rewards: CloudRewardsToApply;

  //usertype: string;
  //authtoken: string;
  // punchh_authentication_token: string;
  // punchh_client: string;
  // punchh_secret: string;
}

export interface CloudOrderSubmitRequest {
  locationId: string;
  orderId: string;
  invoiceNo: string;
  orderToken: string;
  tableID: string;
  //cart: CloudOrderCart;
  customer?: CloudOrderCustomer;
  payment: CloudOrderPayment;
  discount?: SMBDiscount
  // orderId: string;
  // locationId: string;
  // invoice: string;
  // firstname: string;
  // lastname: string;
  // emailaddress: string;
  // thirdPartyTypeId: number;
  // creditCardType: string;
  // orderTotal: number;
  // orderTip: number;
}
export class CloudOrderCart {

  constructor(
    public items: CloudOrderProduct[],
    public tip: number,
    public discount: SMBDiscount | undefined
  ) {}
}

export class CloudOrderCustomer {
  firstname?: string;
  lastname?: string;
  emailaddress?: string;
  phone?: string;
}

export class CloudOrderPayment {
  paymentTypeId?: number;
  thirdPartyPaymentTypeId?: number;
  thirdPartyPaymentId?: string;
  thirdPartyOrderId?: string;
  creditCardType?: string;
}
// export interface CustomField {
//   id: number;
//   value: string;
// }

export interface CloudOrderProduct {
  itemId: string;
  quantity: number;
  choices: CloudOrderChoice[];
  note: string;
}

export interface CloudOrderChoice {
    choiceId: string;
    optionGroupId: string;
    quantity: number;
    choices: CloudOrderChoice[];
}


// export interface OloChainIdProductListReq {
//   invoice: string;
//   firstname: string;
//   lastname: string;
//   emailaddress: string;
//   vendorid: string;
//   basketId: string;
//   tip: string;
//   //customfields: CustomField[];
//   products: OLOChainProduct[];
//   oloRewardsToApply: OloRewardsToApply;
//   deliverymode: string;
//   authtoken: string;
// }


// export interface OLOChainProduct {
//   chainproductid: string;
//   quantity: number;
//   specialinstructions:string;
//   choices: ChainChoice[];
// }

// export interface ChainChoice {
//   chainchoiceid: string;
//   quantity: number;
//   choices: ChainChoice[];
// }

export interface CloudRewardsToApply {
  membershipid: string;
  references: string[];
}
