<!-- <div class="titles grid-container">
    <p [style.color]="bkgdColor" class="grid-item">ITEM</p>
    <p [style.color]="bkgdColor" class="grid-item">QTY</p>
    <p [style.color]="bkgdColor" class="grid-item"> PRICE</p>
</div> -->
<div class="cart-items">
  <ng-container *ngFor="let cartItem of cartItems;let i = index">
    <ng-container *ngIf="ConcessionaireId == '' || cartItem.ConcessionaireId == ConcessionaireId">

      <div class="tax-list">
        <div class="list-box">
          <div class="left-box">
            <div class="img-list">
              <div class="img-div">
                <img src="{{ getFormattedUrl(cartItem) }}" (click)="editItemV2(cartItem)"
                  onerror="this.onerror=null;" class="img-responsive"
                  [ngStyle]="{'border-radius': bordersTypeItem ? imageRadius : '0%'}" />
                <p *ngIf="cartItem.DiscountAmount>0 || cartItem.IsBOGO=='True'">
                  {{cartItem.DiscountName}}
                </p>
              </div>
              <div class="text-list">
                <div (click)="editItemV2(cartItem)" class="items">
                  <strong class="item-name">
                    {{
                    (cartItem.OrderReviewDisplayName && cartItem.OrderReviewDisplayName !='')
                    ? cartItem.OrderReviewDisplayName : cartItem.Name
                    }}
                  </strong>
                  <p class="item-price" *ngIf="cartItem.Price && cartItem.Price!='0.00' && !isRemovePricingAndPayments"
                    [style.color]="'#000000'">
                    {{
                    cartItem.Price | number:
                    "1.2-2" | CurrencyPipe
                    }}
                  </p>


                  <div class="">
                    <ng-container *ngIf="cartItem.Variations && cartItem.Variations.length > 0">

                      <div *ngFor="let variation of cartItem.Variations">
                        <h1 style="font-weight: bold;">
                          {{ variation.Name }}
                        </h1>
                        <div *ngFor="let option of variation.variationOptions">
                          <span *ngIf="option.isSelected" class="order-attribute" [ngStyle]="{'color':accentColor}">
                            <p>
                              {{option.OptionName}}
                              <span *ngIf="option.Price && option.Price!='0.00' && !isRemovePricingAndPayments"
                                [style.color]="'#000000'">
                                {{
                                option.Price | number:
                                "1.2-2" | CurrencyPipe
                                }}
                              </span>
                            </p>
                          </span>
                        </div>

                      </div>
                    </ng-container>

                    <ng-container *ngIf="cartItem.Modifiers && cartItem.Modifiers.length > 0">
                      <div
                        [innerHtml]="cartItem.Modifiers | functionCall : getFormattedModifier : isRemovePricingAndPayments ">
                      </div>
                    </ng-container>

                    <ng-container *ngIf="cartItem.ComboGroup && cartItem.ComboGroup.length > 0">

                      <!-- Check for box builder mode -->

                      <div *ngIf="cartItem.IsBoxBuilderMode && cartItem.IsBoxBuilderMode=='True'">
                        <div *ngFor="let comboItem of getComboItemsConsolidated(cartItem)">
                          <ng-container *ngIf="comboItem.isSelected">
                            <strong class="item-name">
                              {{ comboItem.Name }}<span
                                *ngIf="comboItem.ItemConsolidateQuantity && (comboItem.ItemConsolidateQuantity > 1)">
                                ({{
                                comboItem.ItemConsolidateQuantity
                                }})
                              </span>
                            </strong>
                            <span *ngIf="comboItem.Price && comboItem.Price!='0.00' && !isRemovePricingAndPayments"
                              [style.color]="'#000000'">
                              {{
                              comboItem.Price | number:
                              "1.2-2" | CurrencyPipe
                              }}
                            </span>
                            <br />

                            <div class="">
                              <ng-container *ngIf="comboItem.Variations && comboItem.Variations.length > 0">

                                <div *ngFor="let variation of comboItem.Variations">
                                  <!-- <p>
                                    {{ variation.Name }}
                                  </p> -->

                                  <div *ngFor="let option of variation.variationOptions">
                                    <span *ngIf="option.isSelected" class="order-attribute"
                                      [ngStyle]="{'color':accentColor}">
                                      <p>
                                        {{option.OptionName}}
                                        <span
                                          *ngIf="option.Price && option.Price!='0.00' && !isRemovePricingAndPayments"
                                          [style.color]="'#000000'">
                                          {{
                                          option.Price | number:
                                          "1.2-2" | CurrencyPipe
                                          }}
                                        </span>
                                      </p>
                                    </span>
                                  </div>

                                </div>

                              </ng-container>

                              <ng-container *ngIf="comboItem.Modifiers && comboItem.Modifiers.length > 0">
                                <div [innerHtml]="comboItem.Modifiers | functionCall : getFormattedModifier"></div>
                              </ng-container>

                            </div>

                            <!-- <br /> -->
                          </ng-container>
                        </div>
                      </div>
                      <!-- End of box builder mode -->
                      <div *ngIf="cartItem.IsBoxBuilderMode && cartItem.IsBoxBuilderMode=='False'">
                        <div *ngFor="let combo of cartItem.ComboGroup">

                          <div *ngFor="let comboItem of combo.Items">
                            <ng-container *ngIf="comboItem.isSelected">
                              <strong class="item-name">
                                {{ comboItem.Name }}<span *ngIf="comboItem.Quantity && (comboItem.Quantity != '1')">
                                  ({{ comboItem.Quantity }})
                                </span>
                              </strong>
                              <span *ngIf="comboItem.Price && comboItem.Price!='0.00' && !isRemovePricingAndPayments"
                                [style.color]="'#000000'">
                                {{
                                comboItem.Price| number:
                                "1.2-2" | CurrencyPipe
                                }}
                              </span>
                              <br />

                              <div class="">
                                <ng-container *ngIf="comboItem.Variations && comboItem.Variations.length > 0">

                                  <div *ngFor="let variation of comboItem.Variations">
                                    <!-- <p>
                                      {{ variation.Name }}
                                    </p> -->
                                    <div *ngFor="let option of variation.variationOptions">
                                      <span *ngIf="option.isSelected" class="order-attribute"
                                        [ngStyle]="{'color':accentColor}">
                                        <p>
                                          {{option.OptionName}}
                                          <span
                                            *ngIf="option.Price && option.Price!='0.00' && !isRemovePricingAndPayments"
                                            [style.color]="'#000000'">
                                            {{
                                            option.Price | number:
                                            "1.2-2" | CurrencyPipe
                                            }}
                                          </span>
                                        </p>
                                      </span>
                                    </div>

                                  </div>

                                </ng-container>

                                <ng-container *ngIf="comboItem.Modifiers && comboItem.Modifiers.length > 0">
                                  <div [innerHtml]="comboItem.Modifiers | functionCall : getFormattedModifier"></div>
                                </ng-container>

                              </div>

                              <!-- <br /> -->
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <div class="specialRequest">
                      <p class="specialRequest-text" *ngIf="cartItem.specialRequest && cartItem.specialRequest.length > 0">
                        Sp: {{ cartItem.specialRequest }}
                      </p>
                      <p *ngIf="cartItem.GuestName && cartItem.GuestName !== ''">
                        Customer Name: {{ cartItem.GuestName }}
                      </p>
                      <p *ngIf="
                        showCalorie === 'True' &&
                        cartItem.Calorie !== '' &&
                        cartItem.Calorie !== undefined
                      " style=" font-style: italic; color: #9e9e9e; font-size: smaller; font-weight: normal; ">
                        {{ cartItem.MaxCalories !=='' ? cartItem.Calorie + ' - ' + cartItem.MaxCalories + calorieText : cartItem.Calorie + calorieText }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="top-right">
            <div class="number-counter">
              <div class="number-content"
                *ngIf="!(cartItem.EnablePricebyWeight && cartItem.EnablePricebyWeight == 'True'); else weightLayout">
                <span
                  [ngStyle]="{'visibility':cartItem.AllowQuantitySelection=='True'&&!cartItem.IsLoyaltyDiscountItem?'visible':'hidden'}"
                  id="{{ cartItem.guid }}" class="input-number-decrement" (click)="decreaseQuantity(i)">
                  <img src="../../../../assets/images/minus.svg"
                    onerror="this.onerror = null;this.src = '../../../../assets/images/Image_21.png';" alt="-" />
                </span>
                <input id="{{ cartItem.guid }}" class="input-number" type="text"
                  value="{{ cartItem.Quantity| number:'1.0':'en-US' }}" min="0" max="" disabled />
                <span
                  [ngStyle]="{'visibility':cartItem.AllowQuantitySelection=='True'&&!cartItem.IsLoyaltyDiscountItem?'visible':'hidden'}"
                  id="{{ cartItem.guid }}" class="input-number-increment" (click)="increaseQuantity(i)">
                  <img src="../../../../assets/images/plus.svg" alt="+"
                    onerror="this.onerror=null; this.src='../../../../assets/images/Image_21.png';" />
                </span>
              </div>
              <ng-template #weightLayout>
                <div class="weightlayout" [ngStyle]="{'color': priceColor}">
                  {{cartItem.Weight}}
                  <p [ngSwitch]="cartItem.WeightUnitID">
                    <span *ngSwitchCase="0">oz.</span>
                    <span *ngSwitchCase="1">lb.</span>
                    <span *ngSwitchCase="2">g.</span>
                    <span *ngSwitchCase="3">kg.</span>
                    <span *ngSwitchDefault></span>
                  </p>
                </div>
              </ng-template>



              <p class="text-danger" *ngIf="cartItem.IsLoyaltyDiscountItem">
                {{editRewardText}}
              </p>
              <p class="text-danger" *ngIf="
                  !cartItem.IsLoyaltyDiscountItem &&
                  !(cartItem.AllowQuantitySelection == 'True')
                "
              >
                {{editUpsellText}}
              </p>
            </div>
            <div class="item-price-total" [ngStyle]="{ color: priceColor }" *ngIf="!isRemovePricingAndPayments">
              <h5 [style.textDecoration]="
                  cartItem.DiscountAmount>
                0 ? 'line-through' : ''
                " [style.color]="textColor" [style.color]="'#000000'" class="price-text">
                {{
                ((cartItem.totalPrice ? cartItem.totalPrice : 0)) | number:
                "1.2-2" | CurrencyPipe
                }}
              </h5>
              <p class="item-discount" *ngIf="cartItem.DiscountAmount > 0">
                {{
                (((cartItem.totalPrice ? cartItem.totalPrice : 0.00)) -
                (cartItem.DiscountAmount>0?cartItem.DiscountAmount:0.00)) | numberConvert
                | number: "1.2-2"
                | CurrencyPipe
                }}
              </p>
            </div>

            <div class="delete-div">
              <span class="warn">
                <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg"
                  id="{{ cartItem.ItemID }}" (click)="remove(cartItem)">
                  <path
                    d="M3.5 27C3.5 27.7957 3.81607 28.5587 4.37868 29.1213C4.94129 29.6839 5.70435 30 6.5 30H21.5C22.2956 30 23.0587 29.6839 23.6213 29.1213C24.1839 28.5587 24.5 27.7957 24.5 27V9H27.5V6H21.5V3C21.5 2.20435 21.1839 1.44129 20.6213 0.87868C20.0587 0.31607 19.2956 0 18.5 0H9.5C8.70435 0 7.94129 0.31607 7.37868 0.87868C6.81607 1.44129 6.5 2.20435 6.5 3V6H0.5V9H3.5V27ZM9.5 3H18.5V6H9.5V3ZM8 9H21.5V27H6.5V9H8Z"
                    fill="#666666" />
                  <path d="M9.5 12H12.5V24H9.5V12ZM15.5 12H18.5V24H15.5V12Z" fill="#666666" />
                </svg>

              </span>
            </div>
          </div>
        </div>
        <div class="tax-div" *ngIf="!isOLO && !isRemovePricingAndPayments && !isTaxIncluded">
          <p class="tax-left" *ngIf="!isTaxHidden && cartItem.TaxAmount">
            {{ taxType == "2" ? "VAT " : "Item Tax" }} <span [style.color]="'#000000'">({{ cartItem.TaxPercentage |
              number: "1.0-3" }}%)</span>
          </p>

          <p class="tax-right" *ngIf="!isTaxHidden && cartItem.TaxAmount" [style.color]="'#000000'">
            {{ cartItem.TaxAmount | number: "1.2-2" | CurrencyPipe }}
          </p>
        </div>
      </div>

    </ng-container>

  </ng-container>

  <ng-container *ngIf="isConcessionaire">
    <div class="tax-list conDiscountList" *ngIf="conDiscount > 0">
      <div class="list-box">
        <div class="left-box">
          <div class="img-list">
            <div class="img-div">



            </div>
            <div class="text-list">

            </div>
          </div>
        </div>

        <div class="top-right">
          <div class="number-counter">
            <div class="number-content">
              Discount
            </div>
          </div>
          <div class="item-price-total" [ngStyle]="{ color: 'green' }" *ngIf="!isRemovePricingAndPayments">
            <p>- ${{ conDiscount }} </p>
          </div>

          <div class="delete-div">
            <span class="warn">

            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="tax-list conDiscountList" *ngIf="conSubTotal > 0">
      <div class="list-box">
        <div class="left-box">
          <div class="img-list">
            <div class="img-div"></div>
            <div class="text-list"></div>
          </div>
        </div>

        <div class="top-right">
          <div class="number-counter">
            <div class="number-content">
              <b class="store-total">Store Total</b>
            </div>
          </div>
          <div class="item-price-total" [ngStyle]="{ color: priceColor }" *ngIf="!isRemovePricingAndPayments">
            <p>{{ (conSubTotal - conDiscount ) | number: "1.2-2" | CurrencyPipe}}</p>
          </div>

          <div class="delete-div">
            <span class="warn">

            </span>
          </div>
        </div>
      </div>
      <div class="tax-div">
        <p class="tax-left">

        </p>

        <p class="tax-right" *ngIf="!isTaxHidden" [style.color]="'#000000'">

        </p>
      </div>
    </div>
  </ng-container>
</div>
