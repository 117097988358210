
export class StringUtils {
    static discount_items_not_available = "You dont have enough items to apply this discount,do you want to add them to cart?";
    static only_one_discount_apply_msg = "We can only apply one discount per order,Do you want to use this discount instead?";
    static yes = "Yes";
    static no = "No";
}
export enum ALERT_TYPE {
    DISCOUNT_REMOVE_ALERT = 1,
    DISCOUNT_NOT_APPLY = 2
}