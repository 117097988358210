import { PrinterService } from 'src/app/services/printer.service';
import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CategoryReport, CreditCardsSalesBreakdownListItem } from 'src/app/models/categoryReport';
import { DataService } from 'src/app/services/data.service';
import { loggingData, LogService } from 'src/app/services/log.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-settings-z-report',
  templateUrl: './settings-z-report.component.html',
  styleUrls: ['./settings-z-report.component.css'],
})
export class SettingsZReportComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  categories: CategoryReport[] = [];
  totalCategorySales: string = '0.00';
  totalCategoryQty: string = '0.00';
  dataService: DataService;
  @Input() zReport: any;

  dt: Date = new Date();

  selectedDate: Date = new Date();
  grossSalesDisplay: string = '0.00';
  discounts: string = '0.00';
  refunds: string = '0.00';
  netSales: string = '0.00';
  fees: string = '0.00';
  taxes: string = '0.00';
  tips: string = '0.00';
  amountCollect: string = '0.00';
  openOrders: string = '0.00';
  totalOrders: string = '0.00';
  totalCashOrders: string = '0.00';
  totalCreditOrders: string = '0.00';
  totalCash: string = '0.00';
  totalCredit: string = '0.00';
  kioskSales: string = '0.00';
  totalTenderOrders: string = '0.00';
  totalTenderNetSales: string = '0.00';
  totalTenderOrderTaxes: string = '0.00';
  totalTenderOrderTips: string = '0.00';
  totalTenderOrderFees: string = '0.00';
  totalTenderAmountCollected: string = '0.00';
  totalHouseOrders: string = '0.00';
  totalHouseNetSales: string = '0.00';
  totalHouseOrderTaxes: string = '0.00';
  totalHouseOrderTips: string = '0.00';
  totalHouseOrderFees: string = '0.00';
  totalHouseAmountCollected: string = '0.00';
  totalNetOtherOrders: string = '0.00';
  totalNonCashFees: string = '0.00';
  orderDiscounts: string = '0.00';
  loyaltyDiscounts: string = '0.00';
  totalDiscounts: string = '0.00';
  totalGiftCardRedemptions: string = '0.00';
  complimentary: string = '0.00';
  categoryTotal: string = '0.00';
  totalRefunds: string = '0.00';
  canceledOrdersTotal: string = '0.00';
  cashTips: string = '0.00';
  creditCardTips: string = '0.00';
  tenderTips: string = '0.00';
  houseTips: string = '0.00';
  totalTips: string = '0.00';
  zReportDisplay: any = [];
  creditCardsSales: CreditCardsSalesBreakdownListItem[] = [];

  constructor(
    DataService: DataService,
    private logger: LogService,
    private datePipe: DatePipe,
    private printService: PrinterService,
    private language: LanguageService
  ) {
    this.dataService = DataService;
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    )
  }
  grossSalesText:string=''
  zReportText:string=''
  netSalesText:string=''
  totalText:string=''
  printText:string=''
  discountsLoyaltyText:string=''
  refundsText:string=''
  feesText:string=''
  taxesText:string=''
  tipsText:string=''
  amountCollectedText:string=''
  openOrdersText:string=''
  paymentTypesText:string=''
  totalOrdersText:string=''
  totalCashText:string=''
  totalCreditText:string=''
  channelSalesText:string=''
  kioskSalesText:string=''
  otherPaymentSummaryText:string=''
  totalTenderOrdersText:string=''
  totalTenderOrderTaxesText:string=''
  totalTenderNetSalesText:string=''
  totalTenderOrderTipsText:string=''
  totalTenderOrderFeesText:string=''
  totalTenderAmountCollectedText:string=''
  totalHouseOrdersText:string=''
  totalHouseNetSalesText:string=''
  totalHouseOrderTaxesText:string=''
  totalHouseOrderTipsText:string=''
  totalHouseOrderFeesText:string=''
  totalHouseAmountCollectedText:string=''
  totalNetOtherOrdersText:string=''
  feesSummaryText:string=''
  totalNonCashFeesText:string=''
  discountLoyaltySummaryText:string=''
  orderDiscountsText:string=''
  loyaltyDiscountsText:string=''
  totalDiscountsText:string=''
  totalGiftRedemptionsText:string=''
  complimentaryText:string=''
  salesByCategoryText:string=''
  totalSalesText:string=''
  canceledOrdersSummaryText:string=''
  totalRefundsText:string=''
  tipSummaryText:string=''
  cashTipsText:string=''
  creditCardTipsText:string=''
  tenderTipsText:string=''
  houseTipsText:string=''
  totalTipsText:string=''
  creditCardSalesText:string=''

  loadText() {
    this.zReportText = this.language.getTextElement('lbl_reports_z')
    this.grossSalesText = this.language.getTextElement('lbl_reports_gross_sales')
    this.netSalesText = this.language.getTextElement('lbl_reports_net_sales')
    this.zReportText = this.language.getTextElement('text_total')
    this.printText = this.language.getTextElement('lbl_print')
    this.discountsLoyaltyText = this.language.getTextElement('lbl_discounts_loyalty')
    this.refundsText = this.language.getTextElement('refunds')
    this.feesText = this.language.getTextElement('lbl_fees')
    this.taxesText = this.language.getTextElement('lbl_taxes')
    this.tipsText = this.language.getTextElement('lbl_tips')
    this.amountCollectedText = this.language.getTextElement('lbl_amount_collected')
    this.openOrdersText = this.language.getTextElement('lbl_open_orders')
    this.paymentTypesText = this.language.getTextElement('payment_types')
    this.totalOrdersText = this.language.getTextElement('lbl_total_orders')
    this.totalCashText = this.language.getTextElement('lbl_total_cash')
    this.totalCreditText = this.language.getTextElement('lbl_total_credit')
    this.channelSalesText = this.language.getTextElement('channel_sales')
    this.kioskSalesText = this.language.getTextElement('lbl_kiosk_sales')
    this.otherPaymentSummaryText = this.language.getTextElement('other_payment_types_summary')
    this.totalTenderOrdersText = this.language.getTextElement('lbl_total_tender_orders')
    this.totalTenderNetSalesText = this.language.getTextElement('lbl_total_tender_net_sales')
    this.totalTenderOrderTaxesText = this.language.getTextElement('total_tender_order_taxes')
    this.totalTenderOrderTipsText = this.language.getTextElement('lbl_total_tender_order_tips')
    this.totalTenderOrderFeesText = this.language.getTextElement('lbl_total_tender_order_fees')
    this.totalTenderAmountCollectedText = this.language.getTextElement('lbl_total_tender_amount_collected')
    this.totalHouseOrdersText = this.language.getTextElement('lbl_total_house_orders')
    this.totalHouseNetSalesText = this.language.getTextElement('lbl_total_house_net_sales')
    this.totalHouseOrderTaxesText = this.language.getTextElement('lbl_total_house_order_taxes')
    this.totalHouseOrderTipsText = this.language.getTextElement('lbl_total_house_order_tips')
    this.totalHouseOrderFeesText = this.language.getTextElement('lbl_total_house_order_fees')
    this.totalHouseAmountCollectedText = this.language.getTextElement('lbl_total_house_amount_collected')
    this.totalNetOtherOrdersText = this.language.getTextElement('lbl_total_net_other_orders')
    this.feesSummaryText = this.language.getTextElement('fees_summary')
    this.totalNonCashFeesText = this.language.getTextElement('lbl_total_non_cash_fees')
    this.discountLoyaltySummaryText = this.language.getTextElement('discount_loyalty_summary')
    this.orderDiscountsText = this.language.getTextElement('lbl_order_discounts')
    this.loyaltyDiscountsText = this.language.getTextElement('lbl_loyalty_discounts')
    this.totalDiscountsText = this.language.getTextElement('lbl_total_discounts')
    this.totalGiftRedemptionsText = this.language.getTextElement('lbl_total_gift_card_redemptions')
    this.complimentaryText = this.language.getTextElement('lbl_complimentary')
    this.salesByCategoryText = this.language.getTextElement('sales_by_category')
    this.totalSalesText = this.language.getTextElement('lbl_total_sales')
    this.canceledOrdersSummaryText = this.language.getTextElement('canceled_orders_summary')
    this.totalRefundsText = this.language.getTextElement('lbl_total_refunds')
    this.tipSummaryText = this.language.getTextElement('tip_summary')
    this.cashTipsText = this.language.getTextElement('lbl_cash_tips')
    this.creditCardTipsText = this.language.getTextElement('lbl_credit_card_tips')
    this.tenderTipsText = this.language.getTextElement('lbl_tender_tips')
    this.houseTipsText = this.language.getTextElement('lbl_house_tips')
    this.totalTipsText = this.language.getTextElement('lbl_total_tips')
    this.totalText = this.language.getTextElement('text_total')
    this.creditCardSalesText = this.language.getTextElement('credit_card_sales_breakdown')
  }
  ngOnInit(): void {
    this.parseData(this.zReport)
    // const input = document.querySelector("input[type='date']");
    // if (input != null) (<HTMLInputElement>input).valueAsDate = new Date();
  }

  setDate(e: any) {
    // this.dateDisplay = e.target.value;
    this.dt = e;
    const a = this.datePipe.transform(this.dt, 'MMM d YYYY');
    // on date change reset categories array
    this.categories = [];
    this.allSubsCription.push(
      this.dataService
        .getZreport(a!.toString(), a!.toString())
        .subscribe(
          (val) => {
            this.zReport = val;
            this.parseData(JSON.parse(JSON.stringify(val)));
            var log = new loggingData(
              'Z Report',
              'Success',
              'General',
              'Z report from cloud are successful',
              false
            );

            this.logger.sendLogToServer(log);
          },
          (response) => {
            var log = new loggingData(
              'POS Z report',
              'POS Z report Api Failed',
              'POS Z report',
              response,
              true
            );

            this.logger.sendLogToServer(log);
          },
          () => { }
        )
    );

    var date = Date.parse(e);
    this.selectedDate = new Date(date);

    this.dt = this.selectedDate;
  }

  dateTime() {}

  parseData(data: any): void {
    var zData = data.data.NewZReport.ZReport;
    // console.log(zData);

    // let keys = Object.keys(zData);
    // let values = Object.values(zData);

    // for (var i = 0; i < keys.length; i++) {
    //   keys[i] = keys[i].replace(/([a-z])([A-Z])/g, '$1 $2');
    //   this.zReportDisplay.push({ keys: keys[i], values: values[i] });
    // }

    // console.log(keys, this.zReportDisplay, zData);

    var len: Number = zData.SalesByCategoryList
      ? zData.SalesByCategoryList.length
      : 0;
    for (var i = 0; i < len; i++) {
      var categoryData = zData.SalesByCategoryList[i];
      var category: CategoryReport = {
        name: categoryData.CategoryName,
        quantity: categoryData.TotalItemQty,
        sales: categoryData.TotalItemTotal,
      };
      this.categories.push(category);
    }

    if (zData && zData.SalesByCategoryList && zData.SalesByCategoryList[0]) {
      this.totalCategoryQty = zData.SalesByCategoryList
        ? zData.SalesByCategoryList[0].TotalSalesByCategoryQty
        : 0;
      this.totalCategorySales = zData.SalesByCategoryList
        ? zData.SalesByCategoryList[0].TotalSalesByCategoryAmount
        : 0;
    } else {
      this.totalCategorySales = '0.00';
      this.totalCategoryQty = '0.00';
    }

    this.grossSalesDisplay = zData.TotalGrossSales;
    this.discounts = zData.TotalDiscounts;
    this.refunds = zData.TotalRefunds;
    this.netSales = zData.TotalNetSales;
    this.fees = zData.TotalFees;
    this.taxes = zData.TotalTaxes;
    this.tips = zData.TotalTips;
    this.amountCollect = zData.AmmountCollected;
    this.openOrders = zData.OpenOrders;
    this.totalOrders = zData.TotalOrders;
    this.totalCashOrders = zData.TotalCashOrders;
    this.totalCreditOrders = zData.TotalCreditOrders;
    this.totalCash = zData.TotalCash;
    this.totalCredit = zData.TotalCredit;
    this.kioskSales = zData.KioskSales;
    this.totalTenderOrders = zData.TotalTenderOrders;
    this.totalTenderNetSales = zData.TotalTenderNetSales;
    this.totalTenderOrderTaxes = zData.TotalTenderOrderTaxes;
    this.totalTenderOrderTips = zData.TotalTenderOrderTips;
    this.totalTenderOrderFees = zData.TotalTenderOrderFees;
    this.totalTenderAmountCollected = zData.TotalTenderGrossSales;
    this.totalHouseOrders = zData.TotalHouseOrders;
    this.totalHouseNetSales = zData.TotalHouseNetSales;
    this.totalHouseOrderTaxes = zData.TotalHouseOrderTaxes;
    this.totalHouseOrderTips = zData.TotalHouseOrderTips;
    this.totalHouseOrderFees = zData.TotalHouseOrderFees;
    this.totalHouseAmountCollected = zData.TotalHouseGrossSales;
    this.totalNetOtherOrders = zData.TotalNetOtherOrders;
    this.totalNonCashFees = zData.TotalNonCashFees;
    this.orderDiscounts = zData.OrderDiscounts;
    this.loyaltyDiscounts = zData.LoyaltyDiscounts;
    this.totalDiscounts = zData.TotalDiscounts;
    this.totalGiftCardRedemptions = zData.TotalGiftCardRedemptions;
    this.complimentary = zData.Complimentary;
    this.totalRefunds = zData.TotalRefunds;
    this.cashTips = zData.CashTips;
    this.creditCardTips = zData.CreditCardTips;
    this.tenderTips = zData.TenderTips;
    this.houseTips = zData.HouseTips;
    this.totalTips = zData.TotalTips;
    this.canceledOrdersTotal = zData.TotalCanceledOrdersSummary;

    this.creditCardsSales = [];
    var len: Number = zData.CreditCardsSalesBreakdownList
    ? zData.CreditCardsSalesBreakdownList.length
    : 0;
    for (var i = 0; i < len; i++) {
      var creditCardSalesData = zData.CreditCardsSalesBreakdownList[i];
      var creditCardSales: CreditCardsSalesBreakdownListItem = {
        NetworkName : creditCardSalesData.NetworkName,
        OrderTotal: creditCardSalesData.OrderTotal,
      };
      this.creditCardsSales.push(creditCardSales);
    }
  }

  print() {
    let zData = this.zReport.data.NewZReport.ZReport;
    // console.log('printing z report', zData);
    this.printService.KioskDataPrint({
      type: 'ZReport',
      data: zData,
    });
  }
}
