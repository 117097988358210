import { Component, OnDestroy, OnInit } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { interval, Subscription } from 'rxjs'
import { LanguageService } from 'src/app/services/language.service'

@Component({
  selector: 'app-upgrade-kiosk',
  templateUrl: './upgrade-kiosk-app.component.html',
  styleUrls: ['./upgrade-kiosk-app.component.css'],
})
export class UpgradeKioskAppComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private modalRef: any;

  private allSubsCription: Subscription[] = [];

  ref: any;
  headerText: string = 'Make sure to leave the Kiosk plugged into the (Power) AC adapter, and ensure to have a reliable network connection';
  descriptions: string[] = [];
  description: string = '';
  index: number = 0;

  currentStateText: string = '...';
  indexText: string = '';
  path: string = '';

  constructor(private language: LanguageService) {
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }

  loadText() {
  }

  ngOnInit(): void {
    this.description = this.language.getTextElement('loading_menu');
  }

  public static open(modalService: NgbModal) {
    const ref = modalService.open(UpgradeKioskAppComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    ref.componentInstance.modalRef = ref;

    return ref;
  }

  // clear() {
  //   this.ngOnDestroy();

  //   if (this.modalRef && this.modalRef != null) {
  //     this.modalRef.close();
  //   }
  // }

  // public setCurrentStateMessage(message: string) {
  //   this.currentStateText = message;
  // }

  // public setIndexText(message: string) {
  //   this.indexText = message;
  // }
}
