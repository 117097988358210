import { Injectable } from '@angular/core';
import { Interface } from 'readline';
import { from, Observable, Subject } from 'rxjs';
import { DatabaseHandler } from 'src/app/DatabaseHandler';
import { Printer } from 'src/app/models/Printer';
import { CartService } from 'src/app/services/cart.service';
import _, { forEach } from 'lodash';
import { EatOptionsService } from 'src/app/services/eat-options.service';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { ItemV2 } from '../models/item';
import { loggingData, LogService } from 'src/app/services/log.service';
import { LoyaltyService } from './loyalty.service';
import { UserService } from './user.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { CurrencyService } from './currency.service';
import { CurrencyPipe } from '../CustomTools/currency.pipe';
import { OLOService } from 'src/app/services/olo.service';
import { receipt } from '../models/receipt';
import { ParBrinkService } from './par-brink.service';
import { CommonCalculations, CommonFunctions } from './common';
import { PaymentService } from './payment.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ChatService } from './chat.service';
import { DatabaseService } from './database.service';
import { ReceiptGroup } from '../models/ReceiptGroup';
import { OracleService } from './oracle.service';
import { LoyaltyType } from '../models/loyaltyModal';
import { LanguageService } from './language.service';
import { OrderListReq } from '../models/OrderListReq';
@Injectable({
  providedIn: 'root',
})
export class PrinterService {
  public kitchenDataCreated: Subject<boolean> = new Subject<boolean>();

  constructor(
    private cartService: CartService,
    private optionsService: EatOptionsService,
    private logger: LogService,
    private loyaltyService: LoyaltyService,
    private userService: UserService,
    private currencyService: CurrencyService,
    private olo: OLOService,
    private oracle: OracleService,
    private datePipe: DatePipe,
    private parBrinkService: ParBrinkService,
    private payment: PaymentService,
    private databaseService: DatabaseService,
    private chatService: ChatService,
    private http: HttpClient,
    private language: LanguageService,
    private paymentService: PaymentService
  ) {}
  pipe = new DatePipe('en-US');
  dpipe = new DecimalPipe('en-US');
  cpipe = new CurrencyPipe(this.currencyService);

  currentPrinter = 'kitchen';
  errorOnKitchen = false;
  errorOnCustomer = false;

  //FLOW --> MAP PRINTERS TO KITCHENS, ITEMS TO KITCHENS. ONLY PRINT ITEMS IN KITCHEN ON RESPECTIVE PRINTER. FOR EVERY 'PRINT' CHECK IF ITEM IS IN THE PRINTER'S KITCHEN.

  //query returns Printer Name, Printer ID (staff module ID), Kitchen Name, Kitchen ID MAPS PRINTERS TO KITCHENS
  // query = `
  // select
  // sp.Name as "Printer Name",
  // sp.StaffModuleID as "Printer ID",
  // k.Name as "Kitchen Name",
  // k.KitchenID as "Kitchen ID"
  // from StaffModuleMasters sp
  // JOIN KitchenMasters k
  // ON
  // sp.StaffModuleID = k.StaffModuleID
  // GROUP BY k.Name`

  //query returns KitchenID, Item Name, Item ID MAPS ITEMS TO KITCHENS
  // query = `
  // select k.KitchenID,
  // i.Name,
  // i.ItemID from
  // Kitchen_Item_Mapping K
  // JOIN Items i
  // WHERE
  // i.ItemID = k.ItemID`

  // printMappingMaster!: Map<String, String[]>;

  // // printerQueue: any[] = [];
  // mapPrinters() {
  //   this.printMappingMaster = new Map<String, String[]>();
  //   let query = `
  //   select
  //   sp.StaffModuleID as "SID",
  //   k.KitchenID as "KID"
  //   from StaffModuleMasters sp
  //   JOIN KitchenMasters k
  //   ON
  //   sp.StaffModuleID = k.StaffModuleID
  //   GROUP BY k.KitchenID`;

  //   const exec = (tx: string, result: any) => {
  //     var data = [];
  //     for (const row of result.rows) {
  //       let key = this.printMappingMaster.get(row.SID);
  //       if (key) {
  //         key.push(row.KID);
  //       } else {
  //         this.printMappingMaster.set(row.SID, [row.KID]);
  //       }
  //     }
  //   };
  //   var callbackFail = (tx: string, result: any) => {
  //     //console.log(result);
  //     var log = new loggingData(
  //       'Printer Error',
  //       'Encountered Error Fetching PrintMasters',
  //       `Printer Error`,
  //       `Encountered Error Fetching PrintMasters: ${result}`,
  //       true
  //     );
  //     this.logger.sendLogToServer(log);
  //   };
  //   DatabaseHandler.executeSqlStatement(query, [], exec, callbackFail);
  // }

  resetState() {
    this.currentPrinter = 'kitchen';
    this.errorOnKitchen = false;
    this.errorOnCustomer = false;
  }

  // prettier-ignore
  // DAVE - STAR PRINTER CHANGES
  public sendOrderDataToSTARPrinter(orderListReq: OrderListReq) {
    // console.log("sendOrderDataToSTARPrinter() is called");
    // console.log('orderListReq: ' + JSON.stringify(orderListReq));
    
    const headers = {
      "Content-Type": "application/json",
      "Accept": "*/*",
      "Access-Control-Allow-Origin": "*",
    }

    var body: any = {}
    let orderType = "";
    if (orderListReq.OrderMaster[0]) {
      // set order type
      if (orderListReq.OrderMaster[0].OrderTypeID == '1') {
        orderType = "Dine In";
      }
      else if (orderListReq.OrderMaster[0].OrderTypeID == '2') {
        orderType = "Take Out";
      }
      // console.log("orderListReq.OrderMaster[0]: ", orderListReq.OrderMaster[0]);
      body.printerIPAddress = GeneralSetting.getSTARPrinterSP700IPAddress();
      body.createdDate = orderListReq.OrderMaster[0].CreatedDate;
      body.customerName = orderListReq.OrderMaster[0].CustomerName;
      body.orderType = orderType;
      body.tokenCount = orderListReq.OrderMaster[0].TokenCount;
      body.tableID = orderListReq.OrderMaster[0].TableID
      body.orderInvoiceNo = orderListReq.OrderMaster[0].OrderInvoiceNo;
      body.orderItemMaster = orderListReq.OrderMaster[0].OrderItemMaster;
      body.kitchenID = orderListReq.OrderMaster[0].KitchenID;
      body.kotFooterMessage = GeneralSetting.getKOTFooterMessage();
      body.kitchenName = GeneralSetting.getSTARPrinterSP700KitchenName();
    }
    else {
      // console.log("orderListReq.OrderMaster[0] doesn't exist.")
    }

    return this.http.post(environment.NodeUrl + 'sendOrderDataToSTARPrinter', body, { responseType: 'text' })
      .toPromise()
      .then((response) => {
        return true;
      })
      .catch((exception) => {
        return false;
      })
  }

  doSTARTestPrint(printer: any) {
    const body = {
      printerName: printer.Name,
      printerIPAddress: printer.IpAddress,
      printerData: 'test print',
      printDate: this.datePipe.transform(new Date(), 'MMM dd YYYY hh:mm a'),
    };

    return this.http.post(environment.NodeUrl + 'doSTARTestPrint', body, {
      responseType: 'text' as 'json',
    });
  }

  doSTARHealthPrint(printer: any) {
    const body = {
      printerName: printer.Name,
      printerIPAddress: printer.IpAddress,
      printerData: 'test print',
      printDate: this.datePipe.transform(new Date(), 'MMM dd YYYY hh:mm a'),
    };

    return this.http.post(environment.NodeUrl + 'doSTARHealthPrint', body);
  }

  createPrinterConnection(body: any) {
    // console.log("createPrinterConnection() is called");

    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
    };

    return this.http.post(
      environment.NodeUrl + 'createPrinterConnection',
      body,
      { responseType: 'text' as 'json' }
    );
  }
  // getKitchenPrinters(refresh: boolean): Observable<Printer[]> {
  //   return from(
  //     new Promise<Printer[]>(async (resolve: any, reject: any) => {
  //       localStorage.setItem('kitchenPrinters', JSON.stringify(null));
  //       var allPrinters: Printer[] = [];
  //       var kitchenPrinters = await this.getOnlyKitchenPrinters(allPrinters)
  //       var invoicePrinters = await this.getOnlyInvoicePrinters(allPrinters)

  //       // allPrinters = allPrinters.concat(invoicePrinters)
  //       // const tryPrinters = (tx: string, result: any) => {
  //       // var returnedPrinterTests = 0
  //       // var totalPrinterTests = result.rows.length;
  //       window.onmessage = (event: any) => {
  //         if (event.data.messageType == 'PrinterTestReturn') {
  //           // only messages that of the type we want

  //           var currentPrinters: Printer[] = event.data.data;
  //           // currentPrinters = _.uniqBy(currentPrinters, 'IpAddress');
  //           for (let printer of currentPrinters) {
  //             if (printer.isConnected == 'true') {
  //               this.doUpdateDeviceStatus(printer.StaffModuleID, '1');
  //             } else {
  //               this.doUpdateDeviceStatus(printer.StaffModuleID, '2');
  //             }
  //           }
  //           localStorage.setItem(
  //             'kitchenPrinters',
  //             JSON.stringify(currentPrinters)
  //           ); // add to printers
  //           resolve(currentPrinters);
  //         }
  //       };

  //       // for (const printer of result.rows) {

  //       // }
  //       let data = [];

  //       // for (let row of _.uniqBy(result.rows, 'IpAddress') as any[]) {
  //       for (let row of allPrinters as any[]) {
  //         row['KID'] = this.printMappingMaster.get(row.StaffModuleID) || ['0',];
  //         data.push(row);
  //       }

  //       let messageType = '';
  //       if (refresh) {
  //         messageType = 'refreshPrinters';
  //       } else {
  //         messageType = 'getPrinters';
  //       }

  //       let message = {
  //         messageType: messageType,
  //         data: data,
  //       };
  //       window.top!.postMessage(message, '*');
  //       // };
  //       // var callback = tryPrinters;
  //       // var callbackFail = function (tx: string, result: any) {
  //       //   //console.log(result);
  //       // };
  //       // DatabaseHandler.executeSqlStatement(
  //       //   sqlString,
  //       //   [],
  //       //   callback,
  //       //   callbackFail
  //       // );
  //     })
  //   );
  // }

  // getOnlyKitchenPrinters(printers: Printer[]): Promise<Printer[]> {
  //   return new Promise<Printer[]>((resolve: any, reject: any) => {
  //     let branchId = GeneralSetting.getBranchId();
  //     let companyId = GeneralSetting.getCompanyId();
  //     // only take IsKitchenPrinter variable for kitchenprinters
  //     var sqlString: string = `
  //       select km.Name as Name,
  //       sm.IpAddress as IpAddress,
  //       sm.IsKitchenPrinter as IsKitchenPrinter,
  //       pm.BrandName as BrandName,
  //       pm.ModelName as ModelName,
  //       km.KitchenID,
  //       km.KDSIPAddress ,
  //       sm.StaffModuleID,
  //       sm.PrinterStatus from StaffModuleMasters as sm inner join KitchenMasters as
  //       km on km.StaffModuleID=sm.StaffModuleID inner join PrinterMasters as
  //       pm on pm.PrinterID = sm.PrinterID where sm.ModuleID = '4' and km.IsActive = 'True'
  //       and sm.CompanyID = '${companyId}' and sm.IsKitchenPrinter = 'True' and pm.IsActive = 'True'
  //     `;

  //     const tryPrinters = (tx: string, result: any) => {
  //       let data = Array.from(result.rows);
  //       data.forEach((element: any) => {
  //         element.IsInvoicePrinter = 'False'
  //         printers.push(element)
  //       });
  //       resolve(result.rows)
  //     };
  //     var callback = tryPrinters;
  //     var callbackFail = function (tx: string, result: any) {
  //       //console.log(result);
  //       resolve("")
  //     };
  //     DatabaseHandler.executeSqlStatement(
  //       sqlString,
  //       [],
  //       callback,
  //       callbackFail
  //     );
  //   })
  //     ;
  // }

  // getOnlyInvoicePrinters(printers: Printer[]): Promise<Printer[]> {
  //   return new Promise<Printer[]>(async (resolve: any, reject: any) => {

  //     var staffModuleId = await this.getInvoicePrinterIDbyDeviceID()

  //     var sqlString: string = `
  //       select sm.Name as Name,
  //       sm.IpAddress as IpAddress,
  //       pm.BrandName as BrandName,
  //       pm.ModelName as ModelName,
  //       sm.StaffModuleID,
  //       sm.PrinterStatus
  //       from StaffModuleMasters as sm
  //       inner join PrinterMasters as pm on pm.PrinterID = sm.PrinterID
  //         where sm.ModuleID = '4'  and sm.IsInvoicePrinter = 'True' and pm.IsActive = 'True'and sm.StaffModuleID = '${staffModuleId}'
  //     `;

  //     // SELECT *
  //     //   FROM StaffModuleMasters
  //     // WHERE IsKitchenPrinter = 'True' OR IsInvoicePrinter = 'True'`

  //     const tryPrinters = (tx: string, result: any) => {
  //       let data = Array.from(result.rows);
  //       data.forEach((element: any) => {
  //         element.IsKitchenPrinter = 'False'
  //         element.IsInvoicePrinter = 'True'
  //         printers.push(element)
  //       });
  //       resolve(result.rows)
  //     };
  //     var callback = tryPrinters;
  //     var callbackFail = function (tx: string, result: any) {
  //       //console.log(result);
  //       resolve("")
  //     };
  //     DatabaseHandler.executeSqlStatement(
  //       sqlString,
  //       [],
  //       callback,
  //       callbackFail
  //     );
  //   })
  //     ;
  // }

  // getInvoicePrinterIDbyDeviceID(): Promise<string> {
  //   return new Promise<string>((resolve: any, reject: any) => {

  //     var sqlString: string = `
  //       SELECT InvoicePrinterID
  //       FROM StaffModuleMasters
  //       WHERE DeviceID = '${GeneralSetting.getSerialNo()}'
  //     `;

  //     const tryPrinters = (tx: string, result: any) => {
  //       if (result.rows.length > 0) {
  //         resolve(result.rows[0].InvoicePrinterID);
  //       } else {
  //         resolve("");
  //       }
  //     };
  //     var callback = tryPrinters;
  //     var callbackFail = function (tx: string, result: any) {
  //       //console.log(result);
  //       resolve("")
  //     };
  //     DatabaseHandler.executeSqlStatement(
  //       sqlString,
  //       [],
  //       callback,
  //       callbackFail
  //     );
  //   })
  //     ;
  // }

  PrintKitchen(data: any) {
    var totalPrinters = 1;
    var resolvedPrinters = 0;
    return from(
      new Promise<string>(async (resolve: any, reject: any) => {
        //console.log(`set window.onmessage kitchen`);

        let currentOption = this.optionsService.getCurrentEatOption();
        let itemsClone = data;
        console.log('items ordered(kitchen):', itemsClone); // added for easy debugging

        await this.cartService.calculateAll();
        await this.isLoyaltyEnable();

        let orderTotal = this.cartService.total;
        let tax;
        if (GeneralSetting.getParBrinkIntegrationID() != '') {
          tax = this.parBrinkService.Tax;
          orderTotal = this.parBrinkService.Total;
        } else {
          tax = this.cartService.tax;
          orderTotal = this.cartService.total;
        }
        let thirdPartyOrderId = '';
        let discount =
          this.loyaltyService.selectedLoyaltyReward == ''
            ? this.cartService.discount
            : 0;
        let subTotal = this.cartService.subtotal;
        let otherCharges = 0;
        if (this.olo.isOLO) {
          if (this.olo.validationRes && this.olo.validationRes.data) {
            orderTotal = this.olo.validationRes.data.total;
            subTotal = this.olo.validationRes.data.subtotal;
            tax = this.olo.validationRes.data.tax;
            if (this.olo.validationRes.basketData.discount > 0) {
              discount = this.olo.validationRes.basketData.discount;
            }
            if (this.olo.validationRes.basketData?.totalfees) {
              otherCharges = this.olo.validationRes.basketData.totalfees;
            }
          }
        }
        if (this.oracle.isOracle && this.oracle.calculationResponse) {
          if (this.oracle.isOracleMicross) {
            subTotal =
              Number(this.oracle.calculationResponse.TotalsSubTotal) +
              this.cartService.reward +
              this.cartService.discount;
          } else {
            if (
              !isNaN(
                Number(
                  this.oracle.calculationResponse.TotalsSubTotalWithDiscount
                )
              )
            ) {
              subTotal = Number(
                this.oracle.calculationResponse.TotalsSubTotalWithDiscount
              );
            }
          }
          if (!isNaN(Number(this.oracle.calculationResponse.TotalsTaxTotals))) {
            tax = Number(this.oracle.calculationResponse.TotalsTaxTotals);
          }
          if (!isNaN(Number(this.oracle.calculationResponse.TotalsTotalDue))) {
            orderTotal = Number(this.oracle.calculationResponse.TotalsTotalDue);
          }
        }

        /* Calculating total if Upcharge card used */
        if (this.paymentService.UpChargeAmountCard !== 0) {
          orderTotal = orderTotal + this.paymentService.UpChargeAmountCard;
        }

        if (
          GeneralSetting.getThirdPartyOrderId() &&
          GeneralSetting.getThirdPartyOrderId().length > 0
        ) {
          thirdPartyOrderId = GeneralSetting.getThirdPartyOrderId();
        }

        let cardType = '';
        let cardNumber = '';

        if (GeneralSetting.getPaymentMethod() === '17') {
          cardType =
            GeneralSetting.getPaymentResponse() != ''
              ? JSON.parse(GeneralSetting.getPaymentResponse()).PAYMENT_MEDIA
              : '';
          cardNumber =
            GeneralSetting.getPaymentResponse() != ''
              ? JSON.parse(GeneralSetting.getPaymentResponse()).ACCT_NUM
              : '';
        } else if (GeneralSetting.getPaymentMethod() === '13') {
          if (GeneralSetting.getPaymentResponse() != '') {
            let paymentResponse = JSON.parse(
              GeneralSetting.getPaymentResponse()
            );

            let cardType = '';
            let cardNumber = '';

            if (
              paymentResponse.payment.cardTransaction &&
              typeof paymentResponse.payment.cardTransaction !== 'undefined'
            ) {
              cardType = paymentResponse.payment.cardTransaction.cardType;
              cardNumber =
                paymentResponse.payment.cardTransaction.first6 +
                '******' +
                paymentResponse.payment.cardTransaction.last4;
            }
          }
        }
        let dataToSend = {
          printData: {
            ServiceFee: otherCharges,
            items: JSON.stringify(itemsClone),
            orderType: GeneralSetting.getLocationPreference(),
            TicketNum: Number(GeneralSetting.getOrderInvoiceNo()),
            orderNumber: Number(GeneralSetting.getOrderInvoiceNo()),
            phoneNumber: GeneralSetting.getCustomerPhone(),
            date: this.datePipe.transform(new Date(), 'MMM dd YYYY hh:mm a'),
            footerText: GeneralSetting.getKOTFooterMessage(),
            footerTextMerchant:
              GeneralSetting.getFooterTextForMerchantInKiosk(),
            customerName:
              GeneralSetting.getCustomerName() != ''
                ? GeneralSetting.getCustomerName()
                : this.language.getTextElement('txt_guest'),
            prefix: GeneralSetting.getOrderPrefix(),
            subtotal: subTotal,
            total: orderTotal,
            shippingFee: this.cartService.shippingFee,
            tax: tax,
            promo: this.cartService.promotionAmount,
            promotionPercent: this.cartService.promotionPercent,
            promotionIsTotal: this.cartService.promotionIsTotal,
            tip: this.cartService.tip,
            discount: discount,
            address1:
              GeneralSetting.getBranchAddress1() !== ''
                ? GeneralSetting.getBranchAddress1().trim()
                : null,
            address2:
              GeneralSetting.getBranchAddress2() !== ''
                ? GeneralSetting.getBranchAddress2().trim()
                : null,
            branchContactNo: GeneralSetting.getBranchContactNo().trim(),
            branchTagline: GeneralSetting.getBranchTagLine().trim(),
            paymentType: GeneralSetting.getPaymentType(),
            thankYouMessage: GeneralSetting.getThankYouMessage(),
            branchName: GeneralSetting.getBranchName(),
            PaymentDisplayName: sessionStorage.getItem('PaymentDisplayName')!,
            invoiceNotodb: GeneralSetting.getInvoiceNoToDb(),
            OrderNo: GeneralSetting.getOrderInvoiceNo(),
            customerPhone:
              GeneralSetting.getCustomerPhone() != ''
                ? GeneralSetting.getCustomerPhone()
                : GeneralSetting.getCustomerLoginMobile(),
            customerEmail: GeneralSetting.getCustomerEmail(),
            headerText: GeneralSetting.getHeaderTextForMerchantInKiosk(),
            together:
              GeneralSetting.getIsPrintTokenAndCustomerReceiptTogether(),
            isTableTent: currentOption.KioskTableTent!,
            tableTentNumber: GeneralSetting.getTableTentNumber(),
            reward: this.cartService.reward,
            rewardRedeem: this.rewardRedeem,
            rewardRedeemDiscountAmount: this.rewardRedeemDiscountAmount,
            rewardBottomTextEarnPoint: this.rewardBottomTextEarnPoint,
            rewardBottomTextVisitCount: this.rewardBottomTextVisitCount,
            rewardBottomTextRedeemPoints: this.rewardBottomTextRedeemPoints,
            rewardBottomTextTotalPoints: this.rewardBottomTextTotalPoints,
            ShowModifierGroupName: GeneralSetting.getShowModifierGroupName(),
            CommaSeparatedModifiers:
              GeneralSetting.getCommaSeparatedModifiers(),
            copyType: 'CUSTOMER COPY',
            thirdPartyOrderId: thirdPartyOrderId,
            doNotPrintOrderToken: GeneralSetting.getDonotPrintOrderToken(),
            IsConcessionaire:
              GeneralSetting.getIsConcessionaire().toLowerCase() == 'true',
            CPrintConOrderItemsKiosk:
              GeneralSetting.getCPrintConOrderItemsKiosk(),
            concessionaireTotal:
              GeneralSetting.getIsConcessionaire().toLowerCase() == 'true'
                ? this.getConcessionaireTotal(
                    itemsClone,
                    tax,
                    this.cartService.tip,
                    discount,
                    orderTotal,
                    subTotal
                  )
                : [],
            isRemovePricingAndPayments:
              GeneralSetting.getIsRemovePricingAndPayments(),
            currencySymbol: GeneralSetting.getCurrencySymbol(),
            cardType: cardType,
            cardNumber: cardNumber,
            taxType: GeneralSetting.getTaxType(),
            isTaxHidden: GeneralSetting.getHideItemTaxPrice() == 'True',
            showNegativeModifiers: GeneralSetting.getShowNegativeModifiers(),
            showDefaultModifiers: GeneralSetting.getShowDefaultModifiers(),
            IsUpChargeCard: this.paymentService.IsUpChargeCard,
            IsPercentageCard: this.paymentService.IsPercentageCard,
            PercentageOrAmounCard: this.paymentService.PercentageOrAmounCard,
            UpChargeAmountCard: this.paymentService.UpChargeAmountCard,
            isParbrink: GeneralSetting.getParBrinkIntegrationID() != '',
          },
        };

        const dataLabels = {
          dateLabel: this.language.getTextElement('lbl_date') + ' ',
          phoneLabel: this.language.getTextElement('txt_phone_s') + ' ',
          specialNotesLabel: this.language.getTextElement('lbl_special_notes') + ' ',
          customerNameLabel: this.language.getTextElement('txt_customer_name') + ' ',
          tableTentLabel: this.language.getTextElement('table_tent_label') + ' ',
          orderTokenLabel: this.language.getTextElement('order_token_label') + ' ',
          orderNumberLabel: this.language.getTextElement('lbl_order_no') + ': ',
          itemLabel: this.language.getTextElement('item').toLocaleUpperCase(),
        };

        for (
          let index = 0;
          index < this.databaseService.starKitchenPrinters.length;
          index++
        ) {
          const printer = this.databaseService.starKitchenPrinters[index];
          
          const body = {
            printData: dataToSend,
            printerData: printer,
            printerLabels: dataLabels,
          };

          //console.log('starprinter', body);
          this.chatService.sendMessage('StarPrinter', body);

          // this.createPrinterConnection(body).subscribe((response: any) => {
          //   console.log("response: ", response);
          //   Snackbar.show(response, 3000);
          // }, (error) => {
          //   console.log("error: ", error);
          //   Snackbar.show(error, 3000);
          // });
        }

        let message = {
          messageType: 'sendToPrinters',
          data: dataToSend,
        };
        
        window.top!.postMessage(message, '*');

        window.onmessage = (event: any) => {
          //console.log(`PrintKitchen Recieved message: ${JSON.stringify(event.data)}`);
          if (event.data.messageType == 'KitchenPrinterReturn') {
            // only messages that of the type we want
            // resolvedPrinters++;
            if (event.data.result == 'success') {
              this.kitchenDataCreated.next(true);
              this.doUpdateDeviceStatus(event.data.printer.StaffModuleID, '1');
              let printer = JSON.parse(event.data.printer);
              var log = new loggingData(
                `Choice of Receipt`,
                `${
                  printer.IsInvoicePrinter == 'True' ? 'Merchant Copy' : 'KOT'
                }`,
                `Printing`,
                `
                Invoice Number  : ${
                  event.data.printData
                    ? event.data.printData.invoiceNotodb ?? ''
                    : ''
                }
                Payment Type : ${
                  event.data.printData
                    ? event.data.printData.PaymentDisplayName ?? ''
                    : ''
                }
                Type : ${
                  printer.IsInvoicePrinter == 'True' ? 'Merchant Copy' : 'KOT'
                }
                Printer IP : ${printer.IpAddress ?? ''}
                Printer : ${printer.BrandName ?? ''}
                Printer Model : ${printer.ModelName ?? ''}
                Print Status : ${'Receipt Printed Successfully'}`,
                false
              );
              this.logger.sendLogToServer(log);
            } else if (event.data.result == 'failure') {
              this.kitchenDataCreated.next(true);
              this.doUpdateDeviceStatus(event.data.printer.StaffModuleID, '2');

              let printer = JSON.parse(event.data.printer);
              var log = new loggingData(
                `Epson printer failed to Print at branch ${GeneralSetting.getBranchName()}`,
                'Fail to Print',
                `Fail to Print`,
                `Error: 3 attempted completed: ${
                  event.data.printData
                    ? event.data.printData.invoiceNotodb ?? ''
                    : ''
                }

                Invoice Number  : ${
                  event.data.printData
                    ? event.data.printData.invoiceNotodb ?? ''
                    : ''
                }
                Payment Type : ${
                  event.data.printData
                    ? event.data.printData.PaymentDisplayName ?? ''
                    : ''
                }
                Type : ${
                  printer.IsInvoicePrinter == 'True' ? 'Merchant Copy' : 'KOT'
                }
                Printer IP : ${printer.IpAddress ?? ''}
                Printer : ${printer.BrandName ?? ''}
                Printer Model : ${printer.ModelName ?? ''}
                BASE DELAY : 10000
                reason : ${event.data.printerState ?? ''}
`,
                false,
                true
              );
              this.logger.sendLogToServer(log);
            } else if (event.data.result == 'retry') {
              let printer = JSON.parse(event.data.printer);
              this.doUpdateDeviceStatus(printer.StaffModuleID, '2');
              var log = new loggingData(
                `Print`,
                `Print Invoice :${
                  event.data.printData
                    ? event.data.printData.invoiceNotodb ?? ''
                    : ''
                } - ${
                  printer.IsInvoicePrinter == 'True' ? 'Merchant Copy' : 'KOT'
                } Attempt :  ${event.data.retryCount}`,
                `${printer.BrandName ?? ''} Printer`,
                `
                Invoice Number  : ${
                  event.data.printData
                    ? event.data.printData.invoiceNotodb ?? ''
                    : ''
                }
                Payment Type : ${
                  event.data.printData
                    ? event.data.printData.PaymentDisplayName ?? ''
                    : ''
                }
                Type : ${
                  printer.IsInvoicePrinter == 'True' ? 'Merchant Copy' : 'KOT'
                }
                Printer IP : ${printer.IpAddress ?? ''}
                Printer : ${printer.BrandName ?? ''}
                Printer Model : ${printer.ModelName ?? ''}
                BASE DELAY : 10000
                reason : ${event.data.printerState ?? ''}
`,
                false
              );
              this.logger.sendLogToServer(log);
            }
          } else if (event.data.messageType == 'KitchenPrinterCount') {
            if (event.data.data == 0) {
              //console.log(`PrintKitchen RESOLVE: failure`);
              this.currentPrinter = 'customer';
              this.errorOnKitchen = true;
              // resolve('failure'); // fail if no printers are avalible
            }
          } else if (event.data.messageType == 'sendToPrinters') {
            // resolve('success');
          } else if (event.data.messageType == 'PrintDataCreatedKitchen') {
            // resolve(true);
            this.kitchenDataCreated.next(true);
          }
        };
      })
    );
  }

  //To send any other data besides customer receipt to Kiosk printer
  KioskDataPrint(payload: any) {
    return from(
      new Promise<string>((resolve: any, reject: any) => {
        //console.log(`set window.onmessage customer`);
        window.onmessage = (event: any) => {
          //console.log(`PrintCustomer Recieved message: ${JSON.stringify(event.data)}`);
          if (event.data.messageType == 'CustomerPrinterReturn') {
            // only messages that of the type we want
            if (event.data.result == 'success') {
              resolve('success');
            } else if (event.data.result == 'failure') {
              this.errorOnCustomer = true;
              resolve('failure');
            }
          } else if (event.data.messageType == 'Print') {
            resolve('success');
          }
        };

        let data = {
          baudRate: GeneralSetting.getBaudRate(),
          payload: payload,
          universal: true,
          isRemovePricing: GeneralSetting.getIsRemovePricingAndPayments(),
          currencySymbol: GeneralSetting.getCurrencySymbol(),
        };
        let message = {
          messageType: 'Print',
          data: data,
        };
        // console.log('Sent to the printer');
        window.top!.postMessage(message, '*');
      })
    );
  }

  private getSubtotal(items: ItemV2[]) {
    let subtotal = 0.0;

    items.forEach((element: any) => {
      subtotal +=
        Number(this.getItemPrice(element, true)) * Number(element.Quantity);
    });

    return subtotal;
  }

  getItemPrice(item: ItemV2, withDiscount: boolean): number {
    let itemCost = CommonFunctions.getItemPrice(item as ItemV2); // basePrice
    if (
      (!this.cartService.promotionIsTotal || item.DiscountAmount > 0) &&
      withDiscount
    ) {
      if (this.cartService.promotionPercent != 0.0) {
        itemCost =
          itemCost *
          (1 - this.cartService.promotionPercent / Number(item.Quantity) / 100);
      }

      this.cartService.didDiscount = true;
    }

    return itemCost;
  }

  private getItemTotalTaxV2(
    items: ItemV2[],
    discount: number,
    subTotal: number
  ) {
    let totalTax = 0;
    // this.totalTaxTypes = []; Tax getting blank because of this initialization
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      const itemTotal = this.getItemPrice(item, true) * Number(item.Quantity);

      const itemLevelDiscountAmount =
        item.DiscountAmount > 0 ? item.DiscountAmount : 0.0;

      let discountOnItem = 0;

      if ((itemTotal - itemLevelDiscountAmount) * discount != 0) {
        discountOnItem =
          ((itemTotal - itemLevelDiscountAmount) * discount) / subTotal;
      }

      const itemTotalWithDiscount =
        itemTotal - itemLevelDiscountAmount - discountOnItem;

      if (item.taxDetail.length > 0) {
        for (let j = 0; j < item.taxDetail.length; j++) {
          if (item.totalPrice) {
            const itemTax =
              (Number(item.taxDetail[j].Percentage) * itemTotalWithDiscount) /
              100;

            totalTax += itemTax;
          }
        }
      }
    }

    return totalTax;
  }

  private getConcessionaireTotal(
    items: ItemV2[],
    totalTax: number,
    totalTip: number,
    discount: number,
    orderTotal: number,
    subTotal: number
  ) {
    var allConcessionaire: any[] = [];
    var concessionaireTotal: any[] = [];

    let conDetail = this.cartService.conDetailSub.value;

    items.forEach(function (x) {
      allConcessionaire.push({
        ConcessionaireId: x.ConcessionaireId,
        ConcessionaireName: x.ConcessionaireName,
        ConcessionaireColorCode: x.ConcessionaireColorCode,
      });
    });

    let uniqueCon = [
      ...new Map(
        allConcessionaire.map((item) => [item['ConcessionaireId'], item])
      ).values(),
    ];

    let totalBeforeTip = orderTotal - totalTip;

    for (let con of uniqueCon) {
      let conDiscount = 0;

      let conItems = items.filter(
        (x) => x.ConcessionaireId == con.ConcessionaireId
      );
      //Get Data from ConcessionaireOrderSplitBill
      let concessionaireOrderSplitBill = this.cartService.orderSplitBill.find(
        (x) => x.BranchSectionConcessionaireID == con.ConcessionaireId
      );

      let conDiscountDetails = conDetail.filter(
        (x) => x.concessionaireId == con.ConcessionaireId
      );

      if (conDiscountDetails.length > 0) {
        conDiscount = Number(conDiscountDetails[0].Discount);
      }

      let subtotalPer = 0;

      if (conItems.length > 0) {
        let conTax = CommonCalculations.getItemTax(
          conItems,
          0,
          subTotal,
          discount,
          conDetail
        ).totalTax;

        let conSubTotal = CommonFunctions.getSubTotalByItems(conItems);

        //Item wise discount [Start]
        let discounItemWise = 0.0;

        conItems.forEach((element: any) => {
          discounItemWise +=
            element.DiscountAmount && element.DiscountAmount != 0
              ? element.DiscountAmount
              : 0;
        });
        //Item wise discount [End]
        conSubTotal = conSubTotal - discounItemWise;

        subtotalPer = (conSubTotal / subTotal) * 100;

        let conTip = (subtotalPer * totalTip) / 100;

        //this.getItemTotalTaxV2(conItems, discount, subTotal);

        let storeTotal = conSubTotal - conDiscount + conTax;

        concessionaireTotal.push({
          ConcessionaireId: con.ConcessionaireId,
          ConcessionaireTotal: CommonFunctions.roundDigit(
            conSubTotal,
            2
          ).toFixed(2),
          Tax: CommonFunctions.roundDigit(conTax, 2).toFixed(2),
          Tip: CommonFunctions.roundDigit(conTip, 2).toFixed(2),
          Discount: CommonFunctions.roundDigit(conDiscount, 2).toFixed(2),
          StoreTotal: CommonFunctions.roundDigit(storeTotal, 2).toFixed(2),
          ThirdPartOrderId: concessionaireOrderSplitBill?.SplitInvoiceNo,
        });
      }
    }

    return concessionaireTotal;
  }

  // checkpoint
  PrintCustomer(data: any, printCus: boolean) {
    console.log('PrintCustomer', data);

    return from(
      new Promise<string>(async (resolve: any, reject: any) => {
        //console.log(`set window.onmessage customer`);
        window.onmessage = (event: any) => {
          //console.log(`PrintCustomer Recieved message: ${JSON.stringify(event.data)}`);
          if (event.data.messageType == 'CustomerPrinterReturn') {
            // only messages that of the type we want
            if (event.data.result == 'success') {
              resolve('success');
            } else if (event.data.result == 'failure') {
              this.errorOnCustomer = true;
              resolve('failure');
            }
          } else if (event.data.messageType == 'Print') {
            resolve('success');
          }
        };
        let currentOption = this.optionsService.getCurrentEatOption();
        let itemsClone = this.cartService.getCartItems();
        console.log('items ordered(customer):', itemsClone); // added for easy debugging

        /**
         * receipt item group consolidate items by receipt group in cart @nilesh
         */
        var cartReceiptGroup: ReceiptGroup[] = [];
        if (GeneralSetting.getShowReceiptItemGroupsonReceipts() == 'True') {
          let receiptGroups = await this.databaseService.getReceiptItemGroups();
          if (receiptGroups && receiptGroups.length > 0) {
            receiptGroups.push({
              name: 'Other',
              ReceiptItemGroupID: '0',
              total: '0',
            });

            for (let i = 0; i < receiptGroups.length; i++) {
              const receiptGroup = receiptGroups[i];
              var receiptItems = itemsClone.filter(
                (x) => x.ReceiptItemGroupID == receiptGroup.ReceiptItemGroupID
              );
              if (receiptItems && receiptItems.length > 0) {
                var totalAmount = 0;
                for (let j = 0; j < receiptItems.length; j++) {
                  totalAmount += Number(receiptItems[j].totalPrice);
                }
                receiptGroup.total = totalAmount.toString();
                cartReceiptGroup.push(receiptGroup);
              }
            }
          }
        }

        /*************************************** */

        await this.cartService.calculateAll();
        await this.isLoyaltyEnable();

        let orderTotal = this.cartService.total;
        let tax;

        if (GeneralSetting.getParBrinkIntegrationID() != '') {
          tax = this.parBrinkService.Tax;
          orderTotal = this.parBrinkService.Total;
        } else {
          tax = this.cartService.tax;
          orderTotal = this.cartService.total;
        }
        let thirdPartyOrderId = '';
        let discount =
          this.loyaltyService.selectedLoyaltyReward == ''
            ? this.cartService.discount
            : 0;
        let subTotal = this.cartService.subtotal;
        let otherCharges = 0;
        if (this.olo.isOLO) {
          if (this.olo.validationRes && this.olo.validationRes.data) {
            orderTotal = this.olo.validationRes.data.total;
            tax = this.olo.validationRes.data.tax;
            thirdPartyOrderId = GeneralSetting.getThirdPartyOrderId();
            subTotal = this.olo.validationRes.data.subtotal;
            if (this.olo.validationRes.basketData.discount > 0) {
              discount = this.olo.validationRes.basketData.discount;
            }
            if (this.olo.validationRes.basketData?.totalfees) {
              otherCharges = this.olo.validationRes.basketData.totalfees;
            }
          }
        }

        if (this.oracle.isOracle && this.oracle.calculationResponse) {
          if (this.oracle.isOracleMicross) {
            subTotal =
              Number(this.oracle.calculationResponse.TotalsSubTotal) +
              this.cartService.discount +
              this.cartService.reward;
            if (
              !isNaN(Number(this.oracle.calculationResponse.TotalsTaxTotals))
            ) {
              tax = Number(this.oracle.calculationResponse.TotalsTaxTotals);
            }
            if (
              !isNaN(Number(this.oracle.calculationResponse.TotalsTotalDue))
            ) {
              orderTotal = Number(
                this.oracle.calculationResponse.TotalsTotalDue
              );
            }
          } else {
            subTotal = Number(
              this.oracle.calculationResponse.TotalsSubTotalWithDiscount
            );
            if (
              !isNaN(Number(this.oracle.calculationResponse.TotalsTaxTotals))
            ) {
              tax = Number(this.oracle.calculationResponse.TotalsTaxTotals);
            }
            if (
              !isNaN(Number(this.oracle.calculationResponse.TotalsTotalDue))
            ) {
              orderTotal = Number(
                this.oracle.calculationResponse.TotalsTotalDue
              );
            }
          }
        }

        /* Calculating total if Upcharge card used */
        if (this.paymentService.UpChargeAmountCard !== 0) {
          orderTotal = orderTotal + this.paymentService.UpChargeAmountCard;
        }

        if (
          GeneralSetting.getThirdPartyOrderId() &&
          GeneralSetting.getThirdPartyOrderId().length > 0
        ) {
          thirdPartyOrderId = GeneralSetting.getThirdPartyOrderId();
        }

        let cardType = '';
        let cardNumber = '';
        let cardNetwork = '';

        if (GeneralSetting.getPaymentMethod() === '17') {
          cardType =
            GeneralSetting.getPaymentResponse() != ''
              ? JSON.parse(GeneralSetting.getPaymentResponse()).PAYMENT_MEDIA
              : '';
          cardNumber =
            GeneralSetting.getPaymentResponse() != ''
              ? JSON.parse(GeneralSetting.getPaymentResponse()).ACCT_NUM
              : '';
        } else if (GeneralSetting.getPaymentMethod() === '13') {
          if (GeneralSetting.getPaymentResponse() != '') {
            let paymentResponse = JSON.parse(
              GeneralSetting.getPaymentResponse()
            );

            if (
              paymentResponse.payment.cardTransaction &&
              typeof paymentResponse.payment.cardTransaction !== 'undefined'
            ) {
              cardNumber =
                paymentResponse.payment.cardTransaction.first6 +
                '******' +
                paymentResponse.payment.cardTransaction.last4;
              cardNetwork = paymentResponse.payment.cardTransaction.cardType;

              cardType = paymentResponse.payment.cardTransaction.cardType;
            }
          }
        }

        // let beepboop=0
        let data: receipt = {
          text: {
            vat: this.language.getTextElement('vat'),
            tax: this.language.getTextElement('lbl_taxes_and_fees') + ':',
            itemTax: this.language.getTextElement('txt_item_tax'),
            specialNotesLabel:
              this.language.getTextElement('lbl_special_notes'),
            customerNameLabel:
              this.language.getTextElement('txt_customer_name'),
            nonCashPlaceholder: this.language.getTextElement(
              'non_cash_fees_replace'
            ),
            nonCash: this.language.getTextElement('non_cash_fees'),
            tableTentLabel: this.language.getTextElement('table_tent_label'),
            orderTokenLabel: this.language.getTextElement('order_token_label'),
            phoneLabel: this.language.getTextElement('txt_phone_s'),
            tipLabel: this.language.getTextElement('lbl_tip'),
            serviceFeeLabel: this.language.getTextElement('lbl_service_fee'),
            shippingFeeLabel: this.language.getTextElement('lbl_surcharge'),
            tentText: this.language.getTextElement('printing_tent'),
            tpInvoiceNumber: this.language.getTextElement('tp_invoice_number'),
            refund: this.language.getTextElement('refund'),
            customerName: this.language.getTextElement('txt_customer_name'),
            guest: this.language.getTextElement('txt_guest'),
            dateLabel: this.language.getTextElement('lbl_date'),
            paymentTypeLabel: this.language.getTextElement('lbl_payment_type'),
            orderTypeLabel: this.language.getTextElement('txt_order_type'),
            invoiceNumber: this.language.getTextElement('invoice_number'),
            discount: this.language.getTextElement('lbl_discount'),
            purchase: this.language.getTextElement('purchase'),
            qty: this.language.getTextElement('txt_qty').toLocaleUpperCase(),
            item: this.language.getTextElement('item').toLocaleUpperCase(),
            each: this.language.getTextElement('text_each').toLocaleUpperCase(),
            total: this.language
              .getTextElement('lbl_total')
              .toLocaleUpperCase(),
            subtotalLabel: this.language.getTextElement('lbl_subtotal'),
            storeTotalLabel: this.language.getTextElement('lbl_store_total'),
            grandTotalLabel: this.language.getTextElement('lbl_grand_total'),
            totalLabel: this.language.getTextElement('text_total'),
            rewardsLabel: this.language.getTextElement('rewards'),
          },
          baudRate: GeneralSetting.getBaudRate(),
          subtotal: subTotal,
          total: orderTotal + (this.olo.orderRes.surcharge ?? 0),
          shippingFee: this.olo.orderRes.surcharge,
          tax: tax,
          reward: this.cartService.reward,
          date: this.datePipe.transform(new Date(), 'MMM dd YYYY hh:mm a'),
          promo: this.cartService.promotionAmount,
          promotionPercent: this.cartService.promotionPercent,
          promotionIsTotal: this.cartService.promotionIsTotal,
          tip: this.cartService.tip,
          discount: discount,
          items: JSON.stringify(itemsClone),
          address1:
            GeneralSetting.getBranchAddress1() !== ''
              ? GeneralSetting.getBranchAddress1()!.trim()
              : null,
          address2:
            GeneralSetting.getBranchAddress2() !== ''
              ? GeneralSetting.getBranchAddress2()!.trim()
              : null,
          branchContactNo: GeneralSetting.getBranchContactNo().trim(),
          branchTagline: GeneralSetting.getBranchTagLine().trim(),
          paymentType: GeneralSetting.getPaymentType(),
          orderType: GeneralSetting.getLocationPreference().toUpperCase(),
          thankYouMessage: GeneralSetting.getThankYouMessage(),
          branchName: GeneralSetting.getBranchName(),
          PaymentDisplayName:
            sessionStorage.getItem('PaymentDisplayName') ?? '',
          invoiceNotodb: GeneralSetting.getInvoiceNoToDb(),
          OrderNo: GeneralSetting.getOrderInvoiceNo(),
          customerName:
            GeneralSetting.getCustomerName() != ''
              ? GeneralSetting.getCustomerName()
              : this.language.getTextElement('txt_guest'),
          customerPhone:
            GeneralSetting.getCustomerPhone() != ''
              ? GeneralSetting.getCustomerPhone()
              : GeneralSetting.getCustomerLoginMobile(),
          customerEmail: GeneralSetting.getCustomerEmail(),
          headerText: this.language.getCustomMapValues(
            'menu_setting_receipt__customize_message_header_text'
          ),
          footerText: this.language.getCustomMapValues(
            'menu_setting_receipt__customize_message_footer_text'
          ),
          prefix: GeneralSetting.getOrderPrefix(),
          together: GeneralSetting.getIsPrintTokenAndCustomerReceiptTogether(),
          isTableTent: currentOption.KioskTableTent!,
          tableTentNumber: GeneralSetting.getTableTentNumber(),
          rewardRedeem: this.rewardRedeem,
          rewardRedeemDiscountAmount: this.rewardRedeemDiscountAmount,
          rewardBottomTextEarnPoint: this.rewardBottomTextEarnPoint,
          rewardBottomTextVisitCount: this.rewardBottomTextVisitCount,
          rewardBottomTextRedeemPoints: this.rewardBottomTextRedeemPoints,
          rewardBottomTextTotalPoints: this.rewardBottomTextTotalPoints,
          ShowModifierGroupName: GeneralSetting.getShowModifierGroupName(),
          CommaSeparatedModifiers: GeneralSetting.getCommaSeparatedModifiers(),
          copyType: this.language.getTextElement('customer_copy'),
          shouldPrintCustomerPrint: printCus,
          CreatedDate: new Date().toLocaleString('en-US'),
          isRefund: false,
          refundedAmount: '0.00',
          doNotPrintOrderToken: GeneralSetting.getDonotPrintOrderToken(),
          thirdPartyOrderId: thirdPartyOrderId,
          IsConcessionaire:
            GeneralSetting.getIsConcessionaire().toLowerCase() == 'true',
          CPrintConOrderItemsKiosk:
            GeneralSetting.getCPrintConOrderItemsKiosk(),
          concessionaireTotal:
            GeneralSetting.getIsConcessionaire().toLowerCase() == 'true'
              ? this.getConcessionaireTotal(
                  itemsClone,
                  tax,
                  this.cartService.tip,
                  discount,
                  orderTotal,
                  subTotal
                )
              : [],
          isRemovePricingAndPayments:
            GeneralSetting.getIsRemovePricingAndPayments(),
          footerTextMerchant: GeneralSetting.getFooterTextForMerchantInKiosk(),
          BarcodeOrQRcodeData: GeneralSetting.getBarcodeOrQRCodeData(),
          BarcodeOrQRcodeDescription:
            GeneralSetting.getBarcodeOrQRCodeDescription(),
          BarcodeOrQRcodeonReceipt:
            GeneralSetting.getBarcodeOrQRCodeOnReceipt(),
          ShowSurveyLinkOnReceipt:
            GeneralSetting.getShowSurveyLinkOnReceipt() == 'True',
          StoreID: GeneralSetting.getStoreID(),
          SurveyLink: CommonFunctions.surveyCodeReplacer(
            CommonFunctions.surveyCodeScrambler(this.payment.getRandomOrderNo())
          ),
          SurveyCode: CommonFunctions.surveyCodeScrambler(
            this.payment.getRandomOrderNo()
          ),
          ServiceFee: otherCharges,
          currencySymbol: GeneralSetting.getCurrencySymbol(),
          cardType: cardType,
          cardNumber: cardNumber,
          ReceiptItemGroups: cartReceiptGroup,
          ShowReceiptItemGroupsonReceipts:
            GeneralSetting.getShowReceiptItemGroupsonReceipts(),
          taxType: GeneralSetting.getTaxType(),
          isTaxHidden: GeneralSetting.getHideItemTaxPrice() == 'True',
          showNegativeModifiers: GeneralSetting.getShowNegativeModifiers(),
          showDefaultModifiers: GeneralSetting.getShowDefaultModifiers(),
          IsUpChargeCard: this.paymentService.IsUpChargeCard,
          IsPercentageCard: this.paymentService.IsPercentageCard,
          PercentageOrAmounCard: this.paymentService.PercentageOrAmounCard,
          UpChargeAmountCard: this.paymentService.UpChargeAmountCard,
          isParbrink: GeneralSetting.getParBrinkIntegrationID() != '',
        };

        let message = {
          messageType: 'Print',
          data: data,
        };

        console.log('message', message);

        window.top!.postMessage(message, '*');
      })
    );
  }

  isLoyalty: boolean = false;
  pointsEarned: number = 0;
  visitCount: number = 0;
  totalLoyaltyPoints: number = 0;
  rewardBottomTextEarnPoint = '';
  rewardBottomTextVisitCount = '';
  rewardBottomTextRedeemPoints = '';
  rewardBottomTextTotalPoints = '';
  rewardRedeem = '';
  rewardRedeemDiscountAmount = '';

  isLoyaltyEnable(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      var discount = this.cartService.discount;
      this.rewardBottomTextEarnPoint = '';
      this.rewardBottomTextVisitCount = '';
      this.rewardBottomTextRedeemPoints = '';
      this.rewardBottomTextTotalPoints = '';
      this.rewardRedeem = '';
      this.rewardRedeemDiscountAmount = '';
      if (this.userService.isUserLoggedIn) {
        this.isLoyalty = this.loyaltyService.isLoyalty;
        if (
          this.isLoyalty &&
          this.loyaltyService.loyaltyType == LoyaltyType.Referrizer
        ) {
          if (this.loyaltyService.customerVisitRes.message != '') {
            resolve(true);
          } else {
            this.loyaltyService.getCustomerVisit()!.then((data) => {
              if (data != undefined && data != null) {
                this.pointsEarned = data.data.points;

                const reward = this.loyaltyService.loyaltyRewards.find(
                  (x) => x.id == this.loyaltyService.selectedLoyaltyReward
                );

                if (reward != undefined) {
                  this.totalLoyaltyPoints =
                    this.userService.referrizerLoyaltyUser.loyaltyPointsCount +
                    this.pointsEarned -
                    Number(reward.points);
                  this.rewardRedeem = 'Redeemed[' + reward.points + ' points]';
                  if (
                    reward.value != null &&
                    reward.value != '' &&
                    reward.value != '0'
                  ) {
                    this.rewardRedeemDiscountAmount =
                      '' +
                      this.cpipe.transform(
                        this.dpipe.transform(discount, '1.2-2')
                      );
                  } else {
                    this.rewardRedeemDiscountAmount = '';
                  }
                } else {
                  this.totalLoyaltyPoints =
                    this.userService.referrizerLoyaltyUser.loyaltyPointsCount +
                    this.pointsEarned;
                }

                this.visitCount =
                  this.userService.referrizerLoyaltyUser.visitsCount + 1;

                let number = this.userService.referrizerLoyaltyUser.phone;
                number = number.replace('(\\d{3})(\\d{3})(\\d+)', '($1) $2-$3');

                this.rewardBottomTextEarnPoint =
                  'You have earned ' +
                  this.pointsEarned +
                  ' points this visit.';
                this.rewardBottomTextVisitCount =
                  'Your total visit count ' + this.visitCount + '.';

                this.rewardBottomTextRedeemPoints =
                  this.rewardRedeemDiscountAmount != '' && reward
                    ? 'You redeemed ' + reward.points + ' points.'
                    : '';
                this.rewardBottomTextTotalPoints =
                  'Your reward points are ' + this.totalLoyaltyPoints;
                resolve(true);
              } else {
                resolve(true);
              }
            });
          }
        } else {
          resolve(true);
        }
      } else {
        resolve(true);
      }
    });
  }

  async doUpdateDeviceStatus(staffModuleId: String, status: String) {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json; charset=UTF-8');
    myHeaders.append('CompanyID', GeneralSetting.getCompanyId());
    myHeaders.append('BranchID', GeneralSetting.getBranchId());
    myHeaders.append('DeviceID', GeneralSetting.getSerialNo());

    let currentTime = this.getFormattedDate();

    let map = [
      {
        StaffModuleID: staffModuleId,
        StatusId: status,
        StatusDate: currentTime,
      },
    ];

    var raw = JSON.stringify(map);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    var response = await fetch(
      'https://staging.grubbrr.com/api/DeviceStatusUpdateSK',
      requestOptions as any
    );
    // .then(response => response.text())
    // .then(result => //console.log(result))
    // .catch(error => //console.log('error', error));

    if (!response.ok) {
      //console.log(response);
      switch (response.status) {
        case 401:
          // Utils.sendLog("Device Status Update Failed", "Device Status Update Failed", "Response Log", "\nError:401 Handle unauthorized")
          break;
        case 400:
          // Utils.sendLog("Device Status Update Failed", "Device Status Update Failed", "Response Log", "\nError:400 Bad Request")
          break;
        case 500:
          // Utils.sendLog("Device Status Update Failed", "Device Status Update Failed", "Response Log", "\nError:500 Internal Server Error")
          break;
        default:
          // Utils.sendLog("Device Status Update Failed", "Device Status Update Failed", "Response Log", "\nError:Error occurred")
          break;
      }
    }
  }

  getFormattedDate() {
    return new Date().toLocaleString('en-US');
  }

  getItemsNoLoop(items: ItemV2[]): any {
    let itemsClone: ItemV2[] = [];
    let index = 0;
    for (const item of items) {
      // itemsClone[index] = {};
      let itemClone = new ItemV2();
      itemClone.Price = item.Price;
      itemClone.Price = this.cartService.getItemPrice(item, false).toString();
      itemClone.ItemName = item.Name;
      itemClone.Quantity = item.Quantity;
      itemClone.DiscountAmount = item.DiscountAmount;
      itemClone.DiscountID = item.DiscountID;
      itemClone.DiscountPer = item.DiscountPer;
      itemClone.DiscountTypeID = item.DiscountTypeID;
      // itemClone.height = item.height
      itemClone.Variations = item.Variations;
      itemClone.isSelected = item.isSelected;
      itemClone.Modifiers = item.Modifiers;
      // itemClone.IsPremium = item.IsPremium
      itemClone.Kitchens = item.Kitchens;
      itemClone.specialRequest = item.specialRequest;
      itemClone.specialRequest = item.specialRequest;
      if (item.flattenedMods) {
        let flattnedModIndex = 0;
        itemsClone[index].flattenedMods = [];
        for (const mod of item.flattenedMods) {
          itemClone.flattenedMods[flattnedModIndex] = {};
          let flattenedClone = itemClone.flattenedMods[flattnedModIndex];
          flattenedClone.count = mod.count;
          flattenedClone.height = mod.height;
          flattenedClone.GroupName = mod.GroupName;
          flattenedClone.Name = mod.Name;
          flattenedClone.Category = mod.Category;
          flattenedClone.DisplayName = mod.DisplayName;
          flattenedClone.ExtraPrice = mod.ExtraPrice;
          // flattenedClone.Price = mod.Price
          flattenedClone.selectedIngrediants = mod.selectedIngrediants;
          flattenedClone.isSelected = mod.isSelected;
          flattenedClone.isNegativeModifier = mod.isNegativeModifier;
          flattenedClone.associatedVariations = mod.associatedVariations;
          flattenedClone.IsPremium = mod.IsPremium;
          if (mod.selectedIngrediants) {
            let selectedIngrediantIndex = 0;
            for (const selected of mod.selectedIngrediants) {
              flattenedClone.selectedIngrediants[selectedIngrediantIndex] = {};
              let selectedClone =
                flattenedClone.selectedIngrediants[selectedIngrediantIndex];
              selectedClone.Name = selected.Name;
              selectedClone.ExtraPrice = selected.ExtraPrice;
              // selectedClone.Price = selected.Price
              selectedClone.height = selected.height;
              selectedClone.DisplayName = selected.DisplayName;
              selectedClone.Countable = selected.Countable;
              selectedClone.Quantity = selected.Quantity;
              selectedClone.isSelected = selected.isSelected;
              selectedClone.isNegativeModifier = selected.isNegativeModifier;
              selectedClone.IsPremium = selected.IsPremium;
              selectedClone.IngredientID = selected.IngredientID;
              selectedClone.ModifierID = selected.ModifierID;

              selectedIngrediantIndex++;
            }
          }

          flattnedModIndex++;
        }
      }

      index++;
      itemsClone.push(itemClone);
    }
    return itemsClone;
  }
}
